import userAPI from '../../api/user'
import Vue from 'vue'
import VueCookie from 'vue-cookie'
 
Vue.use(VueCookie);
const state = {
    authenticated: false,
    created: false,
    updated: false,
    removed: false,
    user: {
        name: ''
    },
    _token: '',
    users: []
}
const getters = {

}

const actions = {
    async loginUser({ commit }, credentials) {
        const data = await userAPI.login(credentials)
         if(data.data.thumbnail_path === 200) {
            data.data.role = 'admin'
            commit('SET_USER_AUTHENTICATED', true)
            commit('SET_USER', data.data)
            VueCookie.set('user_token', data.data.token)
            return data.data;
        }   else {
            commit('SET_USER_AUTHENTICATED', false)
            return data.data;
        }
    },
    async getUsers({ commit }) {
        const { data: data } = await userAPI.list()
        commit('USERS', data)
    },
    async createUser({ commit }, data) {
        const { status } = await userAPI.create(data)
        if(status === 200) {
            commit('CREATED_USER', true)
            return true
        } else {
            commit('CREATED_USER', false)
            return false
        }
    },
    async editUser({ commit }, data) {
        const { status } = await userAPI.update(data)
        if (status === 200) {
            commit('UPDATED_USER', true)
            return true
        } else {
            commit('UPDATED_USER', false)
            return false
        }
    },
    async getUser({commit}, {...searchParams}) {
        const { status, data: data } = await userAPI.find(searchParams)
        if (status === 200) {
            commit('USER', data)
        }
    },
    async validateAuthorizationU({commit}) {
        const authorization = VueCookie.get('user_token');
        if(!authorization || authorization === 'undefined' || authorization == null)   return false;
        const {status , data: user} = await userAPI.validToken(authorization)
        if (status === 200) {
            commit("SET_USER_AUTHENTICATED", true);
            commit("SET_USER", user);
            return true;
        }
    },
    async logoutUser({commit}) {
        commit('SET_USER_AUTHENTICATED', false)
        VueCookie.delete('user_token') 
     },
    async removedUser({commit}, id) {
        const { status } = await userAPI.remove(id)
        if (status) commit('REMOVED_USER', true)
    }
}
const mutations = {
    CREATED_USER(state, val) {
        state.created = val;
    },
    UPDATED_USER(state, val) {
        state.updated = val;
    },
    REMOVED_USER(state, val) {
        state.removed = val;
    },
    SET_USER_AUTHENTICATED(state ,val) {
        state.authenticated = val;
    },
    SET_USER(state, val) {
        state.user = val;
    },
    USER(state, val) {
      state.user = val
    },
    USERS(state, val) {
        state.users = val
    }
}
export default {
    actions,
    mutations,
    getters,
    state
}