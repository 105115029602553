import ApiClient from '../source/Api'

const auth =  {
    list({limit = 50, name, id }) {
        return ApiClient().get(`/loteries/list?limit=${limit}&name=${name}&id=${id}`)
    },
    create(params) {
        return ApiClient().post(`/loteries/create`, params) 
    },

    fetchTop() {
        return ApiClient().get(`/loteries/top`)  
    },
    listLess() {
        return ApiClient().get(`/loteries/list-less`)   
    },

    rangeLast(searchParams) { 
        return ApiClient().get(`/analysis/range?from=${searchParams.from}&to=${searchParams.to}&name=${searchParams.name}`)  
    },
    rangeLastDetail(searchParams) {
        return ApiClient().get(`/analysis/range-detail?from=${searchParams.from}&to=${searchParams.to}&name=${searchParams.name}`) 
    }, 
    listDetail(){
        return ApiClient().get(`/loteries/list-lotto`)

    },

}

export default auth;