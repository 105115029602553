import lottoAPI from '../../api/lotto'
   
const state = {
    type:1,
    loteries: [],
    lotto: {},
    more: [],
    less: [],
    pairless: [],
    pairmore: [],
    items_analysis: [],
    listLess: [],
    cartillaTopLength: 69,
    mainLoteries: [],
    cartillaBottomLength: 26,
    lastFlorida: {},
    lastPowerball: {},
    lastTinka: {},
    lastMegamillion: {},
    moredivide: [],
    lessdivide: [],
    pairmoredivide: [],
    loterie: {}
}
const actions = {
    async fetchLoteries({ commit }, { ...searchParams }) { 
        const { status, data: loteries } = await lottoAPI.list(searchParams); 
        if(status === 200) commit("ADD_LOTERIES", loteries); 
    },  
    async getLoteriesMain({commit}, { ...searchParams }) { 
        const { status, data: data } = await lottoAPI.listDetail(searchParams); 
        let _ = [];
        data.loteries.forEach(async (element) => {
            element.loterie = await actions.loterieChange(element.name, data)
            _.push(element)
        }); 
        // console.log("data: ", data);
        if(status === 200) commit("LOTERIES", data.loteries); 
    },

    async create_loteries({commit}, data) {
        const { status, data: lotto } = await lottoAPI.create(data);
        if(status === 200) commit("ADD_LOTTER", lotto) 
    } ,
    async top({commit}) {
        const { status, data: data } = await lottoAPI.fetchTop();
        if(status === 200) commit("FETCH_TOP", data) 
    },

     
   
    async loterieChange(name, data) {
        let __;
        switch ( name) {
            case 'powerballs':
                __ = data.lastPowerballs
                break;
            case 'megamillions':
                __ = data.lastMegamillions

                break;
            case 'floridas':
                __ = data.lastFlorida

                break;
            case 'tinkas':
                __ = data.lastTinkas

                break;
            default:
                break;
        }
        return __
    }

    
}

const getters = {
 
}

const mutations = {
    LAST_FLORIDA(state, val) { 
        state.lastFlorida = val
    },
    LAST_TINKA(state, val) { 
      state.lastTinka = val
    }, 
    LAST_POWERBALL(state, val) { 
        state.lastPowerball = val
    },
    LAST_MEGAMILLION(state, val) { 
        state.lastMegamillion = val
    },
    CARTILLA_TOP_LENGTH(state, val) {
        state.cartillaTopLength = val
    },
    CARTILLA_BOTTOM_LENGTH(state, val) {
        state.cartillaBottomLength = val 
    },
    ADD_LOTERIES(state, loteries) {
        if (loteries) { 
            state.loteries = [] 
            state.loteries.push(...loteries); 
        }
    },
    LOTERIES(state, val) {
        if (val) { 
            state.mainLoteries = [] 
            state.mainLoteries.push(...val); 
        }
    },
    LOTERIE(state, val) { 
        state.loterie = val
    },
    FETCH_TOP(state, data) {
       state.items_analysis = [] 
        state.items_analysis.push(...data);  
    },
   
    ADD_LOTTER(state, val) {
        state.lotto = val;
    },
    LESS_FREQUENT(state, data) {
        state.less = []
        state.less.push(...data) 
    },
    MORE_FREQUENT(state, data) {
        state.more = [] 
        state.more.push(...data)
    }, 
    PAIR_MORE_FREQUENT(state, data) {
        state.pairmore = []  
        state.pairmore.push(...data)
    },
    PAIR_LESS_FREQUENT(state, data) {
        state.pairless = [] 
        state.pairless.push(...data)
    }, 
    CHANGE_SELECTED(state, val) { 
        state.type = val
    },

   
    
}

export default { 
    state,
    actions,
    getters,
    mutations
}
