<template>
    <div class="dashboard-nav">
      <div class="dashboard-nav-inner">
          <ul data-submenu-title="Main">
              <li class="">
                  <router-link  :to="{  tag: 'a', path: '/admin' }" >
                      <i class="far fa-user"></i>
                      Usuarios
                  </router-link>
              </li>
              <li class="">
                  <router-link  :to="{  tag: 'a', path: '/admin/loterias' }" >
                      <i class="far fa-calendar"></i>
                      Loterias
                  </router-link>
              </li>
              <li class="">
                  <router-link  :to="{  tag: 'a',    path: '/admin/results' }" >
                      <i class="fas fa-poll"></i>
                      Resultados
                  </router-link>
              </li>
              <li class="">
                  <router-link  :to="{  tag: 'a', name:'winners-list', path: '/admin/winners' }" >
                      <i class="fas fa-trophy"></i>
                      Ganadores
                  </router-link>
              </li>
              <li>
                  <router-link :to="{  tag: 'a', name:'list-costumer',  path: '/admin/soporte/costumer' }">
                      <i class="fad fa-clipboard-user"></i>
                       Clientes
                  </router-link>
              </li>
              <li class="">
                  <router-link  :to="{  tag: 'a', name:'tickets-list',  path: '/admin/tickets' }" >
                      <i class="fas fa-question-square"></i>
                      Tickets
                  </router-link>
              </li>

              <li class="">
                  <router-link  :to="{  tag: 'a', name:'soporte',  path: '/admin/soporte' }" >
                      <i class="fas fa-envelope-open-text"></i>
                        Soporte
                  </router-link>
              </li>
              <li class="">
                  <router-link  :to="{  tag: 'a', name:'questions-list',  path: '/admin/questions' }" >
                      <i class="fas fa-question-square"></i>
                      Preguntas Frecuentes
                  </router-link>
              </li>
          </ul>
          <ul data-submenu-title="Salir?">
              <li>
                  <a href="#" @click='logout'>
                      <i class="sl sl-icon-power"></i> Salir
                  </a>
              </li>
          </ul>
      </div>
    </div>
</template>

<script>

import { mapActions } from 'vuex'

export default {

    methods: {
        ...mapActions({
            logoutUser: 'logoutUser'       
        }),
        logout() {
            this.logoutUser();
            this.$router.push("/login")

        }
    }
}
</script>

<style>

</style>