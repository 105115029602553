import promotionAPI from '../../api/promotion'
   
const state = {
    paquetes: [],  
}
const actions = {   
      
    async listPromotion({commit}, {...searchParams}) {   
        const { status, data: data } = await promotionAPI.listPromotion(searchParams);  
        if(status === 200) 

            commit("LIST_PROMOTION", data.listPromotion) ;    
    } 
}

const getters = {
 
}

const mutations = {
  
     
    LIST_PROMOTION(state, data) {
        state.paquetes = data;  
    }, 
     
}

export default { 
    state,
    actions,
    getters,
    mutations
}
