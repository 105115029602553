import resultAPI from '../../../api/admin/result.js'
import lotteryAPI from '../../../api/loterie.js'

const state = {
     created_result: false,
     list_lotteries_admin:[],
     list_results_lottery_admin:[],
     info_lottery_admin: null,
     delete_result:false,
     result:{
         type: 0,
         lottery:{
             image: ''
         },
         detail: [],
         isShow: false,
     },
    detail_remove: [],
    prizes: [],
    updated_result:false,
    list_top: []
}
const actions = {
    async createResultLotteryAdmin({ commit }, { ...searchParams }) {
        const { status } = await resultAPI.create(searchParams)
        if (status === 200) {
            commit('CREATED_USER', true) 
        }
    },  
    async list({ commit }) {
        const {  data: data } = await lotteryAPI.list();
        commit('LIST_LOTTERIES_ADMIN', data)
    },    
    async listResultsLotteryAdmin({ commit },{ ...searchParams }) { 
        const {  data: data } = await resultAPI.list(searchParams);
        commit('LIST_RESULTS_LOTTERY_ADMIN', data.list_results_lottery)
        return data.list_results_lottery;

    },   
    async findInfoLotteryAdmin({ commit },{ ...searchParams }) { 
        const {  data: data } = await resultAPI.one(searchParams);
        commit('INFO_LOTTERY_ADMIN', data.infoLottery)
        return data.infoLottery;

    }, 
    async deleteResultLotteryAdmin({ commit },{ ...searchParams }) { 
        const {  data: data } = await resultAPI.delete(searchParams);
        commit('DELETE_RESULT_LOTTERY_ADMIN', data.ok)
    }, 
    async findResultLotteryAdmin({ commit },{ ...searchParams }) {
        const {  data: data } = await resultAPI.one(searchParams);
        commit('LOTTERIES_RESULT', data)
    }, 
    async updateResultLotteryAdmin({ commit },{ ...searchParams }) { 
        const {  status, data: data } = await resultAPI.update(searchParams);
        if (status === 200) commit('UPDATE_RESULT_LOTTERY_ADMIN', true)
        return data;
    },
    async resultTopOneHundred({ commit }, { ...searchParams }) {
        const {   data: data } = await resultAPI.listTopOneHundred(searchParams)
        commit('RESULT_TOP_ONE_HUNDRED', data)
    },
    addResult({ commit}, {...searchParams}) {
        commit('PRIZES_CREATE', searchParams)
    },
    addResultAdmin({ commit}, {...searchParams}) {
        commit('PRIZES', searchParams)
    },
    deletePrizeLevelCreate({commit}, i) {
        commit('REMOVE_PRIZE_CREATE', i)
    },
    deletePrizeLevel({commit}, item ) {
        commit('DETAIL_REMOVE', item)
        commit('REMOVE_PRIZE', item.id)
    }
}

const getters = {
 
}

const mutations = {

    CREATED_USER(state, val){
        state.created_result = val
    },
    LIST_LOTTERIES_ADMIN(state, data){
        state.list_lotteries_admin = data
    },
    LIST_RESULTS_LOTTERY_ADMIN(state, data){
        state.list_results_lottery_admin = data
    },
    INFO_LOTTERY_ADMIN(state, data){
        state.info_lottery_admin = data 
    },
    DELETE_RESULT_LOTTERY_ADMIN(state, data){
        state.delete_result = data 
    },
    LOTTERIES_RESULT(state, data){
        state.result = data
    },
    UPDATE_RESULT_LOTTERY_ADMIN(state, val){
        state.updated_result = val
        state.detail_remove = []
    },
    PRIZES_LIST(state, val) {
        state.prizes = val
    },
    PRIZES_CREATE(state, val){
        state.prizes.push(val)
    },
    REMOVE_PRIZE_CREATE(state,val){
        state.prizes.splice(val, 1);
    },
    PRIZES(state, val) {
        state.result.detail.push(val)
    },
    REMOVE_PRIZE(state, val) {
        state.result.detail.splice(state.result.detail.findIndex(e => e.id === val),1);

       // state.result.detail.splice(val, 1);
    },
    DETAIL_REMOVE(state, val) {
        state.detail_remove.push(val)
    },
    DETAIL_RESET(state, val) {
        state.detail_remove = val
    },
    RESULT_TOP_ONE_HUNDRED(state, val) {
        state.list_top = val
    }
}

export default { 
    state,
    actions,
    getters,
    mutations
}
