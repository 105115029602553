import ApiClient from '../source/Api'
  
const lottery =  {
  
    findOne(searchParams) { 
        return ApiClient().get(`/lottery/one?id=${searchParams.id}`)
    },
    createClasic(searchParams) { 
        return ApiClient().post(`/lottery/create-clasic`, searchParams)
    },
    edit(searchParams) {
        return ApiClient().post(`/lottery/update/${searchParams.get('id')}/`, searchParams)
    },
    list() {
        return ApiClient().get(`/lottery/list`)
    },
    delete(searchParams){
        return ApiClient().post('/lottery/delete', searchParams)
    },
    list_results({name, id, type, page}){
        return ApiClient().post(`/lottery/results/${name}/${id}?type=${type}&page=${page}`)
    },
    getLotteryResult(searchParams) {
        return ApiClient().get(`/lottery/results/last/${searchParams.name}/${searchParams.id}?type=${searchParams.type}`)
    },
    validNumbers(searchParams) {
        return ApiClient().post(`/lottery/results/valid-numbers?type=${searchParams.type}`, searchParams)
    },
}

export default lottery;