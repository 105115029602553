import penasAPI from '../../api/pena'
import Vue from 'vue'
import VueCookie from 'vue-cookie'
 
Vue.use(VueCookie);

const state = { 
    penas: [],  
    purchases: [],
    penas_list: []
}
const getters = {

}

const actions = { 
    async listPenas({ commit }, {...searchParams }) {
        const { data: data } = await penasAPI.list(searchParams) 
        // console.log("data", data);
 
        commit('PENAS',  data ) 
     },
    async playPena({commit}, {...searchParams}) {
        const { data: data } = await penasAPI.create(searchParams)
        commit('PURCHASES', data) 
        return data;
    },
    async createLines({commit}, {...searchParams}) {
        const { data: data } = await penasAPI.exportFile(searchParams)
        commit('PURCHASES', data)  
        return data;
    },
    separeteArray(x) {
        let z = []
        const LENGTH = 2;  
        for (let i = 0; i < x.length; i += LENGTH) {
            let pedazo = x.slice(i, i + LENGTH);
            z.push(pedazo);
        }
        return z; 
    },
    async listTypePenas({ commit }, {...searchParams }) {
        const { data: data } = await penasAPI.list_penas(searchParams)   
        commit('PENAS_LIST',  data ) 
     },
}

const mutations = {
    PENAS(state, data) {
        state.penas = []
        state.penas =  data   
    },
    PENAS_LIST(state, data) {
        state.penas_list = []
        state.penas_list =  data   
    },
    PURCHASES(state , data) {
        state.purchases = []
        state.purchases.push(data)
    }
  
    
}
export default {
    actions,
    mutations,
    getters,
    state
}