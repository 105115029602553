import Api from '../source/ApiUser'
 
const auth =  { 
    login(credentials) {
      return Api().post(`/users/login`, credentials) 
                  .catch((e) => {
                      if (e.toString().includes("401")) {
                          return {status: 401, error: e}
                      }
                  });
    },
    create(data) {
      return Api().post(`/users/create`, data);
    },
    update(data) {
      return Api().post(`/users/update/${data.id}`, data);
    },
    list() {
        return Api().get(`/users`)
    },
    validToken(token) {
      return Api().post(`/users/valid-token`, { token : token }) 
    },
    remove(id) {
        return Api().post(`/users/delete/${id}`)
    },
    find(searchParams) {
        return Api().get(`/users/one/${searchParams.id}`)
    }
}

export default auth;