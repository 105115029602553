import checkoutAPI from '../../api/checkout' 
import forecastAPI from '../../api/forecast' 
import Vue from 'vue'
import VueCookie from 'vue-cookie' 
 
Vue.use(VueCookie);
 
const state = { 
    listNumbersToggle:[],   
    procartillasNormal:[],
    procartillasLaggards:[],
    loterie:true, 
    suggestionJugadas:[],
    lotteryForecast:{
        info:{
            image:null
        }

    }, 
    
    ballForecast :0, //cantidad principal ejem 69
    extraballForecast: 0, //cantidad secundaria ejem 25
    columnasForecast: 0,   
    numberBallsForecast: 0,
    
    subnameForecast: null,
    descriptionForecast:null,
    nombreForecast:null,
    listColdForecast:[],
    listHotForecast:[],
    pares : [],   
    numbers_frequency:[],
    list_forecast: {},
    analytic: {
        lotteryForecast: {
            info:{
                image:null
            }
        },
        ballForecast: 0 ,
        extraballForecast: 0,
        columnasForecast: 0  ,
        numberBallsForecast: 0,
        subnameForecast: null,
        descriptionForecast: null ,
        nombreForecast: null,
        listColdForecast: [] ,
        listHotForecast: [],
        numbers_frequency: [] ,
        pares: [],
    },
    repechation: {
        lotteryForecast: {
            info:{
                image:null
            }
        },
        ballForecast: 0 ,
        extraballForecast: 0,
        columnasForecast: 0  ,
        numberBallsForecast: 0,
        subnameForecast: null,
        descriptionForecast: null ,
        nombreForecast: null,
        listColdForecast: [] ,
        listHotForecast: [],
        numbers_frequency: [] ,
        pares: [],
    }
    
}
const getters = {  
}

const actions = {  
    async createForecast({ commit }, { ...searchParams }) { 
        const { status  } = await checkoutAPI.createOneCheckout(searchParams)
        if (status === 200)  commit('CREATE_FORECAST', true) 
    },    
    async getLoterieForecast({ commit }, {...searchParams }) {
        const { data: data } = await forecastAPI.getLoterieForecast(searchParams)
        commit('DETAIL_LOTERIE_FORECAST', data)
    },
    async getForecastResult({ commit }, {...searchParams }) {
        const { data: data } = await forecastAPI.getForecastResult(searchParams)
        commit('DETAIL_RESULT_FORECAST', data)
    },
    async getSuggestionForecast({ commit }, {...searchParams }) {  
        // console.log('searchParams: ', searchParams);
        const { data: data } = await forecastAPI.getSuggestionForecast(searchParams)
        commit('DETAIL_SUGGESTION_FORECAST', data.resp)   
    }, 
    async findCicleForecast({ commit }, { ...searchParams }) {
        const { status, data: data } = await forecastAPI.findCicleForecast(searchParams); 
        if (status === 200) {
            console.log('data: ',data);
            commit("APPEARENCES_FORECAST", data)  
        }
    },
    async listForecast({commit}, {...searchParams }) {
        const { status, data: { previous_plays, previous_plays_extra, colds, hots }  } = await forecastAPI.list(searchParams)
        let params = {
            previous_plays_extra: previous_plays_extra,
            previous_plays: previous_plays,
            colds: colds,
            hots: hots
        };
        if (status === 200) {
            commit("LIST_FORECAST", params)
        }
        return params
    },
    async listForecastRange({commit}, {...searchParams}) {
        const { status, data: { previous_plays, previous_plays_extra, colds, hots }  } = await forecastAPI.list_range_forecast(searchParams)
        let params = {
            previous_plays_extra: previous_plays_extra,
            previous_plays: previous_plays,
            colds: colds,
            hots: hots
        };
        if (status === 200) {
            commit("LIST_FORECAST_RANGE", params)
        }
        return params
    }
     
}

const mutations = {  

    CLEAR_LISTNUMBERSTOGGLE(state) {
        state.listNumbersToggle = [];
    }, 
    async RELOAD_CARTILLAS_PRONOSTICO_RESULTADO(state, val) {  
        // console.log('Inserción');
        // state.procartillas.unshift(val);
        state.listNumbersToggle.push(val);
    },
    CREATE_FORECAST(state, val) {
        state.loterie = val
    }, 
    DETAIL_LOTERIE_FORECAST( state, val ){
           
        state.lotteryForecast       =   val;     
        state.ballForecast          =   state.lotteryForecast.info.size;//cantidad principal ejem 69
        state.extraballForecast     =   state.lotteryForecast.info.sub_length; //cantidad secundaria ejem 25
        state.columnasForecast      =   state.lotteryForecast.info.columnCart;   //COLUMNAS DE CARTILLAS
        state.numberBallsForecast   =   state.lotteryForecast.info.selectNumber; 
        state.subnameForecast       =   val.info.sub_name ;
        state.descriptionForecast   =   val.info.description ;
        state.nombreForecast        =   val.info.name ;
        state.listColdForecast      =   val.listCold ;
        state.listHotForecast       =   val.listHot; 
        state.numbers_frequency       =   val.numbers_frequency; 
        state.pares = val.listPar;   
    },
    DETAIL_RESULT_FORECAST(state, val) {
        state.analytic.lotteryForecast       =   val;
        state.analytic.ballForecast          =   state.analytic.lotteryForecast.info.size;//cantidad principal ejem 69
        state.analytic.extraballForecast     =   state.analytic.lotteryForecast.info.sub_length; //cantidad secundaria ejem 25
        state.analytic.columnasForecast      =   state.analytic.lotteryForecast.info.columnCart;   //COLUMNAS DE CARTILLAS
        state.analytic.numberBallsForecast   =   state.analytic.lotteryForecast.info.selectNumber;
        state.analytic.subnameForecast       =   val.info.sub_name ;
        state.analytic.descriptionForecast   =   val.info.description ;
        state.analytic.nombreForecast        =   val.info.name ;
        state.analytic.listColdForecast      =   val.listCold ;
        state.analytic.listHotForecast       =   val.listHot;
        state.analytic.numbers_frequency       =   val.numbers_frequency;
        state.analytic.pares = val.listPar;
    },
    DETAIL_RESULT_FORECAST_REPECHATION(state, val) {
        state.repechation.lotteryForecast       =   val;
        state.repechation.ballForecast          =   state.repechation.lotteryForecast.info.size;//cantidad principal ejem 69
        state.repechation.extraballForecast     =   state.repechation.lotteryForecast.info.sub_length; //cantidad secundaria ejem 25
        state.repechation.columnasForecast      =   state.repechation.lotteryForecast.info.columnCart;   //COLUMNAS DE CARTILLAS
        state.repechation.numberBallsForecast   =   state.repechation.lotteryForecast.info.selectNumber;
        state.repechation.subnameForecast       =   val.info.sub_name ;
        state.repechation.descriptionForecast   =   val.info.description ;
        state.repechation.nombreForecast        =   val.info.name ;
        state.repechation.listColdForecast      =   val.listCold ;
        state.repechation.listHotForecast       =   val.listHot;
        state.repechation.numbers_frequency       =   val.numbers_frequency;
        state.repechation.pares = val.listPar;
    },
    DETAIL_SUGGESTION_FORECAST( state, val ){
        state.suggestionJugadas = val
    },
    LIST_FORECAST(state, val) {
        state.list_forecast = val
    }
  
   
}
export default {
    actions,
    mutations,
    getters,
    state
}