<template>
  <div class="end-footer footer-area">
    <div class="container">
      <div  class="vc_row wpb_row vc_row-fluid vc_custom_1596774091579 vc_column-gap-default ts-row-wide "  data-vc-full-width="true" data-vc-full-width-init="false"  >
        <div class="wpb_column vc_column_container vc_col-sm-12">
          <div class="wpb_wrapper">
            <div class="vc_separator wpb_content_element vc_separator_align_center vc_sep_width_100 vc_sep_pos_align_center vc_separator_no_text" >

              <span class="vc_sep_holder vc_sep_holder_r"><span  style="border-color:#dbe0eb;" class="vc_sep_line"></span></span>
            </div>
            <div class="vc_row wpb_row vc_inner vc_row-fluid vc_custom_1596621074116 vc_column-gap-default vc_row-o-content-middle vc_row-flex">
              <div class="wpb_column vc_column_container vc_col-sm-6">
                <div class="vc_column-inner">
                  <div class="wpb_wrapper">
                    <div class="wpb_text_column wpb_content_element  vc_custom_1598243668036 ts-aligncenter-mobile" >
                      <div class="wpb_wrapper">
                        <p><span style="color: white;">Copyright © 2020 Lotery. Todos los derechos</span></p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="wpb_column vc_column_container vc_col-sm-6">
                <div class="vc_column-inner">
                  <div class="wpb_wrapper">
                    <div class="wpb_text_column wpb_content_element  vc_custom_1598243679899 ts-aligncenter-mobile" >
                      <div class="wpb_wrapper">
                        <p><img loading="lazy" class="size-full wp-image-16780 alignright" src=" " alt="" width="480" height="40" srcset="/uploads/paypal.png " sizes="(max-width: 480px) 100vw, 120px" style=" max-width: 100px;" /></p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
export default {
  name: "Copiright"
}
</script>

<style scoped>

</style>