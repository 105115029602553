<template>
  <div style="width: 100%; height: 100vh; background: #1f2027">
    <div style="display: flex; flex-direction: row; justify-content: space-evenly;padding-top: 10%;">
      <div>
        <img src="/uploads/favicon.png" alt="" style="width: 350px">
      </div>
      <div id="authenticated" style="display: flex; flex-direction: column">
        <h1 for=""> ¿ Olvidaste tu contrasena ?</h1>
        <h4 >Ingresa el Email associado a la cuenta y recibiras un correo en tu cuenta.</h4>
        <form class="login-form cmn-frm">
          <div class="frm-group">
            <input id="email" type="email" class="form-control" v-model="credentials.email" placeholder="Email">
           </div>

          <div class="frm-group margin-top-10" style="text-align: center; display: flex; flex-direction: column">
            <button class="sign-in" @click="sendForgot">Enviar</button>
           </div>
        </form>
        <div class="margin-top-60">
          <label style="text-align: center">Copyright © 2020 Lotery. Todos los derechos</label>
        </div>
<!--        <loading :active.sync="isLoading" :is-full-page="fullPageForgot" />-->
      </div>
    </div>
  </div>
</template>

<script>


import {mapActions} from "vuex";

export default {
  name: 'Forgot',
  data() {
    return {
      credentials: {
        email: ''
      },
      fullPageForgot: false,
    };
  },
  mounted () {


  },
  computed: {

  },
  methods: {
    ...mapActions({
      forgot: 'forgot',

    }),
    async sendForgot() {
      await this.forgot(this.credentials)
    },
  }
}
</script>

<style scoped>
.cmn-frm .frm-group i {
  color: #85878f;
  position: relative;
  bottom: 0 !important;
  left: 0 !important;
}
.toggle {
  background: none;
  border: none;
  color: #337ab7;
  /*display: none;*/
  /*font-size: .9em;*/
  font-weight: 600;
  /*padding: .5em;*/
  position: absolute;
  right: 10px;
  top: 7px;
  z-index: 9;
}
.frm-group {
  position: relative;
  width: 100%;
}
a.account {
  margin-top: 10px;
  text-align: center;
  color: #fdad59;
  text-underline-mode: none;
}
#authenticated {
  width: 420px;
}
#authenticated h1, h2, h4 {
  color: #fdad59;
  text-align: center;
}
#authenticated input {
  height: 56px;
  color: #fdad59;
  border: 2px solid #fdad59 !important;
  border-radius: 0 !important;
  padding-left : 45px;
  -webkit-text-fill-color: #fdad59;
  font-weight: 600;
}
input:-webkit-autofill {
  -webkit-box-shadow:0 0 0 50px white inset; /* Change the color to your own background color */
  -webkit-text-fill-color: #fdad59;
}
input:-webkit-autofill:focus {
  -webkit-box-shadow:0 0 0 50px white inset; /* Change the color to your own background color */
  -webkit-text-fill-color: #fdad59;
  font-family: "Montserrat", "HelveticaNeue", "Helvetica Neue", Helvetica, Arial, sans-serif;
}

#btnToggle:hover {
  border: 0 solid transparent !important;
}
#authenticated .sign-in:hover, .sign-up:hover {
  color: #fdad59 !important;
  background: white !important;
}
#email::placeholder {
  color: #fdad59;
  font-weight: 600;
}
#authenticated .sign-in {
  width : 100%;
  margin-top: 10px;
  border-radius: 0 !important;
  background: #fdad59;
  font-size: 16px;
}
#password::placeholder {
  color: #fdad59;
  font-weight: 600;
}
</style>

