import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

export default {
    components: {
        'loading': Loading
    },
    data() {
        return {
            isLoading: true,
            fullPage: true,
            isLoadingRegister: true,
            isLoadingJAS: true,
            isLoadingRange: false,
            isLoadingForecast: false,
            isLoadingCount: false,
            fullPageCount: false,
            fullPageRange: false,
            fullPageForecast: false,
            fullPageRegister: true,
        }
    },
    mounted() {
        if(this.getFoundPosts === undefined){
            this.isLoading = false
            this.isLoadingRegister = false
        }

    },
    beforeUpdate() {
      // this.isLoading = true
    }
}