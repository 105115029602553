import Api from '../../source/ApiUser'

const auth =  {
    
    create(searchParams) {
        return Api().post(`/loteries/create`, searchParams)
    },
    list({name}) {
        return Api().get(`/loteries/list-result-loterie/${name}`)
    },
    delete(searchParams) {
        return Api().post(`/loteries/delete/`, searchParams)
    }, 
    one({name, lottery_id , id}) {
        return Api().get(`/loteries/one/${name}/${lottery_id}/${id}/`)
    }, 
    update({name, id, resultado}) {
        return Api().post(`/loteries/update/${name}/${id}`, resultado)
    },
    listTopOneHundred(searchParams, limit = 100) {
        return Api().get(`/loteries/top/${searchParams.name}/${searchParams.id}?limit=${limit}`)
    }

} 

export default auth;