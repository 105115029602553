import Api from '../../source/ApiUser'

const auth =  {
    createAnswerSupport(searchParams) {   
        return Api().post(`/supports/create_answer`, searchParams)  
    }, 
    listQuestionSupport() {     
        return Api().get(`/supports/list-questions`) 
    },
    // listOneCheckout(searchParams) {   
    //     return ApiClient().get(`/checkouts/oneList?id=${searchParams.id}&name=${searchParams.name}`)
    // },
    // deleteOneCheckout(id){ 
    //     return ApiClient().delete(`/checkouts/list/${id}`)
    // },
    // deleteOneLotteryCheckout(id){ 
    //     return ApiClient().delete(`/checkouts/listLottery/${id}`)
    // },
    // deleteAllCheckout( ){ 
    //     return ApiClient().delete(`/checkouts/list`)
    // },
} 

export default auth; 