<template>
   <div>
    <div class=" " style=" margin: 20px 200px;">  
      <div style="display: flex;justify-content: space-between; margin-top: 40px;align-items: center;">
          <h2 style="margin-right:20px"> <img style="width: 300px;" :src="loterie.image" alt="">  </h2>
           <div style="display: flex; ">
                 <div class="form-group"> 
                    <label >Desde</label>
                    <input type="date" class="form-control" v-model="date.from"> 
                </div>
               <div class="form-group"> 
                <label >Hasta</label>
                <div class="input-group mb-3" style=" display: flex;">
                <input type="date" class="form-control"  v-model="date.to" placeholder="Recipient's username" aria-label="Recipient's username" aria-describedby="basic-addon2">
                <div class="input-group-append">
                    <button class="btn-send" type="button" @click="rangePrimer">Enviar</button>
                </div>
                </div>
            </div> 
          </div> 
      </div>
       
        <hr>
         <div style= 'display:flex '>
            <div class="containerOpciones"> 
                <div class="opcionesTituloTodo"> 
                    <div><h4 style="color: #294977; font-size: x-large;font-weight: 500;">Analisis</h4></div>
                </div>  
                 <div class="form-group">
                    <button class="btn-send-options-cycle" type="button" @click="cycleSidebarShow">Ciclo</button>
                </div>    
                <div class="form-group">
                    <button class="btn-send-options-moda" type="button" @click="modaSidebarShow"># Moda</button>
                </div>          
                <div class="form-group">
                    <button class="btn-send-options-more" type="button" @click="topSidebarShow"><i class="fas fa-plus"></i> Frec </button>
                </div>
                <div class="form-group">
                    <button class="btn-send-options-less" type="button" @click="lessSidebarShow"><i class="fas fa-minus"></i> Frec </button>
                </div>
                <div class="form-group">
                    <button class="btn-send-options-pair" type="button" @click="morePairSidebarShow">Pares <i class="fas fa-plus"></i> Frec </button>
                </div>  
            </div> 
            <div class="containerCartillas">
            <!-- containerLoterie(): ? 'play-card-little-tinkas': 'play-card-little-default' -->
                <div :style="{width:changeWidth()}" :class="{ punteroNormal: cartillaDetailStyle(index) }" class="play-card-little-default" v-on:click="cartillaDetail(index, item)" v-b-modal.modal-lg ref="btnCartillaDetailModal" :key="'items'+ index" v-for="(item, index) in items">
                    <div class="play-card-header-little">
                        <span class="number-amount"> {{ format_date(item.fecha) }}</span> 
                        <div class="header-btn-area"></div>
                    </div> 
                    <div class="listShow">
                        <div class="listNumeros "  v-for="(i, index2) in cartillaTopLength" :key="'analysis-'+index2">  
                    
                       
                            <i class="fas fa-times desactiveCross" :class="[ pintarPairMore(index, i) ? 'stylePair' :  pintarMore(index, i, 'single') ? 'styleOrange' : pintarLess(index, i, 'single') ? 
                                    'styleBlue' : pintarAnalisis(index, i) ? 'styleGreen' : pintarDefault(index, i) ]"></i> 
                        </div> 
                    </div>  
                <!-- 
                    <div v-if="cartillaBottomLength !=0" style='display: flex;'>
                        <span class="add-more-text">{{loterie.subname}}</span>
                    </div>-->
                    <!--<div class="listShow">
                        <template v-if="cartillaBottomLength !=0" > 
                            <div class="listNumeros" v-for="j in cartillaBottomLength" :key="'sub-tnumber-'+j">
                            <i class="fas fa-times  desactiveCross" :class="{ styleDefault: pintar_bf_analysis(index, j)}"></i> <div style="font-weight: 500;color: rgb(80, 100, 102);font-size:13px"> </div>

                            </div>
                        </template>
                    </div>-->
                </div>
            </div>
        
             
        </div>
    </div>

  </div>
</template>

<script>



import { mapActions, mapState } from 'vuex'
import moment from 'moment';
import $ from 'jquery' 


export default { 
    name: 'AnalysisDetail',
    data(){
        return { 
            info: {}, 
            date: {
                from: '',
                to: ''
            }
        }
    },
    components: {

    }, 
    computed: {
        ...mapState({ 
            datamore: state => state.lotto.more, 
            dataless: state => state.lotto.less, 
            pairmore: state => state.lotto.pairmore, 
            items: state => state.analysis.items,
            cartillaBottomLength: state => state.analysis.cartillaBottomLength,
            cartillaTopLength: state => state.analysis.cartillaTopLength,
            loterie: state => state.lottery.lottery,
            costumer: state => state.costumer.costumer, 
            existeSubscription: state =>state.subscription.existeSubscription
         }) 
    }, 
    async mounted(){ 
      await this.validateOneSubscription(); 
      this.getLotery() 
      this.getLoterie_()
      this.returnHome();  
     },
    methods: {
        returnHome:function(){
          //console.log('this.existeSubscription: ', this.existeSubscription);
            if (! this.existeSubscription ) {
                location.href ='/';
            }
        },
        mostrarCartillaNumero:function( index ){
          if ( this.items[index] == undefined) {
            return true;
          }
        },
        cartillaDetailStyle:function( index ){ 
            if ( this.items[index] == undefined ) { 
                return true;
            }   
        }, 
        cartillaDetail(index, item) {
            // console.log(item);
            console.log(index);
            this.showModalTicket(item);

            this.$root.$emit('bv::show::modal', 'cartillaDetailModal', '#btnCartillaDetailModal')  

            /*if (authorization) { 
                this.showCartilla(item)  
                this.$router.push(`/analisis/${this.$route.params.id}/${this.$route.params.name}/${index}?from=${this.date.from}&to=${this.date.to}`); 
            }*/   
        },
        changeWidth()  {
            if (this.$route.params.name != 'tinkas') {
                return 135 + 'px';
            } else {
                return 115 + 'px';
            }
        },
        modaSidebarShow() { 
            $("#modaSidebarContent").css('display', 'flex')   
            $("#cycleSidebarContent").css('display', 'none')  
            $("#topSidebarContent").css('display', 'none')
            $("#lessSidebarContent").css('display', 'none')
            $("#morePairSidebarContent").css('display', 'none')
        },
        cycleSidebarShow() { 
            $("#cycleSidebarContent").css('display', 'flex')  
            $("#topSidebarContent").css('display', 'none')
            $("#lessSidebarContent").css('display', 'none')
            $("#morePairSidebarContent").css('display', 'none')
            $("#modaSidebarContent").css('display', 'none')   
        },
        topSidebarShow() { 
            $("#cycleSidebarContent").css('display', 'none')  
            $("#topSidebarContent").css('display', 'flex')
            $("#lessSidebarContent").css('display', 'none')
            $("#morePairSidebarContent").css('display', 'none') 
            $("#modaSidebarContent").css('display', 'none')   
        },
        lessSidebarShow() {
            $("#cycleSidebarContent").css('display', 'none')
            $("#morePairSidebarContent").css('display', 'none')
            $("#lessSidebarContent").css('display', 'flex') 
            $("#modaSidebarContent").css('display', 'none')  
            $("#topSidebarContent").css('display', 'none')   
        },
        morePairSidebarShow() {
            $("#cycleSidebarContent").css('display', 'none')   
            $("#topSidebarContent").css('display', 'none')
            $("#lessSidebarContent").css('display', 'none')
            $("#morePairSidebarContent").css('display', 'flex') 
            $("#modaSidebarContent").css('display', 'none')   
        }, 
        format_date(value) {
            if (value) {
                return moment(String(value)).format('YYYY-MM-DD')
            }
        },
       
        ...mapActions({ 
            fetchAnalysis: 'fetchAnalysis',
            rangeAnalysis: 'rangeAnalysis',
            getLoterie: 'getLottery',
            showModalTicket: 'showModalTicket',
            validateOneSubscription: 'validateOneSubscription',
        }),
        async rangePrimer(){
            this.date.name = this.$route.params.name
            await this.rangeAnalysis(this.date)
        }, 
        async getLoterie_() {
            await this.getLoterie( this.$route.params )  
        },
        async getLotery() { 
            this.info = this.$route.params
            //await this.fetchAnalysis(this.$route.params) 
        }, 
        containerLoterie() { 
            return this.$route.params.name == 'tinkas' ? true: false; 
        },
        pintarDefault() {

        },
        addPena(){
            this.items.push({
                numbers : [],
                bExtra  : null 
            });
            this.total = this.items.length * this.price
        }, 
        deletePena(index){ 
            this.items.splice(index,1);
            this.total = this.items.length * this.price
        },
        pintarLess(index, i) {
            for(let j = 0; j < this.items[index].numbers.length; j++){
                if(i == this.items[index].numbers[j]){ 
                    for (let k = 0; k < this.dataless.length; k++) {
                        if (i == this.dataless[k].position) { 
                            return true
                        }
                    } 
                }
            }
        }, 
        pintarMore(index, i, type){  
            if (type == 'single') {
                for(let j = 0; j < this.items[index].numbers.length; j++){
                    if(i == this.items[index].numbers[j]){ 
                        for (let k = 0; k < this.datamore.length; k++) {
                            if (i == this.datamore[k].position) { 
                                return true
                            }
                        } 
                    }
                }
            }   
        },
        pintarPairMore(index, i) {
            for(let j = 0; j < this.items[index].numbers.length; j++){
                if(i == this.items[index].numbers[j]){ 
                    
                    for (let k = 0; k < this.pairmore.length; k++) {
                    let val1 = this.items[index].numbers.includes(this.pairmore[k].val1)
                    let val2 = this.items[index].numbers.includes(this.pairmore[k].val2)
                    let flat = [this.pairmore[k].val1 , this.pairmore[k].val2].includes(i) 
    
                        if (val1) {
                            if (val2) { 
                                if (flat) {
                                    return true
                                } 
                            }
                        } 
                    } 
                }
            }
        },
        pintarAnalisis(index, i) {  
            for(let j = 0; j < this.items[index].numbers.length; j++){
                if(i == this.items[index].numbers[j]){ 
                    return true  
                }
            }
        },
        pintar(index, i){ 
            for(let j = 0; j < this.items[index].numbers.length; j++){
                if(i == this.items[index].numbers[j]){  
                        return true 
                }
            }
        }, 
        pintar_bf_analysis(index, j){
            if(j == this.items[index].bRed){
                return true
            }
        }, 
    }
}
</script>
<style lang="css" scoped>
    .btn-send-options-moda{
        cursor: pointer;
        display: flex;
        width: 287px;
        align-items: center;
        justify-content: center; 
        border-radius: 7px;
        margin: 5px;
        box-shadow: 0px 0px 2px #008000;
        background: #008000;  
        border-color: #008000; 
        padding: 10px;
    }
    .btn-send-options-moda:hover {
        border-color: #008000 !important;
        background: transparent !important;
        border: 1px solid #008000 !important;
        color: #008000 !important;
    }
    .btn-send-options-cycle{
        cursor: pointer;
        display: flex;
        width: 287px;
        align-items: center;
        justify-content: center; 
        border-radius: 7px;
        margin: 5px;
        box-shadow: 0px 0px 2px black;
        padding: 10px;
    }
    .btn-send-options-less{
        cursor: pointer;
        display: flex;
        width: 287px;
        align-items: center;
        justify-content: center; 
        border-radius: 7px;
        margin: 5px;
        background: #027b76; 
        box-shadow: 0px 0px 2px #027b76;
        padding: 10px;
        border-color: #027b76; 
    }
    .btn-send-options-less:hover {
        border-color: #027b76 !important;
        background: transparent !important;
        border: 1px solid #027b76 !important;
        color: #027b76 !important;
    }
    .btn-send-options-more{
        cursor: pointer;
        display: flex;
        width: 287px;
        align-items: center;
        justify-content: center; 
        border-radius: 7px;
        margin: 5px;
        background: #68001c; 
        box-shadow: 0px 0px 2px #68001c;
        border-color: #68001c;  
        padding: 10px;
    }
    .btn-send-options-more:hover {
        border-color: #68001c !important;
        background: transparent !important;
        border: 1px solid #68001c !important;
        color: #68001c !important;
    }
    .btn-send-options-pair{
        cursor: pointer;
        display: flex;
        width: 287px;
        background: #AB853A;
        align-items: center;
        justify-content: center; 
        border-radius: 7px;
        margin: 5px;
        font-weight: 600;
        box-shadow: 0px 0px 2px #AB853A;
        padding: 10px;
        border-color: #AB853A;
    }
    .btn-send-options-pair:hover {
        border-color: #AB853A !important;
        background: transparent !important;
        border: 1px solid #AB853A !important;
        color: #AB853A !important;
    }
    

    .play-card-little-default:hover {
        background: #dfdfdf;
    }
    .btn-send-options:hover {
        background: #294977; 
        color:white
    }
    
    .col {
        width: 100%;
        padding: 12px;
    }
    .row {
        display: flex; 
    }
    .activeCross{
      /* float: left;
      margin-left: inherit;
      margin-top: inherit; */
      color: #506466 !important
    }
    .desactiveCross{
        float: left;
        margin-left: 0px;
        margin-top: -3px;
        color: transparent;
        font-size: 20px;
    }
    .fa {
        display: inline-block;
        font: normal normal normal 14px/1 FontAwesome;
        font-size: inherit;
        text-rendering: auto;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }
    close:before, .fa-times:before {
        content: "\f00d";
    }

    .fa-remove:before, .fa-close:before, .fa-times:before {
    content: "\f00d";
    }
    .b-sidebar-outer {
        z-index: 1071 !important;
    }
    .b-sidebar-outer {
        position: fixed !important;
        top: 0 !important;
        left: 0 !important;
        right: 0 !important;
        height: 0 !important;
        overflow: visible !important;
        z-index: 1035 !important;
    }
    .b-sidebar.b-sidebar-right {
        left: auto !important;
        right: 0 !important;
    }
    .b-sidebar {
        display: flex !important;
        flex-direction: column !important;
        width: 320px !important;
        max-width: 100% !important;
        max-height: 100% !important;
        margin: 0 !important;
        outline: 0 !important;
        -webkit-transform: translateX(0) !important;
        transform: translateX(0) !important;
    }
    .b-sidebar, .b-sidebar-backdrop {
        position: fixed !important;
        top: 0 !important;
        height: 100vh !important;
    }
    .b-sidebar>.b-sidebar-header {
        font-size: 1.5rem !important;
        padding: .5rem 1rem !important;
        display: flex !important;
        flex-direction: row !important;
        flex-grow: 0 !important;
        align-items: center !important;
    }
    
    .containerCartillas .listShow .listNumeros i.stylePair {
        color: #AB853A;
        width: 13px;
        height: 13px; 
    } 
    .containerCartillas .listShow .listNumeros i.styleOrange {
        color: #68001C;
        width: 13px;
        height: 13px; 
    }
    .containerCartillas .listShow .listNumeros i.styleBlue {
        color: #027B76;
        width: 13px;
        height: 13px; 
    } 
    .containerCartillas .listShow .listNumeros i.styleGreen {
        color: #10811a;
        width: 13px;
        height: 13px; 
    }
    .containerCartillas .listShow .listNumeros i.styleDefault {
       /*  color: rgba(51, 55, 69, 0.8);*/
        width: 13px;
        height: 13px; 
    }
    .play-card-little {
        -ms-flex: 0 0 10%;
        flex: 0 0 10%; 
        position: relative;
        padding: 0 2px;
        cursor: pointer;
    }
    .play-card-little-default {  
        cursor: pointer;
        box-shadow: 0px 0px 5px black; 
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: row;
        flex-wrap: wrap;
        background: white;
        height: fit-content;
        margin: 10px;
        border-radius: 7px;
        padding-bottom: 10px;
        padding-top: 10px;
        padding-right: 5px;
        padding-left: 5px;
    }
    .play-card-little-tinkas {
        max-width: 200px !important;
        width: 200px !important;
        min-width: 200px !important; 
        
        box-shadow: 0px 0px 5px black;
        /* border: 1px solid #e6d7d7; */
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: row;
        flex-wrap: wrap;
        background: white;
       
        margin: 10px;
        border-radius: 7px;
        padding-bottom: 10px;
        padding-top: 10px;
        padding-right: 5px;
        padding-left: 5px;
    }
    .modalRegenerarColumna{
          cursor: pointer; 
          text-align: -webkit-center;
          box-shadow: black 0px 0px 3px;
          border-radius: 7px;
          font-weight: 700;
          color: #294977;
          padding: inherit;
          width: 80px;
          height: 80px;
          margin: 9px;
    }

    .modalRegenerarColumna:hover {
          background: #294977; 
          color: white; 
    }

    .modalRegenerarTitulo{
      color: #294977;
      color: rgb(41, 73, 119);
      font-size: larger;
      font-weight: 700;
    }
    .containerCartillaOpciones{ /* Cartilla ,as opciones */  
      display: flex; 
      /* flex-direction: row;   */
      /* flex-wrap: wrap; */
      /* font-size: 15px;  */
      /* text-align: center;  */
      /* width: 745px; */
      /* padding: 3px; */
    }
    .containerOpciones{
      display: flex;  
      flex-direction: column;
      flex-wrap: wrap;
      /* font-size: 15px; 
      text-align: center; 
      width: 745px;
      padding: 3px; */
    }
    .opcionesTituloTodo{ 
      display: flex; 
      align-items: center;
        justify-content: center;  
      height: max-content;
      border-radius: 7px;
      margin: 5px;
    }
    .opcionesRegenerar{
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      height: max-content;
      border-radius: 7px;
      margin: 5px;
      box-shadow: 0px 0px 2px black;
      padding: 10px;
    }
    .opcionesRegenerar:hover {
      background: #294977; 
      color:white
    }
    .textoOpciones{ 
      color:#294977;
      font-weight: 500;
    }

    .opcionesRegenerar:hover div h4 { 
      color:white
    }

     
    .opcionesEliminarTodo{
      cursor:pointer;
      display: flex; 
      align-items: center;
      /* justify-content: center; */
      height: max-content;
      border-radius: 7px;
      margin: 5px;
    }
    .desactiveBoton{
      cursor:not-allowed;
    }
    .activeCross{
      /* float: left;
      margin-left: inherit;
      margin-top: inherit; */
      color: #506466 !important
    }
   
    .containerCartillas{
      display: flex; 
      flex-direction: row;  
      flex-wrap: wrap;
      justify-content: center;
      font-size: 15px; 
      text-align: center;  
      padding: 3px;
      margin-right: 250px;
    }
      /* not-allowed */
    .punteroNormal{
      cursor:context-menu !important;
    }

    /* width: 135px; */

    
    .listCartillas{
      cursor:pointer;
       box-shadow: 0px 0px 5px black;
      /* border: 1px solid #e6d7d7; */
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: row;
      flex-wrap: wrap;
      background: white;
      
       margin: 10px;
      border-radius: 7px;
      padding-bottom: 10px;
      padding-top: 10px;
      padding-right: 5px;
      padding-left: 5px;
    }

     
    .listCartillas:hover { 
        background: #dfdfdf; 
    } 
    .listNumeroCartillas{ 
        color: #D1D8E3;
        font-size: 70px; 
    } 
    .listCartillas:hover div h1{ 
        color: white;
    }  
    .listShow{
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: row;
        flex-wrap: wrap;
    }
    .listNumeros{
        background: #F6F6F8;
        width: 13px;
        height: 13px;
        margin-right: 2px;
        margin-left: 2px;
        margin-top: 2px;
        border-radius: 4px;
    }
</style>
 
<style scoped>
 .btn-send{
    background-color: #66676b;
    top: 0;
    padding: 9px 20px;
    color: #fff;
    position: relative;
    font-size: 15px;
    font-weight: 600;
    display: inline-block;
    transition: all 0.2s ease-in-out;
    cursor: pointer;
    margin-right: 6px;
    overflow: hidden;
    border: none;
    border-radius: 0px;
 }
 .btn-send:hover {
       background-color: #7a7c85 !important;
       border: 0px !important;
       color: white !important;
 }
 #TopMoreTable { 
  border-collapse: collapse;
  width: 100%;
}
  #TopMoreTable #TopLessTable, #TopLesspairTable ,#TopMorepairTable,  thead tr th, tbody tr td  {
    padding: 5px;
    text-align: center;
  }
 @media (min-width: 1367px) {
    .container {
        width: 1310px;
    }
 }
 
 
  .active_content {
    border: 1px solid #bebec1 !important;
     box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.15);
  }
  .play-card {
      -ms-flex: 0 0 20%;
      flex: 0 0 20%;
      max-width: 20%;
      position: relative;
      padding: 0 4px;
      cursor: pointer;
  } 
  .fa {
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  close:before, .fa-times:before {
      content: "\f00d";
  }

  .fa-remove:before, .fa-close:before, .fa-times:before {
    content: "\f00d";
  }
  .play-card-inner {
    border: 1px solid #e8e8ea;
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.15);
    overflow: hidden;
    border-radius: 15px;
    -webkit-border-radius: 15px;
    -moz-border-radius: 15px;
    -ms-border-radius: 15px;
    -o-border-radius: 15px;
}
.text-center {
    text-align: center!important;
}
.play-card-header {
    padding: 15px 10px 10px 10px;
}
 
 
 .single-body-inner {
    margin: 0 -4px;
}
.d-flex {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}
.d-flex {
    display: -ms-flexbox!important;
    display: flex!important;
}

*, ::after, ::before {
    box-sizing: border-box;
}

.play-card-inner {
    border: 1px solid #e8e8ea;
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.15);
    overflow: hidden;
    border-radius: 15px;
    -webkit-border-radius: 15px;
    -moz-border-radius: 15px;
    -ms-border-radius: 15px;
    -o-border-radius: 15px;
}
 
 

.close-play-card {
    padding: 0px;
    position: absolute;
    top: -10px;
    right: 0;
    width: 30px;
    height: 30px;
    background-color: #ffffff;
    box-shadow: 0 0 0px 1px rgba(0, 0, 0, 0.15);
    color: #333745;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
}
 

.play-card-header .number-amount {
    color: #34b4f5;
    font-style: normal;
}

.play-card-header {
    padding: 15px 10px 10px 10px;
}
 
*, ::after, ::before {
    box-sizing: border-box;
}

 
body {
    margin: 0;
    font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    text-align: left;
    background-color: #fff;
}

 
.number-list li {
    width: 30px;
    height: 30px;
    border: 1px solid #9fa1a7;
    font-size: 14px;
    text-align: center;
    line-height: 30px;
    display: inline-block;
    margin: 3px;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
}
  
  .number-list li.active {
      background: #0f8e1b; 
      color: #ffffff; 
  }
  ul li {
      line-height: 20px;
      padding: 0px 0;
  }
  .play-card-header .header-btn-area button {
      background-color: transparent;
      color: rgba(51, 55, 69, 0.8);
      text-transform: capitalize;
      padding: 3px 15px;
      border-radius: 999px;
      -webkit-border-radius: 999px;
      -moz-border-radius: 999px;
      -ms-border-radius: 999px;
      -o-border-radius: 999px;
  }
   
  .button-next {
    padding: 15px;
    color: black;
    width: 45px;
    height: 45px;
    background-color: transparent;
    border: 1px solid #efefef;
    text-align: center;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    -webkit-border-radius: 50%;
  }
  .d-flex {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  }
  .card-header button {
    color: white;
    background: rgba(91, 108, 143, 1);
  }
  .item-children {
    cursor: pointer;
    margin:2px !important
  }

  .item-children-v2 {
    cursor: pointer; 
    margin: 0px 3px 0px 3px;
    border-radius: 0% !important;
    -webkit-border-radius: 0% !important;

  }
  li {
      display: list-item;
      text-align: -webkit-match-parent;
  }
 

.play-card-body .number-list li.active {
    background-color: #32a842;
    color: #ffffff;
}

.activehot {
  color: #68001C !important;
}

.activecold {
    color: #027B76 !important;
   
}

.activepar {
    color: #AB853A !important; 
}

.play-card-body .number-list li.activecartilla {
    background-color: #8F8F8F;
    color: #ffffff;
    text-shadow: 2px 1px #0d0e12;
}

.play-card-body .number-list li.activealeatorio {
    background-color: #32a842;
    color: #ffffff;
    text-shadow: 2px 1px #0d0e12;
}

.play-card-body .number-list li.activecartillaagrupacionM1 {
    background-color: #8F8F8F;
    color: #ffffff;
    text-shadow: 2px 1px #0d0e12;
}
.play-card-body .number-list li.activecartillaagrupacionM2 {
    background-color: #8F8F8F;
    color: #ffffff;
    text-shadow: 2px 1px #0d0e12;
}

.play-card-body .number-list li.activeM1celeste {
    background-color: #F76300;
    color: #ffffff;
    text-shadow: 2px 1px #0d0e12;
}

.play-card-body .number-list li.activeM1amarillo {
    background-color: #DC001A;
    color: #ffffff;
    text-shadow: 2px 1px #0d0e12;
}

.play-card-body .number-list li.activeM1naranja {
    background-color: #660001;
    color: #ffffff;
    text-shadow: 2px 1px #0d0e12;
}

.play-card-body .number-list li.activeM2celeste {
    background-color: #F76300;
    color: #ffffff;
    text-shadow: 2px 1px #0d0e12;
}

.play-card-body .number-list li.activeM2amarillo {
    background-color: #DC001A;
    color: #ffffff;
    text-shadow: 2px 1px #0d0e12;
}

.play-card-body .number-list li.activeM2naranja {
    background-color: #660001;
    color: #ffffff;
    text-shadow: 2px 1px #0d0e12;
}

.play-card-body .number-list li.activeM3celeste {
    background-color: #F76300;
    color: #ffffff;
    text-shadow: 2px 1px #0d0e12;
}

.play-card-body .number-list li.activeM3amarillo {
    background-color: #DC001A;
    color: #ffffff;
    text-shadow: 2px 1px #0d0e12;
}

.play-card-body .number-list li.activeM3naranja {
    background-color: #660001;
    color: #ffffff;
    text-shadow: 2px 1px #0d0e12;
}

.play-card-body .number-list li.activecartillaagrupacionM3 {
    background-color: #8F8F8F;
    color: #ffffff;
    text-shadow: 2px 1px #0d0e12;
}

.play-card-body .number-list li.activecartillaagrupacion {
    background-color: #8F8F8F;
    color: #ffffff;
    text-shadow: 2px 1px #0d0e12;
}

.play-card-body .number-list li {
    width: 26px;
    height: 26px; 
    border: none;
    background-color: #f5f5f6;
    margin-top: 5px;
    margin-bottom: 5px;
    color: rgba(51, 55, 69, 0.8);
    cursor: pointer;
}


 
 
</style>