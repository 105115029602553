import Vue from 'vue'
import VueRouter from 'vue-router'
//BACKEND
import QuestionList from '../components/backend/questions/List'
import QuestionListId from '../components/backend/questions/Listid'
import EditLottery from '../components/backend/lottery/edit.vue'
import ListWinner from '../components/backend/winners/ListResult.vue'
import CreateUser from '../components/backend/users/create.vue'
import GroupGame from '../components/backend/group/GroupIndex'
import LotteryList from '../components/backend/results/list.vue'
import NewResult from '../components/backend/results/create.vue'
import ResultList from '../components/backend/results/detail.vue'
import EditResult from '../components/backend/results/edit.vue'
import ListSupportQuestion from '../components/backend/soporte/ListSoporteQuestion.vue'
import TicketList from '../components/backend/tickets/ListTickets.vue'
import ListCostumer from '../components/backend/costumers/list'
import MainUser from '../components/backend/App'
import MainHomeUser from '../components/backend/users/list'
import LoginUser from '../components/backend/auth/Login'
import LoginCostumer from '../components/frontend/auth/LoginCostumer'
import RegisterCostumer from '../components/frontend/auth/RegisterCostumer'

import LotteriesList from '../components/backend/lottery/list.vue'
import EditUser from '../components/backend/users/edit.vue'
//FRONTEND
import MainFrontEnd from '../components/frontend/lotery/Main'

import Story from '../components/frontend/Storie/Storie'
import StoryDetail from '../components/frontend/Storie/StorieDetail.vue'
import MainCostumer from '../components/frontend/App'
import MainHomeCostumer from '../components/frontend/Main/HomeTest'
import MainHome from '../components/frontend/Main/Main'

import HomeAnimation from '../components/frontend/Main/HomeAnimation'

import Questions from '../components/frontend/questions/Questions'
import ListPromotions from '../components/frontend/Promotions/ListPromotions'
import Profile from '../components/frontend/auth/Profile'
import ResultListFront from '../components/frontend/results/List.vue'
import ResultDetail from '../components/frontend/results/Detail.vue'
import MainPrognostic from '../components/frontend/Prognostic/MainPrognostic'
import MainAnalysis from '../components/frontend/Analysis/Main'
import DetailAnalysis from '../components/frontend/Analysis/Detail2'
import DetailTwoAnalysis from '../components/frontend/Analysis/DetailTwo'
import Support from '../components/frontend/Support/Main'
//import Register from '../components/frontend/auth/Register'
import Checkout from '../components/frontend/Checkout/Main'
//import Son from '../components/frontend/Main/son'

import PageHome from '../components/frontend/Main/Pages/Home'
import PageTicket from '../components/frontend/Main/Pages/Ticket'
import PagePlans from '../components/frontend/Main/Pages/Plans'
import PageSupport from '../components/frontend/Main/Pages/Support'
import PageQuestion from "@/components/frontend/Main/Pages/Questions";
import Forgot from '@/components/frontend/auth/Forgot';
import ChangePasswordCostumer from '@/components/frontend/auth/changePasswordCostumer';

Vue.use(VueRouter)
const router = new VueRouter({
    mode: "history",
    routes: [
        {
            path: '/login-client',
            name: 'LoginCostumer',
            meta: { auth: false, },
            component: LoginCostumer
        },
        {
            path: '/forgot',
            name: 'Forgot',
            meta: { auth: false },
            component: Forgot
        },
        {
            path: '/change-password',
            name: 'ChangePassword',
            component: ChangePasswordCostumer
        },
        {
            path: '/register-client',
            name: 'RegisterCostumer',
            meta: { auth: false, },
            component: RegisterCostumer
        },
        {
            path: '/loterias',
            component: MainCostumer,
            meta: {
                roles: ['costumer'],
                auth: false,
                label: 'mainCostumer'
            },
            children: [
                {
                    path: '/',
                    name: 'mainCostumer',
                    meta: { roles: ['costumer'], auth: false, },
                    component: MainHomeCostumer
                },


                {
                    name: 'animation',
                    path: '/animation',
                    component: HomeAnimation,
                },
                {
                    path: '/perfil',
                    component: Profile,
                    meta: {
                        title: "Perfil del usuario",
                        auth: true,
                        roles: ['costumer']
                    }
                },
                {
                    path: '/checkout/:id/:name',
                    component: Checkout,
                    meta: {
                        auth: true,
                        roles: ['costumer']
                    }
                },
                {
                    path: '/loteria/:id/:name',
                    name: 'Test',
                    meta: { roles: ['costumer'], auth: true },
                    component: MainFrontEnd,

                    // DetailForecast
                }, 
                {
                    path: '/analisis/:id/:name',
                    name: 'DetailAnalisis',
                    meta: { roles: ['costumer'], auth: true },
                    component: DetailAnalysis
                },
                {
                    path: '/analisis/:id/:name/:index',
                    name: 'DetailTwoAnalysis',
                    meta: { roles: ['costumer'], auth: true },
                    component: DetailTwoAnalysis

                }, 
                {
                    path: '/analisis',
                    name: 'Analisis',
                    meta: { roles: ['costumer'], auth: true },
                    component: MainAnalysis,
                },
                {
                    path: '/pronostico',
                    name: 'Prognostic',
                    meta: { roles: ['costumer'], auth: true },
                    component: MainPrognostic,
                },
                {
                    path: '/resultados',
                    name: 'resultados',
                    meta: { roles: ['costumer'], auth: true },
                    component: ResultListFront
                },
                {
                    path: '/resultados/:id/:name',
                    name: 'resultados-id-name',
                    meta: { roles: ['costumer'], auth: true },
                    component: ResultDetail
                },

                {
                    path: '/preguntas-frecuentes',
                    name: 'Questions', 
                    component: Questions
                },
                {   
                    path: '/lista-promociones',
                    name: 'ListPromotions', 
                    component: ListPromotions
                }, 
                {
                    path: '/testimonios',
                    name: 'Storie',
                    meta: { roles: ['costumer'], auth: true },
                    component: Story
                },
                {
                    path: '/stories/detail',
                    name: 'StorieDetail',
                    meta: { roles: ['costumer'], auth: true },
                    component: StoryDetail
                },
                {
                    path: '/soporte',
                    name: 'Support',
                    meta: { roles: ['costumer'], auth: true },
                    component: Support
                }
            ]
        },
        {
            path: '/',
            name: 'MainHome',
            meta: { roles: ['costumer'], auth: false, },
            component: MainHome,
            children: [
                {
                    path: '/',
                    component: PageHome,
                },
                {
                    path: '/promotion',
                    component: PagePlans
                },

                {
                    path: '/support',
                    component: PageSupport
                },
                {
                    path: '/questions',
                    component: PageQuestion
                }
                ,
                {
                    path: '/ticket',
                    component: PageTicket
                }
            ]
        },
        {
            path: '/admin',
            component: MainUser,
            children: [
                {
                    path: '/admin',
                    meta: { label: 'users', auth: true, roles: ['admin'] },
                    component: MainHomeUser,
                },
                {
                    path: '/admin/questions',
                    name: 'questions-list',
                    meta: { label: 'questions', auth: true, roles: ['admin'] },
                    component: QuestionList
                },
                {
                    //Preguntas del tema
                    path: '/admin/questions/:id',
                    name: 'question-list-id',
                    meta: { label: 'question-list-id', auth: true, roles: ['admin'] },
                    component: QuestionListId
                },
                {
                    path: '/admin/editUser/:id',
                    meta: { label: 'EditUser', auth: true, roles: ['admin'] },
                    component: EditUser
                },
                {
                    path: '/admin/newUser',
                    meta: { label: 'CreateUser', auth: true, roles: ['admin'] },
                    component: CreateUser
                },
                {
                    path: '/admin/winners',
                    name: 'winners-list',
                    meta: { label: 'winners-list', auth: true, roles: ['admin'] },
                    component: ListWinner
                },

                {
                    path: '/admin/results',
                    name: 'lottery-list',
                    meta: { label: 'lotteryList', auth: true, roles: ['admin'] },
                    component: LotteryList
                },
                {
                    path: '/admin/results/edit/:name/:lottery_id/:id',
                    name: 'editResult',
                    meta: { label: 'lottery-edit', auth: true, roles: ['admin'] },
                    component: EditResult
                },
                {
                    path: '/admin/results/new/:name/:id',
                    name: 'newResult',
                    meta: { label: 'newResult', auth: true, roles: ['admin'] },
                    component: NewResult
                },
                {
                    path: '/admin/results/:name/:id',
                    name: 'lottery-results',
                    meta: { label: 'lottery-results', auth: true, roles: ['admin'] },
                    component: ResultList
                },

                {
                    path: '/admin/loterias',
                    name: 'lotery-list',
                    meta: { label: 'lotery-list', auth: true, roles: ['admin'] },
                    component: LotteriesList
                },
                {
                    path: '/admin/loterias/:id',
                    name: 'lottery-list-edit',
                    meta: { label: 'lottery-list-edit', auth: true, roles: ['admin'] },
                    component: EditLottery
                },
                {
                    path: '/admin/tickets',
                    name: 'tickets-list',
                    meta: { label: 'tickets-list', auth: true, roles: ['admin'] },
                    component: TicketList
                },
                {
                    path: '/admin/group-game',
                    name: 'group-game',
                    meta: { label: 'group-game', auth: true, roles: ['admin'] },
                    component: GroupGame
                },

                {
                    path: '/admin/soporte',  
                    name: 'soporte',
                    meta: { label: 'soporte', auth: true, roles: ['admin'] },
                    component: ListSupportQuestion
                },
                {
                    path: '/admin/soporte/costumer',
                    name: 'list-costumer',
                    meta: { label: 'list-costumer', auth: true, roles: ['admin'] },
                    component: ListCostumer
                }
            ]
        },

        {
            path: '/login',
            name: 'LoginUser',
            meta: { roles: ['admin'], auth: false, },
            component: LoginUser
        }
    ]
})



export default router;