import costumerAPI from '../../api/costumer'
import Vue from 'vue'
import VueCookie from 'vue-cookie'
Vue.use(VueCookie);
 
const state = {
    authenticated: false,
    updated_costumer: false,
    created_costumer: false,
    costumer: {},
    lottery : {},
    list: [],
    emails: [],
    costumers: [],
    primary_email: 0,
    ticket: [],
    change_password: {},
    updated_password: false,
    countries: ['Argentina',
        'Bahamas',
        'Barbados',
        'Belice',
        'Bolivia', 'Brasil', 'Chile',
        'Colombia', 
        'Cuba',
        'Dominica',
        'Ecuador', 'El Salvador', 'Estados Unidos',
        'Granada',
        'Guatemala',
        'Guyana',
        'Haiti',
        'Honduras', 'Jamaica', 'Mexico',
        'Nicaragua',
        'Panama', 'Paraguay',
        'Peru',
        'Republica Dominicana',
        'San Cristóbal y Nieves',
        'San Vicente y las Granadinas',
        'Santa Lucia',
        'Surinam',
        'Trinidad y Tobago',
        'Uruguay',
        'Venezuela',
    ],
    expiredChangePassword: false,

    theme_light: {
        textColor: '#000',
        backgroundColor: '#1f2027',
        borderColor: 'white',
        borderCardCircle: '#1f2027',
        backgroundCard: '#fff'

    },
    theme_dark: {
        textColor: '#FFF',
        backgroundColor: '#1f2027',
        borderColor: '1f2027',
        borderCardCircle: '#b68c64',
        backgroundCard: '#34343e'
    },
    picked: false,

}
const getters = {

}
const actions = {
    async register({commit}, credentials) {
        const data = await costumerAPI.register(credentials) 
        if(data.data.thumbnail_path === 200) {
            commit('ADD_COSTUMER', true); 
            return data.data

        } else {
            return data.data  
        }
    },
    setTheme({commit}, val) {
        commit('SET_THEME', val)
    },
    async forgot({commit}, credentials) {
        const {data: data } = await  costumerAPI.forgot(credentials)
        commit('FORGOT', data)

    },
    async recoverPassword({commit}, credentials) {
        const {data: data } = await  costumerAPI.recoverPassword(credentials)
        commit('RECOVER_PASSWORD', data)

    },
    async validateExpireChangePassword({ commit }, searchParams) {
        let {data: valid } = await costumerAPI.validateExpireChangePassword(searchParams);
        commit('EXPIRE_CHANGE_PASSWORD' , valid)
    },
    async login({commit}, credentials) {
        const { data: data } = await costumerAPI.login(credentials)

        if(data.thumbnail_path === 200) {
            data.role = 'costumer';
            commit('SET_AUTHENTICATED', true)
            commit('SET_COSTUMER', data)
            VueCookie.set('costumer_auth', data.id)
            VueCookie.set('costumer_token', data.token)
            return data
        } else {
            commit('SET_AUTHENTICATED', false) 
            return data
        }  
     },
    async getProfile({commit}) { 
        const fullData = {}
        const authorization = VueCookie.get('costumer_token');

        fullData.token = authorization

        if(authorization) {             
            const { data: costumer } = await costumerAPI.profile(fullData);  
            const _ = costumer.emails.filter(item => item.type === 1);
            commit('PRIMARY_EMAIL', _[0].id)    
            commit('SET_COSTUMER', costumer);
            return costumer;
        } else {
            return false
        }
    }, 
    async changeEmailPrimary({commit}, {...searchParams}){
        const authorization = VueCookie.get('costumer_token');
        if (authorization) {
            const { data: data } = await costumerAPI.changeTypeEmail(searchParams)
            const _ = data.emails.filter(item => item.type === 1);
            commit('PRIMARY_EMAIL', _[0].id)
            commit('SET_COSTUMER', data); 
        }
    },
    logoutCostumer({commit}) {
        commit('SET_AUTHENTICATED', false)
        VueCookie.delete('costumer_auth'); 
        VueCookie.delete('costumer_token') 
    },
    async validateAuthorizationC({commit}) { 
        const authorization = VueCookie.get('costumer_token');  
        if(!authorization || authorization === 'undefined' || authorization == null) return false;
        const { status, data: costumer } = await costumerAPI.validToken(authorization) 
        if(status === 200) {
            commit('SET_AUTHENTICATED', true)
            commit('SET_COSTUMER', costumer)  
           return true; 
        }
        //if(status === 401) VueCookie.delete('costumer_auth'); 
    }, 
    async createLotery({commit}, data ) {
        await costumerAPI.createLotery(data)  
        commit('CREATE_LOTERY', data)
    },
    async listPowerBall({commit}) {
        const {data: list} = await costumerAPI.listPowerBall();
        commit('LIST_POWERBALL', list) 
    }, 
    async sendEmail({commit}, {...searchParams}) {
        const { data: data } = await costumerAPI.sendEmail(searchParams)
        if (data.status === 200) {
            commit('ADD_EMAIL', data) 
        }  
    },
    async removeEmail({commit}, {...searchParams}) {
        const { data: data } = await costumerAPI.removeEmail(searchParams); 
        if (data === "OK") {
            commit('REMOVE_EMAIL', searchParams);
        }
    },
    async saveProfile({commit}, {...searchParams}) {
        await costumerAPI.saveProfile(searchParams); 
        commit('SAVE_PROFILE');
    },
    async showModalTicket({commit}, {...searchParams}) {
        commit('SHOW_TICKET', searchParams)
    },
    async sendChangePassword({commit}, {...searchParams}) {
        const { data: data } = await costumerAPI.sendChangePassword(searchParams)
        VueCookie.set('costumer_token', data.data)
        if (this.data.data) commit('CHANGE_PASSWORD', true)
        return data;
    },
    async changePassword({commit}, searchParams) {
        const {status} = await costumerAPI.changePassword(searchParams)

        if (status) commit('CHANGE_PASSWORD_ADMIN', true)

    },
    async updateCostumer({commit}, {...searchParams}) {
        const { data: data }  = await costumerAPI.update(searchParams)
        commit('UPDATE_COSTUMER', data)
    },

    async getCostumers({commit}) {
        const { data: data } = await costumerAPI.list()
        commit('COSTUMERS', data)
    }
}

const mutations = {
    EXPIRE_CHANGE_PASSWORD(state, val) {
      state.expiredChangePassword = val;
    },
    UPDATE_COSTUMER(state, val) {
      state.updated_costumer = val
    },
    COSTUMERS(state, val) {
      state.costumers = val
    },
    CHANGE_PASSWORD_ADMIN(state, val) {
        state.updated_password = val
    },
    SET_AUTHENTICATED(state, val) {
        state.authenticated = val
    },
    SET_COSTUMER(state, costumer) {
        state.costumer = costumer
    }, 
    ADD_COSTUMER(state, val){ 
        state.created_costumer = val
    },
    CREATE_LOTERY(state, val) {
        state.lotery = val
    },
    LIST_POWERBALL(state, data) {
        state.list = data
    },
    ADD_EMAIL(state, data) {
        if (data) {
            state.costumer.emails.push(data)  
        }
    },
    REMOVE_EMAIL(state, data) {
        if (data) {
            const val = state.costumer.emails.filter(x => {
                return x.id != data.id;
            })
            state.costumer.emails = val;
        }
    },
    SAVE_PROFILE()  {},
    PRIMARY_EMAIL(state, val) {
        state.primary_email = val
    },
    SHOW_TICKET(state, val) {
        state.ticket = []
        state.ticket.push(val)  
    },
    CHANGE_PASSWORD(state, val) {
        state.change_password = val;
    },
    SET_THEME(state, val) {
        state.picked = val

    }
}
export default { 
    state,
    actions,
    getters,
    mutations
}
