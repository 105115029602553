<template>
<div>
  <v-app>
        <div class="d-flex  flex-row  ">  
                 <p class="text-h4 mx-5 mt-5 mr-auto">Administrar preguntas</p>  
                 
                 <p class="mx-5 mt-5 ml-auto">Dashboard > Preguntas frecuentes > Administrar preguntas</p>
          </div> 
          <v-main>
              <v-card class="mx-auto mt-5 mb-2" color="transparent" max-width="1280" elevation="0">
                  <v-btn class="mx-5" :fab="false" dark color="#458ef3" @click="formNuevo()"> <v-icon>add</v-icon> </v-btn>
              </v-card>

              <v-expansion-panels focusable>
                <v-expansion-panel  v-for="( question, index ) of questions" :key="index" >
                  <v-expansion-panel-header>
                    <b-row>
                                    <b-col sm="6" lg="6" class="py-0">
                                        <div class="text-h5 text-left">
                                            {{question.title}}
                                        </div> 
                                    </b-col>
                                    <b-col align="right" sm="6" lg="6" class="py-0 text-rigth"> 
                                      <v-btn class="mx-5 orange"  dark samll :fab="false" @click="formEditar(question.id, question.title, question.description)"><v-icon>edit</v-icon></v-btn>
                                      <v-btn class="red" dark samll :fab="false" @click="borrar(question.id)"><v-icon>delete</v-icon></v-btn>
                                     </b-col>
                                </b-row> 
                    
                    </v-expansion-panel-header>
                  <v-expansion-panel-content>
                      <b-card-text>
                              <div v-html="question.description"></div>
                      </b-card-text>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>

              <!-- Componente  de dialogo para CREAR y EDITAR -->
              <v-dialog v-model="dialog" max-width="800" >
                <v-card>
                  <v-card-title class="purple darken-4 white--text"  v-if="operacion == 'crear'">Nueva pregunta</v-card-title>
                  <v-card-title class="purple darken-4 white--text"  v-if="operacion == 'editar'">Editar pregunta</v-card-title>
                  <v-form>
                      <v-card-text>   
                            <b-row class="my-1">
                              <b-col sm="3" lg="3">
                                <label for="input-valid">Pregunta:</label>
                              </b-col>
                              <b-col sm="3" lg="9">
                                <div>
                                  <b-form-input v-model="articulo.title"  placeholder="Nombre de la pregunta"></b-form-input>
                                </div> 
                              </b-col>
                              <b-col sm="12" lg="12">
                                <label for="input-valid">Respuesta:</label>
                              </b-col>
                              <b-col sm="12" lg="12">
                                <!-- Componenete editor -->
                                <div> 
                                <ckeditor :editor="editor" v-model="articulo.description" :config="editorConfig"></ckeditor>
                                </div>

                                <!-- <div>
                                     <b-form-textarea
                                        id="textarea"
                                        v-model="articulo.description"
                                        placeholder="Respuesta a la pregunta"
                                        rows="3"
                                        max-rows="6"
                                        ></b-form-textarea>
                                  </div>  -->
                              </b-col>
                            </b-row>  
                      </v-card-text>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn @click="dialog=false" color="blue-grey" dark>Cancelar</v-btn>
                        <v-btn @click="guardar()" type="Submit" color="blue accent-3" dark>Guardar</v-btn>
                      </v-card-actions>
                  </v-form>
                </v-card>
              </v-dialog>
   </v-main>
      </v-app>
  </div>
</template>
 
<script>
import axios from "axios";
import swal from 'sweetalert2';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
// let url = 'https://new.ialotteryplay.com/questions/list/';
let url = 'https://latam.ialotteryplay.com/questions/list/';

export default {
    name:'QuestionListid',
    data() {
        return {     
          editor: ClassicEditor,
                editorData: '<p>Content of the editor.</p>',
                editorConfig: {
                    // The configuration of the editor.
                },       
            questions: [],
            dialog: false,
            operacion: '',            
            articulo:{ 
                id: null,
                title:'',
                subject_id :this.$route.params.id,
                description:''
            }          
        }
       },
       created(){               
            this.mostrar();
       },  
       methods:{          
            //MÉTODOS PARA EL CRUD
            async mostrar(){
              let datos = await axios.get(url+this.$route.params.id)
              //console.log(datos.data);
                this.questions = datos.data;                   
            },
            async crear(){
                event.preventDefault();
                let parametros = {title:this.articulo.title, description:this.articulo.description};                
                await axios.post(url+this.articulo.subject_id, parametros);
                // console.log(datos);
                this.mostrar();  
                this.articulo.title="";   
                this.articulo.description=""; 
            }, 
            async editar(){
                event.preventDefault();
                let parametros = {title:this.articulo.title, description:this.articulo.description};                        
                await axios.put(url+this.articulo.subject_id+'/'+this.articulo.id, parametros);     
                this.mostrar();
                this.articulo.id="";   
                this.articulo.title=""; 
                this.articulo.description=""; 
            },
            async borrar(id){
                event.preventDefault();
             swal.fire({
                title: '¿Confirma eliminar el registro?',   
                confirmButtonText: `Confirmar`,                  
                showCancelButton: true,                          
              }).then(async (result) => {                
                if (result.isConfirmed) {      
                      //procedimiento borrar
                      await axios.delete(url+this.articulo.subject_id+'/'+id);
                      this.mostrar();  
                      this.articulo.id="";   
                      this.articulo.title=""; 
                      this.articulo.description=""; 
                      swal.fire('¡Eliminado!', '', 'success')
                } else if (result.isDenied) {        
                  //sconsole.log('Cancelado');          
                }
              });              
            },
            //Botones y formularios
            guardar(){
              if(this.operacion=='crear'){
                this.crear();                
              }
              if(this.operacion=='editar'){ 
                this.editar();                           
              }
              this.dialog=false;                        
            }, 
            formNuevo() {
              this.dialog=true;
              this.operacion='crear';
              this.articulo.title='';
              this.articulo.description='';    
            },
            formEditar(id, title, description){              
              this.articulo.id = id;
              this.articulo.title = title;
              this.articulo.description=description;                         
              this.dialog=true;                            
              this.operacion='editar';
            },
            
       }
}
// console.log(resOrder.data.links[1].href);  
//        
</script>

<style scoped>
  /* .accordion .card:not(:first-of-type):not(:last-of-type), .accordion .card:last-of-type {
    border-radius: 0px;
    -webkit-border-radius: 0px;
    -moz-border-radius: 30px;
    -ms-border-radius: 30px;
    -o-border-radius: 30px;
}
 
.accordion .card:first-of-type {
    border-radius: 30px;
    -webkit-border-radius: 0px;
    -moz-border-radius: 30px;
    -ms-border-radius: 30px;
    -o-border-radius: 30px;
} */
 button, a.button-readmore, .ts-banner-button a, .woocommerce button.button.alt:hover, .woocommerce input.button.alt:hover,  .woocommerce div.product, a.dokan-btn, .dokan-btn, .button.button-light:hover, a.button.button-light:hover, .product-category .meta-wrapper a.button:hover, .ts-shortcode .shop-more a.button:hover, .ts-shortcode .show-all-button a.button:hover, .wishlist_table .product-add-to-cart a.button, .woocommerce div.product div.summary form.cart .button, .woocommerce .wishlist_table .product-add-to-cart a.button:hover, .woocommerce .wishlist_table .product-add-to-cart a.button.alt:hover, .product-style-3 .ts-megamenu-container .ts-shortcode .products .product .meta-wrapper-2 .loop-add-to-cart .button, .product-style-4 .ts-megamenu-container .ts-shortcode .products .product .meta-wrapper-2 .loop-add-to-cart .button, .woocommerce .widget_shopping_cart .buttons a:first-child:hover {
    border-color: rgb(255 255 255);
    background: rgb(255 255 255);
    color: #000000;
    
     border-radius: 30px;
    -webkit-border-radius: 0px;
    -moz-border-radius: 30px;
    -ms-border-radius: 30px;
    -o-border-radius: 30px;
}
</style>