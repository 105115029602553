<template>
  <div>
      <img
          class="d-block w-100"
          width="1024"
          height="480"
          src="/uploads/img-black.png"
          alt="image slot" >
      <div>
        <img src="/uploads/img-persons.png" alt="" style="position: absolute; left: 15%; top: 15%; width: 200px;">
        <div class="animate center">
          <div id = "cloud">
            <div class="txt-position">Sabias que?, la probabilidad de acertar 5 numeros de 69 es de 1 a en 292 201 338.</div>
          </div>
        </div>
        <div >
          <div class='cloud-circle-one'> </div>
          <div class='cloud-circle-two'> </div>
        </div>
      </div>
      <div>
        <img src="/uploads/img-person-two.png" alt="" style="position: absolute; left: 75%; top: 25%; width: 170px;">
        <div class="animate center-two">
          <div id = "cloud-bubble">
            <div class="txt-position-bubble">Te ofresco mediante la App la posibilidad de reducir hasta un 40%. es decir eliges 5 de 36 aproximadamente. Y tienes mayor chance al gordo</div>
            <button class='btn-position-bubble' @click="showPromotion">Descubre como Aqui!!!</button>
          </div>
        </div>
      </div>
  </div>
</template>
<script>
  export default {
      methods:{
        showPromotion() {
          window.location.href = '/promotion'
        }
      }
  }
</script>

<style scoped>
 img {
    height: 100%;
    object-fit: scale-down;
}
.btn-position-bubble {
  width: max-content;
  position: absolute;
  z-index: 9;
  top: 65%;
  left: 35%;
  border-color: #b68c64 !important;
  background: #b68c64 !important;
  border: 1px solid #b68c64 !important;
  color: white !important;
}
.btn-position-bubble:hover {
  border-color: #b68c64 !important;
  background: transparent !important;
  border: 1px solid #b68c64 !important;
  color: #b68c64 !important;
}
</style>