import ApiClient from '../source/Api'
  
const penas =  {  
    list(searchParams) { 
        return ApiClient().get(`/penas/list?game_id=${searchParams.game_id}&lottery_id=${searchParams.id}` )
    }, 
    create(searchParams) {
        return ApiClient().post(`/penas/create`, searchParams)
    },
    list_penas(searchParams) { 
        return ApiClient().get(`/penas/list_type?game_id=${searchParams.game_id}&lottery_id=${searchParams.id}` )
    }, 
    exportFile(searchParams) {
        return ApiClient().post(`/penas/create_lines`, searchParams)
    }
}

export default penas; 