<template>
  <div>
    <div class="container" v-bind:id="'content-table-media-'+this.type"  style="margin-top: 20px; ">
      <highcharts :options="chartOptions"  ></highcharts>
    </div>
    <div id="figure_date" :class="{ dark: picked }"  @mousedown="moveSuggestion" @mouseup="stopSuggestion"   style=" right: 0; top: 0; cursor: move;box-shadow: 0px 0px 10px black;position: absolute; background: white; width: 300px;  height: max-content; z-index: 3; border-radius: 5px; display: flex;flex-direction: column; text-align: center; padding: 10px;  ">

      <div   >

          <div>
            <p class="styleTextOne" style="text-align: center">LEYENDA</p>
          </div>
          <div style="display: flex; flex-direction: column">
            <div>
              <div>Bolilla:<span>{{ indications.ball }}</span></div>
              <div>Cantidad:<span>{{ indications.count }}</span></div>
              <div>
                Fecha de Aparicion:
                 <div  style="overflow-x: auto; " :class="[ indications.dates.length > 3 ? 'legend-max-height' : 'legend-min-height' ] ">
                   <div v-for="(date,i ) in indications.dates" :key="date" style="display: flex; justify-content: center;">
                     <b-button id="show-less" v-if="i === 3" class="m-1" @click="eventCollapse('less')" style="display: none">Ver menos</b-button>
                   </div>
                  <ul>
                    <li  style="list-style: none" v-for="(date,i ) in indications.dates" :key=' i +"-date"'>
                      <div style=" display: flex; justify-content: space-evenly; align-items: center;" v-if="i<3">
                        <div style=" display: flex; flex-wrap: wrap; justify-content: space-evenly; margin: 5px 0; ">
                          <div class="numeroConstellationStyle" style="width: 150px !important">
                            <p class="styleTextTwo">{{ date }}</p>
                          </div>
                        </div>
                        <div class="copiarStyleForecast">
                          <div style="font-size: 20px; cursor: pointer" @click="sendDate(date)">
                            <i class="fas fa-search"></i>
                          </div>
                        </div>
                      </div>
                    </li>
                  </ul>

                  <div v-for="(date,i ) in indications.dates" :key="date" style="display: flex; justify-content: center;">
                    <b-button id="show-more" v-if="i === 3" class="m-1" @click="eventCollapse('more')">Ver mas</b-button>
                  </div>
                  <b-collapse id="collapse-2">
                    <ul v-for="(date,i ) in indications.dates" :key="date">
                      <li style="list-style: none"  v-if="i >= 3" >
                        <div style="
                        display: flex;
                        justify-content: space-evenly;
                        align-items: center;
                      " >
                          <div style=" display: flex; flex-wrap: wrap; justify-content: space-evenly; margin: 5px 0; ">
                            <div class="numeroConstellationStyle" style="width: 150px !important">
                              <p class="styleTextTwo">{{ date }}</p>
                            </div>
                          </div>
                          <div class="copiarStyleForecast">
                            <div style="font-size: 20px; cursor: pointer" @click="sendDate(date)">
                              <i class="fas fa-search"></i>
                            </div>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </b-collapse>
                </div>
              </div>
            </div>
          </div>

       
      </div>
    </div>

  </div>
</template>

<script>
import Moment from "moment";
import $ from "jquery";
import { mapState, mapMutations } from "vuex";
import { Chart } from "highcharts-vue";
//import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import { EventBus }  from '../../../../plugins/event-bus';
import analyticAPI from "../../../../api/analytic";

export default {
  name: "Media",
  components: {
    //DatePicker,
    highcharts: Chart,
  },
  data() {
    return {

      labels: null,
      data: null,
      appearance: "",
      filter: {
        month: true,
        year: false,
      },
      month: {
        from: "2015-01",
        to: Moment(new Date()).format("YYYY-MM"),
      },
      year: {
        from: new Date("01-01-2015"),
        to:  new Date()
      },
      indications: {
        dates: [],
        ball: 0,
        count: 0,
      },
      chartOptions: {
        chart: {
          type: "spline",
          spacingBottom: 5,
          spacingTop: 0,
          spacingLeft: 0,
          spacingRight: 0,
          backgroundColor: null,
          borderColor: this.picked,
          //width: 1400,
          //height: 500,
        },
        legend: {
          symbolHeight: 125,
          itemDistance: 1,
          symbolWidth: 0,
          useHTML: true,
          align: "left",
          verticalAlign: "middle",
          width: 300,
          itemWidth: 40,
          alignColumns:true,
          borderColor:"#000",
          borderRadius:3,
          itemCheckboxStyle:{ "width": "123px" },
          itemHoverStyle: {
            color: '#b68c64'
          },
          itemStyle: {
            color: "#b68c64",
            cursor: "pointer",
            fontSize: "12px",
            fontWeight: "bold",
            textOverflow: "ellipsis",
          },
          style: {
            color: '#000'
          },
          shadow: false,
          backgroundColor: '#f1ecec',
          labelFormatter: function( ) {
            if (this.visible) {
              return `<div class="item-lottery"><i class="fas fa-times active-icon"></i></div>`;
            } else {
              return `<div class="item-lottery">${this.name}</div>`;
            }
          },
        },
        tooltip: {
          formatter: function () {
            let dates = [];
            for (let i = 0; i < this.point.dates.length; i++) {
              if (i === 0) dates[i] = `<div class="numeroConstellationStyle" style="width: 150px !important; margin-left: 30px"><p>Fecha de Aparicion : ${this.point.dates[i]}</p></div><br>`;
              if (i < 3 && i !== 0) {
                dates[i] = `<div class="numeroConstellationStyle" style="width: 150px !important; margin-left: 30px"><p><div style="color: #f8f8f8">Fecha de Aparicion :</div> ${this.point.dates[i]}</p></div><br>`;
              }
            }
            if (this.point.dates.length > 3) dates[3] = `<div class="numeroConstellationStyle" style="width: 150px !important; margin-left: 30px"><div style="color: #f8f8f8">Fecha de Aparicion :</div>...<p></p></div><br>`
            return `<div ><br> Cantidad: ${this.point.y} <br> Bolilla: ${this.point.ball} <br>  <br>  ${dates.join(' ') }</div> `;
          },
        },
        xAxis: {
          categories: null,
          title: {
            style: {
              color: null
            }
          },
          labels: {
            style: {
              color: null
            }
          }
        },
        plotOptions: {
          series: {
            cursor: "pointer",
            events: {
              legendItemClick: (e) => {
                let item = e.target
                let lottery_item = `#content-table-media-${this.type} .highcharts-legend-item.highcharts-spline-series.highcharts-series-${item.index} span .item-lottery`;
                if (!item.visible) {
                  if ($(`${lottery_item}`)[0].innerText === item.name) {
                    $(`${lottery_item}`)[0].innerText = ""
                    $(`${lottery_item}`).append(`<i class="fas fa-times active-icon"></i>`)
                    this.chartOptions.series.map((x) => { if (parseInt(x.name) !== item.index+1) { x.visible = false } return x })
                    this.chartOptions.series[item.index].visible =   true
                  } else {
                    $(`${lottery_item}`)[0].empty();
                    $(`${lottery_item}`)[0].text = item.index + 1;
                  }
                } else {
                  $(`${lottery_item}`).empty();
                  $(`${lottery_item}`)[0].innerText = item.index + 1;
                }
              }
            },
            point: {
              events: {
                click: (e) => {
                  this.indications.dates = e.point.options.dates;
                  this.indications.ball = e.point.options.ball;
                  this.indications.count = e.point.options.y;
                },
              },
            },
          },
        },
        yAxis: {
          title: {
            text: "CANTIDAD",
            style: {
              color: null
            }
          },
          labels: {
            style: {
              color: '#000'
            }
          }
        },
        title: { text: "DIAGRAMA DE CICLO DE APARICIÓN", style: { color: "#b68c64" }} ,
        series: null,
      },
    };
  },
  computed: {
    ...mapState({
        ballAppearance: (state) => state.analysis.ballAppearance,
        picked: state => state.costumer.picked,
        theme_dark: state => state.costumer.theme_dark,
        theme_light: state => state.costumer.theme_light,
    }),
  },
  props: {
    type: {
      type: Number
    }
  },
  methods: {
    ...mapMutations({ 
      DATE_APPEARANCE: 'DATE_APPEARANCE', 
      NAVIGATE_TAB_PRINCIPAL:'NAVIGATE_TAB_PRINCIPAL'
    }),
    eventCollapse(type){
      this.$root.$emit('bv::toggle::collapse', 'collapse-2')
      if (type === 'more') {
        $(`#show-less`).css('display', 'block')
        $(`#show-more`).css('display', 'none')
      } else {
        $(`#show-less`).css('display', 'none')
        $(`#show-more`).css('display', 'block')
      }
    },
    async changeFilter(param) {
      if (param === "year") {
        this.filter.year = true;
        this.filter.month = false;
      } else {
        this.filter.year = false;
        this.filter.month = true;
      }
    },
    async sendDate(searchParams) { 
      this.NAVIGATE_TAB_PRINCIPAL(0);
      this.DATE_APPEARANCE(searchParams);
    },
    async init() {
      const searchParams = {
        name: this.$route.params.name,
        id: this.$route.params.id,
        type: this.type,
        from: this.month.from,
        to: this.month.to,
        limit: 10,
        appearance: this.ballAppearance[0]
      };
      const { data: data } = await analyticAPI.findBimestre(searchParams);
      this.chartOptions.xAxis.categories = data.labels;
      this.chartOptions.series = data.data;
    },
    async filterDate({ appearance = "null", type  }) {
      this.indications.dates = []
      this.indications.ball = 0
      this.indications.count = 0
      let searchParams = {
          name: this.$route.params.name,
          id: this.$route.params.id,
          limit: 10,
          type: this.type,
          appearance: appearance,
          from: type === "year" ? this.year.from : this.month.from ? '2015-01':  '2015',
          to: type === "year" ? this.year.to : this.month.to ? '2021-07':  '2021',
        };
        const { data: data } = await analyticAPI.findBimestre(searchParams);
        this.chartOptions.xAxis.categories = data.labels;
        this.chartOptions.series = data.data;
    },
    async initMedia(searchParams) {
      const { data: data } = await analyticAPI.findBimestre(searchParams);
      this.chartOptions.xAxis.categories = data.labels;
      this.chartOptions.series = data.data;
    },
    toTourMedia() {
      this.$nextTick(() => {
        const tour_ = this.$shepherd({
          useModalOverlay: true,
          cancelIcon: {
            enabled: true
          }
        });
        tour_.addStep({
          attachTo: { element: "#media-btn-month", on: 'right' },
          text: "Al seleccionar el boton Mes podremos visualizar los filtros por mes al lado izquiero.",
          classes: 'shepherd-theme-arrows',
          buttons: [
            {
              text: 'Salir',
              action: tour_.cancel
            },
            {
              text: 'Sig.',
              action: tour_.next
            }
          ],
        });
        tour_.addStep({
          attachTo: { element: "#media-btn-year", on: 'left' },
          text: "Al seleccionar el boton Año podremos visualizar los filtros por año al lado izquiero.",
          classes: 'shepherd-theme-arrows',
          buttons: [
            {
              action: tour_.back,
              classes: 'shepherd-button-secondary',
              text: 'Ant.'
            },
            {
              text: 'Sig.',
              action: tour_.next
            }
          ]
        });
        tour_.addStep({
          attachTo: { element: "#media-input-filter", on: 'left' },
          text: "Los filtros desde hasta donde se podra elegir las fechas y asi visualizar el comportamiento de las bolillas.",
          classes: 'shepherd-theme-arrows',
          buttons: [
            {
              action: tour_.back,
              classes: 'shepherd-button-secondary',
              text: 'Ant.'
            },
            {
              text: 'Sig.',
              action: tour_.next
            }
          ]
        });
        tour_.addStep({
          attachTo: { element: `#content-table-media-${this.type}`, on: 'left' },
          text: "Listado de todas las bolillas, al hacer click en una bolilla me mostrara al lado derecho el grafico.",
          classes: 'shepherd-theme-arrows',
          buttons: [
            {
              action: tour_.back,
              classes: 'shepherd-button-secondary',
              text: 'Ant.'
            },
            {
              text: 'Hecho',
              action: tour_.complete
            }
          ]
        });
        tour_.start();
      });
    },
    mobile(){
      let details = navigator.userAgent;
      /* Creating a regular expression
      containing some mobile devices keywords
      to search it in details string*/
      let regexp = /android|iphone|kindle|ipad/i;
      /* Using test() method to search regexp in details
      it returns boolean value*/
      let isMobileDevice = regexp.test(details);
      if (isMobileDevice) { return true; }
      return false;
    },
    moveSuggestion (e){
      let figure_date = document.getElementById("figure_date");

      let left = parseInt( window.getComputedStyle(figure_date).getPropertyValue("left") );
      let top = parseInt( window.getComputedStyle(figure_date).getPropertyValue("top") );
      let mouseX = e.clientX;
      let mouseY = e.clientY;

      document.onmousemove = function (e) {
        let dx = mouseX - e.clientX;
        let dy = mouseY - e.clientY;
        figure_date.style.left = left - dx + "px";
        figure_date.style.top = top - dy + "px";
      };
    },
    stopSuggestion: function(){
      // console.log('stopSuggestion');
      document.onmousemove = null;
    },
    eventBusTheme() {
      this.chartOptions.legend.backgroundColor = this.picked ? this.theme_dark.backgroundCard: this.theme_light.backgroundCard
      this.chartOptions.xAxis.labels.style.color = this.picked ? this.theme_dark.textColor: this.theme_light.textColor
      this.chartOptions.xAxis.title.style.color = this.picked ? this.theme_dark.textColor: this.theme_light.textColor
      this.chartOptions.yAxis.labels.style.color = this.picked ? this.theme_dark.textColor: this.theme_light.textColor
      this.chartOptions.yAxis.title.style.color = this.picked ? this.theme_dark.textColor: this.theme_light.textColor
    }
  },
  mounted() {
    this.init();
    EventBus.$on('clicked', this.initMedia);
    EventBus.$on('change_theme', this.eventBusTheme)
  },

};

</script>

<style    >
  .highcharts-container {
    max-width:100%;
  }
  .legend-min-height {
    height: 150px;
  }
  .legend-max-height {
    height: 230px;
  }

  i.fas.fa-times.active-icon {
    color : rgb(0, 0, 0);
  }
  .highcharts-data-table table {
    min-width: 310px;
    max-width: 100%;
    margin: 1em auto;
  }
.indications {
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: black 0 0 3px;
  border-radius: 6px;
  z-index: 1;
  right: 25px;
  padding: 4px;
  width: 250px;
  background: white;
  top: 140px;
  position: fixed;
}
.highcharts-data-table table {
  font-family: Verdana, sans-serif;
  border-collapse: collapse;
  border: 1px solid #ebebeb;
  margin: 10px auto;
  text-align: center;
  width: 100%;
  max-width: 1500px;
}


.mx-datepicker svg {
  width: 1.2em !important;
  height: 1.2em !important;
  margin-right: 10px !important;
  color: #000 !important;
}
  input {
 /*   background: white !important;*/
    border: 1px solid #ccc !important;
  }
  .btn-filter {
    width: 80px;
    margin-left: 10px;
  }
  i.mx-icon-double-right,
  i.mx-icon-double-left {
    color: black !important;
  }
  .item-lottery {
    cursor: pointer;
    border-radius: 7px;
    margin: 3px 3px;
    font-size: 15px;
    font-weight: 600;
    padding: 7px;
    width: 30px;
    height: 30px;
    display: flex;
    border: 1px solid #b68c64;
    justify-content: center;
    align-items: center
  }
  .active-icon {
    font-size: 48px;
    margin: 3px;
  }

</style>