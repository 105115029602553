import Vue from 'vue'
import VueAxios from 'vue-axios'
import CKEditor from '@ckeditor/ckeditor5-vue2';  
import axios from 'axios'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
//import GoogleAuth from '@/config/google_oAuth.js'
import vuetify from './plugins/vuetify'; 
import VueCarousel from 'vue-carousel';
import VueCookie from "vue-cookie";
import 'vue-loading-overlay/dist/vue-loading.css';
import App from './App'
import router from './router/index'
import store from './store/index' 
import 'roboto-fontface/css/roboto/roboto-fontface.css'
import 'material-design-icons-iconfont/dist/material-design-icons.css'
import LoadScript from 'vue-plugin-load-script';
//import Validate from 'vuelidate'
import HighchartsVue from "highcharts-vue";
import VueShepherd from 'vue-shepherd';
import 'shepherd.js/dist/css/shepherd.css';

import swal from 'sweetalert2';
/*const authOption = {
  clientId: '628406124447-k92eqhdg372tr9ie37gv0hp6sfo9ftds.apps.googleusercontent.com',
  scope: 'profile email',
  prompt: 'select_account'
}
Vue.use(GoogleAuth, authOption)
  */
axios.defaults.headers.common['authorization'] = VueCookie.get("_token");
import loadingMixin from './components/helpers/loader';


window.Swal = swal;
  
Vue.use(VueAxios, axios) 
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(VueCarousel)
//Vue.use(Validate)
Vue.use(CKEditor);
Vue.mixin(loadingMixin)
Vue.use(LoadScript);
Vue.config.productionTip = false;
Vue.use(HighchartsVue);
Vue.use(VueShepherd);


router.beforeEach(async (to, from, next) => {
  const costumer = await store.dispatch("validateAuthorizationC")    
  const user = await store.dispatch("validateAuthorizationU");  

  if (to.meta.auth) { 
    if (to.meta.roles[0] === 'admin') {
      if (user) { 
        return next();  
      }  else {
        next({ name:'LoginUser' })
      }
    } 
    if (to.meta.roles[0] === 'costumer') {
      if (costumer) { 
          return next();  
      }  else {
         next({ name:'mainCostumer' })
      }
    }
  } 
  next(); 
}); 
 
new Vue({
  render: h => h(App),
  router,
  vuetify,
  store
}).$mount('#app')
