<template>
    <div class="container">
        <br>
        <img v-bind:src="loterie.image"  style="width: 200px;">  
        <br>    
        <div>
            <br>  
            <b-table
                :items="loteries"
                :fields="fields"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc" 
                responsive="sm">
                <template #cell(ball_one)="data">
                    <div style="display:flex;justify-content: center;"  >
                        <div class="LottoBall__circle" style="background-color: #fff; color: #544c4c;width: 30px; height: 30px;margin: 1px" >{{data.item.ball_one}}</div>
                        <div class="LottoBall__circle" style="background-color: #fff; color: #544c4c;width: 30px; height: 30px;margin: 1px" >{{data.item.ball_two}}</div>
                        <div class="LottoBall__circle" style="background-color: #fff; color: #544c4c;width: 30px; height: 30px;margin: 1px" >{{data.item.ball_three}}</div>
                        <div class="LottoBall__circle" style="background-color: #fff; color: #544c4c;width: 30px; height: 30px;margin: 1px" >{{data.item.ball_four}}</div>
                        <div class="LottoBall__circle" style="background-color: #fff; color: #544c4c;width: 30px; height: 30px;margin: 1px" >{{data.item.ball_five}}</div>
                        <div class="LottoBall__circle" style="background-color: red; color: #fff;width: 30px; height: 30px;margin: 1px" >{{data.item.ball_red}}</div>
                    </div>
                </template>
                <template #cell(prize)="data">
                    <div style="display:flex;justify-content: center;"  >
                        <div>{{data.item.prize}}</div>
                    </div>
                </template>
            </b-table> 
       
        </div> 
     
    </div> 
</template>

<script>
import { mapActions, mapState } from 'vuex'
export default {
    name: 'ResultDetail',
     computed: {
        ...mapState({
            loteries: state => state.lotto.loteries, 
            costumer: state => state.costumer.costumer,
            loterie: state => state.lottery.lottery,

        }) 
    },
    data() {
        return {
            sortBy: 'name',
            sortDesc: true,
            fields: [  
                { key: "ball_one", label: "Loteria Resultado", formatter: "assignResults" , sortable: true}, 
                { key: 'date', label: "Fecha", sortable: true },
 
            ], 
        }
    },
    mounted(){
        this.fetchLoteries({id:this.$route.params.id, name:this.$route.params.name})
       
        this.getLoterie_()
    },
    methods: {
        ...mapActions({
            fetchLoteries: "fetchLoteries", 
            getLoterie: 'getLoterie'
       
        }),
         async getLoterie_() {
            await this.getLoterie( this.$route.params )  
        },
        assignResults(id) {
            const lotery = this.loteries.find(lotery => lotery.id === id);
            return lotery ? `${lotery.ball_one} - ${lotery.ball_two} -  ${lotery.ball_three} - ${lotery.ball_four} - ${lotery.ball_five} - ${lotery.ball_red} x ${lotery.potenciador}`: "loading...";
        },
        assignNames() {
            return "Megamillion"
        },
        assignPrize() {
            return "184 millones $"
        }
    }
}
</script>

<style>
    table th {
        text-align: center;
    }
</style>