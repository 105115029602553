<template>
  <header id="header-container" class="fixed fullwidth dashboard">
    <div id="header" class="not-sticky">
      <div class="container">
        <div class="left-side">
          <div id="logo">
            <!--<h1 class="white text-center"><strong>LoteryPlay</strong></h1>-->
            <a href="index.html"> <img  v-bind:src="`/uploads/logo.png`" alt="image"> </a>
            <a href="index.html" class="dashboard-logo"> <img  v-bind:src="`/uploads/logo.png`" alt="image"></a>
          </div>

          <div class="mmenu-trigger">
            <button class="hamburger hamburger--collapse" type="button">
              <span class="hamburger-box">
                <span class="hamburger-inner"></span>
              </span>
            </button>
          </div>
          <nav id="navigation" class="style-1">
            <ul id="responsive" >
                <li class=""> <router-link  :to="{  tag: 'a', path: '/admin' }" > <i class="far fa-user"></i> Usuarios </router-link> </li>
                <li class=""> <router-link  :to="{  tag: 'a', path: '/admin/loterias' }" > <i class="far fa-calendar"></i>  Loterias </router-link> </li>
                <li class="">
                  <router-link  :to="{  tag: 'a',    path: '/admin/results' }" > <i class="fas fa-poll"></i>
                    Resultados
                  </router-link>
                </li>
                <li class="">
                  <router-link  :to="{  tag: 'a', name:'winners-list', path: '/admin/winners' }" >
                    <i class="fas fa-trophy"></i> Ganadores
                  </router-link>
                </li>
                <li>
                  <router-link :to="{  tag: 'a', name:'list-costumer',  path: '/admin/soporte/costumer' }">
                    <i class="fad fa-clipboard-user"></i> Clientes
                  </router-link>
                </li>
                <li class="">
                  <router-link  :to="{  tag: 'a', name:'tickets-list',  path: '/admin/tickets' }" >
                    <i class="fas fa-question-square"></i> Tickets
                  </router-link>
                </li>

                <li class="">
                  <router-link  :to="{  tag: 'a', name:'soporte',  path: '/admin/soporte' }" >
                    <i class="fas fa-envelope-open-text"></i> Soporte
                  </router-link>
                </li>
                <li class="">
                  <router-link  :to="{  tag: 'a', name:'questions-list',  path: '/admin/questions' }" >
                    <i class="fas fa-question-square"></i> reguntas Frecuentes
                  </router-link>
                </li>
                <li>
                  <a href="#" @click='logout'>
                    <i class="sl sl-icon-power"></i> Salir
                  </a>
                </li>
            </ul>
          </nav>
          <div class="clearfix"></div>
        </div>
       <div class="right-side">
           <div class="header-widget">
               <div class="user-menu">
                <div class="user-name"><span><img src="/uploads/favicon.png" style="height:100%" alt=""></span><strong>{{ user.name }} {{ user.lastname }}</strong></div>
                 <ul>
                   <li><a href=""><i class="sl sl-icon-power"></i> Salir</a></li>
                 </ul>
               </div>

           </div>
         </div>
      </div>
    </div>
  </header>
</template>
<script>
import $ from 'jquery';
import {mapActions, mapState} from "vuex";

export default {
  name: 'Header',
  mounted() {
    this.initMenu();
    $(window).resize(() => {
      this.initMenu();
    });
    //this.getUser({})
  },
  computed:{
    ...mapState({
      user: state => state.user.user
    })
  },
  methods: {
    initMenu() {
    },
    ...mapActions({
      logoutUser: 'logoutUser',
      //getUser: 'getUser'
    }),
    logout() {
      this.logoutUser();
      this.$router.push("/login")
    }

  }
}

</script>

<style>
@media only screen and (max-width: 1024px) {
  #header-container.header-style-2 .right-side, .right-side {
    height: 83px !important;
  }
}
@media (min-width: 1025px) and (max-width: 1239px) {
  .right-side {
    margin-top: -42px !important;
  }
}
</style>