<template>
  <ListPromotions></ListPromotions>
</template>

<script>
import ListPromotions from "@/components/frontend/Promotions/ListPromotions";
export  default  {
  components: {
    ListPromotions
  }
}
</script>