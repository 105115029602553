<template>
  <section class="online-ticket-section section-padding padding-top-0">
    <div class="row">
      <div class="col-lg-4 col-sm-6 " v-for="(item,i) in lotteries" :key="i"  >
        <router-link :to="{  tag: 'a',  path: `/admin/results/${item.name}/${item.id}  `}"  >
          <div class="jackpot-item text-center loterias" style="height: 215px;">
            <img v-bind:src="item.image" alt="image" class="change">
            <h5 class="title margin-top-20 margin-bottom-20">{{item.description}}</h5>
            <div class="cmn-btn">
              Ver Numeros
            </div>
          </div>
        </router-link>
      </div>
    </div>
  </section>
</template>

<script>
// ,mapMutations
import { mapActions, mapState} from "vuex";
export default {
  name: 'LotteryList',
  data(){
    return{  }
  },
  computed: {
    ...mapState({
      lotteries: state => state.lottery.lotteries,
    })
  },
  methods:{
    ...mapActions({
      getLotteries: 'getLotteries',
    }),
  },
    mounted(){
      this.getLotteries({...this.$route.params});
  },

}
</script>
<style scoped>
.loterias{
  cursor:pointer;
}
img.change{
  height: 110px !important;
}
.cmn-btn {
  background-color: #28a745 !important;
}
.jackpot-item {
  border-bottom: 2px solid #28a745 !important;
}
h5, h5>a {
  font-size: 20px !important;
}
img {
  object-fit: contain;
}
</style>