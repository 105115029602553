import ApiClient from '../source/Api'
  
const support =  {  
    createOneSupport( searchParams ) {  
        return ApiClient().post(`/supports/create`, searchParams)
    } 
    // listCheckout() { 
    //     return ApiClient().get(`/checkouts/list`)
    // },
    // deleteOneCheckout(id){ 
    //     return ApiClient().delete(`/checkouts/list/${id}`)
    // },
    // deleteAllCheckout( ){ 
    //     return ApiClient().delete(`/checkouts/list`)
    // },
  
}

export default support; 