import { render, staticRenderFns } from "./PronosticoNormalPage.vue?vue&type=template&id=536e6108&scoped=true"
import script from "./PronosticoNormalPage.vue?vue&type=script&lang=js"
export * from "./PronosticoNormalPage.vue?vue&type=script&lang=js"
import style0 from "./PronosticoNormalPage.vue?vue&type=style&index=0&id=536e6108&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "536e6108",
  null
  
)

export default component.exports