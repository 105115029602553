import ApiClient from '../source/Api'
  
const notification =  {  
     
    listNewNotification() { 
        return ApiClient().get(`/notifications/listNew`) 
    },
    checkedNotification(searchParams){
        return ApiClient().post(`/notifications/checked`, searchParams)
    }
}

export default notification; 