import ApiClient from '../source/Api'

const auth =  {
    
    topResult(searchParams) {
        return ApiClient().get(`/results/list?id=${searchParams.id}&name=${searchParams.name}&type=${searchParams.type}`)     
    },   
    fetchRangeResult(searchParams) { 
        return ApiClient().get(`/results/range?id=${searchParams.id}&from=${searchParams.from}&to=${searchParams.to}&name=${searchParams.name}&type=${searchParams.type}`) 
    }, 
    searchBallResult(searchParams) {    
        return ApiClient().get(`/results/rangeBallFilter?id=${searchParams.id}&from=${searchParams.from}&to=${searchParams.to}&name=${searchParams.name}&ball=${searchParams.selected}&type=${searchParams.type}`) 
    }, 
    constellationSame(searchParams) {  
        return ApiClient().post(`/results/constellations`, searchParams)  
    }, 
    allInResult(searchParams) {   
        return ApiClient().get(`/results/allIn?name=${searchParams.name}&fecha=${searchParams.fecha}&type=${searchParams.type}&id=${searchParams.id}`) 
    } 
      
} 

export default auth;