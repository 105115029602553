<template>
    <div>
        <b-modal id="authenticated" ref="authenticated" >
            <div class="modal-content">
                <div class="modal-body">
                    <div class="login-registration-area">
                      <b-card no-body >
                          <b-tabs pills card justified>
                            <b-tab title="Inicio de session" class="nav-link show" active>
                                <div>
                                    <div class="tab-pane fade active show" id="login" role="tabpanel" aria-labelledby="login-tab">
                                        <form class="login-form cmn-frm">
                                            <div class="frm-group">
                                            <label>Email</label>
                                            <input type="email" class="form-control" v-model="credentials.email" placeholder="Email">
                                                <i class="fa fa-user"></i>
                                            </div>
                                            <div class="frm-group">
                                            <label>Contraseña</label>
                                            <input type="password" v-model="credentials.password" placeholder="Contraseña">
                                            <i class="fa fa-lock"></i>
                                            </div>
                                            <div class="frm-group">
                                            <button  @click="signIn">Iniciar sesion</button>
                                            </div>
                                        </form>
                                      <loading :active.sync="isLoading" :is-full-page="fullPage" />

                                    </div>
                                </div>
                            </b-tab>
                            <b-tab title="Registrar" class="nav-link">
                                    <div  >
                                        <form class="registration-form cmn-frm">
                                            <div class="frm-group">
                                              <label>Email</label>
                                              <input type="email" v-model="user.email" placeholder="Email">
                                              <i class="fa fa-user"></i>
                                            </div>
                                            <div class="frm-group">
                                              <label>Fecha de Nacimiento</label>
                                              <input type="date" v-model="user.birthday" placeholder="Fecha de Nacimiento">
                                            </div>
                                            <div class="frm-group">
                                            <label>Contraseña</label>
                                            <input type="password" v-model="user.password" placeholder="Contraseña">
                                            <i class="fa fa-lock"></i>
                                            </div>
                                            <div class="frm-group">
                                            <label>Confirmar Contraseña</label>
                                            <input type="password" v-model="user.confirm_password" placeholder="Confirmar contraseña">
                                            <i class="fa fa-lock"></i>
                                            </div>
                                            <div class="frm-group">
                                                <button @click="signUp">Registrar</button>
                                            </div>

                                        </form>
                                        <loading :active.sync="isLoadingRegister" :is-full-page="fullPageRegister" />
                                    </div>
                            </b-tab>
                          </b-tabs>
                      </b-card>
                    </div>
                </div>
            </div>


        </b-modal>
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import Swal from "sweetalert2";
//import { initFbsdk } from '@/config/facebook_oAuth.js'

 
export default {
    name: 'Login',
    data() {
        return {
            credentials: {
                email: "",
                password: "" 
            },
            user: {
                birthday: "",
                email: "", 
                password: "",
                confirm_password: ""
            },
            fbSignInParams: {
                scope: "email",
                return_scopes: true
            }, 
            googleSignInParams: {
                client_id: ""
            },
            fullPage: false,
            fullPageRegister: false,
            warnings:[]

        };
    }, 
     mounted () {
     //   initFbsdk()
    },
    computed: {
        ...mapState({
            isAuthenticated: state => state.costumer.isAuthenticated
        })
    },
    methods: {
        ...mapActions({
            login: "login",
            oauthLogin: "oauthLogin",
            register: "register",
            validateSubscription: 'validateSubscription',

        }),
        async signUp2(e) {
            e.preventDefault();
            e.stopPropagation();
            const credentials = this.user;
            this.isLoading = true;
            await this.register(credentials);
        
        },
        async signUp(e) {
          e.preventDefault();
          e.stopPropagation();
          this.isLoadingRegister = true;
          this.errors = [];
          let regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[$@.,/:^%;#})<'">({$!%*?&])([A-Za-z\d$@.,/:^%;#})<'">({$!%*?&]|[^ ]){6,20}$/;

          if (this.user.email && this.user.password && this.user.birthday && this.user.confirm_password && regex.test(this.user.password) && regex.test(this.user.confirm_password)) {
            setTimeout( async() => {
              let data = await this.register(this.user)
              switch (data.status) {
                case 405:
                  this.errors.push(data.message);
                  this.swallErrors()
                  break;
                case 406:
                  this.errors.push(data.message);
                  this.swallErrors()
                  break;
                default:
                  //this.$router.push("/")

                  Swal.fire({
                    position: 'center',
                    icon: 'success',
                    title: 'Registrado correctamente',
                    showConfirmButton: false,
                    timer: 1500
                  })
                  this.$refs['authenticated'].hide()
                  break;
              }
              this.isLoadingRegister = false
            },2000)
          } else {

            setTimeout( async() => {
              if (!this.user.email) {
                this.errors.push('El Email es obligatorio.');
                this.swallErrors()
              }
              if (!this.user.birthday) {
                this.errors.push('La Fecha de nacimiento es obligatoria.');
                this.swallErrors()
              }
              if (!this.user.password) {
                this.errors.push('La Contraseña es obligatoria.');
                this.swallErrors()
              }
              if (this.user.password !== this.user.confirm_password) {
                this.errors.push('La Contraseñas deben coincidir.');
                this.swallErrors()
              }
              if(!regex.test(this.user.password) &&  !regex.test(this.user.password)) {
                this.warnings.push('La contraseña debe tener:')
                this.warnings.push('Al menos una mayuscula.')
                this.warnings.push('Al menos un numero.')
                this.warnings.push('Al menos 8 caracteres.')
                this.warnings.push('Al menos un caracter especial.')
                console.log('TEST', this.warnings);
                this.swallWarnings()

              }
              this.isLoadingRegister = false
                this.warnings = [];
            },2000)

          }
        },
        async signIn2(e) {
            e.preventDefault()
            e.stopPropagation()
            const credentials = this.credentials;
            this.isLoading = true;
            await this.login(credentials) 
            this.$refs['authenticated'].hide() 
        },
        async signIn(e) {
          e.preventDefault()
          e.stopPropagation()
          this.isLoading = true;
          this.errors = [];
          if (this.credentials.email && this.credentials.password) {
            setTimeout( async() => {
              let data = await this.login(this.credentials)
              switch (data.status) {
                case 405:
                  this.errors.push(data.message);
                  this.swallErrors()
                  break;
                case 406:
                  this.errors.push(data.message);
                  this.swallErrors()
                  break;
                default:
                  this.$refs['authenticated'].hide();
                  await this.validateSubscription();
                  break;
              }
              this.isLoading = false
            },2000)
          } else {
            setTimeout(  () => {
              if (!this.credentials.email) {
                this.errors.push('El Email es obligatorio.');
                this.swallErrors()
              }
              if (!this.credentials.password) {
                this.errors.push('La Contraseña es obligatoria.');
                this.swallErrors()
              }
              this.isLoading = false
            },2000)
          }
        },
        swallWarnings() {

          let _ = []

          for (let i = 0; i < this.warnings.length; i++) {
            _[i] = `<li>${this.warnings[i]}</li>`
          }
          Swal.fire({
            icon: 'error',
            title: 'Error',
            footer: `<ul>${_}</ul>`,
          })
        },
        swallErrors() {
          let _ = []
          for (let i = 0; i < this.errors.length; i++) {
            _[i] = `<li> ${this.errors[i]}  </li>`
          }
          Swal.fire({
            icon: 'error',
            title: 'Error',
            footer: `<ul>${_}</ul>`,

          })
        },
        redirectToBack() {
            this.$router.push("/") 
        }, 
        loginWithGoogle () {
          /*this.$gAuth
                .signIn()
                .then(GoogleUser => {
                  console.log('GoogleUser', GoogleUser)
                  console.log('getId', GoogleUser.getId())
                  console.log('getBasicProfile', GoogleUser.getBasicProfile())
                  console.log('getAuthResponse', GoogleUser.getAuthResponse())
                var userInfo = {
                    loginType: 'google',
                    google: GoogleUser
                }
                this.$store.commit('setLoginUser', userInfo)
                  router.push('/home')
                }).catch(error => {
                  console.log('error', error)
                })
           */
        },
        loginWithFacebook () {
            /*window.FB.login(response => {
                var userInfo = {
                loginType: 'fb',
                fb: response
                }
                console.log('fb response', response)
                this.$store.commit('setLoginUser', userInfo)
                router.push('/home')
            }, this.params)*/
        },
        
    }
}
</script>

<style scoped>
  .modal-content {
    border: none;
  }
  #authenticated input {
    padding-left : 45px;
  }
  #authenticated button {
    width : 100%;
  }

</style>

