<template>
  <div id="app"> 
    <router-view></router-view>  
  </div>
</template> 
<script> 
export default {
  name: 'app', 
}
</script>

<style scoped>
  html {
    overflow: auto;
  }
  .highcharts-credits {
    display: none;
  }
  .nav-pills .nav-link.active, .nav-pills .show>.nav-link {
    color: #fff;
    background-color: #b68c64 !important;
  }

</style>

 