<template>
<div>  
  <transition name="fade">
        <div v-if="show2" class="progres-content">
          <div  class="margenTestHead">  
              <div  style="display: flex; flex-direction: column; align-items: center; margin: 30px; text-align: center; background: white;   display: flex; align-items: center; justify-content: center;margin: 40px;">
                  <img  src="/uploads/logo.png"  style="width: 450px;"> 
              </div>
          </div>
          <div>
             <div id="total" class="outerTest" :style="{width:setPHead()}">
                  <div id="progress" class="innerTest" :style="{width:setPBody()}"></div>
              </div>  
          </div> 
          <b-container class="bv-example-row" style="margin-top:60px;margin-bottom:60px">
            <b-row>  
              <b-col v-bind:key="index"  v-for="(item, index) in mainLoteries"  md="4" lg="4" sm="12">
                  <div class="text-center card-main">
                    <div class="card-main-img">
                      <div bg-variant="light" header="Light">
                        <img v-bind:src="item.image" class="img" style="object-fit: scale-down;">  
                      </div> 
                    </div>
                  </div> 
              </b-col>  
            </b-row>
          </b-container>
      </div>
  </transition>
  <b-container class="bv-example-row" style="margin-top:10%;margin-bottom:60px;  display: flex; justify-content: center; align-items: center">
    <b-row>
      <b-col @click="showLottery(item)" v-bind:key="index"  v-for="(item, index) in mainLoteries"   md="4" lg="4" sm="12">
          <div class="text-center card-main">
            <div class="card-main-img">
              <div bg-variant="light" header="Light">
                <img v-bind:src="item.image" class="img" style="object-fit: scale-down;">  
              </div> 
            </div>
            <div class="card-main-title">
              <span style="color: #fdad59; font-weight: 500; text-transform: uppercase">Jugar</span>
            </div>
          </div> 
      </b-col>
    </b-row>
  </b-container>
  <b-modal v-model="show"  title="Renovar suscripción" :header-bg-variant="headerBgVariant"  :header-text-variant="headerTextVariant"  >
    <b-container fluid> 
          <div><p> Su subscripción finaliza en {{countDayExpired}} días, renuévelo para seguir teniendo acceso al menú de Resultados, Analítica y Pronóstico Mix.  </p></div> 
      <div>
          <div style="background: #00974D; padding: 15px; border-radius: 5px; border: 5px solid white; cursor: pointer;display: flex;  justify-content: center; height: 65px;" @click="menuSubscription()">
            <div style="color: white;"><i class="fas fa-bags-shopping"></i></div>
            <div style="margin-left: 10px;"><h4 style="color: white; font-weight: 500;font-size: inherit;">Suscribirse</h4></div>   					
          </div>
      </div>
    </b-container>

    <template #modal-footer>
      <div class="w-100"> 
        <div style="cursor: pointer;" @click="show=false" class="float-right">
          Cerrar
        </div> 
      </div>
    </template>
  </b-modal>
  <b-modal v-model="showPromotion"  title="Pagar" :header-bg-variant="headerBgVariant"  :header-text-variant="headerTextVariant" >
    <b-container fluid>
          <div  class="modalSubtitle"  >  Elija su método de pago  </div>
      <div>
        <div ref="paypal" ></div>
      </div>
    </b-container>
    <template #modal-footer>
      <div class="w-100">
        <div style="cursor: pointer;" @click="showPromotion=false" class="float-right">
          Cerrar
        </div>
      </div>
    </template>
  </b-modal>
 </div> 
</template>

<script>
import { mapState,mapActions } from 'vuex'
import VueCookie from 'vue-cookie'

import Swal from 'sweetalert2';
export default { 
  name: 'frontend-home-test',
  components: {
    // carousel,
    // Slider
  },
  data(){
    return { 
      headerBgVariant: 'primary',
      headerTextVariant: 'light',
      img_banner: '/uploads/2020/05/blog10.jpg', 
      show: false,   
      showPromotion: false,  
      show2:true,
      pBodyBar:0,
      value: 45,
      max: 100

    } 
  },
  mounted() { 
    this.validateOneSubscription()  
    this.getLoteriesMain({})
     this.activePay()
    this.listNewNotification({})
    this.hiddenLoading() 
    this.verifyPromotion();
    // this.init()
   },
    methods: {
    ...mapActions({
      getLoteriesMain: 'getLoteriesMain',
      timeOneSubscription: 'timeOneSubscription', 
      listNewNotification: 'listNewNotification',
      createOneSubscription: 'createOneSubscription',
      validateOneSubscription: 'validateOneSubscription',
      renovateOneSubscription: 'renovateOneSubscription'
    }),
    menuSubscription(){
      location.href ='/perfil?suscription=true';
    },
    verifyPromotion(){  
      if (this.$route.query.price === undefined || this.$route.query.months === undefined) {
          return false;
      } else {
        this.activePayPromotion();
      } 
    },
    activePayPromotion:function( ){    
            this.valorPaquete = this.$route.query.price ;
            this.mesesPaquete = this.$route.query.months; 
                    this.showPromotion = true;  
                    const script = document.createElement("script");
                        script.src = "https://www.paypal.com/sdk/js?client-id=AX8EVyFc1JuBRVw0wP80QzZY1ziLbaC7zMuV5vAAREjOZEIE2jF3FSOpTSMjEKAUc2UnYLrmU9xwYvA5";
                        script.addEventListener("load", this.setLoaded);
                        document.body.appendChild(script);   
        },
        setLoaded: function() {  
                window.paypal
                .Buttons({ 
                    createOrder: (data, actions) => {
                      return actions.order.create({ 
                          purchase_units: [
                          {
                              description: "Compra de una suscripcion de 9 dolares mensual",
                              amount: { 
                              currency_code: "USD",
                              value: this.valorPaquete
                              } 
                          }, 
                          ]
                      });
                    },
                    onApprove: async (data, actions) => {
                        // const order = 
                      await actions.order.capture();
                      
                                  let params = { meses: parseInt(this.mesesPaquete), precio: parseInt(this.mesesPaquete) }
                                  if (this.existeSubscription == false) { 
                                      await this.createOneSubscription(params); 
                                      // await this.renovateOneSubscription(params);
                                  }else{ 
                                      await this.renovateOneSubscription(params); 
                                  }
                                  this.validateOneSubscription();

                          // ----------------------------------------
                    
                          this.showPromotion = false;
                          Swal.fire('¡Suscripción realizada!', '', 'success')
                    },
                    onError: err => {
                        console.log(err);
                        Swal.fire('¡Vuelva a intentarlo más tarde!', '', 'success')
                    }
                })
                .render(this.$refs.paypal);
        },
    // async init() {

    //   const google = await gmapsInit();
    //   //const geocoder = new google.maps.Geocoder();
    //   //const map = new google.maps.Map(this.$el);
    //   const geocoder = new google.maps.Geocoder()
    //   navigator.geolocation.getCurrentPosition(function(position) {
    //     let lngLnt = { lat: parseFloat(position.coords.latitude), lng: parseFloat(position.coords.longitude) }
    //     geocoder.geocode({ location: lngLnt }, (results) => {
    //       console.log("results[0]", results);
    //     });
    //   })
    // },
   
    setPHead:function () {
      return '500px';
    },
    setPBody:function () {
      
      return this.pBodyBar+'px';
    },
    async hiddenLoading(){  
        setTimeout(() => {
                this.show2 = false;  
        }, 2000);
        var intervalo;
        var tope= 0;
        intervalo=setInterval(() => { 
        tope++;
        this.pBodyBar = this.pBodyBar + 1;
        if (tope>=500) {
          clearInterval(intervalo);
        }
        },10); 
        },
        showLottery(item) {
          const authorization = VueCookie.get('costumer_token');
          if (authorization) {
            this.$router.push(`/loteria/${item.id}/${item.name}`)
          } else {
            //this.$root.$emit('bv::show::modal', 'authenticated', '#btnShowAnalysis')
            this.$router.push('/login-client')
          }
        },
        async activePay(){  
          await this.timeOneSubscription() 
          this.show = this.alertaWillExpiredSubscription;
                    // console.log('this.show: ', this.show);
                    // const script = document.createElement("script");
                    //     script.src = "https://www.paypal.com/sdk/js?client-id=AWFR6DpC6P2bboUuBVAMxB9MZysiB4mbpcilwqdxqPCGY3tVTFMcBK6XQ_c-5gr-cm0kKM0KlXq11E84";
                    //     script.addEventListener("load", this.setLoaded);
                    //     document.body.appendChild(script);
        },

  }, 
  computed: {
    ...mapState({
        mainLoteries: state => state.lotto.mainLoteries, 
        lastFlorida: state => state.lotto.lastFlorida,
        lastPowerball: state => state.lotto.lastPowerball,
        lastMegamillion: state => state.lotto.lastMegamillion,
        lastTinka: state => state.lotto.lastTinka,
        alertaWillExpiredSubscription: state => state.subscription.alertaWillExpiredSubscription,
        countDayExpired:  state => state.subscription.countDayExpired,
        existeSubscription: state =>state.subscription.existeSubscription,
    }) 
  } 
}

</script>

<style scoped>
.progress {
    height: 1rem;
    line-height: 0;
    font-size: .75rem;
    background-color: #e9ecef;
    border-radius: .25rem;
}
.progres-content {
    background:white;
    position: fixed; 
    top: 0px; 
    left: 0; 
    width: 100%; 
    height: 100%;
    z-index: 993;
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    justify-content: center;
}
    .card-main { 
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-self: center;
      height: 220px;
      border: 1px solid rgba(0, 0, 0, 0.125) !important;

      background-color: #fff;
      background: #fff;

      border-radius: 1px;

      transition: transform .3s;
      transform: translate3d(0, 0, 0);
      cursor: pointer;
      box-shadow: 0 1px 1px rgb(0 0 0 / 4%), 0 2px 2px rgb(0 0 0 / 6%), 0 4px 4px rgb(0 0 0 / 8%), 0 8px 8px rgb(0 0 0 / 10%);
    }
    .card-main:hover {
      transform: translate3d(0, -6px, 0);
    }
    .card-main-img {
      flex: 1 1 auto;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 20px;
    }
    .card-main-title { 
      padding: 20px;

      border-top: 2px solid rgba(0, 0, 0, 0.125) !important;
    }

    .card-main-title span {
       font-size: 1.5rem;
      font-weight: 400;
      line-height: 1.5;
      color: #212529;
     }
    .card-main .img {
      width: 220px;
    }
    .outerTest { 
      height: 20px;
      background: #e6e6e6;
      border-radius: 15px;
    }
    .innerTest { 
      height: 20px;
      background: #0f8c1b;
      border-radius: 15px;
    }
    .fade-enter-active, .fade-leave-active {
      transition: opacity .3s
    }
    .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
      opacity: 0
    }   
    .margenTestHead{
      display: flex; 
      align-items: center; 
      justify-content: space-evenly; 
      flex-wrap: wrap;  
    }
  .margenTest{
    display: flex; 
    align-items: center; 
    justify-content: space-evenly; 
    flex-wrap: wrap;  
    margin: 0px 27%;
  }
  @media (max-width: 1000px) {

    .margenTestHead{
      display: flex; 
      align-items: center; 
      justify-content: space-evenly; 
      flex-wrap: wrap;  
    }
    .margenTest{
      margin: 0px 15%;
    }
  }
  .elementor-row {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}
.elementor-column-wrap {
    width: 100%;
}
.elementor-element .elementor-widget-container {
    -webkit-transition: background .3s,border .3s,-webkit-border-radius .3s,-webkit-box-shadow .3s;
    transition: background .3s,border .3s,-webkit-border-radius .3s,-webkit-box-shadow .3s;
    -o-transition: background .3s,border .3s,border-radius .3s,box-shadow .3s;
    transition: background .3s,border .3s,border-radius .3s,box-shadow .3s;
    transition: background .3s,border .3s,border-radius .3s,box-shadow .3s,-webkit-border-radius .3s,-webkit-box-shadow .3s;
}
.icon-box-2 {
    box-shadow: 0 2px 6px 0 rgba(0,0,0,0.07);
    padding: 40px;
    background: #fff;
    border-radius: 4px;
    transition: 0.4s;
    cursor: default;
}
.elementor-column, .elementor-column-wrap {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}
  .elementor-column-gap-extended>.elementor-row>.elementor-column>.elementor-element-populated {
      padding: 15px;
  }
  
  .section-padding {
      padding-top: 40px;
      padding-bottom: 40px;
  }
  ol li:first-child, ul li:first-child {
      /*padding-top: unset !important;*/
    }
  .number-list li {
      width: 30px;
      height: 30px;
      color: var(--heading-color);
      border: 1px solid #9fa1a7;
      font-size: 14px;
      text-align: center;
      line-height: 30px;
      display: inline-block; 
      border-radius: 50%;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      -ms-border-radius: 50%;
      -o-border-radius: 50%;
  }
  .number-list li.active { 
    background-color:  red;
    color: #ffffff; 
    border-color:  red;
    box-shadow: 0 10px 10px 0 rgba(51, 181, 247, 0.15);
  }
  ul li {
      line-height: 20px;
      padding: 0px 0;
  }
  .icon-box-2 {
    text-align: center;
    margin-top: 45px;
    position: relative;
    display: flex;
    flex-direction: column;
}
</style>