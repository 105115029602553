<template>
    <div class="responsive-result-height" style="overflow: hidden scroll;">

        <div style="display: flex; flex-flow: row wrap">
            <div class="tooltip-button-variantdate" style="display: flex; flex-direction: row; ">
                <div   style="margin-bottom: 0rem !important ">
                    <label  style="margin-bottom: 0;">Desde</label>
                    <input type="date" class="form-control" v-model="date.from">
                </div>
                <div  style="margin-bottom: 0rem !important ">
                    <label style="margin-bottom: 0;">Hasta</label>
                    <div class="input-group mb-3" style=" display: flex;">
                        <input type="date" class="form-control"  v-model="date.to" placeholder="Recipient's username" aria-label="Recipient's username" aria-describedby="basic-addon2">
                        <div class="input-group-append"  >
                            <button class="btn-send" type="button" @click="rangePrimer">ENVIAR</button>
                        </div>
                    </div>
                </div>
            </div>
            <div style="display: flex;  flex-direction: row;"  >
              <div style="margin-left: 5px;" class="media-btn-year tooltip-seleccionar-bolilla">
                <label style="margin-bottom: 0;">Bolilla</label>
                <div class="input-group mb-3"  style=" display: flex;">
                  <select  @change="buscarBolillaResultado( )" v-model="date.selected" class="form-control" id="exampleFormControlSelect1">
                    <option > - </option>
                    <option v-for="(i, index5) in lotteryForecast.info.size" :key="index5" >   {{i}}   </option>
                  </select>
                </div>
              </div>
              <div class="tooltip-export-excel">
                <label  style="margin-left: 9px;"> Exportar
                  <div >
                    <button style="margin-top: 3px; margin-left: 2px;" :disabled="data.length ? false : true" class="btn btn-success" @click="_export"> Excel</button>
                  </div>
                </label>
              </div>
            </div>
        </div>
        <div class="tooltip-constelaciones-similares" v-if="showSuggestion"  @mousedown="moveSuggestion" @mouseup="stopSuggestion"  id="figur_1" style=" cursor: move;box-shadow: 0px 0px 10px black;position: absolute; background: white; width: 300px;  height: max-content; z-index: 3; border-radius: 5px; display: flex;flex-direction: column; text-align: center; padding: 10px;  ">
            <div >
                <div class="crossSuggestionCartillaJugar" v-on:click="closeSuggestionForecast()" >
                    <div><i class="fas fa-times-circle"></i></div>
                </div>
                <div> <p class="styleTextTwo" > Constelaciones similares: </p> </div>
                
                <div  style="overflow-x: auto; height: 240px " v-if="constellations.length != 0">
                    <div v-for="(constellation, index6) in constellations" :key="index6" style=" display: flex; justify-content: space-evenly; align-items: center;"  >
                        <div style="display: flex;flex-wrap: wrap; justify-content: space-evenly; margin: 5px 0px;">
                            
                            <div  class="numeroConstellationStyle" >
                                <p class="styleTextTwo"> {{constellation.fecha}} </p>
                            </div>
                        </div>
                        <div v-on:click="copySuggestionconstellation(index6)" class="copiarStyleForecast" >
                            <div style=" font-size: 20px;cursor: pointer;"><i class="fas fa-search"></i></div>
                        </div>
                    </div>
                </div>
                <div style="overflow-x: auto; height: 240px; display:flex" v-if="constellations.length === 0" >
                    <div style="align-self: center;" class="styleTextThree">
                        Sin constelaciones, elija otra cartilla.
                    </div>
                </div>
            </div> 
        </div>
        <div   >
            <div class="content-result ">

              <div class="showCartillasResultado content-cards-lottery tooltip-listado-jugadas">
                  <div class="cartillaOneResultado inner-content-resultado" v-for="(item, index3) in procartillas" :key="index3"
                      :style="{ background:selectCartillaForecastTestStyle(index3)}"
                      v-on:click="selectCartillaForecastTest(index3, item.fecha, true)"
                      >
                  <div style="display: flex; justify-content: center; flex-direction: column;  height: max-content;" :style="{ width:validarLoteriaLittle() }">

                      <div> <p style="font-size: 15px;  margin: 0px 0 0px; color: rgb(80, 100, 102); font-weight: 600; text-align: center;" >{{item.fecha}}</p> </div>
                      <div style="display: flex; justify-content: center; flex-wrap: wrap;  height: max-content;" >
                          <div  class="listNumerosJugar" v-for="(i, index4) in lotteryForecast.info.size" :key="index4">
                              <div v-if="activeCrossForecastHotScroll(index3,i)"  > <i class="fas fa-times  activehotScroll" ></i> </div>
                              <div v-if="activeCrossForecastColdScroll(index3,i)"  > <i class="fas fa-times  activecoldScroll" ></i> </div>
                              <div v-if="activeCrossForecastParScroll(index3,i)"  > <i class="fas fa-times  activeparScroll" ></i> </div>
                              <div v-if="activeCrossTestScroll(index3,i)" > <i  class="fas fa-times  activeCross" ></i> </div>
                              <div v-if="activeNumeroTestScroll(index3,i)">  </div>
                          </div>
                      </div>
                  </div>
                </div>
              </div>

                <div style="display: flex;flex-direction: row; " class="margin-top-70-less">

                    <div class="content-card-l">
                        <div  class="cartillaSelectedJugar tooltip-ciclo-aparicion">
                            <div style="display:flex;flex-wrap: wrap;justify-content: center;padding-top: 10px;position: relative; ">
                                <div class="numeros-test" :class="{desactiveBoton:jugarListCartillaTestStyle(i)}"  v-for="(i, index) in lotteryForecast.info.size" :key="index" v-on:click="activeBallTest(paginadorForecast,i)" >
                                    <div v-if="pintarcartillaJA1amarillo( paginadorForecast ,i)" style="position: absolute;z-index:0; background: #DC001A; opacity: 0.8; border-radius: 7px; height: 32px; width: 32px;"> <i class=" activeM3amarillo" ></i> </div>
                                    <div v-if="pintarcartillaJA2amarillo( paginadorForecast ,i)" style="position: absolute;z-index:0; background: #DC001A; opacity: 0.8; border-radius: 7px; height: 32px; width: 32px;"> <i class=" activeM3amarillo" ></i> </div>
                                    <div v-if="pintarcartillaJA3amarillo( paginadorForecast ,i)" style="position: absolute;z-index:0; background: #DC001A; opacity: 0.8; border-radius: 7px; height: 32px; width: 32px;"> <i class=" activeM3amarillo" ></i> </div>
                                    <div v-if="pintarcartillaJA4amarillo( paginadorForecast ,i)" style="position: absolute;z-index:0; background: #DC001A; opacity: 0.8; border-radius: 7px; height: 32px; width: 32px;"> <i class=" activeM3amarillo" ></i> </div>
                                    <div v-if="pintarcartillaJA5amarillo( paginadorForecast ,i)" style="position: absolute;z-index:0; background: #DC001A; opacity: 0.8; border-radius: 7px; height: 32px; width: 32px;"> <i class=" activeM3amarillo" ></i> </div>
                                    <div v-if="pintarcartillaJA6amarillo( paginadorForecast ,i)" style="position: absolute;z-index:0; background: #DC001A; opacity: 0.8; border-radius: 7px; height: 32px; width: 32px;"> <i class=" activeM3amarillo" ></i> </div>
                                    <div v-if="pintarcartillaJA7amarillo( paginadorForecast ,i)" style="position: absolute;z-index:0; background: #DC001A; opacity: 0.8; border-radius: 7px; height: 32px; width: 32px;"> <i class=" activeM3amarillo" ></i> </div>
                                    <div v-if="pintarcartillaJA8amarillo( paginadorForecast ,i)" style="position: absolute;z-index:0; background: #DC001A; opacity: 0.8; border-radius: 7px; height: 32px; width: 32px;"> <i class=" activeM3amarillo" ></i> </div>
                                    <div v-if="pintarcartillaJA9amarillo( paginadorForecast ,i)" style="position: absolute;z-index:0; background: #DC001A; opacity: 0.8; border-radius: 7px; height: 32px; width: 32px;"> <i class=" activeM3amarillo" ></i> </div>
                                    <div v-if="pintarcartillaJA10amarillo( paginadorForecast ,i)" style="position: absolute;z-index:0; background: #DC001A; opacity: 0.8; border-radius: 7px; height: 32px; width: 32px;"> <i class=" activeM3amarillo" ></i> </div>

                                    <div v-if="pintarcartillaJA1naranja( paginadorForecast ,i)" style="position: absolute;z-index:0; background: #660001; opacity: 0.8; border-radius: 7px; height: 32px; width: 32px;"> <i class=" activeM3naranja" ></i> </div>
                                    <div v-if="pintarcartillaJA2naranja( paginadorForecast ,i)" style="position: absolute;z-index:0; background: #660001; opacity: 0.8; border-radius: 7px; height: 32px; width: 32px;"> <i class=" activeM3naranja" ></i> </div>
                                    <div v-if="pintarcartillaJA3naranja( paginadorForecast ,i)" style="position: absolute;z-index:0; background: #660001; opacity: 0.8; border-radius: 7px; height: 32px; width: 32px;"> <i class=" activeM3naranja" ></i> </div>
                                    <div v-if="pintarcartillaJA4naranja( paginadorForecast ,i)" style="position: absolute;z-index:0; background: #660001; opacity: 0.8; border-radius: 7px; height: 32px; width: 32px;"> <i class=" activeM3naranja" ></i> </div>
                                    <div v-if="pintarcartillaJA5naranja( paginadorForecast ,i)" style="position: absolute;z-index:0; background: #660001; opacity: 0.8; border-radius: 7px; height: 32px; width: 32px;"> <i class=" activeM3naranja" ></i> </div>
                                    <div v-if="pintarcartillaJA6naranja( paginadorForecast ,i)" style="position: absolute;z-index:0; background: #660001; opacity: 0.8; border-radius: 7px; height: 32px; width: 32px;"> <i class=" activeM3naranja" ></i> </div>
                                    <div v-if="pintarcartillaJA7naranja( paginadorForecast ,i)" style="position: absolute;z-index:0; background: #660001; opacity: 0.8; border-radius: 7px; height: 32px; width: 32px;"> <i class=" activeM3naranja" ></i> </div>
                                    <div v-if="pintarcartillaJA8naranja( paginadorForecast ,i)" style="position: absolute;z-index:0; background: #660001; opacity: 0.8; border-radius: 7px; height: 32px; width: 32px;"> <i class=" activeM3naranja" ></i> </div>
                                    <div v-if="pintarcartillaJA9naranja( paginadorForecast ,i)" style="position: absolute;z-index:0; background: #660001; opacity: 0.8; border-radius: 7px; height: 32px; width: 32px;"> <i class=" activeM3naranja" ></i> </div>
                                    <div v-if="pintarcartillaJA10naranja( paginadorForecast ,i)" style="position: absolute;z-index:0; background: #660001; opacity: 0.8; border-radius: 7px; height: 32px; width: 32px;"> <i class=" activeM3naranja" ></i> </div>

                                    <div v-if="pintarcartillaJA1celeste( paginadorForecast ,i)" style="position: absolute;z-index:0; background: #F76300; opacity: 0.8; border-radius: 7px; height: 32px; width: 32px;"> <i class=" activeM3celeste" ></i> </div>
                                    <div v-if="pintarcartillaJA2celeste( paginadorForecast ,i)" style="position: absolute;z-index:0; background: #F76300; opacity: 0.8; border-radius: 7px; height: 32px; width: 32px;"> <i class=" activeM3celeste" ></i> </div>
                                    <div v-if="pintarcartillaJA3celeste( paginadorForecast ,i)" style="position: absolute;z-index:0; background: #F76300; opacity: 0.8; border-radius: 7px; height: 32px; width: 32px;"> <i class=" activeM3celeste" ></i> </div>
                                    <div v-if="pintarcartillaJA4celeste( paginadorForecast ,i)" style="position: absolute;z-index:0; background: #F76300; opacity: 0.8; border-radius: 7px; height: 32px; width: 32px;"> <i class=" activeM3celeste" ></i> </div>
                                    <div v-if="pintarcartillaJA5celeste( paginadorForecast ,i)" style="position: absolute;z-index:0; background: #F76300; opacity: 0.8; border-radius: 7px; height: 32px; width: 32px;"> <i class=" activeM3celeste" ></i> </div>
                                    <div v-if="pintarcartillaJA6celeste( paginadorForecast ,i)" style="position: absolute;z-index:0; background: #F76300; opacity: 0.8; border-radius: 7px; height: 32px; width: 32px;"> <i class=" activeM3celeste" ></i> </div>
                                    <div v-if="pintarcartillaJA7celeste( paginadorForecast ,i)" style="position: absolute;z-index:0; background: #F76300; opacity: 0.8; border-radius: 7px; height: 32px; width: 32px;"> <i class=" activeM3celeste" ></i> </div>
                                    <div v-if="pintarcartillaJA8celeste( paginadorForecast ,i)" style="position: absolute;z-index:0; background: #F76300; opacity: 0.8; border-radius: 7px; height: 32px; width: 32px;"> <i class=" activeM3celeste" ></i> </div>
                                    <div v-if="pintarcartillaJA9celeste( paginadorForecast ,i)" style="position: absolute;z-index:0; background: #F76300; opacity: 0.8; border-radius: 7px; height: 32px; width: 32px;"> <i class=" activeM3celeste" ></i> </div>
                                    <div v-if="pintarcartillaJA10celeste( paginadorForecast ,i)" style="position: absolute;z-index:0; background: #F76300; opacity: 0.8; border-radius: 7px; height: 32px; width: 32px;"> <i class=" activeM3celeste" ></i> </div>

                                    <div v-if="pintarcartillaAgrupacionJA1( paginadorForecast ,i)" style="position: absolute;z-index:0; position: absolute; z-index: 0; background: #8f8f8f; opacity: 0.8; border-radius: 7px; height: 32px; width: 32px;"> <i class=" activecartillaagrupacionM3" ></i> </div>
                                    <div v-if="pintarcartillaAgrupacionJA2( paginadorForecast ,i)" style="position: absolute;z-index:0; position: absolute; z-index: 0; background: #8f8f8f; opacity: 0.8; border-radius: 7px; height: 32px; width: 32px;"> <i class=" activecartillaagrupacionM3" ></i> </div>
                                    <div v-if="pintarcartillaAgrupacionJA3( paginadorForecast ,i)" style="position: absolute;z-index:0; position: absolute; z-index: 0; background: #8f8f8f; opacity: 0.8; border-radius: 7px; height: 32px; width: 32px;"> <i class=" activecartillaagrupacionM3" ></i> </div>
                                    <div v-if="pintarcartillaAgrupacionJA4( paginadorForecast ,i)" style="position: absolute;z-index:0; position: absolute; z-index: 0; background: #8f8f8f; opacity: 0.8; border-radius: 7px; height: 32px; width: 32px;"> <i class=" activecartillaagrupacionM3" ></i> </div>
                                    <div v-if="pintarcartillaAgrupacionJA5( paginadorForecast ,i)" style="position: absolute;z-index:0; position: absolute; z-index: 0; background: #8f8f8f; opacity: 0.8; border-radius: 7px; height: 32px; width: 32px;"> <i class=" activecartillaagrupacionM3" ></i> </div>
                                    <div v-if="pintarcartillaAgrupacionJA6( paginadorForecast ,i)" style="position: absolute;z-index:0; position: absolute; z-index: 0; background: #8f8f8f; opacity: 0.8; border-radius: 7px; height: 32px; width: 32px;"> <i class=" activecartillaagrupacionM3" ></i> </div>
                                    <div v-if="pintarcartillaAgrupacionJA7( paginadorForecast ,i)" style="position: absolute;z-index:0; position: absolute; z-index: 0; background: #8f8f8f; opacity: 0.8; border-radius: 7px; height: 32px; width: 32px;"> <i class=" activecartillaagrupacionM3" ></i> </div>
                                    <div v-if="pintarcartillaAgrupacionJA8( paginadorForecast ,i)" style="position: absolute;z-index:0; position: absolute; z-index: 0; background: #8f8f8f; opacity: 0.8; border-radius: 7px; height: 32px; width: 32px;"> <i class=" activecartillaagrupacionM3" ></i> </div>
                                    <div v-if="pintarcartillaAgrupacionJA9( paginadorForecast ,i)" style="position: absolute;z-index:0; position: absolute; z-index: 0; background: #8f8f8f; opacity: 0.8; border-radius: 7px; height: 32px; width: 32px;"> <i class=" activecartillaagrupacionM3" ></i> </div>
                                    <div v-if="pintarcartillaAgrupacionJA10( paginadorForecast ,i)" style="position: absolute;z-index:0; position: absolute; z-index: 0; background: #8f8f8f; opacity: 0.8; border-radius: 7px; height: 32px; width: 32px;"> <i class=" activecartillaagrupacionM3" ></i> </div>


                                    <!--CODE DINAMIC-->
                                    <div v-if="drawCardGroupJA( paginadorForecast ,i, 0)" style="position: absolute;z-index:0; position: absolute; z-index: 0; background: #8f8f8f; opacity: 0.8; border-radius: 7px; height: 32px; width: 32px;"> <i class=" activecartillaagrupacionM3"></i></div>
                                    <div v-if="drawCardGroupJA( paginadorForecast ,i, 2)" style="position: absolute;z-index:0; background: #DC001A; opacity: 0.8; border-radius: 7px; height: 32px; width: 32px;"> <i class=" activeM3amarillo" ></i> </div>
                                    <div v-if="drawCardGroupJA( paginadorForecast ,i, 3)" style="position: absolute;z-index:0; background: #660001; opacity: 0.8; border-radius: 7px; height: 32px; width: 32px;"> <i class=" activeM3naranja" ></i> </div>
                                    <div v-if="drawCardGroupJA( paginadorForecast ,i, 1)" style="position: absolute;z-index:0; background: #F76300; opacity: 0.8; border-radius: 7px; height: 32px; width: 32px;"> <i class=" activeM3celeste" ></i> </div>

                                    <!--CODE DINAMIC-->
                                    <div v-if="activeCrossForecastHot(i)"  > <i class="fas fa-times  activehot" ></i> </div>
                                    <div v-if="activeCrossForecastCold(i)"  > <i class="fas fa-times  activecold" ></i> </div>
                                    <div v-if="activeCrossForecastPar(i)"  > <i class="fas fa-times  activepar" ></i> </div>
                                    <div v-if="activeCrossTest(i)" > <i class="fas fa-times  activeCross" ></i> </div>
                                    <div v-if="activeNumeroTest(i)"> <p class="numero-test" >{{i}}</p> </div>
                                </div>
                            </div>
                        </div>
                        <div class="cartillaSelectedJugar" style="margin-top: 10px;" v-if="lotteryForecast.info.sub_length != 0" >
                        <div><p style="text-align: center;font-size: 20px;  margin: 0px 0 0px; color: rgb(80, 100, 102);  font-weight: 600;"> {{subnameForecast}} </p></div>
                        <div style="display:flex;flex-wrap: wrap;justify-content: center;">
                            <div class="numeros-test"    v-for="(i, index2) in lotteryForecast.info.sub_length" :key="index2" >
                                <div v-if="activeCrossExtraTest(i)"> <i  class="fas fa-times  activeCross" ></i> </div>
                                <div v-if="activeNumeroExtraTest(i)"><p class="numero-test" >{{i}}</p></div>
                                <div v-if="pintarcartillaExtraJA1enaranja( paginadorForecast ,i)" style="position: absolute;background: #660001; opacity: 0.8; border-radius: 7px; height: 32px; width: 32px;"> <i class="  activeM3naranja" ></i> </div>
                                <div v-if="pintarcartillaExtraJA2enaranja( paginadorForecast ,i)" style="position: absolute;background: #660001; opacity: 0.8; border-radius: 7px; height: 32px; width: 32px;"> <i class="  activeM3naranja" ></i> </div>
                                <div v-if="pintarcartillaExtraJA3enaranja( paginadorForecast ,i)" style="position: absolute;background: #660001; opacity: 0.8; border-radius: 7px; height: 32px; width: 32px;"> <i class="  activeM3naranja" ></i> </div>
                                <div v-if="pintarcartillaExtraJA4enaranja( paginadorForecast ,i)" style="position: absolute;background: #660001; opacity: 0.8; border-radius: 7px; height: 32px; width: 32px;"> <i class="  activeM3naranja" ></i> </div>
                                <div v-if="pintarcartillaExtraJA5enaranja( paginadorForecast ,i)" style="position: absolute;background: #660001; opacity: 0.8; border-radius: 7px; height: 32px; width: 32px;"> <i class="  activeM3naranja" ></i> </div>
                                <div v-if="pintarcartillaExtraJA6enaranja( paginadorForecast ,i)" style="position: absolute;background: #660001; opacity: 0.8; border-radius: 7px; height: 32px; width: 32px;"> <i class="  activeM3naranja" ></i> </div>
                                <div v-if="pintarcartillaExtraJA7enaranja( paginadorForecast ,i)" style="position: absolute;background: #660001; opacity: 0.8; border-radius: 7px; height: 32px; width: 32px;"> <i class="  activeM3naranja" ></i> </div>
                                <div v-if="pintarcartillaExtraJA8enaranja( paginadorForecast ,i)" style="position: absolute;background: #660001; opacity: 0.8; border-radius: 7px; height: 32px; width: 32px;"> <i class="  activeM3naranja" ></i> </div>
                                <div v-if="pintarcartillaExtraJA9enaranja( paginadorForecast ,i)" style="position: absolute;background: #660001; opacity: 0.8; border-radius: 7px; height: 32px; width: 32px;"> <i class="  activeM3naranja" ></i> </div>
                                <div v-if="pintarcartillaExtraJA10enaranja( paginadorForecast ,i)" style="position: absolute;background: #660001; opacity: 0.8; border-radius: 7px; height: 32px; width: 32px;"> <i class="  activeM3naranja" ></i> </div>


                                <div v-if="pintarcartillaExtraJA1eamarillo( paginadorForecast ,i)" style="position: absolute;background: #DC001A; opacity: 0.8; border-radius: 7px; height: 32px; width: 32px;"> <i class=" activeM3amarillo" ></i> </div>
                                <div v-if="pintarcartillaExtraJA2eamarillo( paginadorForecast ,i)" style="position: absolute;background: #DC001A; opacity: 0.8; border-radius: 7px; height: 32px; width: 32px;"> <i class=" activeM3amarillo" ></i> </div>
                                <div v-if="pintarcartillaExtraJA3eamarillo( paginadorForecast ,i)" style="position: absolute;background: #DC001A; opacity: 0.8; border-radius: 7px; height: 32px; width: 32px;"> <i class=" activeM3amarillo" ></i> </div>
                                <div v-if="pintarcartillaExtraJA4eamarillo( paginadorForecast ,i)" style="position: absolute;background: #DC001A; opacity: 0.8; border-radius: 7px; height: 32px; width: 32px;"> <i class=" activeM3amarillo" ></i> </div>
                                <div v-if="pintarcartillaExtraJA5eamarillo( paginadorForecast ,i)" style="position: absolute;background: #DC001A; opacity: 0.8; border-radius: 7px; height: 32px; width: 32px;"> <i class=" activeM3amarillo" ></i> </div>
                                <div v-if="pintarcartillaExtraJA6eamarillo( paginadorForecast ,i)" style="position: absolute;background: #DC001A; opacity: 0.8; border-radius: 7px; height: 32px; width: 32px;"> <i class=" activeM3amarillo" ></i> </div>
                                <div v-if="pintarcartillaExtraJA7eamarillo( paginadorForecast ,i)" style="position: absolute;background: #DC001A; opacity: 0.8; border-radius: 7px; height: 32px; width: 32px;"> <i class=" activeM3amarillo" ></i> </div>
                                <div v-if="pintarcartillaExtraJA8eamarillo( paginadorForecast ,i)" style="position: absolute;background: #DC001A; opacity: 0.8; border-radius: 7px; height: 32px; width: 32px;"> <i class=" activeM3amarillo" ></i> </div>
                                <div v-if="pintarcartillaExtraJA9eamarillo( paginadorForecast ,i)" style="position: absolute;background: #DC001A; opacity: 0.8; border-radius: 7px; height: 32px; width: 32px;"> <i class=" activeM3amarillo" ></i> </div>
                                <div v-if="pintarcartillaExtraJA10eamarillo( paginadorForecast ,i)" style="position: absolute;background: #DC001A; opacity: 0.8; border-radius: 7px; height: 32px; width: 32px;"> <i class=" activeM3amarillo" ></i> </div>

                                <div v-if="pintarcartillaExtraJA1eceleste( paginadorForecast ,i)" style="position: absolute;background: #F76300; opacity: 0.8; border-radius: 7px; height: 32px; width: 32px;"> <i class=" activeM3celeste" ></i> </div>
                                <div v-if="pintarcartillaExtraJA2eceleste( paginadorForecast ,i)" style="position: absolute;background: #F76300; opacity: 0.8; border-radius: 7px; height: 32px; width: 32px;"> <i class=" activeM3celeste" ></i> </div>
                                <div v-if="pintarcartillaExtraJA3eceleste( paginadorForecast ,i)" style="position: absolute;background: #F76300; opacity: 0.8; border-radius: 7px; height: 32px; width: 32px;"> <i class=" activeM3celeste" ></i> </div>
                                <div v-if="pintarcartillaExtraJA4eceleste( paginadorForecast ,i)" style="position: absolute;background: #F76300; opacity: 0.8; border-radius: 7px; height: 32px; width: 32px;"> <i class=" activeM3celeste" ></i> </div>
                                <div v-if="pintarcartillaExtraJA5eceleste( paginadorForecast ,i)" style="position: absolute;background: #F76300; opacity: 0.8; border-radius: 7px; height: 32px; width: 32px;"> <i class=" activeM3celeste" ></i> </div>
                                <div v-if="pintarcartillaExtraJA6eceleste( paginadorForecast ,i)" style="position: absolute;background: #F76300; opacity: 0.8; border-radius: 7px; height: 32px; width: 32px;"> <i class=" activeM3celeste" ></i> </div>
                                <div v-if="pintarcartillaExtraJA7eceleste( paginadorForecast ,i)" style="position: absolute;background: #F76300; opacity: 0.8; border-radius: 7px; height: 32px; width: 32px;"> <i class=" activeM3celeste" ></i> </div>
                                <div v-if="pintarcartillaExtraJA8eceleste( paginadorForecast ,i)" style="position: absolute;background: #F76300; opacity: 0.8; border-radius: 7px; height: 32px; width: 32px;"> <i class=" activeM3celeste" ></i> </div>
                                <div v-if="pintarcartillaExtraJA9eceleste( paginadorForecast ,i)" style="position: absolute;background: #F76300; opacity: 0.8; border-radius: 7px; height: 32px; width: 32px;"> <i class=" activeM3celeste" ></i> </div>
                                <div v-if="pintarcartillaExtraJA10eceleste( paginadorForecast ,i)" style="position: absolute;background: #F76300; opacity: 0.8; border-radius: 7px; height: 32px; width: 32px;"> <i class=" activeM3celeste" ></i> </div>

                                <div v-if="pintarcartillaExtraAgrupacionJA1e( paginadorForecast ,i)" style="position: absolute;background: #8f8f8f; opacity: 0.8; border-radius: 7px; height: 32px; width: 32px;"> <i class=" activecartillaagrupacionM3" ></i> </div>
                                <div v-if="pintarcartillaExtraAgrupacionJA2e( paginadorForecast ,i)" style="position: absolute;background: #8f8f8f; opacity: 0.8; border-radius: 7px; height: 32px; width: 32px;"> <i class=" activecartillaagrupacionM3" ></i> </div>
                                <div v-if="pintarcartillaExtraAgrupacionJA3e( paginadorForecast ,i)" style="position: absolute;background: #8f8f8f; opacity: 0.8; border-radius: 7px; height: 32px; width: 32px;"> <i class=" activecartillaagrupacionM3" ></i> </div>
                                <div v-if="pintarcartillaExtraAgrupacionJA4e( paginadorForecast ,i)" style="position: absolute;background: #8f8f8f; opacity: 0.8; border-radius: 7px; height: 32px; width: 32px;"> <i class=" activecartillaagrupacionM3" ></i> </div>
                                <div v-if="pintarcartillaExtraAgrupacionJA5e( paginadorForecast ,i)" style="position: absolute;background: #8f8f8f; opacity: 0.8; border-radius: 7px; height: 32px; width: 32px;"> <i class=" activecartillaagrupacionM3" ></i> </div>
                                <div v-if="pintarcartillaExtraAgrupacionJA6e( paginadorForecast ,i)" style="position: absolute;background: #8f8f8f; opacity: 0.8; border-radius: 7px; height: 32px; width: 32px;"> <i class=" activecartillaagrupacionM3" ></i> </div>
                                <div v-if="pintarcartillaExtraAgrupacionJA7e( paginadorForecast ,i)" style="position: absolute;background: #8f8f8f; opacity: 0.8; border-radius: 7px; height: 32px; width: 32px;"> <i class=" activecartillaagrupacionM3" ></i> </div>
                                <div v-if="pintarcartillaExtraAgrupacionJA8e( paginadorForecast ,i)" style="position: absolute;background: #8f8f8f; opacity: 0.8; border-radius: 7px; height: 32px; width: 32px;"> <i class=" activecartillaagrupacionM3" ></i> </div>
                                <div v-if="pintarcartillaExtraAgrupacionJA9e( paginadorForecast ,i)" style="position: absolute;background: #8f8f8f; opacity: 0.8; border-radius: 7px; height: 32px; width: 32px;"> <i class=" activecartillaagrupacionM3" ></i> </div>
                                <div v-if="pintarcartillaExtraAgrupacionJA10e( paginadorForecast ,i)" style="position: absolute;background: #8f8f8f; opacity: 0.8; border-radius: 7px; height: 32px; width: 32px;"> <i class=" activecartillaagrupacionM3" ></i> </div>



                                <!--DINAMIC CODE-->
                                <div v-if="drawCardExtraGroupJAE( paginadorForecast ,i, 0)" style="position: absolute;background: #660001; opacity: 0.8; border-radius: 7px; height: 32px; width: 32px;"> <i class="  activeM3naranja" ></i> </div>
                                <div v-if="drawCardExtraGroupJAE( paginadorForecast ,i, 3)" style="position: absolute;background: #DC001A; opacity: 0.8; border-radius: 7px; height: 32px; width: 32px;"> <i class=" activeM3amarillo" ></i> </div>
                                <div v-if="drawCardExtraGroupJAE( paginadorForecast ,i, 2)" style="position: absolute;background: #F76300; opacity: 0.8; border-radius: 7px; height: 32px; width: 32px;"> <i class=" activeM3celeste" ></i> </div>
                                <div v-if="drawCardExtraGroupJAE( paginadorForecast ,i, 1)" style="position: absolute;background: #8f8f8f; opacity: 0.8; border-radius: 7px; height: 32px; width: 32px;"> <i class=" activecartillaagrupacionM3" ></i> </div>
                                <!--DINAMIC CODE-->
                            </div>
                        </div>
                    </div>

                    </div>


                    <div style="margin-right: 8px;" >
                        <div class="tooltip-botons-jas" style="display: flex;flex-direction: row; margin-left: 5px; flex-wrap: wrap; width: 180px">
                            <div v-on:click="startClickAgrupacionJA1(paginadorForecast)" @mousedown="startDragAgrupacionJA1( paginadorForecast )"  @mouseup="stopDragAgrupacionJA1( paginadorForecast )" class="jugarPageOptionsBot btn-boot tooltip-boton-jax" style="  display: flex; align-items: center; justify-content: center; width: 50px "  > <p style="font-size: 14px;font-weight: 700;margin: 0px;">JA1</p>  </div>
                            <div v-on:click="startClickAgrupacionJA2(paginadorForecast)"  @mousedown="startDragAgrupacionJA2( paginadorForecast )"  @mouseup="stopDragAgrupacionJA2( paginadorForecast )" class="jugarPageOptionsBot btn-boot tooltip-boton-jax" style=" display: flex; align-items: center; justify-content: center; width: 50px"  > <p style="font-size: 13px;font-weight: 700;margin: 0px;">JA2</p>  </div>
                            <div v-on:click="startClickAgrupacionJA3(paginadorForecast)"  @mousedown="startDragAgrupacionJA3( paginadorForecast )"  @mouseup="stopDragAgrupacionJA3( paginadorForecast )" class="jugarPageOptionsBot btn-boot tooltip-boton-jax" style=" display: flex; align-items: center; justify-content: center; width: 50px"  > <p style="font-size: 13px;font-weight: 700;margin: 0px;">JA3</p>  </div>
                            <div v-on:click="startClickAgrupacionJA4(paginadorForecast)"  @mousedown="startDragAgrupacionJA4( paginadorForecast )"  @mouseup="stopDragAgrupacionJA4( paginadorForecast )" class="jugarPageOptionsBot btn-boot tooltip-boton-jax" style=" display: flex; align-items: center; justify-content: center; width: 50px"  ><p style="font-size: 13px;font-weight: 700;margin: 0px;">JA4</p>  </div>
                            <div v-on:click="startClickAgrupacionJA5(paginadorForecast)"  @mousedown="startDragAgrupacionJA5( paginadorForecast )"  @mouseup="stopDragAgrupacionJA5( paginadorForecast )" class="jugarPageOptionsBot btn-boot tooltip-boton-jax" style=" display: flex; align-items: center; justify-content: center; width: 50px"  ><p style="font-size: 13px;font-weight: 700;margin: 0px;">JA5</p>  </div>
                            <div v-on:click="startClickAgrupacionJA6(paginadorForecast)"  @mousedown="startDragAgrupacionJA6( paginadorForecast )"  @mouseup="stopDragAgrupacionJA6( paginadorForecast )" class="jugarPageOptionsBot btn-boot tooltip-boton-jax" style=" display: flex; align-items: center; justify-content: center; width: 50px"  ><p style="font-size: 13px;font-weight: 700;margin: 0px;">JA6</p>  </div>
                            <div v-on:click="startClickAgrupacionJA7(paginadorForecast)"  @mousedown="startDragAgrupacionJA7( paginadorForecast )"  @mouseup="stopDragAgrupacionJA7( paginadorForecast )" class="jugarPageOptionsBot btn-boot tooltip-boton-jax" style=" display: flex; align-items: center; justify-content: center; width: 50px"  ><p style="font-size: 13px;font-weight: 700;margin: 0px;">JA7</p>  </div>
                            <div v-on:click="startClickAgrupacionJA8(paginadorForecast)"  @mousedown="startDragAgrupacionJA8( paginadorForecast )"  @mouseup="stopDragAgrupacionJA8( paginadorForecast )" class="jugarPageOptionsBot btn-boot tooltip-boton-jax" style=" display: flex; align-items: center; justify-content: center; width: 50px"  ><p style="font-size: 13px;font-weight: 700;margin: 0px;">JA8</p>  </div>
                            <div v-on:click="startClickAgrupacionJA9(paginadorForecast)"  @mousedown="startDragAgrupacionJA9( paginadorForecast )"  @mouseup="stopDragAgrupacionJA9( paginadorForecast )" class="jugarPageOptionsBot btn-boot tooltip-boton-jax" style=" display: flex; align-items: center; justify-content: center; width: 50px"  ><p style="font-size: 13px;font-weight: 700;margin: 0px;">JA9</p>  </div>
                            <div v-on:click="startClickAgrupacionJA10(paginadorForecast)" @mousedown="startDragAgrupacionJA10( paginadorForecast )" @mouseup="stopDragAgrupacionJA10( paginadorForecast )" class="jugarPageOptionsBot btn-boot tooltip-boton-ja1x"   style="  display: flex; align-items: center; justify-content: center; width: 50px"  ><p style="font-size: 12px;font-weight: 700;margin: 0px;">JA10</p>  </div>

                            <button id="btnShowModalPreviousResult" @click="modal_previous_plays_result = !modal_previous_plays_result"
                                    class="jugarPageOptionsBot btn-boot tooltip-boton-1"  style="background: #218838; display: flex; align-items: center; justify-content: center; width: 50px"  >
                                <p style="font-size: 13px;font-weight: 700;margin: 0px;color: white; ">JAS</p>
                            </button>
                            <button @click="empyAgrupacions(paginadorForecast)" class="jugarPageOptionsBot btn-boot tooltip-boton-2" style="background: #17a2b8; color: white; display: flex; align-items: center; justify-content: center; width: 50px"  >
                                <i aria-hidden="true" class="fa fa-eraser" style="font-size: 18px;"></i>
                                <p style="font-size: 12px;font-weight: 700;margin: 0px;"> </p>
                            </button>
                          <div style="width: 100%" >
                            <div  >
                              <div class="jugarPageOptions tooltip-copiar-jugada" v-on:click="copiarJugadaResultado()" style="margin: 5px 5px">
                                <div ><i class="far fa-copy"></i></div>
                                <div ><p class="styleTextOne">COPIAR</p></div>
                              </div>
                            </div>
                            <div  >
                              <div  class="jugarPageOptions  tooltip-comparar-jugada" v-on:click="compararJugadaResultado()" style="margin: 5px 5px">
                                <div ><i class="fas fa-border-center-v"></i></div>
                                <div style="margin-left: 10px;"><p class="styleTextOne">COMPARAR </p></div>
                              </div>
                            </div>
                          </div>
                        </div>
                    </div>

                </div>


        </div>
        <!-- EL UPDATE NO FUNCION SI NO ESTA REDIBUJANDOSE LA VARIABLE -->
        <div hidden>
            <h3>Test: {{fechaAnalitica[0]}}</h3>
        </div>
        
        <b-modal v-model="show2"  title="Resultados" :header-bg-variant="headerBgVariant"  :header-text-variant="headerTextVariant" :body-bg-variant="bodyBgVariant" :body-text-variant="bodyTextVariant" :footer-bg-variant="footerBgVariant" :footer-text-variant="footerTextVariant" >
            <div  style="text-align:center; background: #d4cccc;" class="styleTextOne"  >  INDICACIONES  </div>
            <b-container fluid>
                
                <div >
                    <div style="display: flex;flex-direction: column; overflow-x: auto;height: 400px; width: 420px;padding-right: 20px;">
                        <div style="display: flex;  flex-direction: column; align-items: center; ">
                            <!-- <div style="border-style: double;height:20px; width:30px; margin-right: 5px; background: black" > </div> -->
                            <div style=" text-align: center;"> <p class="styleTextTwo" > RANGO DE FECHAS:  </p> </div>
                            <div style=" text-align: justify;"> <p class="styleTextFour" >  Permite seleccionar un rango de fechas para visualizar las cartillas en ese periodo de tiempo. </p> </div>
                            
                            <div style=" text-align: center;"> <p class="styleTextTwo" > MOSTRAR CICLO DE APARICIÓN:  </p> </div>
                            <div style=" text-align: justify;"> <p class="styleTextFour" > Al seleccionar uno de los números marcados de la cartilla principal, podremos visualizar el ciclo de aparicion de ese número. </p> </div>
                            
                            <div style=" text-align: center;"> <p class="styleTextTwo" > COPIAR JUGADA: </p> </div>
                            <div style=" text-align: justify;"> <p class="styleTextFour" >  Después de seleccionar una cartilla, la jugada sera trasladada al menu "Juego individual". </p> </div>
                            
                            <div style=" text-align: center;"> <p class="styleTextTwo" > COMPARAR JUGADAS: </p> </div>
                            <div style=" text-align: justify;"> <p class="styleTextFour" > Después de seleccionar una cartilla, nos mostrará una lista de fechas con las constelaciones similares. </p> </div>
                            <div style=" text-align: center;"> <p class="styleTextTwo" > CONSTELACIONES SIMILARES: </p> </div>
                            <div style=" text-align: justify;"> <p class="styleTextFour" > Listado de fechas, si le damos a "buscar" entonces visualizaremos la jugada en la cartilla principal. </p> </div>
                        </div>
                    </div>
                </div>
            </b-container>
            <template #modal-footer>
                <div class="w-100">
                    <div style="cursor: pointer;" @click="show2=false" class="float-right">
                        Cerrar
                    </div>
                </div>
            </template>
        </b-modal>
        
        <b-modal v-model="modal_previous_plays_result" title="Jugadas Anteriores" >
            <div class="d-block text-center">
                <div class="" style="display: flex; flex-direction: column; align-items: center">
                    <button class="btn btn-success mb-2" style="width: 250px" @click="modal_plays_count_result = !modal_plays_count_result"> Numero de Fechas</button>
                    <button class="btn btn-success mb-2" style="width: 250px" @click="modal_plays_range_result = !modal_plays_range_result"> Rango</button>
                </div>
            </div>
            <div class=" " style="display: flex; justify-content: flex-end">
                <b-button class="mt-3" variant="outline-gray" id="btnClosePlayPreviousResult"  @click="modal_previous_plays_result=false">Cerrar</b-button>
            </div>
        </b-modal>
        <b-modal v-model="modal_plays_range_result" title="Jugadas Anteriores" >
                 <loading :active.sync="isLoadingRange" :is-full-page="fullPageRange" style="top: -50px !important;"/>
            <div class="d-block text-center">
                <div style="display: flex; flex-direction: column; align-items: center">
                    <div class="mb-3">
                        <div v-if="errors.length" style="margin: 0 0 0px;">
                            <b >Por favor, corrija el(los) error(es):</b>
                            <ul style="margin-top:10px; margin-bottom:10px">
                                <li style="list-style-type: none;" v-for="(error, key) in errors" v-bind:key="'error' + key">
                                    <label for="">{{ error }}</label>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="mb-3">
                        <label class="form-label" style="text-align: left;">Desde*</label>
                        <date-picker
                            :class="[ errors.from.state ? 'play-range-error' : '']"
                            v-model="plays.from"
                            :disabled-date="disabledRange">
                        </date-picker>
                        <label class="error-label" v-if="errors.from.state" :class="[ errors.from.state ? 'play-range-error' : '']">
                            {{errors.from.value}}
                        </label>
                        <!--<input type="date" class="form-control" v-model="plays.from" >-->
                    </div>
                    <div class="mb-3">
                        <label class="form-label" style="text-align: left;">Hasta*</label>
                        <date-picker
                            :class="[ errors.from.state ? 'error' : '']"
                            v-model="plays.to"
                            :disabled-date="disabledRange">
                        </date-picker>
                        <label class="error-label" v-if="errors.to.state" :class="[ errors.to.state ? 'play-range-error' : '']">
                            {{errors.to.value}}
                        </label>
            
                    </div>
        
                </div>
    
            </div>
            <div class=" " style="display: flex; justify-content: flex-end" >
                <b-button class="mt-3" variant="outline-gray" @click="modal_plays_range_result=false">Cerrar</b-button>
                <b-button class="mt-3" variant="outline-success " id="btnConfirmPlayRangeResult" @click="confirmPlayRangeResult">Confirmar</b-button>
            </div>
        </b-modal>
        <b-modal v-model="modal_plays_count_result" title="Jugadas Anteriores">
                 <loading :active.sync="isLoadingCount" :is-full-page="fullPageCount" style="top: -50px !important;"/>
            <div>
                <div class="d-block text-center">
                    <div class="" style="display: flex; flex-direction: column; align-items: center">
                        Seleccione el numero de jugadas anteriores
                        <input :min='1' :max='10' :maxlength="2" type="number" v-model="plays.count" @input="validateInputJAS()">
                    </div>
                </div>
                <div class=" " style="display: flex; justify-content: flex-end">
                    <b-button class="mt-3" variant="outline-gray" @click="modal_plays_count_result=false">Cerrar</b-button>
                    <b-button class="mt-3" variant="outline-success " id="btnConfirmPlayCountResult"  @click="confirmPlayCountResult">Confirmar</b-button>
        
                </div>
            </div>
        </b-modal>
        <!-- LA LEYENDA -->

          <b-modal :id="'m-history-resultado'+val" hide-footer title="LEYENDA">
                         <div class="d-block text-center">
                             <div>
                                 <div style="display: flex;flex-direction: column;">
                                    <div style="display: flex;  flex-direction: row; align-items: center;">
                                        <div style="border-style: double;height:20px; width:30px; background:#68001C;margin-right: 5px;"> </div>
                                        <div> <p class="styleTextTwo"> Más frecuentes</p> </div>
                                    </div>
                                    <div style="display: flex;  flex-direction: row; align-items: center;">
                                        <div style="border-style: double;height:20px; width:30px; background:#027B76;margin-right: 5px;"> </div>
                                        <div> <p class="styleTextTwo"> Menos frecuentes</p> </div>
                                    </div>
                                    <div style="display: flex;  flex-direction: row; align-items: center;">
                                        <div style="border-style: double;height:20px; width:30px; background:#AB853A;margin-right: 5px;"> </div>
                                        <div> <p class="styleTextTwo"> Pares más frecuentes</p> </div>
                                    </div>
                                </div>
                             </div>
                         </div>

                         <div style="display: flex; justify-content: flex-end">
                             <b-button class="mt-3" variant="outline-gray"   @click="$bvModal.hide('m-history-resultado'+val)">Cerrar</b-button> 
                         </div>
                     </b-modal>
    </div>
    </div>
</template>

<script>
import { mapActions, mapState,mapMutations } from 'vuex';
import { EventBus }  from '../../../../plugins/event-bus';
import ApiForecast from '../../../../api/forecast'

// import 'vue-horizontal-scroll/dist/vue-horizontal-scroll.css'
// import HorizontalScroll from 'vue-horizontal-scroll'
import VueCookie from 'vue-cookie'
import swal from 'sweetalert2';
import moment from 'moment'
// import XLSX from 'xlsx';
import Excel from 'exceljs'
// import * as Excel from "exceljs";
import {saveAs} from "file-saver";
import DatePicker from 'vue2-datepicker';

export default {
    name: 'ResultadoPage',
    components: {
        DatePicker,
    },
    props: {
        val: Number
    },
    data() {
      
        return {
            errors: {
                from: {
                    value: 'El campo desde es requerido',
                    state: false
                },
                to:  {
                    value: 'El campo hasta es requerido',
                    state: false
                }
            },
            result_last: {},
            plays: {
                from:'',
                to:'',
                count: null
            },
            item: [],
            date: {
                from: '',
                to: '',
                name:'',
                selected:'-'//PARA EL SELECT
            },
            timeSpan : 1000,
            type:'',
            data: [["S","h","E" ], [1,2,3]],
            datados: [["S2","h2", "E2" ], [21,22,23]],
            statusClickGroupJA: [
                { status: false, click: 0, key: 2 },
                { status: false, click: 0, key: 4 },
                { status: false, click: 0, key: 6 },
            ],
            clicksJA10: 0,
            statusClickAgrupacionJA10:false ,
            clicksJA9: 0,
            statusClickAgrupacionJA9:false ,
            clicksJA8: 0,
            statusClickAgrupacionJA8:false ,
            clicksJA7: 0,
            statusClickAgrupacionJA7:false ,
            clicksJA6: 0,
            statusClickAgrupacionJA6:false ,
            clicksJA5: 0,
            statusClickAgrupacionJA5:false ,
            clicksJA4: 0,
            statusClickAgrupacionJA4:false ,
            clicksJA3: 0,
            statusClickAgrupacionJA3:false ,
            clicksJA2: 0,
            statusClickAgrupacionJA2:false ,
            clicksJA1: 0,
            statusClickAgrupacionJA1:false ,
            files: [],
            disabled: true,
            show2:false,
            modal_previous_plays_result: false,
            modal_plays_range_result: false,
            modal_plays_count_result: false,
            clicksNeeded : 2,
            headerBgVariant: 'primary',
            headerTextVariant: 'light',
            bodyBgVariant: 'light',
            bodyTextVariant: 'dark',
            footerBgVariant: 'light',
            footerTextVariant: 'dark',
            allInCartillas: [], //TODAS LAS CARTILLAS DESDE EL 2015 YA TRANSFORMADAS
            showSuggestion:false,
            show: false,
            paginadorForecast:0,
            r: 0,
            stateSidebar:false,
            procartillas:[], //Las nuevas cartillas aleatorias
            max:69,
            min:1
        };
    },
    computed: {
        ...mapState({
            constellations: state => state.results.constellations,
            fechaAnalitica: state => state.results.fechaAnalitica,
            resultCartillasNormal: state => state.results.resultCartillasNormal,
            resultCartillasLaggards: state => state.results.resultCartillasLaggards,
            existeSubscription: state =>state.subscription.existeSubscription,
            lottery_: state => state.lottery.lottery,
            lotteryForecast:state => state.forecast.analytic.lotteryForecast,
            subnameForecast: state =>       state.forecast.analytic.subnameForecast ,
            descriptionForecast: state =>   state.forecast.analytic.descriptionForecast ,
            nombreForecast: state =>        state.forecast.analytic.nombreForecast ,
            listColdForecast: state =>      state.forecast.analytic.listColdForecast ,
            listHotForecast: state =>       state.forecast.analytic.listHotForecast ,
            pares :state => state.forecast.analytic.pares,
        })
    },
    methods: {
        ...mapActions({
            getForecastResult:'getForecastResult',
            getLoterieForecast: 'getLoterieForecast',
            fetchTopResult: 'fetchTopResult',
            fetchRangeResult: 'fetchRangeResult',
            searchBallResult:'searchBallResult',
            constellationSame: 'constellationSame',
            allInResult: 'allInResult',
            createCartLottery: 'createCartLottery',  // Subo las nuevas cartillas al checkout
            listForecast:'listForecast',
            listForecastRange: 'listForecastRange'
        }),
        ...mapMutations({
            RELOAD_CARTILLAS_PRONOSTICO_RESULTADO: 'RELOAD_CARTILLAS_PRONOSTICO_RESULTADO',
            BALL_APPEARANCE: 'BALL_APPEARANCE',
            NAVIGATE_TABS: 'NAVIGATE_TABS',
            DATE_APPEARANCE_CLOSE: 'DATE_APPEARANCE_CLOSE',
            NAVIGATE_TAB_PRINCIPAL:'NAVIGATE_TAB_PRINCIPAL',
            RESULT_LIST_NORMAL:'RESULT_LIST_NORMAL',
            RESULT_LIST_LAGGARDS:'RESULT_LIST_LAGGARDS'
        }),
        showModalPreviousResult(){
         
           //this.$root.$emit('bv::show::modal', 'modal-previous-plays-result', '#btnShowModalPreviousResult')
            //this.$root.$emit('bv::hide::modal', 'modal-previous-plays-result', '#btnShowModalPreviousResult')
            
        },
        validateInputJAS() {
            let regex = /^[+]?\d+(\d+)?$/
            if(!regex.exec(this.plays.count)) this.plays.count = null
        },
        async confirmPlayRangeResult(){
            this.isLoadingRange = true
            setTimeout(async () => {
                if (this.plays.from && this.plays.to) {
                    let data = await this.listForecastRange({
                        id: this.$route.params.id,
                        name: this.$route.params.name,
                        type: 0,
                        from: moment(this.plays.from).format('YYYY-MM-DD'),
                        to: moment(this.plays.to).format('YYYY-MM-DD')
                    })
                    this.startDrawGroupJA(this.paginadorForecast, data, data.previous_plays.length)
                    this.plays.count = null
                    this.modal_previous_plays_result = false;
                    this.modal_plays_range_result = false;
                } else {
                
                    this.errors.from.state = null
                    let myElements = document.querySelectorAll(".mx-input");
                
                    if (!this.plays.from)  {
                        myElements[0].style.setProperty('border', '1px solid red', 'important');
                        this.errors.from.state = true
                    } else {
                        myElements[0].style.setProperty('border', '1px solid #ccc', 'important');
                        this.errors.from.state = false
                    }
                    if (!this.plays.to) {
                        myElements[1].style.setProperty('border', '1px solid red', 'important');
                        this.errors.to.state = true
                    } else {
                        myElements[1].style.setProperty('border', '1px solid #ccc', 'important');
                        this.errors.to.state = false
                    }
                }
                this.isLoadingRange = false
            }, 2000)
        },
        async confirmPlayCountResult() {
            this.isLoadingCount = true
            let data = await this.listForecast({
                id: this.$route.params.id,
                name: this.$route.params.name,
                type: 0,
                limit: this.plays.count
            })
            setTimeout(() => {
                this.startDrawGroupJA(this.paginadorForecast, data, this.plays.count)
                this.plays.count = null
                this.modal_previous_plays_result = false;
                this.modal_plays_count_result = false;
                this.isLoadingCount = false
            }, 2000)
        },
        startDrawGroupJA(index, data, count_) {
            let count = count_ - 1;
            let previous_plays = data.previous_plays
            let previous_plays_extra = data.previous_plays_extra
            
            this.procartillas[index].ballGroupJA = []
            this.procartillas[index].ballGroupJAE = []
            this.procartillas[index].ballGroupJA = previous_plays[count]
            this.procartillas[index].ballGroupJAE = previous_plays_extra[count]
        },
        drawCardGroupJA(index, i, condition){
            if (this.procartillas[index].ballGroupJA) {
                for(let j = 0; j < this.procartillas[index].ballGroupJA.length; j++){
                    if(i === this.procartillas[index].ballGroupJA[j].balls && this.procartillas[index].ballGroupJA[j].count === condition ){
                        return true
                    }
                    if(i === this.procartillas[index].ballGroupJA[j].balls && this.procartillas[index].ballGroupJA[j].count >= condition ){
                        return true
                    }
                }
            }
        },
        drawCardExtraGroupJAE(index, i, condition) {
            if (this.procartillas[index].ballGroupJAE) {
                for(let j = 0; j < this.procartillas[index].ballGroupJAE.length; j++){
                    if(i === this.procartillas[index].ballGroupJAE[j].balls && this.procartillas[index].ballGroupJAE[j].count === condition){
                        return true
                    }
                    if(i === this.procartillas[index].ballGroupJAE[j].balls && this.procartillas[index].ballGroupJAE[j].count >= condition){
                        return true
                    }
                }
            }
        },
        disabledRange: function (date) {
            const day = new Date(date).getDay()
            const days = JSON.parse(this.lottery_.days);
            const array = [0,1,2,3,4,5,6,7]
            let YY = parseInt(moment( (this.lottery_.date_opening)).format('YYYY'))
            let MM = parseInt(moment( (this.lottery_.date_opening)).format('MM')) - 1
            let DD = parseInt(moment( (this.lottery_.date_opening)).format('DD'))
        
            let YY_ = parseInt(moment( (this.result_last.date)).format('YYYY'))
            let MM_ = parseInt(moment( (this.result_last.date)).format('MM')) - 1
            let DD_ = parseInt(moment( (this.result_last.date)).format('DD'))
        
            let difference = array.filter(x => !days.includes(x));
        
            let [one, two, three, four, five] = difference
        
            return date > new Date(YY_, MM_, DD_)
            || date < new Date(YY, MM, DD)
            || day === one
            || day === two
            || day === three
            || day === four ? five : one
            || day === five ? five : one
        },
        topTest:function(){
            if (this.nombreForecast == 'florida') {
                return '353px';
            }else{
                return '376px';
            }
        },
        leftTest:function(){
            if (this.nombreForecast == 'florida') {
                return '17px';
            }else{
                return '-18px';
            }
        },
        empyAgrupacions:function( index ){
            this.stopDragAgrupacionJA1(index)
            this.stopDragAgrupacionJA2(index)
            this.stopDragAgrupacionJA3(index)
            this.stopDragAgrupacionJA4(index)
            this.stopDragAgrupacionJA5(index)
            this.stopDragAgrupacionJA6(index)
            this.stopDragAgrupacionJA7(index)
            this.stopDragAgrupacionJA8(index)
            this.stopDragAgrupacionJA9(index)
            this.stopDragAgrupacionJA10(index)
            this.stopDrag( index )
            this.procartillas[index].ballGroupJA = []
            this.procartillas[index].ballGroupJAE = []
    
        },
        stopDrag:function( index ) {
            this.procartillas[index].ballcartilla = [];
            this.procartillas[index].ballcartillaExtra = null;
          
        },
        jugarListCartillaTestStyle(i){
            if (this.procartillas[this.paginadorForecast].balls.length === this.numberBallsForecast && !this.procartillas[this.paginadorForecast].balls.includes(i)) {
                return true;
            }
        },
        startClickAgrupacionJA1:function( index ) {
            this.empyAgrupacions(index);
            this.statusClickAgrupacionJA1 = !this.statusClickAgrupacionJA1;
            if (this.statusClickAgrupacionJA1) {
                this.procartillas[index].xJugada = ( this.lotteryForecast.bot.length - 1 >= 0 ) ? 0 : null;
                this.procartillas[index].ballAgrupacionJA1= ( this.lotteryForecast.ja.length - 1 >= 0 ) ? this.lotteryForecast.ja[0] : [];
                this.procartillas[index].ballAgrupacionJA1e= ( this.lotteryForecast.jae.length - 1 >= 0 ) ? this.lotteryForecast.jae[0] : [];

                this.selectCartillaForecastTest(this.paginadorForecast + 1, null, false)
                this.selectCartillaForecastTest(this.paginadorForecast - 1, null, false)
            } else {
                this.procartillas[index].xJugada = null;
                this.procartillas[index].ballAgrupacionJA1 = [];
                this.procartillas[index].ballAgrupacionJA1e = [];

            }
        },
        startClickAgrupacionJA2:function( index ) {
            this.empyAgrupacions(index);
            this.statusClickAgrupacionJA2 = !this.statusClickAgrupacionJA2;
            if (this.statusClickAgrupacionJA1) {
                this.procartillas[index].xJugada = ( this.lotteryForecast.bot.length - 1 >= 1 ) ? 1: null;
                this.procartillas[index].ballAgrupacionJA2= ( this.lotteryForecast.ja.length - 1 >= 1 ) ? this.lotteryForecast.ja[1] : [];
                this.procartillas[index].ballAgrupacionJA2e=( this.lotteryForecast.jae.length - 1 >= 1 ) ? this.lotteryForecast.jae[1] : [];
                this.selectCartillaForecastTest(this.paginadorForecast + 1, null, false)
                this.selectCartillaForecastTest(this.paginadorForecast - 1, null, false)
            } else {
                this.procartillas[index].xJugada = null;
                this.procartillas[index].ballAgrupacionJA2 = [];
                this.procartillas[index].ballAgrupacionJA2e = [];

            }
        },
        startClickAgrupacionJA3:function( index ) {
            this.empyAgrupacions(index);
            this.statusClickAgrupacionJA3 = !this.statusClickAgrupacionJA3;
            if (this.statusClickAgrupacionJA3) {
                this.procartillas[index].xJugada = ( this.lotteryForecast.bot.length - 1 >= 2 ) ? 2 :null;
                this.procartillas[index].ballAgrupacionJA3=  ( this.lotteryForecast.ja.length - 1 >= 2 ) ? this.lotteryForecast.ja[2]: [];
                this.procartillas[index].ballAgrupacionJA3e= ( this.lotteryForecast.jae.length - 1 >= 2 ) ? this.lotteryForecast.jae[2]: [];
                this.selectCartillaForecastTest(this.paginadorForecast + 1, null, false)
                this.selectCartillaForecastTest(this.paginadorForecast - 1, null, false)
            } else {
                this.procartillas[index].xJugada = null;
                this.procartillas[index].ballAgrupacionJA3 = [];
                this.procartillas[index].ballAgrupacionJA3e = [];
            }
        },
        startClickAgrupacionJA4:function( index ) {
            this.empyAgrupacions(index);

            this.statusClickAgrupacionJA4 = !this.statusClickAgrupacionJA4;
            if (this.statusClickAgrupacionJA4) {
                this.procartillas[index].xJugada = ( this.lotteryForecast.bot.length - 1 >= 3 ) ? 3 : null;
                this.procartillas[index].ballAgrupacionJA4=  ( this.lotteryForecast.ja.length - 1 >= 3 ) ? this.lotteryForecast.ja[3] : [];
                this.procartillas[index].ballAgrupacionJA4e= ( this.lotteryForecast.jae.length - 1 >= 3 ) ? this.lotteryForecast.jae[3] : [];
                this.selectCartillaForecastTest(this.paginadorForecast + 1, null, false)
                this.selectCartillaForecastTest(this.paginadorForecast - 1, null, false)
            } else {
                this.procartillas[index].xJugada = null;
                this.procartillas[index].ballAgrupacionJA4 = [];
                this.procartillas[index].ballAgrupacionJA4e = [];
            }
        },
        startClickAgrupacionJA5:function( index ) {
            this.empyAgrupacions(index);
            this.statusClickAgrupacionJA5 = !this.statusClickAgrupacionJA5;
            if (this.statusClickAgrupacionJA5) {
                this.procartillas[index].xJugada = ( this.lotteryForecast.bot.length - 1 >= 4 ) ? 4 : null;
                this.procartillas[index].ballAgrupacionJA5=  ( this.lotteryForecast.ja.length - 1 >= 4 ) ? this.lotteryForecast.ja[4] : [];
                this.procartillas[index].ballAgrupacionJA5e= ( this.lotteryForecast.jae.length - 1 >= 4 ) ? this.lotteryForecast.jae[4] : [];
                this.selectCartillaForecastTest(this.paginadorForecast + 1, null, false)
                this.selectCartillaForecastTest(this.paginadorForecast - 1, null, false)
            } else {
                this.procartillas[index].xJugada = null;
                this.procartillas[index].ballAgrupacionJA5 = [];
                this.procartillas[index].ballAgrupacionJA5e = [];
            }
        },
        startClickAgrupacionJA6:function( index ) {
            this.empyAgrupacions(index);
            if (this.statusClickAgrupacionJA6) {
                this.procartillas[index].xJugada = ( this.lotteryForecast.bot.length - 1 >= 5 ) ? 5 : null;
                this.procartillas[index].ballAgrupacionJA6=  ( this.lotteryForecast.ja.length - 1 >= 5 ) ? this.lotteryForecast.ja[5]: [];
                this.procartillas[index].ballAgrupacionJA6e= ( this.lotteryForecast.jae.length - 1 >= 5 ) ? this.lotteryForecast.jae[5]: [];
                this.selectCartillaForecastTest(this.paginadorForecast + 1, null, false)
                this.selectCartillaForecastTest(this.paginadorForecast - 1, null, false)
            } else {
                this.procartillas[index].xJugada = null;
                this.procartillas[index].ballAgrupacionJA6 = [];
                this.procartillas[index].ballAgrupacionJA6e = [];
            }
        },
        startClickAgrupacionJA7:function( index ) {
            this.empyAgrupacions(index);
            this.statusClickAgrupacionJA7 = !this.statusClickAgrupacionJA7;
            if (this.statusClickAgrupacionJA7) {
                this.procartillas[index].xJugada = ( this.lotteryForecast.bot.length - 1 >= 6 ) ? 6 : null;
                this.procartillas[index].ballAgrupacionJA7=  ( this.lotteryForecast.ja.length - 1 >= 6 ) ? this.lotteryForecast.ja[6] : [];
                this.procartillas[index].ballAgrupacionJA7e= ( this.lotteryForecast.jae.length - 1 >= 6 ) ? this.lotteryForecast.jae[6] : [];
                this.selectCartillaForecastTest(this.paginadorForecast + 1, null, false)
                this.selectCartillaForecastTest(this.paginadorForecast - 1, null, false)
            } else {
                this.procartillas[index].xJugada = null;
                this.procartillas[index].ballAgrupacionJA7 = [];
                this.procartillas[index].ballAgrupacionJA7e = [];
            }
        },
        startClickAgrupacionJA8:function( index ) {
            this.empyAgrupacions(index);

            this.statusClickAgrupacionJA8 = !this.statusClickAgrupacionJA8;
            if (this.statusClickAgrupacionJA8) {
                this.procartillas[index].xJugada = ( this.lotteryForecast.bot.length - 1 >= 7 ) ? 7 : null;
                this.procartillas[index].ballAgrupacionJA8= ( this.lotteryForecast.ja.length - 1 >= 7 ) ? this.lotteryForecast.ja[7] : [];
                this.procartillas[index].ballAgrupacionJA8e=( this.lotteryForecast.jae.length - 1 >= 7 ) ? this.lotteryForecast.jae[7] : [];
                this.selectCartillaForecastTest(this.paginadorForecast + 1, null, false)
                this.selectCartillaForecastTest(this.paginadorForecast - 1, null, false)
            } else {
                this.procartillas[index].xJugada = null;
                this.procartillas[index].ballAgrupacionJA8 = [];
                this.procartillas[index].ballAgrupacionJA8e = [];
            }
        },
        startClickAgrupacionJA9:function( index ) {
            this.empyAgrupacions(index);

            this.statusClickAgrupacionJA9 = !this.statusClickAgrupacionJA9;
            if (this.statusClickAgrupacionJA9) {
                this.procartillas[index].xJugada = ( this.lotteryForecast.bot.length - 1 >= 8 ) ? 8 : null;
                this.procartillas[index].ballAgrupacionJA9= ( this.lotteryForecast.ja.length - 1 >= 8 ) ? this.lotteryForecast.ja[8] : [];
                this.procartillas[index].ballAgrupacionJA9e=( this.lotteryForecast.jae.length - 1 >= 8 ) ? this.lotteryForecast.jae[8] : [];
                this.selectCartillaForecastTest(this.paginadorForecast + 1, null, false)
                this.selectCartillaForecastTest(this.paginadorForecast - 1, null, false)
            } else {
                this.procartillas[index].xJugada = null;
                this.procartillas[index].ballAgrupacionJA9 = [];
                this.procartillas[index].ballAgrupacionJA9e = [];
            }
        },
        startClickAgrupacionJA10:function( index ) {
            this.empyAgrupacions(index);
            this.statusClickAgrupacionJA10 = !this.statusClickAgrupacionJA10;
            if (this.statusClickAgrupacionJA10) {
                this.procartillas[index].xJugada = ( this.lotteryForecast.bot.length - 1 >= 9 ) ? 9 : null;
                this.procartillas[index].ballAgrupacionJA10= ( this.lotteryForecast.ja.length - 1 >= 9 ) ? this.lotteryForecast.ja[9] : [];
                this.procartillas[index].ballAgrupacionJA10e=( this.lotteryForecast.jae.length - 1 >= 9 ) ? this.lotteryForecast.jae[9] : [];
                this.selectCartillaForecastTest(this.paginadorForecast + 1, null, false)
                this.selectCartillaForecastTest(this.paginadorForecast - 1, null, false)
            } else {
                this.procartillas[index].xJugada = null;
                this.procartillas[index].ballAgrupacionJA10 = [];
                this.procartillas[index].ballAgrupacionJA10e = [];
            }
        },
        
        startDragAgrupacionJA1:function( index ) {
            // this.startValidate(this.pronosticoJA1);
            
            this.procartillas[index].ballAgrupacionJA1= ( this.lotteryForecast.ja.length - 1 >= 0 ) ? this.lotteryForecast.ja[0] : [];
            this.procartillas[index].ballAgrupacionJA1e= ( this.lotteryForecast.jae.length - 1 >= 0 ) ? this.lotteryForecast.jae[0] : [];
            this.selectCartillaForecastTest(this.paginadorForecast + 1, null, false)
            this.selectCartillaForecastTest(this.paginadorForecast - 1, null, false)
        },
        startDragAgrupacionJA2:function( index ) {
            // this.startValidate(this.pronosticoJA2);
            
            this.procartillas[index].ballAgrupacionJA2= ( this.lotteryForecast.ja.length - 1 >= 1 ) ? this.lotteryForecast.ja[1] : [];
            this.procartillas[index].ballAgrupacionJA2e= ( this.lotteryForecast.jae.length - 1 >= 1 ) ? this.lotteryForecast.jae[1] : [];
            this.selectCartillaForecastTest(this.paginadorForecast + 1, null, false)
            this.selectCartillaForecastTest(this.paginadorForecast - 1, null, false)
        },
        startDragAgrupacionJA3:function( index ) {
            // this.startValidate(this.pronosticoJA3);
            
            this.procartillas[index].ballAgrupacionJA3= ( this.lotteryForecast.ja.length - 1 >= 2 ) ? this.lotteryForecast.ja[2] : [];
            this.procartillas[index].ballAgrupacionJA3e= ( this.lotteryForecast.jae.length - 1 >= 2 ) ? this.lotteryForecast.jae[2] : [];
            this.selectCartillaForecastTest(this.paginadorForecast + 1, null, false)
            this.selectCartillaForecastTest(this.paginadorForecast - 1, null, false)
           },
        startDragAgrupacionJA4:function( index ) {
            // this.startValidate(this.pronosticoJA4);
            
            this.procartillas[index].ballAgrupacionJA4= ( this.lotteryForecast.ja.length - 1 >= 3 ) ? this.lotteryForecast.ja[3] : [];
            this.procartillas[index].ballAgrupacionJA4e= ( this.lotteryForecast.jae.length - 1 >= 3 ) ? this.lotteryForecast.jae[3] : [];
            this.selectCartillaForecastTest(this.paginadorForecast + 1, null, false)
            this.selectCartillaForecastTest(this.paginadorForecast - 1, null, false)
        },
        startDragAgrupacionJA5:function( index ) {
            // this.startValidate(this.pronosticoJA5);
            
            this.procartillas[index].ballAgrupacionJA5= ( this.lotteryForecast.ja.length - 1 >= 4 ) ? this.lotteryForecast.ja[4] : [];
            this.procartillas[index].ballAgrupacionJA5e= ( this.lotteryForecast.jae.length - 1 >= 4 ) ? this.lotteryForecast.jae[4] : [];
            this.selectCartillaForecastTest(this.paginadorForecast + 1, null, false)
            this.selectCartillaForecastTest(this.paginadorForecast - 1, null, false)
        },
        startDragAgrupacionJA6:function( index ) {
            // this.startValidate(this.pronosticoJA6);
            
            this.procartillas[index].ballAgrupacionJA6 = ( this.lotteryForecast.ja.length - 1 >= 5 ) ? this.lotteryForecast.ja[5] : [];
            this.procartillas[index].ballAgrupacionJA6e = ( this.lotteryForecast.jae.length - 1 >= 5 ) ? this.lotteryForecast.jae[5] : [];
            this.selectCartillaForecastTest(this.paginadorForecast + 1, null, false)
            this.selectCartillaForecastTest(this.paginadorForecast - 1, null, false)
        },
        startDragAgrupacionJA7:function( index ) {
            // this.startValidate(this.pronosticoJA7);
            
            this.procartillas[index].ballAgrupacionJA7= ( this.lotteryForecast.ja.length - 1 >= 6 ) ? this.lotteryForecast.ja[6] : [];
            this.procartillas[index].ballAgrupacionJA7e= ( this.lotteryForecast.jae.length - 1 >= 6 ) ? this.lotteryForecast.jae[6] : [];
            this.selectCartillaForecastTest(this.paginadorForecast + 1, null, false)
            this.selectCartillaForecastTest(this.paginadorForecast - 1, null, false)
        },
        startDragAgrupacionJA8:function( index ) {
            // this.startValidate(this.pronosticoJA8);
            
            this.procartillas[index].ballAgrupacionJA8 = ( this.lotteryForecast.ja.length - 1 >= 7 ) ? this.lotteryForecast.ja[7] : [];
            this.procartillas[index].ballAgrupacionJA8e = ( this.lotteryForecast.jae.length - 1 >= 7 ) ? this.lotteryForecast.jae[7] : [];
            this.selectCartillaForecastTest(this.paginadorForecast + 1, null, false)
            this.selectCartillaForecastTest(this.paginadorForecast - 1, null, false)
        },
        startDragAgrupacionJA9:function( index ) {
            // this.startValidate(this.pronosticoJA9);
            
            this.procartillas[index].ballAgrupacionJA9 = ( this.lotteryForecast.ja.length - 1 >= 8 ) ? this.lotteryForecast.ja[8] : [];
            this.procartillas[index].ballAgrupacionJA9e = ( this.lotteryForecast.jae.length - 1 >= 8 ) ?this.lotteryForecast.jae[8] : [];
            this.selectCartillaForecastTest(this.paginadorForecast + 1, null, false)
            this.selectCartillaForecastTest(this.paginadorForecast - 1, null, false)
        },
        startDragAgrupacionJA10:function( index ) {
            // this.startValidate(this.pronosticoJA10);
            
            this.procartillas[index].ballAgrupacionJA10=  ( this.lotteryForecast.ja.length - 1 >= 9 ) ? this.lotteryForecast.ja[9]  : [];
            this.procartillas[index].ballAgrupacionJA10e= ( this.lotteryForecast.jae.length - 1 >= 9 ) ? this.lotteryForecast.jae[9] : [];
            this.selectCartillaForecastTest(this.paginadorForecast + 1, null, false)
            this.selectCartillaForecastTest(this.paginadorForecast - 1, null, false)
        },
        
        stopDragAgrupacionJA1:function( index ) {
            this.procartillas[index].xJugada = null;
            this.procartillas[index].ballAgrupacionJA1 = [];
            this.procartillas[index].ballAgrupacionJA1e = [];
            this.selectCartillaForecastTest(this.paginadorForecast + 1, null, false)
            this.selectCartillaForecastTest(this.paginadorForecast - 1, null, false)
        },
        stopDragAgrupacionJA2:function( index ) {
            this.procartillas[index].xJugada = null;
            this.procartillas[index].ballAgrupacionJA2 = [];
            this.procartillas[index].ballAgrupacionJA2e = [];
            this.selectCartillaForecastTest(this.paginadorForecast + 1, null, false)
            this.selectCartillaForecastTest(this.paginadorForecast - 1, null, false)
        },
        stopDragAgrupacionJA3:function( index ) {
            this.procartillas[index].xJugada = null;
            this.procartillas[index].ballAgrupacionJA3 = [];
            this.procartillas[index].ballAgrupacionJA3e = [];
            this.selectCartillaForecastTest(this.paginadorForecast + 1, null, false)
            this.selectCartillaForecastTest(this.paginadorForecast - 1, null, false)
        },
        stopDragAgrupacionJA4:function( index ) {
            this.procartillas[index].xJugada = null;
            this.procartillas[index].ballAgrupacionJA4 = [];
            this.procartillas[index].ballAgrupacionJA4e = [];
            this.selectCartillaForecastTest(this.paginadorForecast + 1, null, false)
            this.selectCartillaForecastTest(this.paginadorForecast - 1, null, false)
        },
        stopDragAgrupacionJA5:function( index ) {
            this.procartillas[index].xJugada = null;
            this.procartillas[index].ballAgrupacionJA5 = [];
            this.procartillas[index].ballAgrupacionJA5e = [];
            this.selectCartillaForecastTest(this.paginadorForecast + 1, null, false)
            this.selectCartillaForecastTest(this.paginadorForecast - 1, null, false)
        },
        stopDragAgrupacionJA6:function( index ) {
            this.procartillas[index].xJugada = null;
            this.procartillas[index].ballAgrupacionJA6 = [];
            this.procartillas[index].ballAgrupacionJA6e = [];
            this.selectCartillaForecastTest(this.paginadorForecast + 1, null, false)
            this.selectCartillaForecastTest(this.paginadorForecast - 1, null, false)
        },
        stopDragAgrupacionJA7:function( index ) {
            this.procartillas[index].xJugada = null;
            this.procartillas[index].ballAgrupacionJA7 = [];
            this.procartillas[index].ballAgrupacionJA7e = [];
            this.selectCartillaForecastTest(this.paginadorForecast + 1, null, false)
            this.selectCartillaForecastTest(this.paginadorForecast - 1, null, false)
        },
        stopDragAgrupacionJA8:function( index ) {
            this.procartillas[index].xJugada = null;
            this.procartillas[index].ballAgrupacionJA8 = [];
            this.procartillas[index].ballAgrupacionJA8e = [];
            this.selectCartillaForecastTest(this.paginadorForecast + 1, null, false)
            this.selectCartillaForecastTest(this.paginadorForecast - 1, null, false)
        },
        stopDragAgrupacionJA9:function( index ) {
            this.procartillas[index].xJugada = null;
            this.procartillas[index].ballAgrupacionJA9 = [];
            this.procartillas[index].ballAgrupacionJA9e = [];
            this.selectCartillaForecastTest(this.paginadorForecast + 1, null, false)
            this.selectCartillaForecastTest(this.paginadorForecast - 1, null, false)
        },
        stopDragAgrupacionJA10:function( index ) {
            this.procartillas[index].xJugada = null;
            this.procartillas[index].ballAgrupacionJA10 = [];
            this.procartillas[index].ballAgrupacionJA10e = [];
            this.selectCartillaForecastTest(this.paginadorForecast + 1, null, false)
            this.selectCartillaForecastTest(this.paginadorForecast - 1, null, false)
        },
        
        pintarcartillaAgrupacionJA1:function(index,i){
            let j;
            for(j = 0; j < this.procartillas[index].ballAgrupacionJA1.length; j++){
                if(i === this.procartillas[index].ballAgrupacionJA1[j].balls && this.procartillas[index].ballAgrupacionJA1[j].count === 0 ){
                    return true
                }
            }
        },
        pintarcartillaAgrupacionJA2:function(index,i){
            let j;
            for(j = 0; j < this.procartillas[index].ballAgrupacionJA2.length; j++){
                if(i === this.procartillas[index].ballAgrupacionJA2[j].balls && this.procartillas[index].ballAgrupacionJA2[j].count === 0 ){
                    return true
                }
            }
        },
        pintarcartillaAgrupacionJA3:function(index,i){
            let j;
            for(j = 0; j < this.procartillas[index].ballAgrupacionJA3.length; j++){
                if(i === this.procartillas[index].ballAgrupacionJA3[j].balls && this.procartillas[index].ballAgrupacionJA3[j].count === 0 ){
                    return true
                }
            }
        },
        pintarcartillaAgrupacionJA4:function(index,i){
            let j;
            for(j = 0; j < this.procartillas[index].ballAgrupacionJA4.length; j++){
                if(i === this.procartillas[index].ballAgrupacionJA4[j].balls && this.procartillas[index].ballAgrupacionJA4[j].count === 0 ){
                    return true
                }
            }
        },
        pintarcartillaAgrupacionJA5:function(index,i){
            let j;
            for(j = 0; j < this.procartillas[index].ballAgrupacionJA5.length; j++){
                if(i === this.procartillas[index].ballAgrupacionJA5[j].balls && this.procartillas[index].ballAgrupacionJA5[j].count === 0 ){
                    return true
                }
            }
        },
        pintarcartillaAgrupacionJA6:function(index,i){
            let j;
            for(j = 0; j < this.procartillas[index].ballAgrupacionJA6.length; j++){
                if(i === this.procartillas[index].ballAgrupacionJA6[j].balls && this.procartillas[index].ballAgrupacionJA6[j].count === 0 ){
                    return true
                }
            }
        },
        pintarcartillaAgrupacionJA7:function(index,i){
            let j;
            for(j = 0; j < this.procartillas[index].ballAgrupacionJA7.length; j++){
                if(i === this.procartillas[index].ballAgrupacionJA7[j].balls && this.procartillas[index].ballAgrupacionJA7[j].count === 0 ){
                    return true
                }
            }
        },
        pintarcartillaAgrupacionJA8:function(index,i){
            let j;
            for(j = 0; j < this.procartillas[index].ballAgrupacionJA8.length; j++){
                if(i === this.procartillas[index].ballAgrupacionJA8[j].balls && this.procartillas[index].ballAgrupacionJA8[j].count === 0 ){
                    return true
                }
            }
        },
        pintarcartillaAgrupacionJA9:function(index,i){
            let j;
            for(j = 0; j < this.procartillas[index].ballAgrupacionJA9.length; j++){
                if(i === this.procartillas[index].ballAgrupacionJA9[j].balls && this.procartillas[index].ballAgrupacionJA9[j].count === 0 ){
                    return true
                }
            }
        },
        pintarcartillaAgrupacionJA10:function(index,i){
            let j;
            for(j = 0; j < this.procartillas[index].ballAgrupacionJA10.length; j++){
                if(i === this.procartillas[index].ballAgrupacionJA10[j].balls && this.procartillas[index].ballAgrupacionJA10[j].count === 0 ){
                    return true
                }
            }
        },
        pintarcartillaExtraAgrupacionJA1e:function(index,i){
            let j;
            for(j = 0; j < this.procartillas[index].ballAgrupacionJA1e.length; j++){
                if(i === this.procartillas[index].ballAgrupacionJA1e[j].balls && this.procartillas[index].ballAgrupacionJA1e[j].count === 0){
                    return true
                }
            }
        },
        pintarcartillaExtraAgrupacionJA2e:function(index,i){
            let j;
            for(j = 0; j < this.procartillas[index].ballAgrupacionJA2e.length; j++){
                if(i === this.procartillas[index].ballAgrupacionJA2e[j].balls && this.procartillas[index].ballAgrupacionJA2e[j].count === 0){
                    return true
                }
            }
        },
        pintarcartillaExtraAgrupacionJA3e:function(index,i){
            let j;
            for(j = 0; j < this.procartillas[index].ballAgrupacionJA3e.length; j++){
                if(i === this.procartillas[index].ballAgrupacionJA3e[j].balls && this.procartillas[index].ballAgrupacionJA3e[j].count === 0){
                    return true
                }
            }
        },
        pintarcartillaExtraAgrupacionJA4e:function(index,i){
            let j;
            for(j = 0; j < this.procartillas[index].ballAgrupacionJA4e.length; j++){
                if(i === this.procartillas[index].ballAgrupacionJA4e[j].balls && this.procartillas[index].ballAgrupacionJA4e[j].count === 0){
                    return true
                }
            }
        },
        pintarcartillaExtraAgrupacionJA5e:function(index,i){
            let j;
            for(j = 0; j < this.procartillas[index].ballAgrupacionJA5e.length; j++){
                if(i === this.procartillas[index].ballAgrupacionJA5e[j].balls && this.procartillas[index].ballAgrupacionJA5e[j].count === 0){
                    return true
                }
            }
        },
        pintarcartillaExtraAgrupacionJA6e:function(index,i){
            let j;
            for(j = 0; j < this.procartillas[index].ballAgrupacionJA6e.length; j++){
                if(i === this.procartillas[index].ballAgrupacionJA6e[j].balls && this.procartillas[index].ballAgrupacionJA6e[j].count === 0){
                    return true
                }
            }
        },
        pintarcartillaExtraAgrupacionJA7e:function(index,i){
            let j;
            for(j = 0; j < this.procartillas[index].ballAgrupacionJA7e.length; j++){
                if(i === this.procartillas[index].ballAgrupacionJA7e[j].balls && this.procartillas[index].ballAgrupacionJA7e[j].count === 0){
                    return true
                }
            }
        },
        pintarcartillaExtraAgrupacionJA8e:function(index,i){
            let j;
            for(j = 0; j < this.procartillas[index].ballAgrupacionJA8e.length; j++){
                if(i === this.procartillas[index].ballAgrupacionJA8e[j].balls && this.procartillas[index].ballAgrupacionJA8e[j].count === 0){
                    return true
                }
            }
        },
        pintarcartillaExtraAgrupacionJA9e:function(index,i){
            let j;
            for(j = 0; j < this.procartillas[index].ballAgrupacionJA9e.length; j++){
                if(i === this.procartillas[index].ballAgrupacionJA9e[j].balls && this.procartillas[index].ballAgrupacionJA9e[j].count === 0){
                    return true
                }
            }
        },
        pintarcartillaExtraAgrupacionJA10e:function(index,i){
            let j;
            for(j = 0; j < this.procartillas[index].ballAgrupacionJA10e.length; j++){
                if(i === this.procartillas[index].ballAgrupacionJA10e[j].balls && this.procartillas[index].ballAgrupacionJA10e[j].count === 0){
                    return true
                }
            }
        },
        pintarcartillaExtraJA1eceleste:function(index,i){
            let j;
        
            for(j = 0; j < this.procartillas[index].ballAgrupacionJA1e.length; j++){
                if(i === this.procartillas[index].ballAgrupacionJA1e[j].balls && this.procartillas[index].ballAgrupacionJA1e[j].count === 1){
                    return true
                }
            }
        },
        pintarcartillaExtraJA2eceleste:function(index,i){
            let j;
        
            for(j = 0; j < this.procartillas[index].ballAgrupacionJA2e.length; j++){
                if(i === this.procartillas[index].ballAgrupacionJA2e[j].balls && this.procartillas[index].ballAgrupacionJA2e[j].count === 1){
                    return true
                }
            }
        },
        pintarcartillaExtraJA3eceleste:function(index,i){
            let j;
        
            for(j = 0; j < this.procartillas[index].ballAgrupacionJA3e.length; j++){
                if(i === this.procartillas[index].ballAgrupacionJA3e[j].balls && this.procartillas[index].ballAgrupacionJA3e[j].count === 1){
                    return true
                }
            }
        },
        pintarcartillaExtraJA4eceleste:function(index,i){
            let j;
        
            for(j = 0; j < this.procartillas[index].ballAgrupacionJA4e.length; j++){
                if(i === this.procartillas[index].ballAgrupacionJA4e[j].balls && this.procartillas[index].ballAgrupacionJA4e[j].count === 1){
                    return true
                }
            }
        },
        pintarcartillaExtraJA5eceleste:function(index,i){
            let j;
        
            for(j = 0; j < this.procartillas[index].ballAgrupacionJA5e.length; j++){
                if(i === this.procartillas[index].ballAgrupacionJA5e[j].balls && this.procartillas[index].ballAgrupacionJA5e[j].count === 1){
                    return true
                }
            }
        },
        pintarcartillaExtraJA6eceleste:function(index,i){
            let j;
        
            for(j = 0; j < this.procartillas[index].ballAgrupacionJA6e.length; j++){
                if(i === this.procartillas[index].ballAgrupacionJA6e[j].balls && this.procartillas[index].ballAgrupacionJA6e[j].count === 1){
                    return true
                }
            }
        },
        pintarcartillaExtraJA7eceleste:function(index,i){
            let j;
        
            for(j = 0; j < this.procartillas[index].ballAgrupacionJA7e.length; j++){
                if(i === this.procartillas[index].ballAgrupacionJA7e[j].balls && this.procartillas[index].ballAgrupacionJA7e[j].count === 1){
                    return true
                }
            }
        },
        pintarcartillaExtraJA8eceleste:function(index,i){
            let j;
        
            for(j = 0; j < this.procartillas[index].ballAgrupacionJA8e.length; j++){
                if(i === this.procartillas[index].ballAgrupacionJA8e[j].balls && this.procartillas[index].ballAgrupacionJA8e[j].count === 1){
                    return true
                }
            }
        },
        pintarcartillaExtraJA9eceleste:function(index,i){
            let j;
        
            for(j = 0; j < this.procartillas[index].ballAgrupacionJA9e.length; j++){
                if(i === this.procartillas[index].ballAgrupacionJA9e[j].balls && this.procartillas[index].ballAgrupacionJA9e[j].count === 1){
                    return true
                }
            }
        },
        pintarcartillaExtraJA10eceleste:function(index,i){
            let j;
        
            for(j = 0; j < this.procartillas[index].ballAgrupacionJA10e.length; j++){
                if(i === this.procartillas[index].ballAgrupacionJA10e[j].balls && this.procartillas[index].ballAgrupacionJA10e[j].count === 1){
                    return true
                }
            }
        },
    
    
        pintarcartillaExtraJA1eamarillo:function(index,i){
            let j;
            for(j = 0; j < this.procartillas[index].ballAgrupacionJA1e.length; j++){
                if(i === this.procartillas[index].ballAgrupacionJA1e[j].balls && this.procartillas[index].ballAgrupacionJA1e[j].count === 2){
                    return true
                }
            }
        },
        pintarcartillaExtraJA2eamarillo:function(index,i){
            let j;
            for(j = 0; j < this.procartillas[index].ballAgrupacionJA2e.length; j++){
                if(i === this.procartillas[index].ballAgrupacionJA2e[j].balls && this.procartillas[index].ballAgrupacionJA2e[j].count === 2){
                    return true
                }
            }
        },
        pintarcartillaExtraJA3eamarillo:function(index,i){
            let j;
            for(j = 0; j < this.procartillas[index].ballAgrupacionJA3e.length; j++){
                if(i === this.procartillas[index].ballAgrupacionJA3e[j].balls && this.procartillas[index].ballAgrupacionJA3e[j].count === 2){
                    return true
                }
            }
        },
        pintarcartillaExtraJA4eamarillo:function(index,i){
            let j;
            for(j = 0; j < this.procartillas[index].ballAgrupacionJA4e.length; j++){
                if(i === this.procartillas[index].ballAgrupacionJA4e[j].balls && this.procartillas[index].ballAgrupacionJA4e[j].count === 2){
                    return true
                }
            }
        },
        pintarcartillaExtraJA5eamarillo:function(index,i){
            let j;
            for(j = 0; j < this.procartillas[index].ballAgrupacionJA5e.length; j++){
                if(i === this.procartillas[index].ballAgrupacionJA5e[j].balls && this.procartillas[index].ballAgrupacionJA5e[j].count === 2){
                    return true
                }
            }
        },
        pintarcartillaExtraJA6eamarillo:function(index,i){
            let j;
            for(j = 0; j < this.procartillas[index].ballAgrupacionJA6e.length; j++){
                if(i === this.procartillas[index].ballAgrupacionJA6e[j].balls && this.procartillas[index].ballAgrupacionJA6e[j].count === 2){
                    return true
                }
            }
        },
        pintarcartillaExtraJA7eamarillo:function(index,i){
            let j;
            for(j = 0; j < this.procartillas[index].ballAgrupacionJA7e.length; j++){
                if(i === this.procartillas[index].ballAgrupacionJA7e[j].balls && this.procartillas[index].ballAgrupacionJA7e[j].count === 2){
                    return true
                }
            }
        },
        pintarcartillaExtraJA8eamarillo:function(index,i){
            let j;
            for(j = 0; j < this.procartillas[index].ballAgrupacionJA8e.length; j++){
                if(i === this.procartillas[index].ballAgrupacionJA8e[j].balls && this.procartillas[index].ballAgrupacionJA8e[j].count === 2){
                    return true
                }
            }
        },
        pintarcartillaExtraJA9eamarillo:function(index,i){
            let j;
            for(j = 0; j < this.procartillas[index].ballAgrupacionJA9e.length; j++){
                if(i === this.procartillas[index].ballAgrupacionJA9e[j].balls && this.procartillas[index].ballAgrupacionJA9e[j].count === 2){
                    return true
                }
            }
        },
        pintarcartillaExtraJA10eamarillo:function(index,i){
            let j;
            for(j = 0; j < this.procartillas[index].ballAgrupacionJA10e.length; j++){
                if(i === this.procartillas[index].ballAgrupacionJA10e[j].balls && this.procartillas[index].ballAgrupacionJA10e[j].count === 2){
                    return true
                }
            }
        },
    
        pintarcartillaExtraJA1enaranja:function(index,i){
            let j;
        
            for(j = 0; j < this.procartillas[index].ballAgrupacionJA1e.length; j++){
                if(i === this.procartillas[index].ballAgrupacionJA1e[j].balls && this.procartillas[index].ballAgrupacionJA1e[j].count >= 3){
                    return true
                }
            }
        },
        pintarcartillaExtraJA2enaranja:function(index,i){
            let j;
        
            for(j = 0; j < this.procartillas[index].ballAgrupacionJA2e.length; j++){
                if(i === this.procartillas[index].ballAgrupacionJA2e[j].balls && this.procartillas[index].ballAgrupacionJA2e[j].count >= 3){
                    return true
                }
            }
        },
        pintarcartillaExtraJA3enaranja:function(index,i){
            let j;
        
            for(j = 0; j < this.procartillas[index].ballAgrupacionJA3e.length; j++){
                if(i === this.procartillas[index].ballAgrupacionJA3e[j].balls && this.procartillas[index].ballAgrupacionJA3e[j].count >= 3){
                    return true
                }
            }
        },
        pintarcartillaExtraJA4enaranja:function(index,i){
            let j;
        
            for(j = 0; j < this.procartillas[index].ballAgrupacionJA4e.length; j++){
                if(i === this.procartillas[index].ballAgrupacionJA4e[j].balls && this.procartillas[index].ballAgrupacionJA4e[j].count >= 3){
                    return true
                }
            }
        },
        pintarcartillaExtraJA5enaranja:function(index,i){
            let j;
        
            for(j = 0; j < this.procartillas[index].ballAgrupacionJA5e.length; j++){
                if(i === this.procartillas[index].ballAgrupacionJA5e[j].balls && this.procartillas[index].ballAgrupacionJA5e[j].count >= 3){
                    return true
                }
            }
        },
        pintarcartillaExtraJA6enaranja:function(index,i){
            let j;
        
            for(j = 0; j < this.procartillas[index].ballAgrupacionJA6e.length; j++){
                if(i === this.procartillas[index].ballAgrupacionJA6e[j].balls && this.procartillas[index].ballAgrupacionJA6e[j].count >= 3){
                    return true
                }
            }
        },
        pintarcartillaExtraJA7enaranja:function(index,i){
            let j;
        
            for(j = 0; j < this.procartillas[index].ballAgrupacionJA7e.length; j++){
                if(i === this.procartillas[index].ballAgrupacionJA7e[j].balls && this.procartillas[index].ballAgrupacionJA7e[j].count >= 3){
                    return true
                }
            }
        },
        pintarcartillaExtraJA8enaranja:function(index,i){
            let j;
        
            for(j = 0; j < this.procartillas[index].ballAgrupacionJA8e.length; j++){
                if(i === this.procartillas[index].ballAgrupacionJA8e[j].balls && this.procartillas[index].ballAgrupacionJA8e[j].count >= 3){
                    return true
                }
            }
        },
        pintarcartillaExtraJA9enaranja:function(index,i){
            let j;
        
            for(j = 0; j < this.procartillas[index].ballAgrupacionJA9e.length; j++){
                if(i === this.procartillas[index].ballAgrupacionJA9e[j].balls && this.procartillas[index].ballAgrupacionJA9e[j].count >= 3){
                    return true
                }
            }
        },
        pintarcartillaExtraJA10enaranja:function(index,i){
            let j;
        
            for(j = 0; j < this.procartillas[index].ballAgrupacionJA10e.length; j++){
                if(i === this.procartillas[index].ballAgrupacionJA10e[j].balls && this.procartillas[index].ballAgrupacionJA10e[j].count >= 3){
                    return true
                }
            }
        },
        pintarcartillaJA1celeste:function(index,i){
            let j;
        
            for(j = 0; j < this.procartillas[index].ballAgrupacionJA1.length; j++){
                if(i == this.procartillas[index].ballAgrupacionJA1[j].balls && this.procartillas[index].ballAgrupacionJA1[j].count === 1  ){
                    return true
                }
            }
        },
        pintarcartillaJA2celeste:function(index,i){
            let j;
        
            for(j = 0; j < this.procartillas[index].ballAgrupacionJA2.length; j++){
                if(i == this.procartillas[index].ballAgrupacionJA2[j].balls && this.procartillas[index].ballAgrupacionJA2[j].count === 1  ){
                    return true
                }
            }
        },
        pintarcartillaJA3celeste:function(index,i){
            let j;
        
            for(j = 0; j < this.procartillas[index].ballAgrupacionJA3.length; j++){
                if(i == this.procartillas[index].ballAgrupacionJA3[j].balls && this.procartillas[index].ballAgrupacionJA3[j].count === 1  ){
                    return true
                }
            }
        },
        pintarcartillaJA4celeste:function(index,i){
            let j;
        
            for(j = 0; j < this.procartillas[index].ballAgrupacionJA4.length; j++){
                if(i == this.procartillas[index].ballAgrupacionJA4[j].balls && this.procartillas[index].ballAgrupacionJA4[j].count === 1  ){
                    return true
                }
            }
        },
        pintarcartillaJA5celeste:function(index,i){
            let j;
        
            for(j = 0; j < this.procartillas[index].ballAgrupacionJA5.length; j++){
                if(i == this.procartillas[index].ballAgrupacionJA5[j].balls && this.procartillas[index].ballAgrupacionJA5[j].count === 1  ){
                    return true
                }
            }
        },
        pintarcartillaJA6celeste:function(index,i){
            let j;
        
            for(j = 0; j < this.procartillas[index].ballAgrupacionJA6.length; j++){
                if(i == this.procartillas[index].ballAgrupacionJA6[j].balls && this.procartillas[index].ballAgrupacionJA6[j].count === 1  ){
                    return true
                }
            }
        },
        pintarcartillaJA7celeste:function(index,i){
            let j;
        
            for(j = 0; j < this.procartillas[index].ballAgrupacionJA7.length; j++){
                if(i == this.procartillas[index].ballAgrupacionJA7[j].balls && this.procartillas[index].ballAgrupacionJA7[j].count === 1  ){
                    return true
                }
            }
        },
        pintarcartillaJA8celeste:function(index,i){
            let j;
        
            for(j = 0; j < this.procartillas[index].ballAgrupacionJA8.length; j++){
                if(i == this.procartillas[index].ballAgrupacionJA8[j].balls && this.procartillas[index].ballAgrupacionJA8[j].count === 1  ){
                    return true
                }
            }
        },
        pintarcartillaJA9celeste:function(index,i){
            let j;
        
            for(j = 0; j < this.procartillas[index].ballAgrupacionJA9.length; j++){
                if(i == this.procartillas[index].ballAgrupacionJA9[j].balls && this.procartillas[index].ballAgrupacionJA9[j].count === 1  ){
                    return true
                }
            }
        },
        pintarcartillaJA10celeste:function(index,i){
            let j;
        
            for(j = 0; j < this.procartillas[index].ballAgrupacionJA10.length; j++){
                if(i == this.procartillas[index].ballAgrupacionJA10[j].balls && this.procartillas[index].ballAgrupacionJA10[j].count === 1  ){
                    return true
                }
            }
        },
    
        pintarcartillaJA1amarillo:function(index,i){
            let j;
        
            for(j = 0; j < this.procartillas[index].ballAgrupacionJA1.length; j++){
                if(i == this.procartillas[index].ballAgrupacionJA1[j].balls && this.procartillas[index].ballAgrupacionJA1[j].count === 2  ){
                    return true
                }
            }
        },
        pintarcartillaJA2amarillo:function(index,i){
            let j;
        
            for(j = 0; j < this.procartillas[index].ballAgrupacionJA2.length; j++){
                if(i == this.procartillas[index].ballAgrupacionJA2[j].balls && this.procartillas[index].ballAgrupacionJA2[j].count === 2  ){
                    return true
                }
            }
        },
        pintarcartillaJA3amarillo:function(index,i){
            let j;
        
            for(j = 0; j < this.procartillas[index].ballAgrupacionJA3.length; j++){
                if(i == this.procartillas[index].ballAgrupacionJA3[j].balls && this.procartillas[index].ballAgrupacionJA3[j].count === 2  ){
                    return true
                }
            }
        },
        pintarcartillaJA4amarillo:function(index,i){
            let j;
        
            for(j = 0; j < this.procartillas[index].ballAgrupacionJA4.length; j++){
                if(i == this.procartillas[index].ballAgrupacionJA4[j].balls && this.procartillas[index].ballAgrupacionJA4[j].count === 2  ){
                    return true
                }
            }
        },
        pintarcartillaJA5amarillo:function(index,i){
            let j;
        
            for(j = 0; j < this.procartillas[index].ballAgrupacionJA5.length; j++){
                if(i == this.procartillas[index].ballAgrupacionJA5[j].balls && this.procartillas[index].ballAgrupacionJA5[j].count === 2  ){
                    return true
                }
            }
        },
        pintarcartillaJA6amarillo:function(index,i){
            let j;
        
            for(j = 0; j < this.procartillas[index].ballAgrupacionJA6.length; j++){
                if(i == this.procartillas[index].ballAgrupacionJA6[j].balls && this.procartillas[index].ballAgrupacionJA6[j].count === 2  ){
                    return true
                }
            }
        },
        pintarcartillaJA7amarillo:function(index,i){
            let j;
        
            for(j = 0; j < this.procartillas[index].ballAgrupacionJA7.length; j++){
                if(i == this.procartillas[index].ballAgrupacionJA7[j].balls && this.procartillas[index].ballAgrupacionJA7[j].count === 2  ){
                    return true
                }
            }
        },
        pintarcartillaJA8amarillo:function(index,i){
            let j;
        
            for(j = 0; j < this.procartillas[index].ballAgrupacionJA8.length; j++){
                if(i == this.procartillas[index].ballAgrupacionJA8[j].balls && this.procartillas[index].ballAgrupacionJA8[j].count === 2  ){
                    return true
                }
            }
        },
        pintarcartillaJA9amarillo:function(index,i){
            let j;
        
            for(j = 0; j < this.procartillas[index].ballAgrupacionJA9.length; j++){
                if(i == this.procartillas[index].ballAgrupacionJA9[j].balls && this.procartillas[index].ballAgrupacionJA9[j].count === 2  ){
                    return true
                }
            }
        },
        pintarcartillaJA10amarillo:function(index,i){
            let j;
        
            for(j = 0; j < this.procartillas[index].ballAgrupacionJA10.length; j++){
                if(i === this.procartillas[index].ballAgrupacionJA10[j].balls && this.procartillas[index].ballAgrupacionJA10[j].count === 2  ){
                    return true
                }
            }
        },
    
        pintarcartillaJA1naranja:function(index,i){
            let j;
        
            for(j = 0; j < this.procartillas[index].ballAgrupacionJA1.length; j++){
                if(i === this.procartillas[index].ballAgrupacionJA1[j].balls && this.procartillas[index].ballAgrupacionJA1[j].count >= 3  ){
                    return true
                }
            }
        },
        pintarcartillaJA2naranja:function(index,i){
            let j;
        
            for(j = 0; j < this.procartillas[index].ballAgrupacionJA2.length; j++){
                if(i === this.procartillas[index].ballAgrupacionJA2[j].balls && this.procartillas[index].ballAgrupacionJA2[j].count >= 3  ){
                    return true
                }
            }
        },
        pintarcartillaJA3naranja:function(index,i){
            let j;
        
            for(j = 0; j < this.procartillas[index].ballAgrupacionJA3.length; j++){
                if(i === this.procartillas[index].ballAgrupacionJA3[j].balls && this.procartillas[index].ballAgrupacionJA3[j].count >= 3  ){
                    return true
                }
            }
        },
        pintarcartillaJA4naranja:function(index,i){
            let j;
        
            for(j = 0; j < this.procartillas[index].ballAgrupacionJA4.length; j++){
                if(i === this.procartillas[index].ballAgrupacionJA4[j].balls && this.procartillas[index].ballAgrupacionJA4[j].count >= 3  ){
                    return true
                }
            }
        },
        pintarcartillaJA5naranja:function(index,i){
            let j;
        
            for(j = 0; j < this.procartillas[index].ballAgrupacionJA5.length; j++){
                if(i === this.procartillas[index].ballAgrupacionJA5[j].balls && this.procartillas[index].ballAgrupacionJA5[j].count >= 3  ){
                    return true
                }
            }
        },
        pintarcartillaJA6naranja:function(index,i){
            let j;
        
            for(j = 0; j < this.procartillas[index].ballAgrupacionJA6.length; j++){
                if(i === this.procartillas[index].ballAgrupacionJA6[j].balls && this.procartillas[index].ballAgrupacionJA6[j].count >= 3  ){
                    return true
                }
            }
        },
        pintarcartillaJA7naranja:function(index,i){
            let j;
        
            for(j = 0; j < this.procartillas[index].ballAgrupacionJA7.length; j++){
                if(i === this.procartillas[index].ballAgrupacionJA7[j].balls && this.procartillas[index].ballAgrupacionJA7[j].count >= 3  ){
                    return true
                }
            }
        },
        pintarcartillaJA8naranja:function(index,i){
            let j;
        
            for(j = 0; j < this.procartillas[index].ballAgrupacionJA8.length; j++){
                if(i === this.procartillas[index].ballAgrupacionJA8[j].balls && this.procartillas[index].ballAgrupacionJA8[j].count >= 3  ){
                    return true
                }
            }
        },
        pintarcartillaJA9naranja:function(index,i){
            let j;
        
            for(j = 0; j < this.procartillas[index].ballAgrupacionJA9.length; j++){
                if(i === this.procartillas[index].ballAgrupacionJA9[j].balls && this.procartillas[index].ballAgrupacionJA9[j].count >= 3  ){
                    return true
                }
            }
        },
        pintarcartillaJA10naranja:function(index,i){
            let j;
        
            for(j = 0; j < this.procartillas[index].ballAgrupacionJA10.length; j++){
                if(i === this.procartillas[index].ballAgrupacionJA10[j].balls && this.procartillas[index].ballAgrupacionJA10[j].count >= 3  ){
                    return true
                }
            }
        },
        toTourResultado() {
            const text= {
                range_date:'Permite seleccionar un rango de fechas para visualizar las cartillas en ese periodo de tiempo.',
                select_bolilla:'Filtro complementario para mostrar solo las cartillas con la bolilla seleccionada. ',
                export_excel:'Exporta el rango de resultados en un Excel.',
                constelacion_similar:'Listado de fechas, si le damos a buscar entonces visualizaremos la jugada en la cartilla principal.',
                listado_jugadas:'Listado de las últimas cartillas, se pueden obtener más ampliando las fechas de búsqueda.',
                ciclo_aparicion: 'Al seleccionar uno de los números marcados de esta cartilla, podremos visualizar la fecha de aparición de ese número.',
                // copiar_jugada: 'Después de seleccionar una cartilla, la jugada sera trasladada al menú de "Pronóstico Mix" o "Juego individual".',
                copiar_jugada: 'Después de seleccionar una cartilla, la jugada sera trasladada al menú de juego.',
                comparar_jugada: 'Después de seleccionar una cartilla, nos mostrará una lista de fechas con las constelaciones similares.',
                botons_jas: 'Visualizacion de las últimas jugadas, operaciones de personalización y borrado de jugadas.',
                leyenda_jugada:'Cada marcación de cartillas es evaluado por colores distintos.'
            }
            this.$nextTick(() => {
                const tour = this.$shepherd({
                    useModalOverlay: true,
                    cancelIcon: {
                        enabled: true
                    },
                    classes: 'class-1 class-2',
                    scrollTo: { behavior: 'smooth', block: 'center' }
                });
                tour.addStep({
                    attachTo: { element: ".tooltip-button-variantdate", on: 'right' },
                    text: text.range_date,
                    classes: 'shepherd-theme-arrows',
                    buttons: [
                        {
                            text: 'Salir',
                            action: tour.cancel
                        },
                        {
                            text: 'Sig.',
                            action: tour.next
                        }
                    ],
                });
                tour.addStep({
                    attachTo: { element: ".tooltip-seleccionar-bolilla", on: 'left' },
                    text: text.select_bolilla,
                    classes: 'shepherd-theme-arrows',
                    buttons: [
                        {
                            action: tour.back,
                            classes: 'shepherd-button-secondary',
                            text: 'Ant.'
                        },
                        {
                            text: 'Sig.',
                            action: tour.next
                        }
                    ]
                });
                tour.addStep({
                    attachTo: { element: ".tooltip-export-excel", on: 'left' },
                    text: text.export_excel,
                    classes: 'shepherd-theme-arrows',
                    buttons: [
                        {
                            action: tour.back,
                            classes: 'shepherd-button-secondary',
                            text: 'Ant.'
                        },
                        {
                            text: 'Sig.',
                            action: tour.next
                        }
                    ]
                });
                tour.addStep({
                    attachTo: { element: ".tooltip-listado-jugadas", on: 'left' },
                    text: text.listado_jugadas,
                    classes: 'shepherd-theme-arrows',
                    buttons: [
                        {
                            action: tour.back,
                            classes: 'shepherd-button-secondary',
                            text: 'Ant.'
                        },
                        {
                            text: 'Sig.',
                            action: tour.next
                        }
                    ]
                });
                
                tour.addStep({
                    attachTo: { element: ".tooltip-ciclo-aparicion", on: 'left' },
                    text: text.ciclo_aparicion,
                    classes: 'shepherd-theme-arrows',
                    buttons: [
                        {
                            action: tour.back,
                            classes: 'shepherd-button-secondary',
                            text: 'Ant.'
                        },
                        {
                            text: 'Sig.',
                            action: tour.next
                        }
                    ]
                });

                tour.addStep({
                    attachTo: { element: ".tooltip-botons-jas", on: 'left' },
                    text: text.botons_jas,
                    classes: 'shepherd-theme-arrows',
                    buttons: [
                        {
                            action: tour.back,
                            classes: 'shepherd-button-secondary',
                            text: 'Ant.'
                        },
                        {
                            text: 'Sig.',
                            action: tour.next
                        }
                    ]
                });
                
                // tour.addStep({
                //     attachTo: { element: ".tooltip-leyenda", on: 'left' },
                //     text: text.leyenda_jugada,
                //     classes: 'shepherd-theme-arrows',
                //     buttons: [
                //         {
                //             action: tour.back,
                //             classes: 'shepherd-button-secondary',
                //             text: 'Ant.'
                //         },
                //         {
                //             text: 'Sig.',
                //             action: tour.next
                //         }
                //     ]
                // });
                
                tour.addStep({
                    attachTo: { element: ".tooltip-copiar-jugada", on: 'left' },
                    text: text.copiar_jugada,
                    classes: 'shepherd-theme-arrows',
                    buttons: [
                        {
                            action: tour.back,
                            classes: 'shepherd-button-secondary',
                            text: 'Ant.'
                        },
                        {
                            text: 'Sig.',
                            action: tour.next
                        }
                    ]
                });
                tour.addStep({
                    attachTo: { element: ".tooltip-comparar-jugada", on: 'left' },
                    text: text.comparar_jugada,
                    classes: 'shepherd-theme-arrows',
                    buttons: [
                        {
                            action: tour.back,
                            classes: 'shepherd-button-secondary',
                            text: 'Ant.'
                        },
                        {
                            text: 'Hecho',
                            action: tour.complete
                        }
                    ]
                });
                
                tour.start();
            });
        },
        
        async _export(){
            
            let hoy = new Date();
            let hora = hoy.getHours() + '-' + hoy.getMinutes() + '-' + hoy.getSeconds();
            let columnaPosition = ['A','B','C','D','E','F','G','H','I','J','K','L','M','N','O','P','Q','R','S','T','U','V','W','X','Y','Z'];
            let agregado = [];
            // var cadena = [1,2];//[1,2,3,4,5,6,7,8];
            // var p = this.permutaciones(columnaPosition,2);
            for (let i = 0; i < columnaPosition.length; i++) {
                for (let j = 0; j < columnaPosition.length; j++) {
                    agregado.push(columnaPosition[i]+columnaPosition[j])
                }
            }
            columnaPosition.push(...agregado)
// ------------------------------------------------------------  --------------------
            // Create workbook & add worksheet
            const workbook = new Excel.Workbook();
            
            workbook.creator = 'Diego Armando Condori Cabrera';
            workbook.lastModifiedBy = 'Diego Armando Condori Cabrera';
            workbook.created = new Date(2021, 7, 15);
            workbook.modified = new Date();
            workbook.lastPrinted = new Date(2021, 7, 15);
            
            const worksheet = workbook.addWorksheet('Página 1', {
                properties:{defaultColWidth:3, tabColor:{argb:'FF00FF00'}},
                // pageSetup:{horizontalCentered : true, verticalCentered:true}
            });
            
            
            worksheet.addRow(
                { package_name: "ABC", author_name: "Author 1" }
            );
            
            // let row = worksheet.getRow(2).values;

// ---------------------------------------------------------------------------------------------------
            // 2DA VERSION
            //OBTENCION DE LAS JUGADAS
            
            
            let jugadas = [];
            for (let i = 0; i < this.items.length; i++) {
                jugadas.push({
                    fecha: this.items[i].fecha,
                    balls: [ ...this.items[i].ballcold, ...this.items[i].ballhot, ...this.items[i].ballpar,...this.items[i].balls  ],
                    bExtra: this.items[i].bExtra
                })
                
            }
            
            let filas = [];
            let fila = [];
            
            let valor;
            let count;
            let x = 0;
            for ( x = 0; x < jugadas.length; x++) {
                
                let columnaNombrePosition = (x == 0) ? 2 : ((this.lotteryForecast.info.columnCart * x) + 2* x) + 2 ;
                filas.push(...[ ["","",jugadas[x].fecha] ]);
                // BALLS PRINCIPAL
                for (let i = 1; i <= this.lotteryForecast.info.size; i++) {
                    valor = i;
                    if ( jugadas[x].balls.includes(i) ) {
                        valor = "."+i;
                    }
                    if ( (i %  (this.lotteryForecast.info.columnCart)) != 1 ) {
                        fila.push(valor);
                    }else{
                        filas.push(fila);
                        fila = [];
                        fila.push(valor);
                    }
                    if (i == this.lotteryForecast.info.size) {
                        filas.push(fila);
                        fila = [];
                    }
                }
                // EXTRA BALL
                for (let i = 1; i <= this.lotteryForecast.info.sub_length; i++) {
                    valor = i;
                    if (jugadas[x].bExtra == i ) {
                        valor = "."+i
                    }
                    if ( (i %  (this.lotteryForecast.info.columnCart)) != 1 ) {
                        fila.push(valor);
                    }else{
                        filas.push(fila);
                        fila = [];
                        fila.push(valor);
                    }
                    if (i == this.lotteryForecast.info.sub_length) {
                        filas.push(fila);
                        fila = [];
                    }
                }
                // ------------------------------------
                worksheet.getCell(columnaPosition[columnaNombrePosition]+'5').fill = { type: "pattern",  pattern: "solid",  fgColor: {  argb: "92D050" }, bgColor: { argb: "92D050" } }
                worksheet.getCell(columnaPosition[columnaNombrePosition + 1]+'5').fill = { type: "pattern",  pattern: "solid",  fgColor: {  argb: "92D050" }, bgColor: { argb: "92D050" } }
                worksheet.getCell(columnaPosition[columnaNombrePosition + 2]+'5').fill = { type: "pattern",  pattern: "solid",  fgColor: {  argb: "92D050" }, bgColor: { argb: "92D050" } }
                worksheet.getCell(columnaPosition[columnaNombrePosition + 2]+'5').font = {  color: { argb: 'FFFFFF' }, bold: true  };
                worksheet.getCell(columnaPosition[columnaNombrePosition + 3]+'5').fill = { type: "pattern",  pattern: "solid",  fgColor: {  argb: "92D050" }, bgColor: { argb: "92D050" } }
                worksheet.getCell(columnaPosition[columnaNombrePosition + 4]+'5').fill = { type: "pattern",  pattern: "solid",  fgColor: {  argb: "92D050" }, bgColor: { argb: "92D050" } }
                worksheet.getCell(columnaPosition[columnaNombrePosition + 5]+'5').fill = { type: "pattern",  pattern: "solid",  fgColor: {  argb: "92D050" }, bgColor: { argb: "92D050" } }
                worksheet.getCell(columnaPosition[columnaNombrePosition + 6]+'5').fill = { type: "pattern",  pattern: "solid",  fgColor: {  argb: "92D050" }, bgColor: { argb: "92D050" } }
                // ---------------------------------------
                worksheet.addTable({
                    name: 'MyTable2'+columnaPosition[columnaNombrePosition],
                    ref: columnaPosition[columnaNombrePosition]+'5',
                    headerRow: false,
                    style: {
                        showRowStripes: false,
                    },
                    columns: [
                        {name: '___',  filterButton: false},
                        {name: '____',  filterButton: false},
                        {name: '_____',    filterButton: false},
                        {name: '______',  filterButton: false},
                        {name: '_______',  filterButton: false},
                        {name: '________',   filterButton: false},
                        {name: '_________',   filterButton: false}
                    ],
                    rows: filas,
                });
                
                filas = [];
                count = x;
            }
            
            
            count++;
            // COMBINATORIO
            // CONTEO PRINCIPAL-------------------------------------------------
            filas.push(...[ ["","COMBINACIONES"] ]);
            let balls = []
            for (let i = 0; i < jugadas.length; i++) {
                balls.push( ...jugadas[i].balls)
            }
            
            
            
            var indicesPrincipal = new Array(this.lotteryForecast.info.size); // colocar en vez de 8 el max del array "x"
            indicesPrincipal.fill(0);
            
            for (var i = 1; i <= indicesPrincipal.length; i++) {
                
                for (var j = 0; j < balls.length; j++) {
                    if (i == balls[j]) {
                        indicesPrincipal[i] = indicesPrincipal[i] + 1;
                    }
                }
            }
            
            // CONTEO EXTRA
            
            balls = []
            for ( i = 0; i < jugadas.length; i++) {
                balls.push( jugadas[i].bExtra)
            }
            
            
            var indicesExtra = new Array(this.lotteryForecast.info.sub_length); // colocar en vez de 8 el max del array "x"
            indicesExtra.fill(0);
            
            for ( i = 1; i <= indicesExtra.length; i++) {
                
                for ( j = 0; j < balls.length; j++) {
                    if (i == balls[j]) {
                        indicesExtra[i] = indicesExtra[i] + 1;
                    }
                }
            }
            //AÑADIR COMBINARIO CARTILLA
            
            
            // BALLS PRINCIPAL
            for (let i = 1; i <= this.lotteryForecast.info.size; i++) {
                valor = i;
                if ( indicesPrincipal[i] == 1 ) {
                    valor = "-"+i;
                }
                if ( indicesPrincipal[i] == 2 ) {
                    valor = "+"+i;
                }
                if ( indicesPrincipal[i] == 3 ) {
                    valor = "*"+i;
                }
                if ( indicesPrincipal[i]  > 3 ) {
                    valor = "/"+i;
                }
                if ( (i %  (this.lotteryForecast.info.columnCart)) != 1 ) {
                    fila.push(valor);
                }else{
                    filas.push(fila);
                    fila = [];
                    fila.push(valor);
                }
                if (i == this.lotteryForecast.info.size) {
                    filas.push(fila);
                    fila = [];
                }
            }
            // EXTRA BALL
            for (let i = 1; i <= this.lotteryForecast.info.sub_length; i++) {
                valor = i;
                if ( indicesExtra[i] == 1 ) {
                    valor = "-"+i;
                }
                if ( indicesExtra[i] == 2 ) {
                    valor = "+"+i;
                }
                if ( indicesExtra[i] == 3 ) {
                    valor = "*"+i;
                }
                if ( indicesExtra[i]  > 3 ) {
                    valor = "/"+i;
                }
                if ( (i %  (this.lotteryForecast.info.columnCart)) != 1 ) {
                    fila.push(valor);
                }else{
                    filas.push(fila);
                    fila = [];
                    fila.push(valor);
                }
                if (i == this.lotteryForecast.info.sub_length) {
                    filas.push(fila);
                    fila = [];
                }
            }
            let columnaNombrePosition = (x == 0) ? 2 : ((this.lotteryForecast.info.columnCart * count) + 2* count) + 2 ;
            
            worksheet.getCell(columnaPosition[columnaNombrePosition]+'5').fill = { type: "pattern",  pattern: "solid",  fgColor: {  argb: "92D050" }, bgColor: { argb: "92D050" } }
            worksheet.getCell(columnaPosition[columnaNombrePosition + 1]+'5').fill = { type: "pattern",  pattern: "solid",  fgColor: {  argb: "92D050" }, bgColor: { argb: "92D050" } }
            worksheet.getCell(columnaPosition[columnaNombrePosition + 1]+'5').font = {  color: { argb: 'FFFFFF' }, bold: true };
            worksheet.getCell(columnaPosition[columnaNombrePosition + 2]+'5').fill = { type: "pattern",  pattern: "solid",  fgColor: {  argb: "92D050" }, bgColor: { argb: "92D050" } }
            worksheet.getCell(columnaPosition[columnaNombrePosition + 3]+'5').fill = { type: "pattern",  pattern: "solid",  fgColor: {  argb: "92D050" }, bgColor: { argb: "92D050" } }
            worksheet.getCell(columnaPosition[columnaNombrePosition + 4]+'5').fill = { type: "pattern",  pattern: "solid",  fgColor: {  argb: "92D050" }, bgColor: { argb: "92D050" } }
            worksheet.getCell(columnaPosition[columnaNombrePosition + 5]+'5').fill = { type: "pattern",  pattern: "solid",  fgColor: {  argb: "92D050" }, bgColor: { argb: "92D050" } }
            worksheet.getCell(columnaPosition[columnaNombrePosition + 6]+'5').fill = { type: "pattern",  pattern: "solid",  fgColor: {  argb: "92D050" }, bgColor: { argb: "92D050" } }
            
            worksheet.addTable({
                name: 'MyTable2'+columnaPosition[columnaNombrePosition],
                ref: columnaPosition[columnaNombrePosition]+'5',
                headerRow: false,
                style: {
                    showRowStripes: false,
                },
                columns: [
                    {name: '___',  filterButton: false},
                    {name: '____',  filterButton: false},
                    {name: '_____',    filterButton: false},
                    {name: '______',  filterButton: false},
                    {name: '_______',  filterButton: false},
                    {name: '________',   filterButton: false},
                    {name: '_________',   filterButton: false}
                ],
                rows: filas,
            });
            
            filas = [];
            
            
            //PROCESO DE PINTADO
            
            // PINTADO DE CELDA INDIVIDUAL
            
            columnaNombrePosition = columnaNombrePosition + 7;
            // let test="4"
            worksheet.getCell('A1').value = `IA LOTTERY PLAY`;
            
            worksheet.getCell('A1').font = {
                name: 'Arial Black',
                color: { argb: 'FFFFFF' },
                family: 2,
                size: 14,
                italic: true
            };
            worksheet.getCell('A1').fill = { type: "pattern",  pattern: "solid",  fgColor: {  argb: "92D050" }, bgColor: { argb: "92D050" } }
            worksheet.getCell('B1').fill = { type: "pattern",  pattern: "solid",  fgColor: {  argb: "92D050" }, bgColor: { argb: "92D050" } }
            worksheet.getCell('C1').fill = { type: "pattern",  pattern: "solid",  fgColor: {  argb: "92D050" }, bgColor: { argb: "92D050" } }
            worksheet.getCell('D1').fill = { type: "pattern",  pattern: "solid",  fgColor: {  argb: "92D050" }, bgColor: { argb: "92D050" } }
            worksheet.getCell('E1').fill = { type: "pattern",  pattern: "solid",  fgColor: {  argb: "92D050" }, bgColor: { argb: "92D050" } }
            worksheet.getCell('F1').fill = { type: "pattern",  pattern: "solid",  fgColor: {  argb: "92D050" }, bgColor: { argb: "92D050" } }
            worksheet.getCell('G1').fill = { type: "pattern",  pattern: "solid",  fgColor: {  argb: "92D050" }, bgColor: { argb: "92D050" } }
            worksheet.getCell('H1').fill = { type: "pattern",  pattern: "solid",  fgColor: {  argb: "92D050" }, bgColor: { argb: "92D050" } }
            worksheet.getCell('I1').fill = { type: "pattern",  pattern: "solid",  fgColor: {  argb: "92D050" }, bgColor: { argb: "92D050" } }
            worksheet.getCell('J1').fill = { type: "pattern",  pattern: "solid",  fgColor: {  argb: "92D050" }, bgColor: { argb: "92D050" } }
            
            worksheet.getCell('C2').value = `${this.descriptionForecast}: LISTA DE RESULTADOS DESDE ${jugadas[0].fecha} HASTA ${jugadas[jugadas.length - 1].fecha}`;
            
            worksheet.getCell('C2').font = {
                name: 'Arial Black',
                family: 4,
                size: 16,
                underline: true,
                bold: true
            };
            
            for (let i = 1; i <= columnaNombrePosition; i++) {
                const dobCol = worksheet.getColumn(i);
                
                dobCol.eachCell(function(cell, rowNumber) {
                    
                    console.log("rowNumber: ",rowNumber);
                    let valor = String(cell.value);
                    if (valor.indexOf(".") == 0 ) {
                        cell.fill = {
                            type: "pattern",
                            pattern: "solid",
                            fgColor: {
                                argb: "92D050"
                            },
                            bgColor: {
                                argb: "92D050"
                            }
                        }
                        cell.value = valor.substr(1);
                        cell.font = {  color: { argb: 'FFFFFF' }, bold: true };
                        cell.alignment = { vertical: 'middle', horizontal: 'center' };
                    }
                    
                    if (valor.indexOf("-") == 0 ) {
                        cell.fill = {
                            type: "pattern",
                            pattern: "solid",
                            fgColor: {
                                argb: "92D050"
                            },
                            bgColor: {
                                argb: "92D050"
                            }
                        }
                        cell.value = valor.substr(1);
                        cell.font = {  color: { argb: 'FFFFFF' }, bold: true   };
                        cell.alignment = { vertical: 'middle', horizontal: 'center' };
                    }
                    
                    if (valor.indexOf("+") == 0 ) {
                        cell.fill = {
                            type: "pattern",
                            pattern: "solid",
                            fgColor: {
                                argb: "F76300"
                            },
                            bgColor: {
                                argb: "F76300"
                            }
                        }
                        cell.value = valor.substr(1);
                        cell.font = {  color: { argb: 'FFFFFF' } , bold: true  };
                        cell.alignment = { vertical: 'middle', horizontal: 'center' };
                    }
                    
                    if (valor.indexOf("*") == 0 ) {
                        cell.fill = {
                            type: "pattern",
                            pattern: "solid",
                            fgColor: {
                                argb: "DC001A"
                            },
                            bgColor: {
                                argb: "DC001A"
                            }
                        }
                        cell.value = valor.substr(1);
                        cell.font = {  color: { argb: 'FFFFFF' } , bold: true  };
                        cell.alignment = { vertical: 'middle', horizontal: 'center' };
                    }
                    
                    if (valor.indexOf("/") == 0 ) {
                        cell.fill = {
                            type: "pattern",
                            pattern: "solid",
                            fgColor: {
                                argb: "660001"
                            },
                            bgColor: {
                                argb: "660001"
                            }
                        }
                        cell.value = valor.substr(1);
                        cell.font = {  color: { argb: 'FFFFFF' } , bold: true  };
                        cell.alignment = { vertical: 'middle', horizontal: 'center' };
                    }
                });
                
            }
            
            const buffer = await workbook.xlsx.writeBuffer();
            const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
            const fileExtension = '.xlsx';
            
            const blob = new Blob([buffer], {type: fileType});
            saveAs(blob, `IALOTTERYPLAY ${jugadas[0].fecha} ${jugadas[jugadas.length - 1].fecha} ${hora} ` + fileExtension);
            
        },
        alertDisable(){
            swal.fire({
                position: 'top-center',
                icon: 'success',
                title: (this.disabled) ?  'Botón de ayuda desactivado!' : 'Botón de ayuda activado!',
                showConfirmButton: false,
                timer: 2000
            })
            
            
        },
        disableByRef() {
            if (this.disabled) {
                this.$refs.tooltip.$emit('enable')
            } else {
                this.$refs.tooltip.$emit('disable')
            }
        },
        async buscarBolillaResultado( ){
            const authorization = VueCookie.get('costumer_token');
            this.paginadorForecast = 0;
            if (!authorization) {
                //this.$root.$emit('bv::show::modal', 'authenticated', '#btnShowAnalysis')
              this.$router.push('/login-client')
              return;
            }
            
            this.date.name = this.$route.params.name
            if (this.date.name =='' || this.date.from == '' || this.date.to == '') {
                swal.fire({
                    icon: 'warning',
                    title: 'Ingrese los rangos de fecha!',
                    showConfirmButton: false,
                    timer: 2000
                })
                return false;
            }
            // ????
            let data = await this.searchBallResult({...this.$route.params,...this.date, type:(this.val == "0") ? 0 : 1})
            this.items = []
            this.items.push(...data);
            
            if (this.items.length == 0) {
                swal.fire({
                    icon: 'success',
                    title: `Sin resultados con la bolilla "${this.date.selected}", ingrese otra bolilla!`,
                    showConfirmButton: false,
                    timer: 3000
                })
                
                this.items = await this.fetchTopResult({...this.$route.params, type:(this.val == "0") ? 0 : 1});
                this.date.selected = '-';
                
                await this.transformResult(this.items);
                return false;
            }
            await this.transformResult(this.items);
            
        },
        async copySuggestionconstellation( index  ){
            let param = { name :this.$route.params.name, fecha: this.constellations[index].fecha };
            let data = await this.allInResult({...param, type: (this.val === 0) ? 0 : 1, id:this.$route.params.id });
            this.items.push(...data);
            this.date.selected = '-';
            await this.transformResult(this.items);
            
            for (let index2 = 0; index2 < this.procartillas.length; index2++) {
                const element = this.procartillas[index2];
                if (element.fecha === this.constellations[index].fecha) {
                    document.getElementsByClassName('cartillaOneResultado')[index2 ].scrollIntoView({ behavior: 'smooth', block: 'center' });
                    this.paginadorForecast = index2;
                    let name = this.$route.params.name
                    let { data: data } = await ApiForecast.getForecastCard({ name: name, type: this.val, date: element.fecha })
                    //console.log('TEST', data.ja)
                    this.lotteryForecast.ja = data.ja
                    this.lotteryForecast.jae = data.jae

                    //this.selectCartillaForecastTest(this.paginadorForecast + 1, null, false)
                    //this.selectCartillaForecastTest(this.paginadorForecast - 1, null, false)

                }
            }
        },
        moveSuggestion (e){
            var figur_1 = document.getElementById("figur_1");
            
            var left = parseInt( window.getComputedStyle(figur_1).getPropertyValue("left") );
            var top = parseInt( window.getComputedStyle(figur_1).getPropertyValue("top") );
            var mouseX = e.clientX;
            var mouseY = e.clientY;
            
            document.onmousemove = function (e) {
                var dx = mouseX - e.clientX;
                var dy = mouseY - e.clientY;
                
                figur_1.style.left = left - dx + "px";
                figur_1.style.top = top - dy + "px";
            };
        },
        stopSuggestion: function(){
            document.onmousemove = null;
        },
        closeSuggestionForecast: function( ){
            this.showSuggestion = false;
            // this.numberSuggestion = [];
        },
        validarScroll: function() {
            if ( this.nombreForecast != 'tinkas' &&  this.nombreForecast != 'floridas' ) {
                return '850px;';
            }else{
                return '590px';
            }
        },
        async activePay(){
            const authorization = VueCookie.get('costumer_token');
            if (!authorization) {
                //this.$root.$emit('bv::show::modal', 'authenticated', '#btnShowAnalysis')
              this.$router.push('/login-client')
              return;
            }
            location.href ='/perfil?suscription=true';
        },
        validarLoteriaLittle:function(){
            
            if (this.nombreForecast != 'tinkas') {
                return '100px;';
            }else{
                return '97px';
            }
        },
        transformResultAllIn:function(items){
            this.allInCartillas = items;
            
            for (let z = 0; z < this.allInCartillas.length; z++) {
                
                let index = z;
                
                
                //VALIDANDO PARES
                for (let i = 0; i < this.allInCartillas[index].balls.length - 1; i++) { //vertical
                    for (let j = i + 1; j < this.allInCartillas[index].balls.length; j++) { //horizontal
                        for (let k = 0; k < this.pares.length; k++) {
                            if ( this.pares[k].numeros.includes( this.allInCartillas[index].balls[i] ) &&  this.pares[k].numeros.includes( this.allInCartillas[index].balls[j] ) ) {
                                let numero2 = this.allInCartillas[index].balls[j];
                                let posicion1 =  this.allInCartillas[index].balls.indexOf(this.allInCartillas[index].balls[i]);
                                this.allInCartillas[index].ballpar.push( this.allInCartillas[index].balls[posicion1] );
                                this.allInCartillas[index].balls.splice(posicion1,1);
                                let posicion2 =  this.allInCartillas[index].balls.indexOf(numero2);
                                this.allInCartillas[index].ballpar.push( numero2 );
                                this.allInCartillas[index].balls.splice(posicion2,1);
                                
                            }
                        }
                    }
                }
                
                //VALIDANDO FRIOS
                let colds = this.listColdForecast;
                for (let k = 0; k <  colds.length; k++) {
                    let number = parseInt(colds[k].ball, 10);
                    
                    if ( this.allInCartillas[index].balls.includes(number) &&  ! this.allInCartillas[index].ballhot.includes(number) && !this.allInCartillas[index].ballpar.includes(number) ) {
                        
                        let posicion1 =  this.allInCartillas[index].balls.indexOf(number);
                        this.allInCartillas[index].ballcold.push( this.allInCartillas[index].balls[posicion1] );
                        this.allInCartillas[index].balls.splice(posicion1,1);
                    }
                }
                
                //VALIDANDO CALIENTES
                let hots = this.listHotForecast;
                for (let k = 0; k <  hots.length; k++) {
                    let number = parseInt(hots[k].ball, 10);
                    if ( this.allInCartillas[index].balls.includes(number) &&  !this.allInCartillas[index].ballcold.includes(number) && !this.allInCartillas[index].ballpar.includes(number) ) {
                        let posicion1 =  this.procartillas[index].balls.indexOf(number);
                        this.allInCartillas[index].ballhot.push( this.allInCartillas[index].balls[posicion1] );
                        this.allInCartillas[index].balls.splice(posicion1,1);
                    }
                }
            }
        },
        transformResult:function(items){
            this.procartillas = items;
            for (let z = 0; z < this.procartillas.length; z++) {
                
                let index = z;
                this.procartillas[index].ballAgrupacionJA1 = []
                this.procartillas[index].ballAgrupacionJA1e = []
                this.procartillas[index].ballAgrupacionJA2 = []
                this.procartillas[index].ballAgrupacionJA2e = []
                this.procartillas[index].ballAgrupacionJA3 = []
                this.procartillas[index].ballAgrupacionJA3e = []
                this.procartillas[index].ballAgrupacionJA4 = []
                this.procartillas[index].ballAgrupacionJA4e = []
                this.procartillas[index].ballAgrupacionJA5 = []
                this.procartillas[index].ballAgrupacionJA5e = []
                this.procartillas[index].ballAgrupacionJA6 = []
                this.procartillas[index].ballAgrupacionJA6e = []
                this.procartillas[index].ballAgrupacionJA7 = []
                this.procartillas[index].ballAgrupacionJA7e = []
                this.procartillas[index].ballAgrupacionJA8 = []
                this.procartillas[index].ballAgrupacionJA8e = []
                this.procartillas[index].ballAgrupacionJA9 = []
                this.procartillas[index].ballAgrupacionJA9e = []
                this.procartillas[index].ballAgrupacionJA10 = []
                this.procartillas[index].ballAgrupacionJA10e = []
                this.procartillas[index].ballGroupJA = []
                this.procartillas[index].ballGroupJAE = []
                this.procartillas[index].balls = []
                //VALIDANDO PARES
                for (let i = 0; i < this.procartillas[index].balls.length - 1; i++) { //vertical
                    for (let j = i + 1; j < this.procartillas[index].balls.length; j++) { //horizontal
                        for (let k = 0; k < this.pares.length; k++) {
                            if ( this.pares[k].numeros.includes( this.procartillas[index].balls[i] ) &&  this.pares[k].numeros.includes( this.procartillas[index].balls[j] ) ) {
                                let numero2 = this.procartillas[index].balls[j];
                                let posicion1 =  this.procartillas[index].balls.indexOf(this.procartillas[index].balls[i]);
                                this.procartillas[index].ballpar.push( this.procartillas[index].balls[posicion1] );
                                this.procartillas[index].balls.splice(posicion1,1);
                                let posicion2 =  this.procartillas[index].balls.indexOf(numero2);
                                this.procartillas[index].ballpar.push( numero2 );
                                this.procartillas[index].balls.splice(posicion2,1);
                                
                            }
                        }
                    }
                }
                
                //VALIDANDO FRIOS
                let colds = this.listColdForecast;
                for (let k = 0; k <  colds.length; k++) {
                    let number = parseInt(colds[k].ball, 10);
                    
                    if ( this.procartillas[index].balls.includes(number) &&  ! this.procartillas[index].ballhot.includes(number) && !this.procartillas[index].ballpar.includes(number) ) {
                        
                        let posicion1 =  this.procartillas[index].balls.indexOf(number);
                        this.procartillas[index].ballcold.push( this.procartillas[index].balls[posicion1] );
                        this.procartillas[index].balls.splice(posicion1,1);
                    }
                }
                
                //VALIDANDO CALIENTES
                let hots = this.listHotForecast;
                for (let k = 0; k <  hots.length; k++) {
                    let number = parseInt(hots[k].ball, 10);
                    if ( this.procartillas[index].balls.includes(number) &&  !this.procartillas[index].ballcold.includes(number) && !this.procartillas[index].ballpar.includes(number) ) {
                        let posicion1 =  this.procartillas[index].balls.indexOf(number);
                        this.procartillas[index].ballhot.push( this.procartillas[index].balls[posicion1] );
                        this.procartillas[index].balls.splice(posicion1,1);
                    }
                }
            }
        },
        activeNumeroTestScroll:function( index, i ){
            if (!this.procartillas[index].balls.includes( i ) && !this.procartillas[index].ballpar.includes( i ) && !this.procartillas[index].ballhot.includes( i ) && !this.procartillas[index].ballcold.includes( i )) {
                return true
            }
        },
        activeCrossTestScroll:function( index, i ){
            let j;
            for(j = 0; j < this.procartillas[index].balls.length; j++){
                if(i == this.procartillas[index].balls[j]){
                    return true;
                }
            }
        },
        activeCrossForecastPar:function(i){
            let j;
            if (this.procartillas.length) {
                for(j = 0; j < this.procartillas[this.paginadorForecast].ballpar.length; j++){
                    if(i == this.procartillas[this.paginadorForecast].ballpar[j]){
                        return true;
                    }
                }
            }
        },
        activeCrossForecastParScroll:function(index, i){
            let j;
            for(j = 0; j < this.procartillas[index].ballpar.length; j++){
                if(i == this.procartillas[index].ballpar[j]){
                    return true;
                }
            }
        },
        activeCrossForecastCold:function(i){
            let j;
            if (this.procartillas.length) {
                for(j = 0; j < this.procartillas[this.paginadorForecast].ballcold.length; j++){
                    if(i == this.procartillas[this.paginadorForecast].ballcold[j]){
                        return true;
                    }
                }
            }
        },
        activeCrossForecastColdScroll:function(index,i){
            let j;
            for(j = 0; j < this.procartillas[index].ballcold.length; j++){
                if(i == this.procartillas[index].ballcold[j]){
                    return true;
                }
            }
        },
        activeCrossForecastHot:function(i){
            let j;
            
            if (this.procartillas.length) {
                for(j = 0; j < this.procartillas[this.paginadorForecast].ballhot.length; j++){
                    if(i == this.procartillas[this.paginadorForecast].ballhot[j]){
                        return true;
                    }
                }
            }
            
        },
        activeCrossForecastHotScroll:function(index,i){
            let j;
            for(j = 0; j < this.procartillas[index].ballhot.length; j++){
                if(i == this.procartillas[index].ballhot[j]){
                    return true;
                }
            }
        },
        async compararJugadaResultado(){
            
            const authorization = VueCookie.get('costumer_token');
            if (!authorization) {
                //this.$root.$emit('bv::show::modal', 'authenticated', '#btnShowAnalysis')
                this.$router.push('/login-client')
                return;
            }
            this.showSuggestion = true;
            let index = this.paginadorForecast;
            //PETICION DE BUSCAR CONSTELACIONES SIMILARES CON ESTA FECHA
            let bolillas = [];
            for (let i = 0; i < this.procartillas[index].balls.length; i++) {
                bolillas.push(this.procartillas[index].balls[i]);
                
            }
            for (let i = 0; i < this.procartillas[index].ballhot.length; i++) {
                bolillas.push(this.procartillas[index].ballhot[i]);
            }
            for (let i = 0; i < this.procartillas[index].ballcold.length; i++) {
                bolillas.push(this.procartillas[index].ballcold[i]);
            }
            for (let i = 0; i < this.procartillas[index].ballpar.length; i++) {
                bolillas.push(this.procartillas[index].ballpar[i]);
            }
            let param = { bolillas, fecha:this.items[index].fecha, name: this.$route.params.name };
            
            await this.constellationSame({...param, type: (this.val == "0") ? 0 : 1,id: this.$route.params.id});
            
            
        },
        async copiarJugadaResultado(){
            
            const authorization = VueCookie.get('costumer_token');
            if (!authorization) {
                //this.$root.$emit('bv::show::modal', 'authenticated', '#btnShowAnalysis')
                this.$router.push('/login-client')
                return;
            }
            let index = this.paginadorForecast;
            
            let balls = [...this.procartillas[index].balls,...this.procartillas[index].ballhot,...this.procartillas[index].ballcold, ...this.procartillas[index].ballpar];
            balls = balls.sort(function(a, b){return a - b});
            this.RELOAD_CARTILLAS_PRONOSTICO_RESULTADO( {  numbers:balls ,bExtra:this.procartillas[index].bExtra  } )
            
            let params ={};
            let a = [];
            a.push({numbers:[], bExtra:null })
            a[0].numbers = balls;
            a[0].bExtra = this.procartillas[index].bExtra;
            // console.log("a: ", a);   // console.log("a: ", a);
            //  ---------------------------
            //AÑADE NUEMEROS AL SIDEBAR
            // this.numerosSidebar.push(a[0]);
            //AÑADE NUEMEROS AL TOGGLE
            // -------------------------------
            
            params = { loterie_id: this.$route.params.id , balls: a, date_lottery: this.lotteryForecast.listLotteries[0].date_before ,}
            await this.createCartLottery(params);
            
            this.NAVIGATE_TAB_PRINCIPAL(2);
            swal.fire('¡Copiado a Juego!', '', 'success');
            
        },
        
        
        async rangePrimer(){
            const authorization = VueCookie.get('costumer_token');
            if (!authorization) {
                //this.$root.$emit('bv::show::modal', 'authenticated', '#btnShowAnalysis')
                this.$router.push('/login-client')
                return;
            }
            
            this.date.name = this.$route.params.name
            if (this.date.name =='' || this.date.from == '' || this.date.to == '') {
                swal.fire({
                    icon: 'warning',
                    title: 'Ingrese los rangos de fecha!',
                    showConfirmButton: false,
                    timer: 2000
                })
                return false;
            }
            let data = await this.fetchRangeResult({...this.$route.params,...this.date, type:(this.val == "0") ? 0 : 1});
            this.items = []
            this.items.push(...data);
            this.date.selected = '-';
            await this.transformResult(this.items);
            this.paginadorForecast = 0;
            
        },
        
        validarLoteriaTest:function(){
            if (this.nombreForecast != 'tinkas') {
                return '290px';
            }else{
                return '279px';
            }
        },
        
        activeNumeroExtraTest:function(i){
            if ( this.procartillas.length ) {
                if(i != this.procartillas[this.paginadorForecast].bExtra){
                    return true
                }
            }
            
        },
        activeCrossExtraTest:function(i){
            if ( this.procartillas.length ) {
                if(i == this.procartillas[this.paginadorForecast].bExtra){
                    return true
                }
            }
        },
        
        
        activeNumeroTest:function( i ){
            if ( this.procartillas.length ) {
                if (!this.procartillas[this.paginadorForecast].balls.includes( i ) && !this.procartillas[this.paginadorForecast].ballpar.includes( i ) && !this.procartillas[this.paginadorForecast].ballhot.includes( i ) && !this.procartillas[this.paginadorForecast].ballcold.includes( i )) {
                    return true
                }
            }
        },
        activeCrossTest:function( i ){
            let index = this.paginadorForecast;
            let j;
            if ( this.procartillas.length ) {
                for(j = 0; j < this.procartillas[index].balls.length; j++){
                    if(i === this.procartillas[index].balls[j]){
                        return true;
                    }
                }
            }
        },
        async activeBallTest(index,i){
            console.log('test----------test')
            const authorization = VueCookie.get('costumer_token');
            if (!authorization) {
                this.$router.push('/login-client')
                //this.$root.$emit('bv::show::modal', 'authenticated', '#btnShowAnalysis')
                return;
            }
            
            let posicionBall = this.procartillas[index].balls.indexOf(i);
            let posicionBallhot = this.procartillas[index].ballhot.indexOf(i);
            let posicionBallcold = this.procartillas[index].ballcold.indexOf(i);
            let posicionBallpar = this.procartillas[index].ballpar.indexOf(i);
            
            if (posicionBallhot != -1 || posicionBallcold != -1 || posicionBallpar != -1 || posicionBall != -1) {
                swal.fire({
                    title: '¿Deseas ver su fecha de aparación?',
                    confirmButtonText: `Confirmar`,
                    cancelButtonText: 'Cancelar',
                    showCancelButton: true,
                }).then(async   (result) => {
                    if (result.isConfirmed) {
                        
                        swal.fire('¡Número seleccionado al menu de Analítica!', '', 'success');
                        this.NAVIGATE_TABS([2,1]);
                        this.BALL_APPEARANCE(i);
                        let searchParams = {
                            name: this.$route.params.name,
                            id: this.$route.params.id,
                            limit: 10,
                            type: 'month',
                            appearance: i,
                            from:  '2015-01',
                            to:   '2021-07',
                        };
                        await  EventBus.$emit('clicked',  searchParams)
                    } else if (result.isDenied) {
                        // console.log('Cancelado');
                    }
                });
            }
        },
        
        async selectCartillaForecastTest( index3, date, type ){
           
            const authorization = VueCookie.get('costumer_token');
            if (!authorization) {
                this.$router.push('/login-client')
                //this.$root.$emit('bv::show::modal', 'authenticated', '#btnShowAnalysis')
                return;
            }
            if (type) {
                let name = this.$route.params.name
                let { data: data } = await ApiForecast.getForecastCard({name: name, type: this.val, date: date})
                //console.log('TEST', data.ja)
                this.lotteryForecast.ja = data.ja
                this.lotteryForecast.jae = data.jae
                this.paginadorForecast = index3;
                
            } else {
                this.paginadorForecast = index3;
            }
        },
        selectCartillaForecastTestStyle:function( index3 ){
            if (this.paginadorForecast === index3) {
                return '#c5c4c4 !important';
            }
        },
        changeWidth:function(){
            if (this.nombreForecast !== 'tinkas'){
                return 135 + 'px';
            } else {
                return 125 + 'px';
            }
        },
        
        activeCrossForecast:function(  index, i ){
            let j;
            for(j = 0; j < this.procartillas[index].balls.length; j++){
                if(i === this.procartillas[index].balls[j]){
                    return true
                }
            }
        },
        
        async cargarDatos(){
            if ( this.procartillas.length === 0 ) {
                this.items = await this.fetchTopResult({...this.$route.params, type: (this.val === 0) ? 0 : 1});
                await this.transformResult(this.items);
                this.date.selected = '-';
                await this.getForecastResult({...this.$route.params, type: 0});
                await this.getLoterieForecast({...this.$route.params, type: 0});
    
            }
            this.date.to =  (this.procartillas.length > 0 ) ? this.procartillas[0].fecha : moment().format('YYYY-MM-DD') ;
            this.date.from = (this.procartillas.length > 0 ) ? this.procartillas[ this.procartillas.length - 1 ].fecha : moment().format('YYYY-MM-DD');
        },
        async copyAnaliticaSuggestionConstellation(){
            const fecha =  this.fechaAnalitica[0] ;
            let param = { name :this.$route.params.name, fecha: fecha };
            let data = await this.allInResult({...param, type:(this.val == "0") ? 0 : 1, id:this.$route.params.id});
            this.items.push(...data);
            this.date.selected = '-';
            await this.transformResult(this.items);
            
            for (let index2 = 0; index2 < this.procartillas.length; index2++) {
                const element = this.procartillas[index2];
                if (element.fecha == fecha) {
                    document.getElementsByClassName('cartillaOneResultado')[index2 ].scrollIntoView({ behavior: 'smooth', block: 'center' });
                    this.paginadorForecast = index2;
                }
            }
            
        },
        mobile(){
            /* Storing user's device details in a variable*/
            let details = navigator.userAgent;
            
            /* Creating a regular expression
            containing some mobile devices keywords
            to search it in details string*/
            let regexp = /android|iphone|kindle|ipad/i;
            
            /* Using test() method to search regexp in details
            it returns boolean value*/
            let isMobileDevice = regexp.test(details);
            
            if (isMobileDevice) {
                return true;
            }
            return false;
            
        },
    }, 
    created(){ 
        this.cargarDatos()
    },
    updated() {
        if (  this.fechaAnalitica.length != 0) {
            this.copyAnaliticaSuggestionConstellation();
            this.DATE_APPEARANCE_CLOSE();
        }
    },
}
</script>

<style lang="css" scoped>

button.jugarPageOptionsBot p:hover {
    color: #373b4acc !important;
}
.jugarPageOptionsBot{
        cursor: pointer;
        background: #eaebff;
        text-align: center; 
        margin-bottom: 8px ;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 7px;
        box-shadow: 1px 1px 2px black ;
        padding: 10px;
        font-size: 13px;
        font-weight: 600;
        transition: 0.3s;
    }
    .jugarPageOptionsBot.active{
        cursor: pointer;
        background: #294977;
        text-align: center; 
        margin-bottom: 8px ;
        color: white ;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 7px;
        box-shadow: 1px 1px 2px black ;
        padding: 10px;
        font-size: 13px;
        font-weight: 600;
        transition: 0.3s;
    }
    .jugarPageOptionsBot:hover {
          background: #294977;
          color:white
    }
    .jugarPageOptionsBot:hover p {
          color: white  ;
    }
.draw-default {
    position: absolute;
    z-index:0;
    background: #8f8f8f;
    opacity: 0.8;
    border-radius: 7px;
    height: 32px;
    width: 32px;
}
.texto-boton-ayuda {
    /* display: none; */
    height: auto;
    opacity: 1;
    transition: height 0ms 0ms, opacity 600ms 0ms;
    width: auto;
}
.desactiveBoton{
    cursor:not-allowed !important;
}
.activecartillaagrupacionM3 {
    color: #8F8F8F;
    float: left;
    margin-left: 0px;
    margin-top: -1px;
    font-size: 48px;
}
.boton-ayuda {
    height: max-content;
    width: max-content;
    text-align: center;
    display: flex;
    padding: 10px 20px;
    /* transition: visibility 0s, opacity 0.5s linear; */
}
.showCartillasResultado{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    /* width: 470px;*/
  align-content: baseline;
}

.numeroConstellationStyle{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px 10px;
    margin-left: 5px;
    height: 40px;
    width: 200px;
    background: white;
    border-radius: 5px;
    box-shadow: inset -6px 3px 10px rgb(0 0 0 / 20%), inset 0 0 12px rgb(0 0 0 / 10%);
}
.copiarStyleForecast{
    color: #626571;
    transition: 0.3s;
}
.copiarStyleForecast:hover{
    color:#4652e2
}
.swal2-title {
    margin: 0 0 .4em;
    
    font-size: 2.0em;
    
}
.swal2-content {
    font-size: 18px;
}
.swal2-loader {
    width: 3.2em;
    height: 3.2em;
    margin: 0px 1.875em;
    
}
.modalSubtitle{
    display: flex;
    justify-content: center;
    padding: 10px;
    font-size: large;
}
/* BOTON DE RANGO DE FECHAS */
.btn-send{
    background-color: #17a2b8;
    top: 0;
    padding: 9px 20px;
    color: #fff;
    position: relative;
    font-size: 15px;
    font-weight: 600;
    display: inline-block;
    transition: all 0.2s ease-in-out;
    cursor: pointer;
    margin-right: 6px;
    overflow: hidden;
    border: none;
    border-radius: 2px;
    height: 38px;
}
.btn-send:hover {
    background-color: white !important;
    color: #5B6C8F !important;
    border-style: solid !important;
    border-width: 1px !important;
    border-color: #5B6C8F !important;
}

.styleBotonAgregarJugar{
    width: 90px;
    height: 90px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 72px;
    color: #294977;
    border-radius: 50px;
    box-shadow: 0px 0px 2px black;
    transition: 0.3s;
}
.styleBotonAgregarJugar:hover{
    color: white;
    background: #294977;
    
}
.botonPlayJugar{
    cursor: pointer;
    border-radius: 7px;
    width: 80%;
    height: 70%;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 0px 2px black;
    transition: 0.3s;
}

.botonPlayJugar:hover{
    color: white;
    background: #294977;
}
.botonPlayJugar:hover p{
    color: white;
}

.styleTextFour{
    margin: 0px 0 0px;
    font-size: 15px;
    font-weight: 900;
}
.styleTextThree{
    margin: 0px 0 0px;
    font-size: 18px;
    font-weight: 900;
}
.styleTextTwo{
    margin: 0px 0 0px;
    font-weight: 500;
    font-size: 18px;
}
.styleTextOne{
    margin: 0px 0 0px;
    font-size: 17px;
    font-weight: 600;
    color:#294977
}
.cartillaSelectedJugar{
    box-shadow: 0px 0px 2px black;
    background: #EAF0F5;
    border-radius: 7px;
    padding: 2px;
    transition: 0.3s;
}
.cartillaSelectedJugar:hover{
    background: #c5c4c4;
}
.cartillaOneResultado{
    background: white;
    cursor: pointer;
    transition: 0.3s;
    margin: 3px 3px;
}
.cartillaOneResultado:hover{
    
    background: #c5c4c4 !important
    
}
.cartillaOneResultado:hover div p{
    
    color: white
    
}
.listNumerosJugar{
    margin: 2px;
    background: #dcdcf3;
    height: 10px;
    width: 10px;
    border-radius: 2px;
}

.jugarPageOptions{
    cursor: pointer;
    background: white;
    text-align: center;
    margin: 10px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 7px;
    box-shadow: 0px 0px 2px black;
    padding: 10px;
    transition: 0.3s;
}
.jugarPageOptions:hover {
    background: #294977;
    color:white
}
.jugarPageOptions:hover p {
    color: white  ;
}
.numero-test{
    font-size: 15px;
    margin: 0px 0 0px;
    color: rgb(80, 100, 102);
    font-weight: 600;
}
.botonAdicionarTest{
    width: 20%;
    height: 220px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 20px;
}

.activeUnderline{
    text-decoration: underline
}


.horizontal-scroll {
    display: flex;
    width: 80%;
    height: 220px;
}
.outer {
    display: flex;
    flex: 1;
    width: auto;
    height: 100%;
    padding: 0 20px;
    flex-flow: row nowrap;
    align-items: center;
}
.inner-content-resultado {
    justify-content: center;
    width: 110px;
    height: max-content;
    box-shadow: 0px 0px 5px black;
    border-radius: 5px;
    padding: 5px;
}
 

.activehot {
    font-size: 48px;
    margin: 3px;
    color: #68001C !important;
}
 

.activecold {
    font-size: 48px;
    margin: 3px;
    color: #027B76 !important;
   
} 

.activepar {
    color: #AB853A !important;
    font-size: 48px;
     margin: 3px;
} 
.numeros-test{
    cursor: pointer;
    background: white;
    border-radius: 7px;
    margin: 5px 5px;
    padding: 7px;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.bg-primary {
    background-color: #294977 !important;
}
a.bg-primary:hover, a.bg-primary:focus, button.bg-primary:hover, button.bg-primary:focus {
    background-color: #294977 !important;
}

.numerosSidebarStyle{
    display:flex;
    padding: 10px;
    border-bottom: 1px solid rgb(221, 221, 221);
}
.vinetaSidebarStyle{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 5px;
    height: 40px;
    width: 40px;
    background: white;
    border-radius: 20px;
}
.numeroSidebarStyle{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 5px;
    height: 40px;
    width: 40px;
    background: white;
    border-radius: 20px;
    box-shadow: inset -6px 3px 10px rgb(0 0 0 /20%), inset 0 0 12px rgb(0 0 0 /10%);
}

.opcionesEliminarTodo{
    cursor:pointer;
    display: flex;
    align-items: center;
    /* justify-content: center; */
    height: max-content;
    border-radius: 7px;
    margin: 5px;
}
.desactiveBoton{
    cursor:not-allowed !important;
}
.activeCross{
    color: #506466 !important
}
.activehotScroll{
    color: #68001C !important;
}
.activecoldScroll{
    color: #027B76 !important;
}
.activeparScroll{
    color: #AB853A !important;
}
.desactiveCross{
    float: left;
    margin-left: 0px;
    margin-top: -3px;
    color: transparent;
    font-size: 16px;
}
.containerCartillas{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    font-size: 15px;
    text-align: center;
    width: 660px;
    padding: 3px;
}
.punteroNormal{
    cursor:context-menu !important;
}

.listCartillas{
    cursor:pointer;
    box-shadow: 0px 0px 5px black;
    /* border: 1px solid #e6d7d7; */
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
    background: white;
    
    height: 170px;
    margin: 10px;
    border-radius: 7px;
    padding-bottom: 10px;
    padding-top: 10px;
    padding-right: 5px;
    padding-left: 5px;
}

.listCartillas:hover{
    
    background: #dfdfdf
    
}

.listNumeroCartillasJugar{
    
    color: #D1D8E3;
    font-size: 90px;
    margin: 0px 0 0px;
    
}

.listCartillas:hover div h1{
    
    color: white
    
}

.listShow{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
}
.listNumeros{
    background: #F6F6F8;
    width: 13px;
    height: 13px;
    margin-right: 2px;
    margin-left: 2px;
    margin-top: 2px;
    border-radius: 4px;
}




@media (min-width: 1367px) {
    .container {
        width: 1310px;
    }
}

.text-center {
    text-align: center!important;
}


.activehot {
    color: #68001C !important;
}

.activecold {
    color: #027B76 !important;
    
}

.activepar {
    color: #AB853A !important;
}

p, span, li {
    margin: 0;
    color: rgb(55 59 74 / 80%);
    line-height: 1.7;
    font-size: 16px;
}

 .jugarPageOptionsBotExtra.active{
        cursor: pointer;
        background: #019845;
        text-align: center;
        color: white ;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 7px;
        box-shadow: 1px 1px 2px black ;
        padding: 8px 4px  ;
        font-size: 13px;
        font-weight: 600;
        transition: 0.3s;
    }
    .jugarPageOptionsBotExtra:hover {
          background: #019845;
          color:white
    }
    .jugarPageOptionsBotExtra:hover p {
          color: white  ;
    }
    .btn-boot {
        width: 71px;
        height: 40px;
        padding: 8px 4px !important;
        margin: 5px 5px !important;
        margin-bottom: 7px !important;
    }
    .btn-boot-extra {
        width: 71px;
        height: 40px;
        margin: 5px 5px !important;

    }

    .listNumerosJugar{
        margin: 2px;
        background: #dcdcf3;
        height: 10px;
        width: 10px;
        border-radius: 2px;
        align-items: center;
        display:flex
    }

    .left {  
        position: absolute;
        left: 0px;
        right: 500px;
    }

    .right { 
        position: absolute;
        right: 0px;
        max-width: 500px;
    }
.content-card-l {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 290px;
}
.content-cards-lottery {
  height: 620px;
  overflow-y: auto;
}
.content-result{
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
}
.margin-top-70-less {
  margin-top: -70px;
}
@media screen and (max-width: 1000px) {
  .content-result{
    display: flex; flex-flow: row wrap-reverse;
  }
}
@media screen and (max-width: 2560px) {
  .margin-top-70-less { margin-top: 0  }

  .responsive-result-height{
    height: 80vh;
  }
  .content-cards-lottery {
    height: 900px;
    overflow-x: auto;
  }
}
@media screen and (max-width: 1920px) {
  .margin-top-70-less { margin-top: -70px  }

  .responsive-result-height{
    height: 70vh;
  }
  .content-cards-lottery {
    height: 550px;
    overflow-x: auto;
  }
}
@media screen and (max-width: 1600px) {
  .responsive-result-height{
    height: 70vh;
  }
  .content-cards-lottery {
    height: 550px;
    overflow-x: auto;
  }
}
@media screen and (max-width: 1366px) {
  .responsive-result-height{
    height: 68vh;
  }
  .content-cards-lottery {
    height: 440px;
    overflow-x: auto;
  }
  .activehot, .activecold, .activepar, .activeCross {
    font-size: 38px;
  }
  .numeros-test {
    border-radius: 7px;
    margin: 3px;
    padding: 5px;
    width: 26px;
    height: 26px;
  }
}
@media screen and (max-width: 1024px) {
  .margin-top-70-less { margin-top: 0  }
  .responsive-result-height{
    height: 68vh;
  }
  .activehot, .activecold, .activepar, .activeCross {
    font-size: 38px;
  }
  .numeros-test {
    border-radius: 7px;
    margin: 3px;
    padding: 5px;
    width: 26px;
    height: 26px;
  }
  .content-cards-lottery {
    height: 440px;
    overflow-x: auto;
  }
}
@media screen and (max-width: 900px) {
  .margin-top-70-less { margin-top: 0  }
  .responsive-result-height{
    height: 50vh;
  }
}
@media screen and (max-width: 768px) {
  .margin-top-70-less { margin-top: 0  }

  .responsive-result-height{
    height: 50vh;
  }
}
@media screen and (max-width: 600px) {
  .margin-top-70-less { margin-top: 0 }
  .responsive-result-height{
    height: 50vh;
  }
}
@media screen and (max-width: 480px) {
  .margin-top-70-less { margin-top: 0}
  .responsive-result-height{
    height: 50vh;
  }
}

body, html {
  overflow: hidden !important;
}
@media screen and (max-height: 768px) {
  /*.numeros-test {
    cursor: pointer;
    background: white;
    border-radius: 7px;
    margin: 3px;
    padding: 5px;
    width: 26px;
    height: 26px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .content-card-l {
    width: 250px;
  }
  .content-cards-lottery {
    height: 440px;
    overflow-x: auto;
  }
  .activehot, .activecold, .activepar, .activeCross {
    font-size: 38px;
  }
  .tab-content {
    padding: 15px 30px !important;
  }
  .container {
    width: 1000px;
  }*/
}

.tab-content {
  padding: 15px 30px !important;
}
</style>