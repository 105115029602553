<template>
  <div class="col-md-12"  >
      <div style="display: flex; justify-content: center;">
        <img :src="result.lottery.image ? result.lottery.image : '' " style="width: 300px; text-align: center"/>
      </div>
      <div class="row " >
       <div class="col-md-12">
          <div class="col-md-6">
           <label>Numeros ganadores</label>
           <label>{{ result.date }}</label>
           <div>
             <ul class="number-list" style="display: flex;align-items: center;">
               <li v-for="(item, i) in balls_lottery" :key="i"><span class="LottoBall__circle">{{item}}</span></li>
               <li v-if="result.lottery.ball_lottery !== 'MULTIPLICADOR' "><span class="LottoBall__circle" style="background-color: #ff3b3b;color: #fff">{{result.ball_red}}</span></li>
               <div v-if=" result.enhancer !== 0 && parseInt(type) === 0">x {{result.enhancer}} </div>
             </ul>
           </div>
         </div>
         <div class="col-md-6">
           <label>Siguiente sorteo</label>
           <label>{{ result.date_before }}</label>
           <label>{{ convertPrice(result.amount) }}</label>
         </div>
       </div>
     </div>
     <div  class="col-md-12 " >

       <template v-if="result.lottery.ball_lottery !== 'MULTIPLICADOR'  && parseInt(type) === 0 ">

         <b-table
             :striped="striped"
             :bordered="bordered"
             :borderless="borderless"
             :outlined="outlined"
             :small="small"
             :hover="hover"
             :fixed="fixed"
             :foot-clone="footClone"
             :dark="picked"

             responsive="sm" :fields="fields" :items="result_detail">

           <template  #thead-top="" >
             <b-tr >
               <b-th colspan="1"><span>PREMIO</span></b-th>
               <b-th colspan="2">{{ result.lottery.sub_name }}</b-th>
               <b-th colspan="2">{{ result.lottery.plier_lottery}}</b-th>
             </b-tr>

           </template>
           <template #cell(lottery_prize)="row" >
              <div>
                {{convertPrice(row.item.lottery_prize)}}
              </div>
           </template>

           <template #cell(play_winner)="row">
             <div v-if="row.item.play_winner===''" >
               -
             </div>
             <div v-else>
               {{  row.item.play_winner }}
             </div>
           </template>
           <template #cell(play_prize)="row">
             <div v-if="row.item.play_prize===''">
               -
             </div>
             <div v-else>
               {{convertPrice(row.item.play_prize)}}
             </div>
           </template>
         </b-table>
       </template>
       <template v-else>
         <b-table
             :striped="striped"
             :bordered="bordered"
             :borderless="borderless"
             :outlined="outlined"
             :small="small"
             :hover="hover"
             :fixed="fixed"
             :foot-clone="footClone"
             :dark="picked"
             id="tableLottery"     responsive="sm"  :fields="fields_m" :items="result_detail"  >
           <template #thead-top="">
           </template>
         </b-table>
       </template>
      <div style="display: flex; flex-direction: row;">
        <div style="display: flex; flex-direction: column;">
          <label>Verifica tus aciertos</label>
          <div style="display:flex;align-items: baseline" >
            <div style="margin: 0 5px;" v-for=" (item, i) in result.lottery.selectNumber" :key="'k-'+i">
              <input type="text" v-model="balls[i]"  style="text-align: center; width: 80px" maxlength="2"/>
            </div>
            <template v-if="result.lottery.ball_lottery !== 'MULTIPLICADOR' &&  parseInt(type) === 0">
              x
              <div style="margin: 0 10px;">
                <input  style="text-align: center;width: 80px" type="text" v-model="ball_red" maxlength="2">
              </div>
            </template>
            <button @click="valid"  style="">Enviar</button>
          </div>
        </div>

          <div style=" margin-left: 20px ; display: flex; flex-direction: row; justify-content: center; align-items: center; margin-top: 15px">
            <label class="margin-top-10">Coincidencias: </label>
            <div style="display: flex; flex-direction: row;align-items: center;">
              <ul class="number-list">
                <li v-for="(item, i) in valid_number.balls" :key="'valid-'+i"><span class="LottoBall__circle">{{item}}</span></li>
              </ul>
              <template  v-if="valid_number.ball_red ">
                <ul class="number-list">
                  x<li  ><span class="LottoBall__circle" style="background-color: #ff3b3b;color: #fff">{{valid_number.ball_red}}</span></li>
                </ul>
              </template>
            </div>
          </div>

      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  name: "Lottery",
  data() {
    return {
      ball_red: 0,
      balls: [],
      fields: [
        { key: 'prize_level', label: 'NIVEL'},
        { key: 'lottery_winner', label: 'Ganadores*' },
        { key: 'lottery_prize', label: 'Premio' },
        { key: 'play_winner', label: 'Ganadores*' },
        { key: 'play_prize', label: 'Premio*' },
      ],
      fields_m: [
        { key: 'prize_level', label: 'NIVEL'},
        { key: 'lottery_winner', label: 'Ganadores*' },
        { key: 'lottery_prize', label: 'Premio' },
      ],
      balls_lottery:[],
      result_detail: [],
      result : {
        lottery: {}
      },
      striped: false,
      bordered: false,
      borderless: true,
      outlined: true,
      small: false,
      hover: true,
      dark: true,
      fixed: false,
      footClone: false ,
    //  headVariant: 'light',
    }
  },
  props: {
    type: {
      type: Number
    }
  },
  methods: {
    ...mapActions({
      getLotteryResult: "getLotteryResult", 
      validNumbers: "validNumbers"
    }),
    valid() {
     this.validNumbers({...this.$route.params, balls: JSON.stringify(this.balls), ball_red: this.ball_red, type: this.type})
    },
    show() {
      this.balls_lottery = JSON.parse(this.result.balls)
    },
    convertPrice(val) {
      return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(val)
    }
  },
  computed: {
    ...mapState({
        valid_number: state => state.lottery.valid_number,
      picked: state => state.costumer.picked
    })
  },
  async mounted() { 
    this.result = await this.getLotteryResult({ id: this.$route.params.id, name: this.$route.params.name, type: this.type })

    this.result_detail =  this.result.detail.sort(function(a,b){return a.position - b.position;}); // order asc

    await this.show()
  }
}
</script>

<style  scoped>

@media screen and (max-height: 768px){
  #tableLottery.table tbody tr td {
    padding: 2px !important;
  }
  #tableLottery.table thead tr th {
    padding: 2px !important;
  }
}
button {
  border-color: #b68c64 !important;
  background: #b68c64 !important;
  border: 1px solid #b68c64 !important;
  color: white !important;
}
button:hover {
  border-color: #b68c64 !important;
  background: transparent !important;
  border: 1px solid #b68c64 !important;
  color: #b68c64 !important;
}
/*table th {
  font-family: var(--heading-font);
  font-weight: 400;
  text-transform: capitalize;
  color: var(--heading-color);
  background-color: #f1f2f4;
  padding-top: 5px;
  padding-bottom: 5px;
}*/
/*table tbody tr:nth-child(odd) {
  background-color: #ffffff;
}*/
/*table tbody tr:nth-child(even) {
  background-color: #f1f2f4;
}*/

</style>