import Vue from 'vue'
import Vuex from 'vuex'
import costumer from './modules/costumer'
import user from './modules/user'
import lotto from './modules/lotto'
import lottery from './modules/lottery'
import analysis from './modules/analysis'
import forecast from './modules/forecast'
import checkout from './modules/checkout'
import purchase from './modules/purchases'
import pena from './modules/pena'
import support from './modules/support'
import subscription from './modules/subscription'
import notification from './modules/notification'
import results from './modules/results'
import promotions from './modules/promotions'

import tabsNavegation from './modules/tabsNavegation'

import resultsAdmin from './modules/admin/result'
import supportAdmin from './modules/admin/support'

Vue.use(Vuex) 

const store = new Vuex.Store({
    modules: {
        costumer,
        lottery,
        lotto,
        user,
        analysis,
        forecast,
        checkout,
        purchase,
        pena,
        support,
        subscription,
        notification,
        results,
        promotions,
        tabsNavegation,
        resultsAdmin,
        supportAdmin
    }
});

export default store;