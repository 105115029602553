<template> 
    <!-- ----------------------------- --> 
          <b-modal   id="notificationModal"  title="Detalle de la compra" >
            <b-container fluid> 
                  <!-- <div  class="modalSubtitle"  >  Detalle de la compra  </div>  -->
              <div>
             <div class="my-account-wrapper" > 
                 
                <div class="ts-tiny-account-wrapper">
                    <h3 style="text-align:center">Detalle de la compra </h3>
                    <div class="ts-tiny-account-wrapper">
                        <div style="display:flex; justify-content: center;"> 
                            <div> Número de Orden:</div>  
                            <div style="margin-left: 10px">  # {{ (""+oneNotifications.id).padStart(6, "0") }} </div> 
                        </div>
                        <div style="display:flex; justify-content: center;"> 
                            <div> Loteria:  </div> <div style="margin-left: 10px">  {{oneNotifications.lottery.description}} </div> 
                        </div>
                        <div style="display:flex; justify-content: center;"> 
                            <div> Fecha de compra:  </div> <div style="margin-left: 10px"> {{formatDate(oneNotifications.createdAt)}}  </div> 
                        </div>
                         
                    </div> 
                    <table style="margin-bottom: 0px">
                        <thead>
                            <tr> 
                                <th>#</th>
                                <th style="align-text:center">Descripción</th>  
                                <th>Precio</th>   
                            </tr>
                        </thead>
                        <tbody>
                            <tr >
                                <td>1</td> 
                                <td> 
                                    <ul  class="number-list-small" > 

                                        <li v-for="item in JSON.parse(oneNotifications.balls)" :key="'purchase-modal-'+item" >
                                            <span class="LottoBall__circle"> 
                                                {{item}} 
                                            </span>  
                                        </li> 
                                     
                                        <li v-if="oneNotifications.ball_red != null" > 
                                            <span class="LottoBall__circle" style="background-color: #ff3b3b;color: #fff">  
                                                {{oneNotifications.ball_red}} 
                                            </span>  
                                        </li>  
                                    </ul>	
                                </td>
                                <td>
                                    $9.00
                                </td>
                            </tr> 
                        </tbody>
                    </table>    

                    <h3 style="text-align:center">Detalle de la ganancia </h3>
                    <div style="display:flex; justify-content: center;"> 
                            <div> Fecha de juego:  </div> <div style="margin-left: 10px"> {{formatDate(oneNotifications.jackpot)}}  </div> 
                    </div>
                    <table style="margin-bottom: 0px">
                        <thead>
                            <tr> 
                                <th>#</th>
                                <th style="align-text:center">Bolillas acertadas</th>   
                                <th>Total</th>   
                            </tr>
                        </thead>

                        <tbody>
                            <tr >
                                <td>2</td> 
                                <td>
                                    <ul  class="number-list-small" > 

                                        <li v-for="item in  oneNotifications.winBall" :key="item+'purchase-modal-'+item" >
                                            <span class="LottoBall__circle"> 
                                                {{item}} 
                                            </span>  
                                        </li> 
                                     
                                        <li v-if="oneNotifications.extraWinBall != null" > 
                                            <span class="LottoBall__circle" style="background-color: #ff3b3b;color: #fff">  
                                                {{oneNotifications.extraWinBall}} 
                                            </span>  
                                        </li>  
                                    </ul>    
                                </td> 
                                <td>
                                    ${{oneNotifications.monto}}
                                </td>
                            </tr> 
                        </tbody>
                    </table>  
                    <div class="col-lg-12" style="display: flex;justify-content: center;margin-bottom: 20px;">
                        <img :src="'/uploads/lotto.jpg'" style="height: 400px; object-fit: cover;" alt="">
                    </div>
                </div>
            </div>
        </div>   
            </b-container>

            <template #modal-footer>
              <div class="w-100"> 
                <div style="cursor: pointer;" @click="show2 = false " class="float-right">
                  Cerrar
                </div> 
              </div>
            </template>
          </b-modal>
          <!-- -------------------------------------- -->
</template>

<script>
    import { mapActions, mapState,mapMutations } from 'vuex';
    import moment from 'moment'
 
    export default { 
        name: 'NotificationModal',
        data() {
            return { 
                show2: false
            };
        },   
        computed: {
            ...mapState({  
                oneNotifications: state =>state.notification.oneNotifications  
             })
        }, 
        methods: {
             isset (ref) { return typeof ref !== 'undefined' },
            ...mapActions({ 
            }),  
            ...mapMutations({ 
                ONE_NOTIFICATION: 'ONE_NOTIFICATION',
                SHOW_MODAL_NOTIFICATION:'SHOW_MODAL_NOTIFICATION'
                
            }), 
            formatDate(value) {
                return moment(String(value)).format('YYYY-MM-DD')
            }
        },
        async created(){ 
        },
        
    }
</script>

<style>  
    table  td,th{
        text-align: center;
    }
    
    @media (min-width: 576px) {
        .modal-dialog {
            max-width: 450px !important;
            margin: 1.75rem auto;
        }
    }
    
    .number-list-small li {
        width: 30px;
        height: 30px; 
        text-align: center;
        line-height: 30px;
        display: inline-block;
        margin: 3px;
        border-radius: 50%;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        -ms-border-radius: 50%;
        -o-border-radius: 50%;
    }
</style>

