<template>
  <div>
    <div id="titlebar">
      <div class="row">
        <div class="col-md-12">
            <h2>Lista Loterias</h2>
            <!-- Breadcrumbs -->
            <nav id="breadcrumbs">
              <ul>
                <li><a href="#">Loterias</a></li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-12 col-md-12">
            <div class="dashboard-list-box-static">
              <div class="row with-forms">
                <table class="table-striped  margin-top-40">
                    <thead>
                      <tr class="">
                        <th class="text-center">Nombre</th>
                        <th class="text-center">Nombre de bolilla</th>
                        <th class="text-center">Numeros</th>
                        <th class="text-center">Numero especial</th>
                        <th class="text-center">Jugadas/Dia</th>
                        <th class="text-center">Acciones</th>
                      </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(lottery, i) in lotteries" :key="i" >
                      <template v-if="!lottery.state">
                        <td class="text-center"><strong>{{lottery.description}}</strong> </td>
                        <td class="text-center"> {{lottery.sub_name}}</td>
                        <td class="text-center"> 1 - {{lottery.size}} </td>
                        <td class="text-cen ter"> 1 - {{lottery.sub_length}} </td>
                        <td class="text-center"> {{lottery.intents}} </td>
                        <td class="text-center" style="display: flex;">
                            <div class="add-slot-btn">
                              <button href="#" class="" @click="edit(lottery.id)"><i class="sl sl-icon-note"></i> Edit</button>
                            </div>
                            <div class="add-slot-btn">
                              <button href="#" class="margin-left-10" @click="remove(lottery.id)"><i class="sl sl-icon-close"></i> Remove</button>
                            </div>
                        </td>
                      </template>
                    </tr>
                    </tbody>
                  </table>
              </div>
            </div>
        </div>
      </div>
    </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import sweet from 'sweetalert2'

export default {
    name: 'List',
    data() {
        return {}
    },
    mounted(){
      console.log("this.$route.params", this.$route.params)
        this.getLotteries({...this.$route.params})
    },
    computed: {
      ...mapState({
        lotteries: state => state.lottery.lotteries
      })
    },
    methods:{
        ...mapActions({
           getLotteries : 'getLotteries',
           deleteLottery: 'deleteLottery'
        }),
        edit(id){
          this.$router.push("/admin/loterias/" +id)
        },
        remove(id){
          sweet.fire({
                title: '¿Deseas eliminar el registro?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Si, eliminar',
                cancelButtonText: 'Cancelar'                         
            }).then(  (result) => {
                if (result.isConfirmed) { 
                   this.deleteLottery({id: id})
                   sweet.fire('¡Registro eliminado!','El registro ha sido eliminado','success')
                } else if (result.isDenied) {
                  console.log("")
                }
            })              
        },
    }
}
</script>

<style scoped>

 table  {
   color: #707070;
   font-size: 15px;
   line-height: 27px;
   font-family: Raleway;
   border-radius: 5px !important;
   box-shadow: none !important;
   border: 2px solid #e8e8e8 !important;
 }

 table > thead > tr {

   border-bottom: 2px solid #EDEDED;

 }
  table > thead > tr > th {
    border-top: 0;
    padding: 15px;
    vertical-align: middle;
    line-height: 1.42;
    box-sizing: border-box;
    text-align: left;
  }

 table > tbody > tr > td {
   padding: 15px;
   vertical-align: middle;
   border-top: 1px solid #EDEDED;
 }
</style>