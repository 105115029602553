<template>
  <div class="container" style="margin-top:15px">
      <h2>Preguntas Frecuentes</h2>
      <p>¡Elija una categoría y reciba de inmediato ayuda sobre la lotería en línea! Si nuestra sección de Preguntas Frecuentes no responde a su consulta, por favor, contáctenos y ¡nuestro Servicio de Atención al Cliente estará feliz de poder ayudarlo!</p>
      
      <b-row class="mt-3">
        <b-col cols="12" md="12" lg="12">
           <Question/>
        </b-col>
      </b-row>
  </div>
</template>


<script>
import Question from '@/components/frontend/questions/Question.vue'
export default { 
  name : 'Questions',
  components:{
    Question
  }
}
</script>


 