<template>
  <div>
  
        <div>
          <div class="row">
            <div class="col-md-12">
                        <h1 class="title">Usuarios</h1>			
              
            </div>
          </div>
        </div>


    <div class="dashboard-list-box margin-top-0">

      <div class="list-box-listing">
        <div>
          <h4 style="background-color: #f7f7f7; border-bottom: 0px solid #eaeaea;"></h4>
        </div>
        <div class="list-box-listing-content col-md-10 padding-bottom-25">
          <div class="inner float-right" style="display:flex; justify-content:flex-end">
            <router-link  custom v-slot="{ navigate }" to="/admin/newUser" >
              <a  @click="navigate"  @keypress.enter="navigate" role="link"  style="margin-left:5px" class="button">
              Ingresar Nuevo Usuario
              </a>
            </router-link>
          </div>
        </div>
      </div>
      <ul>
      
        <li :key="index" v-for="(user, index) in users" style="list-style: none;">
          <div class="list-box-listing">
            <div class="list-box-listing-img margin-left-25">
                <img style="height:145px;" v-bind:src="'/uploads/profile.png'" class="attachment-listeo_core-preview size-listeo_core-preview wp-post-image" alt="Imagen" >
            </div>
            <div class="list-box-listing-content padding-bottom-20">
              <div class="inner">
                <h3><a href="#">{{user.name}} {{user.lastname}}</a></h3>
                <div class="listing-list-small-badges-container">
                  <div class="listing-small-badge pricing-badge">
                    DNI: {{user.dni}}
                  </div>
                  <div class="listing-small-badge pricing-badge">
                    Usuario: {{user.email}}
                  </div>
                  <div class="listing-small-badge pricing-badge">
                    Fecha: {{format_date(user.createdAt)}}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="buttons-to-right">
            <button class="button gray" @click="edit(user.id)"><i class="sl sl-icon-close"></i> Editar</button>

            <button class="button gray" @click="remove(user.id)"><i class="sl sl-icon-close"></i> Eliminar</button>
          </div>
        </li>
        
        
      </ul>
    </div>
    

  </div>
</template>

<script>
import moment from 'moment'
import sweet from 'sweetalert2'
import {mapActions, mapState} from "vuex";
export default {
    name: 'Users',
    data(){
        return {

        }
    },
    mounted(){               
      this.getUsers()
    },
    computed: {
      ...mapState({
          users : state => state.user.users,
          removed: state => state.user.removed
       })
    },
    methods:{
      ...mapActions({
        getUsers: 'getUsers',
        removedUser: 'removedUser'
      }),
      format_date(value){
         if (value) {
           return moment(String(value)).format('DD/MM/YYYY HH:mm')
         }
      },
      remove(id){

          sweet.fire({
            title: '¿Deseas eliminar el registro?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Si, eliminar',
            cancelButtonText: 'Cancelar'
            }).then((result) => {
              if (result.isConfirmed) {
                this.removedUser(id)
                this.getUsers()
                if (this.removed) {
                  sweet.fire(
                      '¡Registro eliminado!',
                      'El registro ha sido eliminado',
                      'success'
                  )

                }
              }
            })
      },
      edit(id){
        this.$router.push("/admin/editUser/"+id) 
      }
    }
}
</script>

<style>

</style>