import checkoutAPI from '../../api/checkout' 
import Vue from 'vue'
import VueCookie from 'vue-cookie'
 
Vue.use(VueCookie);

const state = { 
    numberCheckout: 0,
    message_delete_one:'',
    country:null,
    numberCreate:null
}
const getters = { 

}

const actions = { 
 
    async listOneCheckout({ commit }, { ...searchParams }) {   
            const {  data: data } = await checkoutAPI.listOneCheckout(searchParams); 
            if(data.ok){   
                commit("LIST_CHECKOUT", data.listCheckout); 
                commit("COUNTRY_CHECKOUT", data.country); 
                return data; 
            }  
            return data.listCheckout;  
    },  
    async listCheckout({ commit }, { ...searchParams }) {
            const {  data: data } = await checkoutAPI.listCheckout(searchParams);
            if(data.ok){   
                commit("LIST_CHECKOUT", data.listCheckout); 
                commit("COUNTRY_CHECKOUT", data.country);
                return data.listCheckout; 
            }  
            return data.listCheckout;  
    },   
    // ELIMINAMOS UN REGISTRO DEL CHEKOUTS Y DEL CARRITO DE COMPRAS
    async deleteOneCheckout({commit}, id) {  
        const { status, data: data } = await checkoutAPI.deleteOneCheckout(id);
        if(status == 200) commit("DELETE_ONE_CHECKOUT", data); 
    },  
    async deleteOneLotteryCheckout({commit}, id) {  
        const { status, data: data } = await checkoutAPI.deleteOneLotteryCheckout(id);
        if(status == 200) commit("DELETE_ONE_CHECKOUT", data); 
    },  
    async deleteAllCheckout(  { ...searchParams }) { 
         await checkoutAPI.deleteAllCheckout(searchParams);
         
    },  
    async createCartLottery({ commit }, { ...searchParams }) { 
        const { status, data  } = await checkoutAPI.createOneCheckout(searchParams)   
        if (status === 200)  commit('CREATE_CHECKOUT', data) 
        return data;
    },
    async createCartNormalLottery({ commit }, { ...searchParams }) { 
        const { status, data  } = await checkoutAPI.createOneNormalCheckout(searchParams)   
        if (status === 200)  commit('CREATE_CHECKOUT', data) 
        return data;
    },
    
    
}

const mutations = {
    CREATE_CHECKOUT(state, val) {
        state.numberCreate = val.registered;
    },
    COUNTRY_CHECKOUT(state, data) {   
         
        state.country = data;
    }, 
    LIST_CHECKOUT(state, data) {  
        let count = 0;
        for (let i = 0; i < data.length; i++) { 
            count ++
        }
        state.numberCheckout = count;
    }, 
    DELETE_ONE_CHECKOUT(state, data) {  
         state.message_delete_one = data.message; 
    }, 
}
export default {
    actions,
    mutations,
    getters,
    state
}