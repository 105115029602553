<template> 
    <div class="container"> 
        <carousel  :autoplay="false" :nav="false" :loop="false" :dots="false"  :items="4"  >  
            <section v-bind:key="index"  v-for="(item, index) in mainLoteries" >  
                 <div style=" margin: 15px;  " class="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-7c28a103" data-id="7c28a103" data-element_type="column">
                    <div class="elementor-column-wrap elementor-element-populated">
                        <div class="elementor-widget-wrap">
                        <div class="elementor-element elementor-element-6e5a1c95 elementor-widget elementor-widget-listeo-iconbox" data-id="6e5a1c95" data-element_type="widget" data-widget_type="listeo-iconbox.default">
                            <div class="elementor-widget-container">
                                <div class="icon-box-2 "> 
                                    <a @click="showPrognostic(item)" style="display: flex;justify-content: center;" > 
                                        <img v-bind:src="item.image"  style="width: 200px;"> 
                                    </a>  
                                </div>
                            </div>
                        </div>
                        </div>
                    </div>
                </div> 
            </section>   
        </carousel>  

        <!-- ----------------------------- --> 
        <!-- <Alerta/> -->
       
          <!-- -------------------------------------- -->
    </div>
</template>

<script>
import carousel from 'vue-owl-carousel' 
import { mapState, mapActions } from 'vuex'
import VueCookie from 'vue-cookie'
import swal from 'sweetalert2'; 
// import Alerta from './Alerta'
export default {
    data(){
        return {
            show: false
        }
    },
    components: {
        carousel, 
        // Alerta 
    },
    mounted(){
        
        //this.getLoteriesMain()
     
        this.validateOneSubscription()  
    },  
    computed: {
        ...mapState({
                mainLoteries: state => state.lotto.mainLoteries, 

                existeSubscription: state =>state.subscription.existeSubscription,
                
                isAuthenticated: state => state.costumer.authenticated, 
        })
    },
    methods: {
        ...mapActions({ 
         //   getLoteriesMain: 'getLoteriesMain',
  
            validateOneSubscription: 'validateOneSubscription',
            createOneSubscription: 'createOneSubscription',
            renovateOneSubscription: 'renovateOneSubscription' 
        }),
        async showPrognostic(item) {  
             
            const authorization = VueCookie.get('costumer_token'); 
            if (authorization) { 
                await this.validateOneSubscription();
                if (! this.existeSubscription) { 
                    
                    await swal.fire( 'Solo para suscriptores!', 'Suscribte ahora o hazlo desde el menú de perfil del cliente!', 'success')
                    this.activePay(); 
                    return; 
                }  
                location.href ='/pronostico/'+item.id +'/'+item.name; 
            } else { 
               // this.$root.$emit('bv::show::modal', 'authenticated', '#btnShowAnalysis')
              this.$router.push('/login-client')
            }
        },
        activePay:function(){  
                    this.show = true; 
                    const script = document.createElement("script");
                        script.src = "https://www.paypal.com/sdk/js?client-id=AWFR6DpC6P2bboUuBVAMxB9MZysiB4mbpcilwqdxqPCGY3tVTFMcBK6XQ_c-5gr-cm0kKM0KlXq11E84";
                        script.addEventListener("load", this.setLoaded);
                        document.body.appendChild(script);   
            },
            setLoaded: function() { 
                this.loaded = true;
                window.paypal
                .Buttons({
                    style: {
                         
                    },
                    createOrder: (data, actions) => {
                    return actions.order.create({ 
                        purchase_units: [
                        {
                            description: "Compra de una suscripcion de 9 dolares mensual",
                            amount: {
                            currency_code: "USD",
                            value: 9
                            }
                        }
                        ]
                    });
                    },
                    onApprove: async (data, actions) => {
                    const order = await actions.order.capture(); 
                    console.log(order);
                     
                                if (this.existeSubscription == false) {
                                    await this.createOneSubscription(); 
                                }else{
                                    await this.renovateOneSubscription(); 
                                }

                        // ----------------------------------------
                   
                        this.show = false;
                    swal.fire('¡Suscripción realizada!', '', 'success')

                    },
                    onError: err => {
                    console.log(err);
                    swal.fire('¡Vuelva a intentarlo más tarde!', '', 'success')
                    }
                })
                .render(this.$refs.paypal);
            },
        
    }
}
</script>

<style>
.owl-carousel.owl-loaded {
    display: flex !important;
    justify-content: center !important;
}
</style>