import ApiClient from '../source/Api'

const auth = { 
    register(searchParams) {
        return ApiClient().post(`/costumers/create`, searchParams)
    },
    getUser(searchParams) {
        return ApiClient().get(`/costumers/one/${searchParams}`)
    },
    login(data) {
      return ApiClient().post(`/costumers/login`, data);
    },
    logout() {
        return ApiClient().post(`/costumers/logout`);
    },
    validToken(token) {
      return ApiClient().post(`/costumers/valid-token`, { token : token })
    },
    profile() {
      return ApiClient().get(`/costumers/profile`);
    }, 
    createLotery(searchParams) {
      return ApiClient().post(`/costumers/create_loteries`, searchParams)
    },
    listPowerBall() {
      return ApiClient().get(`/costumers/algoritmos`); 
    }, 
    sendEmail(searchParams) {
      return ApiClient().post(`/costumers/emails`, searchParams);
    },
    removeEmail(searchParams) { 
      return ApiClient().get(`/costumers/emails?id=${searchParams.id}`);
    },
    saveProfile(searchParams) {
      return ApiClient().post(`/costumers/updated`, searchParams);
    },
    changeTypeEmail(searchParams) {
      return ApiClient().get(`/costumers/emails/type?costumer_id=${searchParams.costumer_id}&id=${searchParams.id}`);
    },
    sendChangePassword(searchParams) {
      return ApiClient().post(`/costumers/password`, searchParams)
    },
    changePassword(searchParams) {
        return ApiClient().post(`/costumers/admin/change-password/${searchParams.costumer_id}`, searchParams)
    },
    update(searchParams) {
        return ApiClient().post(`/costumers/admin/updated/one/${searchParams.id}`, searchParams)
    },
    forgot(searchParams) {
        return ApiClient().post(`/costumers/forgot/`, searchParams)
    },
    recoverPassword(searchParams) {
        return ApiClient().post(`/costumers/recover/`, searchParams)
    },
    validateExpireChangePassword(searchParams) {
        return ApiClient().get(`/costumers/validate-expire?email=${searchParams.email}&token=${searchParams.token}`)
    },
    list() {
        return ApiClient().get(`/costumers/`)
    }
}

export default auth;