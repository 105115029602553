import ApiClient from '../source/Api'
  
const checkout =  {  
    createOneCheckout(searchParams) { 
        return ApiClient().post(`/checkouts/create`, searchParams)  
    },  
    createOneNormalCheckout(searchParams) { 
        return ApiClient().post(`/checkouts/createNormal`, searchParams)  
    }, 
    listCheckout() {  
        return ApiClient().get(`/checkouts/list`) 
    },
    listOneCheckout(searchParams) {   
        return ApiClient().get(`/checkouts/oneList?id=${searchParams.id}&name=${searchParams.name}`)
    },
    deleteOneCheckout(id){ 
        return ApiClient().delete(`/checkouts/list/${id}`)
    },
    deleteOneLotteryCheckout(id){ 
        return ApiClient().delete(`/checkouts/listLottery/${id}`)
    },
    deleteAllCheckout( ){ 
        return ApiClient().delete(`/checkouts/list`)
    },
  
}

export default checkout; 