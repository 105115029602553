<template> 
  <div class="form-signin" style="margin-top:300px">
      <loading :active.sync="isLoading" :is-full-page="fullPage" />
      <h1 class="h3 mb-3 font-weight-normal">Iniciar session</h1>
      
      <div class="form-group">
        <label >DNI </label>
        <input type="text" v-model="credentials.dni" class="form-control"   aria-describedby="emailHelp">
      </div>
      <div class="form-group">
        <label>Contraseña</label>
        <input type="password" class="form-control" v-model="credentials.password">
      </div> 
      <div class="form-group"> 
        <button  @click="login"   class="button button-primary" style="width: 100%;">Iniciar Sesion</button>
      </div>  
  </div> 
</template>

<script>
import { mapActions } from 'vuex'
 import Swal from 'sweetalert2';

export default {
    name: 'LoginUser',
    data(){
      return {
         credentials: {
          dni: "",
          password: ""
        },
          errors: [],
      }
    },
    methods: {
      ...mapActions({
          loginUser: "loginUser", 
      }),
        login(e) { 
          e.preventDefault()
          e.stopPropagation()
          this.isLoading = true;
                         this.errors = [];  


           if (this.credentials.dni && this.credentials.password) {
                  setTimeout( async() => {
                    let data = await this.loginUser(this.credentials)
                    switch (data.status) {
                        case 405:
                            this.errors.push(data.message);
                            this.swallErrors() 
                            break;
                        case 406:
                            this.errors.push(data.message); 
                            this.swallErrors()
                            break;
                        default:
                            break;
                    }
                    if (data.thumbnail_path === 200) {
                        this.$router.push("/admin")  

                    }
                    this.isLoading = false
                },2000)
            } else {
               
                setTimeout( async() => { 
                if (!this.credentials.dni) { 
                    this.errors.push('El DNI es obligatorio.');
                    this.swallErrors()
                }
                if (!this.credentials.password) {
                    this.errors.push('La Contraseña es obligatoria.');
                    this.swallErrors()
                }  
                    this.isLoading = false
                },2000)
            }
        }  ,
        swallErrors() {
        
            let _ = []
     
            for (let i = 0; i < this.errors.length; i++) {
                _[i] = `<li> ${this.errors[i]}  </li>`
            }
            Swal.fire({
                icon: 'error',
                title: 'Error', 
                footer: `<ul>${_}</ul>`,

             })
        },
    }
} 
</script>

<style>
   
  .text-center {
    text-align: center!important;
  }
  .form-signin {
    width: 100%; 
    max-width: 330px;
    padding: 15px;
    margin: 0 auto;
  } 
</style>