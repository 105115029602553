<template> 
    <b-modal id="purchasesModal" hide-footer>
        <div  >
             <div class="my-account-wrapper"  v-if="isset(purchase)">
                 
                <div class="ts-tiny-account-wrapper" >
                    <h3 style="text-align:center">Detalle de compra </h3>
                    <div class="ts-tiny-account-wrapper">
                        <div style="display:flex; justify-content: center;"> 
                            <div> Numero de Orden:  </div> 
                            <div style="margin-left: 10px">  #{{  purchase.id.toString().padStart(6, "0") }} </div> 
                        </div>
                        <div style="display:flex; justify-content: center;"> 
                            <div> Loteria:  </div> <div style="margin-left: 10px"> {{ purchase ? purchase.loterie.sub_name : 0}} </div>
                        </div>
                        <div style="display:flex; justify-content: center;"> 
                            <div> Fecha de compra:  </div> <div style="margin-left: 10px"> {{ purchase ? formatDate(purchase.createdAt): 0}} </div> 
                        </div>
                        <div style="display:flex; justify-content: center;">
                            <div> Direccion:  </div> <div style="margin-left: 10px"> {{ costumer ? costumer.address: 0 }} </div> 
                        </div> 
                    </div> 
                    <table style="margin-bottom: 0px">
                        <thead>
                            <tr> 
                                <th>#</th>
                                <th style="align-text:center">Descripcion</th>
                                <th>Total</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr >
                                <td>1</td> 
                                <td> 
                                    <ul  class="number-list-small" > 

                                        <li v-for="item in JSON.parse(purchase.balls)" :key="'purchase-modal-'+item" >
                                            <span class="LottoBall__circle"> 
                                                {{item}} 
                                            </span>  
                                        </li> 
                                     
                                        <li v-if="purchase.ball_red != null" > 
                                            <span class="LottoBall__circle" style="background-color: #ff3b3b;color: #fff">  
                                                {{purchase.ball_red}} 
                                            </span>  
                                        </li>  
                                    </ul>	
                                </td>
                                <td>
                                    $19.90
                                </td>
                            </tr> 
                        </tbody>
                    </table>  
                    <div class="col-lg-12" style="display: flex;justify-content: center;margin-bottom: 20px;">
                        <img :src="'/uploads/lotto.jpg'" style="height: 400px; object-fit: cover;" alt="">
                    </div>
                </div>
            </div>
        </div>
       
        <loading :active.sync="isLoading" :is-full-page="fullPage" />
    </b-modal> 
</template>

<script>
    import { mapActions, mapState } from 'vuex';
    import moment from 'moment'
    export default {
        name: 'PurchaseModal',
        data() {
            return {
                
            };
        },  
        computed: {
            ...mapState({ 
                purchase: state => state.purchase.purchase,
                costumer: state => state.costumer.costumer,
                picked_: state => state.costumer.picked,

            })
        },
        methods: {
             isset (ref) { return typeof ref !== 'undefined' },
            ...mapActions({
                 getProfile: "getProfile", 

            }),
            async signUp(e) {
                e.preventDefault();
                e.stopPropagation(); 
            },
            formatDate(value) {
                return moment(value).format('YYYY-MM-DD')
            }
        },
        async created(){
            await this.getProfile(); 
        },
        
    }
</script>

<style>  
    table  td,th{
        text-align: center;
    }
    
    @media (min-width: 576px) {
        .modal-dialog {
            max-width: 450px !important;
            margin: 1.75rem auto;
        }
    }
    
    .number-list-small li {
        width: 30px;
        height: 30px; 
        text-align: center;
        line-height: 30px;
        display: inline-block;
        margin: 3px;
        border-radius: 50%;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        -ms-border-radius: 50%;
        -o-border-radius: 50%;
    }
</style>

