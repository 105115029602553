import axios from 'axios'

//const HOST = process.env.VUE_APP_ROOT_HOST || 'http://localhost:3000/';
const HOST = 'https://ialotteryplay.com/';


const port = process.env.API_PORT || 3000;
import VueCookie from 'vue-cookie'

export default () => {
    return axios.create({
        baseURL: `${HOST}`,
        headers: {
            'accept': 'text/html,application/xhtml+xml,application/xml;q=0.9,*/*;q=0.8',
            'accept-language': 'da,en-gb;q=0.8,en;q=0.7',
            'content-language': 'en-US',
            'authorization': VueCookie.get('costumer_token'),
            "content-type": "application/json",
            'Access-Control-Allow-Origin': "*",
        },
        proxy: { host: HOST, port: port },
    })
}