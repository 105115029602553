<template>
   <section class="blog-details-section section-padding">
    <div class="container">
      <div class="row justify-content-between">
        <div class="col-lg-11">
          <div class="blog-details-wrapper">
            <div class="thumb">
              <img src="" alt="">
            </div>
            <div class="content">
                <h3 class="post-title">Ganar la lotería y qué hacer cuando gana </h3>
              <ul class="post-meta">
                <li><a href="#0"><span>BY</span>David Millward</a></li>
                <li><a href="#0"><i class="fa fa-calendar"></i>11 June 2018</a></li>
                <li><a href="#0"><i class="fa fa-clock-o"></i>12:46AM</a></li>
              </ul>
              
              <p>Hay muchas personas que juegan a la lotería, pero no tantas personas que tienen la capacidad de ganar la lotería. Muchos jugadores han ganado muchas veces, lo que demuestra que tienen talento para el sistema de lotería. Varios ganadores de loterías múltiples poseen sistemas para ganar.</p>
              <p>Mucha gente podría muy bien comprar un boleto y ganar algo de efectivo, y tomar el dinero que ganaron y terminarlo. Ese no es realmente el mejor método para usar, como han aprendido los ganadores de lotería consistentes. En el caso de que juegues $ 1 y ganes $ 20, es esencial que vuelvas a poner los veinte dólares que hayas ganado en la lotería para que tengas la oportunidad de ganar un premio mayor. Con este método, si termina perdiendo, en realidad solo habrá perdido ese dólar original que utilizó para comprar ese primer boleto. Este sistema ha dado a algunos afortunados ganadores varios viajes a la oficina de premios de lotería. Usar sus ganancias para comprar más boletos le ayudará a reducir sus pérdidas reales realizadas, al igual que si compra una acción y baja, su pérdida no se realiza hasta que se agota esa acción.</p>
              <p>Verifique sus boletos usted mismo para ver que ha ganado, en lugar de entregar el boleto de lotería a un trabajador en la tienda de boletos de lotería. Existe una tendencia creciente de los empleados de las tiendas que les dicen a los clientes que su boleto es un perdedor cuando en realidad no lo es. Luego reclaman el boleto como propio.</p>
            </div>
            <div class="footer d-flex justify-content-between align-items-center">
              <ul class="post-tags">
                <li>tag:</li>
                <li><a href="#0">Lotto</a></li>
                <li><a href="#0">Tips</a></li>
                <li><a href="#0">Jackpots</a></li>
              </ul>
              <ul class="post-share">
                <li><a href="#0"><i class="fa fa-facebook-f"></i></a></li>
                <li><a href="#0"><i  class="fa fa-twitter"></i></a></li>
                <li><a href="#0"><i class="fa fa-linkedin"></i></a></li>
                <li><a href="#0"><i class="fa fa-google-plus"></i></a></li>
              </ul>
            </div>
          </div>
          <!-- <div class="comment-area">
            <h3 class="block-title">3 Comment</h3>
            <ul class="comment-list">
              <li>
                <div class="single-comment-wrap">
                  <div class="thumb">
                    <img src="images/blog/rp-1.jpg" alt="comment-thumb">
                  </div>
                  <div class="content">
                    <h6 class="name">David Jacson</h6>
                    <span class="reply-time">November 20, 2018 at 8:31 pm</span>
                    <a href="#" class="reply">reply</a>
                    <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium rem doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo.</p>
                  </div>
                </div>
              </li>
              <li class="has-children">
                <ul>
                  <li>
                    <div class="single-comment-wrap">
                      <div class="thumb">
                        <img src="images/blog/rp-2.jpg" alt="comment-thumb">
                      </div>
                      <div class="content">
                        <h6 class="name">David Jacson</h6>
                        <span class="reply-time">November 20, 2018 at 8:31 pm</span>
                        <a href="#" class="reply">reply</a>
                        <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo.</p>
                      </div>
                    </div>
                  </li>
                </ul>
              </li>
              <li>
                <div class="single-comment-wrap">
                  <div class="thumb">
                    <img src="images/blog/rp-3.jpg" alt="comment-thumb">
                  </div>
                  <div class="content">
                    <h6 class="name">Jena Lambert</h6>
                    <span class="reply-time">November 20, 2018 at 8:31 pm</span>
                    <a href="#" class="reply">reply</a>
                    <p>It has survived not only five centurie, but also leap into electronic typesetting, remaining sei essentially simplu dolor amet dummy text. </p>
                  </div>
                </div>
              </li>
            </ul>
          </div>comment-area end
          <div class="comment-form-wrap">
            <h3 class="block-title">Live a Comment</h3>
            <form class="comment-form">
              <div class="frm-group">
                <div class="row">
                  <div class="col-lg-6">
                    <label for="comnt_name">Name<sup>*</sup></label>
                    <input type="text" name="comnt_name" id="comnt_name" placeholder="enter your name">
                  </div>
                  <div class="col-lg-6">
                    <label for="comnt_email">E-MAIL ADDRESS<sup>*</sup></label>
                    <input type="email" name="comnt_email" id="comnt_email" placeholder="enter your e-mail">
                  </div>
                </div>
              </div>
              <div class="frm-group">
                <label for="comnt_message">E-MAIL ADDRESS<sup>*</sup></label>
                <textarea name="comnt_message" id="comnt_message" placeholder="Write something"></textarea>
              </div>
              <div class="frm-group">
                <button type="submit" class="cmn-btn">Post Comment</button>
              </div>
            </form>
          </div> comment-form-wrap end -->
        </div>
        
      </div>
    </div>
  </section>
</template>

<script>
export default {
name: 'StorieDetail',
  components: {
  //  LoteryItem,
     //Carousel,
     //Login,
    
    },
  data(){
    return {

      /*img_banner: '/images/blog/b-1.jpg',
      items: [ 
          { name: 'Loteria-1', image: '/images/svg-icons/overview-icons/2.svg'}, 
          { name: 'Loteria-2', image: '/images/svg-icons/overview-icons/2.svg'}, 
          { name: 'Loteria-3', image: '/images/svg-icons/overview-icons/2.svg'},
          { name: 'Loteria-4', image: '/images/svg-icons/overview-icons/2.svg'}, 
          { name: 'Loteria-5', image: '/images/svg-icons/overview-icons/2.svg'}, 
          { name: 'Loteria-6', image: '/images/svg-icons/overview-icons/2.svg'}, 

      ],*/ 
       
    } 
  },
}
</script>

<style>

</style>