<template>
  <div style=" display: grid !important;   padding: 0 !important; width: 100%; " >
    <div v-if="existeSubscription === false " > 
      <div>
        <div> <p class="styleTextOne"> SUSCRÍBETE Y TEN ACCESO A : </p> </div>
        <div>
          <div style=" display: flex; flex-direction: column;" >
            <div style="display: flex">
              <div><i class="fas fa-gem"></i></div>
              <div style="margin-left:4px"><p class="styleTextTwo">Menú de Resultados.</p> </div>
            </div>
            <div style="display: flex">
              <div><i class="fas fa-gem"></i></div>
              <div style="margin-left:4px"><p class="styleTextTwo">Menú de Analítica.</p> </div>
            </div>
            <div style="display: flex">
              <div><i class="fas fa-gem"></i></div>
              <div style="margin-left:4px"><p class="styleTextTwo">Menú de Pronóstico Mix.</p> </div>
            </div>
          </div>
          <div>
            <div style="background: #00974D; padding: 15px; border-radius: 5px; border: 5px solid white; cursor: pointer;display: flex; align-items: center; justify-content: center;" @click="activePay()">
              <div style="color: white;"><i class="fas fa-bags-shopping"></i></div>
                <div style="margin-left: 10px;"><h4 style="color: white; font-weight: 500;font-size: inherit;margin: 0px;">Suscribirse</h4></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="existeSubscription === true">
      <b-card no-body :class="{ dark: picked }">
        <b-tabs v-model="message" pills card>
          <b-tab title="Cuadro de frecuencias" active class="tab-content-responsive" >
            <div class="row" style="margin-bottom: 130px; " >
              <b-col>
                <Frequency :type="type" class="content-frequency"  />
              </b-col>
              <b-col>
                <FrequencyPair :type="type" class="content-frequency-pair"/>
              </b-col>
              <b-col>
                <FrequencyTrio :type="type" class="content-frequency-trio"/>
              </b-col>
            </div>
            <div>
              <div :class="{ dark: picked }"  @mousedown="moveSuggestion" @mouseup="stopSuggestion"  id="figure_statistic"   style="  cursor: move; display: flex; flex-direction: column; align-items: center;
                            box-shadow: black 0px 0px 3px;
                            border-radius: 6px;
                            z-index: 1;
                            right: 25px;
                            padding: 4px;
                            width: 250px;
                            background: white;
                            top: 185px;
                            position: fixed; ">
                <div>
                  <p class="styleTextOne" style="text-align: center">LEYENDA</p>
                </div>
                <div style="display: flex; flex-direction: column">
                  <div>
                    <div>
                      Bolilla: <span>{{ this.indicators.ball }}</span>
                    </div>
                    <div>
                      Cantidad: <span>{{ this.indicators.count }}</span>
                    </div>
                    <div>
                      Fecha de aparicion: <span>
                      <ul>
                        <li style="list-style: none"   >
                          <div style="
                                display: flex;
                                justify-content: space-evenly;
                                align-items: center;
                              " >
                            <div style=" display: flex; flex-wrap: wrap; justify-content: space-evenly; margin: 5px 0; ">
                              <div class="numeroConstellationStyle" style="width: 150px !important">
                                <p class="styleTextTwo">{{ indicators.date }}</p>
                              </div>
                            </div>
                            <div class="copiarStyleForecast">
                              <div style="font-size: 20px; cursor: pointer" @click="sendDate(indicators.date)">
                                <i class="fas fa-search" style="margin-left: 5px !important;"></i>
                              </div>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </b-tab>
          <b-tab title="Diagrama de aparición">
            <Media :type="type"/>
          </b-tab>
          <b-tab title="Moda">
            <Mode :type="type"/>
          </b-tab>
          <b-tab title="Resultados Constelación"> 
            <Result :val=type />
          </b-tab>
          <template #tabs-end>
            <div style="display: flex; flex-direction: row; margin-left: auto;"  >
                <div style="width:8px;"> </div>  
                <div  style="width: 52px !important; height: 52px !important;  margin-bottom: 0px" class="jugarPageOptions" @click="verifyTab()">
                  <div><p class="styleTextOne" >?</p></div>
                </div>
                <div style="width:8px;"> </div>             
                <div style="width: 52px !important; height: 52px !important; margin-bottom: 0px" class="jugarPageOptions"  @click="$bvModal.show('m-history-resultado'+type)"  >
                            <div ><i class="fas fa-book"></i></div> 
                </div> 
            </div>
          </template>
        </b-tabs>
      </b-card>
    </div>
  </div>
</template>

<script>
  import { mapActions, mapMutations, mapState } from "vuex";
  import Mode from "../Analityca/Mode";
  import VueCookie from 'vue-cookie'
  import Media from "../Analityca/FrequencyDate";
  import Frequency from "../Analityca/FrequencyMore";
  import FrequencyPair from "../Analityca/FrequencyPair";
  import FrequencyTrio from "../Analityca/FrequencyTrio";
  import Result from '../Analityca/Resultado'

  export default {
    name: "Statistics",
    components: {
      Mode,
      FrequencyTrio,
      FrequencyPair,
      Frequency,
      Media,
      Result
    },
    data() {
      return {
        types_ : ''
      };
    },
    computed: {
      ...mapState({
        existeSubscription: state => state.subscription.existeSubscription,
        indicators: state => state.analysis.indicators,
        tabIndexStatistics: state => state.tabsNavegation.tabIndexStatistics,
        picked: state => state.costumer.picked,
        theme_dark: state => state.costumer.theme_dark,
        theme_light: state => state.costumer.theme_light,

      }),
       message:{
         get(){
           return this.$store.state.tabsNavegation.tabIndexStatistics
         },
         set( value ){
            this.$store.commit('UPDATE_MESSAGE_TAB_ANALYSIS', value)
         }
       }
    },
    props: {
      type: {
        type: Number
      }
    },
      mounted() {
      this.types_ = this.type;
        this.validateOneSubscription();
    },
    methods: {
      ...mapMutations({
        DATE_APPEARANCE: 'DATE_APPEARANCE',
        UPDATE_MESSAGE_TAB_ANALYSIS:'UPDATE_MESSAGE_TAB_ANALYSIS'
      }),
      ...mapActions({
         changeIndicators: "changeIndicators",
         validateOneSubscription: "validateOneSubscription"
      }),
      moveSuggestion (e){
        let figure_statistic = document.getElementById("figure_statistic");

        let left = parseInt( window.getComputedStyle(figure_statistic).getPropertyValue("left") );
        let top = parseInt( window.getComputedStyle(figure_statistic).getPropertyValue("top") );
        let mouseX = e.clientX;
        let mouseY = e.clientY;

        document.onmousemove = function (e) {
          let dx = mouseX - e.clientX;
          let dy = mouseY - e.clientY;
          figure_statistic.style.left = left - dx + "px";
          figure_statistic.style.top = top - dy + "px";
        };
      },
      stopSuggestion: function(){
        // console.log('stopSuggestion');
        document.onmousemove = null;
      },
      toTourFrequency: function () {
        this.$nextTick(() => {
          const tour = this.$shepherd({
            useModalOverlay: true,
            cancelIcon: {
              enabled: true
            },
            classes: 'class-1 class-2',
            scrollTo: { behavior: 'smooth', block: 'center' }
          });
          tour.addStep({
            attachTo: {element: ".content-frequency", on: 'right'},
            text:`Listado de las bolillas mas frecuentes, donde tambien muestra la cantidad de veces que a aparecido.
                  <div style="display: flex; flex-direction: column;">
                    <div style="display: flex; flex-direction: row; align-items: center;">
                      <div style="border-style: double; height: 20px; width: 30px; background: #68001c; margin-right: 5px;"> </div>
                    <div>
                      <p class="styleTextTwo"> Más frecuentes</p>
                    </div>
                  </div>
                  <div style="display: flex; flex-direction: row; align-items: center;">
                  <div style="border-style: double; height: 20px; width: 30px; background: #666666; margin-right: 5px;"> </div>
                    <div>
                    <p class="styleTextTwo">Frecuentes</p>
                    </div>
                  </div>
                  <div style="display: flex; flex-direction: row; align-items: center;">
                    <div style="border-style: double; height: 20px; width: 30px; background: #027b76; margin-right: 5px;">
                      </div>
                        <div>
                          <p class="styleTextTwo"> Menos frecuentes</p>
                        </div>
                    </div>
                  </div>`,

            classes: 'shepherd-theme-arrows',
            buttons: [
              {
                text: 'Sig.',
                action: tour.next
              }
            ],
          });
          tour.addStep({
            attachTo: {element: ".content-frequency-pair", on: 'right'},
            text: `Grafico donde muestran los pares mas frecuentes de forma descendente
                  <div style="display: flex; flex-direction: row; align-items: center;">
                    <div style="border-style: double; height: 20px; width: 30px; background: #ab853a; margin-right: 5px;"> </div>
                    <div>
                      <p class="styleTextTwo">Pares</p>
                    </div>
                  </div>`,
            classes: 'shepherd-theme-arrows',
            buttons: [
              {
                action: tour.back,
                classes: 'shepherd-button-secondary',
                text: 'Ant.'
              },
              {
                text: 'Sig.',
                action: tour.next
              }
            ]
          });
          tour.addStep({
            attachTo: {element: ".content-frequency-trio", on: 'left'},
             text: `Grafico donde muestran los trios mas frecuentes de forma descendente
                  <div style="display: flex; flex-direction: row; align-items: center;">
                    <div style="border-style: double; height: 20px; width: 30px; background: #68001c; margin-right: 5px;"> </div>
                    <div>
                      <p class="styleTextTwo">Trios</p>
                    </div>
                  </div>`,
            classes: 'shepherd-theme-arrows',
            buttons: [
              {
                action: tour.back,
                classes: 'shepherd-button-secondary',
                text: 'Ant.'
              },
              {
                text: 'Hecho',
                action: tour.complete
              }
            ]
          });
          tour.start();
        });
      },
      toTourMedia() {
        this.$nextTick(() => {
          const tour_ = this.$shepherd({
            useModalOverlay: true,
            cancelIcon: {
              enabled: true
            }
          });
          tour_.addStep({
            attachTo: { element: "#media-btn-month", on: 'right' },
            text: "Al seleccionar el boton Mes podremos visualizar los filtros por mes al lado izquiero.",
            classes: 'shepherd-theme-arrows',
            buttons: [
              {
                text: 'Salir',
                action: tour_.cancel
              },
              {
                text: 'Sig.',
                action: tour_.next
              }
            ],
          });
          tour_.addStep({
            attachTo: { element: "#media-btn-year", on: 'left' },
            text: "Al seleccionar el boton Año podremos visualizar los filtros por año al lado izquiero.",
            classes: 'shepherd-theme-arrows',
            buttons: [
              {
                action: tour_.back,
                classes: 'shepherd-button-secondary',
                text: 'Ant.'
              },
              {
                text: 'Sig.',
                action: tour_.next
              }
            ]
          });
          tour_.addStep({
            attachTo: { element: "#media-input-filter", on: 'left' },
            text: "Los filtros desde hasta donde se podra elegir las fechas y asi visualizar el comportamiento de las bolillas.",
            classes: 'shepherd-theme-arrows',
            buttons: [
              {
                action: tour_.back,
                classes: 'shepherd-button-secondary',
                text: 'Ant.'
              },
              {
                text: 'Sig.',
                action: tour_.next
              }
            ]
          });
          tour_.addStep({
            attachTo: { element: `#content-table-media-${this.type}`, on: 'left' },
            text: "Listado de todas las bolillas, al hacer click en una bolilla me mostrara al lado derecho el grafico.",
            classes: 'shepherd-theme-arrows',
            buttons: [
              {
                action: tour_.back,
                classes: 'shepherd-button-secondary',
                text: 'Ant.'
              },
              {
                text: 'Hecho',
                action: tour_.complete
              }
            ]
          });
          tour_.start();
        });
      },
      toTourMode() {
        this.$nextTick(() => {
          const tour = this.$shepherd({
            useModalOverlay: true,
            cancelIcon: {
              enabled: true
            },
            classes: 'class-1 class-2',
            scrollTo: { behavior: 'smooth', block: 'center' }
          });
          tour.addStep({
            attachTo: { element: ".content-table-mode", on: 'right' },
            text: 'Grafico donde muestra las bolillas de moda de los ultimos 3 meses',
            classes: 'shepherd-theme-arrows',
            buttons: [
              {
                text: 'Sig.',
                action: tour.next
              }
            ],
          });
          tour.addStep({
            attachTo: { element: ".content-legend", on: 'left' },
            text: 'Al pasar por el grafico automaticamente mostrara el detalle de cada bolilla como la ultima fecha que aparecio la bolilla y la cantidad.',
            classes: 'shepherd-theme-arrows',
            buttons: [
              {
                action: tour.back,
                classes: 'shepherd-button-secondary',
                text: 'Ant.'
              },
              {
                text: 'Sig.',
                action: tour.next
              }
            ]
          });
          tour.addStep({
            attachTo: { element: ".content-legend-search", on: 'left' },
            text: 'Con este boton podras ver la cartilla en el modulo resultados',
            classes: 'shepherd-theme-arrows',
            buttons: [
              {
                action: tour.back,
                classes: 'shepherd-button-secondary',
                text: 'Ant.'
              },
              {
                text: 'Hecho',
                action: tour.complete
              }
            ]
          });
          tour.start();
        });
      },
      verifyTab(){
        switch (this.message) {
          case 0:
              this.toTourFrequency();

            break;
          case 1:
             this.toTourMedia();
            break;
          case 2:

              this.toTourMode();
            break;
          case 3:
              this.toTourResultado();
            break;

          default:
            break;
        }
      },
      toTourResultado() {
              const text= {
                  range_date:'Permite seleccionar un rango de fechas para visualizar las cartillas en ese periodo de tiempo.',
                  select_bolilla:'Filtro complementario para mostrar solo las cartillas con la bolilla seleccionada. ',
                  export_excel:'Exporta el rango de resultados en un Excel.',
                  constelacion_similar:'Listado de fechas, si le damos a buscar entonces visualizaremos la jugada en la cartilla principal.',
                  listado_jugadas:'Listado de las últimas cartillas, se pueden obtener más ampliando las fechas de búsqueda.',
                  ciclo_aparicion: 'Al seleccionar uno de los números marcados de esta cartilla, podremos visualizar la fecha de aparición de ese número.',
                  // copiar_jugada: 'Después de seleccionar una cartilla, la jugada sera trasladada al menú de "Pronóstico Mix" o "Juego individual".',
                  copiar_jugada: 'Después de seleccionar una cartilla, la jugada sera trasladada al menú de juego.',
                  comparar_jugada: 'Después de seleccionar una cartilla, nos mostrará una lista de fechas con las constelaciones similares.',
                  botons_jas: 'Visualizacion de las últimas jugadas, operaciones de personalización y borrado de jugadas.',
                  leyenda_jugada:'Cada marcación de cartillas es evaluado por colores distintos.'
              }
              this.$nextTick(() => {
                  const tour = this.$shepherd({
                      useModalOverlay: true,
                      cancelIcon: {
                          enabled: true
                      },
                      classes: 'class-1 class-2',
                      scrollTo: { behavior: 'smooth', block: 'center' }
                  });
                  tour.addStep({
                      attachTo: { element: ".tooltip-button-variantdate", on: 'right' },
                      text: text.range_date,
                      classes: 'shepherd-theme-arrows',
                      buttons: [
                          {
                              text: 'Salir',
                              action: tour.cancel
                          },
                          {
                              text: 'Sig.',
                              action: tour.next
                          }
                      ],
                  });
                  tour.addStep({
                      attachTo: { element: ".tooltip-seleccionar-bolilla", on: 'left' },
                      text: text.select_bolilla,
                      classes: 'shepherd-theme-arrows',
                      buttons: [
                          {
                              action: tour.back,
                              classes: 'shepherd-button-secondary',
                              text: 'Ant.'
                          },
                          {
                              text: 'Sig.',
                              action: tour.next
                          }
                      ]
                  });
                  tour.addStep({
                      attachTo: { element: ".tooltip-export-excel", on: 'left' },
                      text: text.export_excel,
                      classes: 'shepherd-theme-arrows',
                      buttons: [
                          {
                              action: tour.back,
                              classes: 'shepherd-button-secondary',
                              text: 'Ant.'
                          },
                          {
                              text: 'Sig.',
                              action: tour.next
                          }
                      ]
                  });
                  tour.addStep({
                      attachTo: { element: ".tooltip-listado-jugadas", on: 'left' },
                      text: text.listado_jugadas,
                      classes: 'shepherd-theme-arrows',
                      buttons: [
                          {
                              action: tour.back,
                              classes: 'shepherd-button-secondary',
                              text: 'Ant.'
                          },
                          {
                              text: 'Sig.',
                              action: tour.next
                          }
                      ]
                  });

                  tour.addStep({
                      attachTo: { element: ".tooltip-ciclo-aparicion", on: 'left' },
                      text: text.ciclo_aparicion,
                      classes: 'shepherd-theme-arrows',
                      buttons: [
                          {
                              action: tour.back,
                              classes: 'shepherd-button-secondary',
                              text: 'Ant.'
                          },
                          {
                              text: 'Sig.',
                              action: tour.next
                          }
                      ]
                  });

                  tour.addStep({
                      attachTo: { element: ".tooltip-botons-jas", on: 'left' },
                      text: text.botons_jas,
                      classes: 'shepherd-theme-arrows',
                      buttons: [
                          {
                              action: tour.back,
                              classes: 'shepherd-button-secondary',
                              text: 'Ant.'
                          },
                          {
                              text: 'Sig.',
                              action: tour.next
                          }
                      ]
                  });

                  // tour.addStep({
                  //     attachTo: { element: ".tooltip-leyenda", on: 'left' },
                  //     text: text.leyenda_jugada,
                  //     classes: 'shepherd-theme-arrows',
                  //     buttons: [
                  //         {
                  //             action: tour.back,
                  //             classes: 'shepherd-button-secondary',
                  //             text: 'Ant.'
                  //         },
                  //         {
                  //             text: 'Sig.',
                  //             action: tour.next
                  //         }
                  //     ]
                  // });

                  tour.addStep({
                      attachTo: { element: ".tooltip-copiar-jugada", on: 'left' },
                      text: text.copiar_jugada,
                      classes: 'shepherd-theme-arrows',
                      buttons: [
                          {
                              action: tour.back,
                              classes: 'shepherd-button-secondary',
                              text: 'Ant.'
                          },
                          {
                              text: 'Sig.',
                              action: tour.next
                          }
                      ]
                  });
                  tour.addStep({
                      attachTo: { element: ".tooltip-comparar-jugada", on: 'left' },
                      text: text.comparar_jugada,
                      classes: 'shepherd-theme-arrows',
                      buttons: [
                          {
                              action: tour.back,
                              classes: 'shepherd-button-secondary',
                              text: 'Ant.'
                          },
                          {
                              text: 'Hecho',
                              action: tour.complete
                          }
                      ]
                  });

                  tour.start();
              });
          },
      async sendDate(searchParams) {
        this.UPDATE_MESSAGE_TAB_ANALYSIS(3)
        this.DATE_APPEARANCE(searchParams);
      },
      async activePay(){
        const authorization = VueCookie.get('costumer_token');
        if (!authorization) {
          //this.$root.$emit('bv::show::modal', 'authenticated', '#btnShowAnalysis')
          this.$router.push('/login-client')
          return;
        }
        location.href ='/perfil?suscription=true';
      },
      mobile(){
                  /* Storing user's device details in a variable*/
                      let details = navigator.userAgent;

                      let regexp = /android|iphone|kindle|ipad/i;

                      let isMobileDevice = regexp.test(details);

                      if (isMobileDevice) {
                          return true;
                      }
                      return false;

      },
    },
    created(){


    },
  };
</script>
<style scoped>

.tab-content {
  padding: 15px 30px !important;
}
  .jugarPageOptions{
    cursor: pointer;
    background: white;
    text-align: center; 
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 7px;
    box-shadow: 0px 0px 2px black;
    padding: 10px;
    transition: 0.3s;
}
.jugarPageOptions:hover {
    background: #294977;
    color:white
}
.jugarPageOptions:hover p {
    color: white  ;
}

.styleTextOne{
    margin: 0px 0 0px;
    font-size: 17px;
    font-weight: 600;
    color:#294977
 }
.tab-content-responsive {
  overflow-x: hidden;
  height: 690px;
}

@media screen and (max-height: 770px){
  .tab-content-responsive {
    overflow-x: hidden;
    height: 500px !important;
  }

}

</style>