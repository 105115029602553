<template>
  <footer class="">
			<div class="col-md-12">
				<div class="copyrights">© 2019 Servicios Rivercon.
	<i>Cool</i>. Dashboard Rivercon for IALoteryPlay Propiedades</div>
			</div>
	</footer>
</template>

<script>
export default {
    name: 'Footer'
}
</script>

<style>

</style>