import purchasesAPI from '../../api/purchases'
import Vue from 'vue'
import VueCookie from 'vue-cookie'
 
Vue.use(VueCookie); 

const state = { 
    purchases: [],
    penas: [],
    purchase: {
        ball_red:21,
        balls:"[1,2,3,4,5]",
        costumer_id:9,
        createdAt:"2021-02-15T13:29:28.000Z",
        id:1,
        image:"",
        loterie:{
            sub_name: ""
        },
        loterie_id:1,
        potenciador:0,
        stateDelete:0,
        stateSold:0,
        updatedAt:"2021-02-15T13:29:28.000Z",
    },
    statePurchase: false,
   
}
const getters = {

}

const actions = { 
    async listPurchases({ commit }, {...searchParams }) {
        const { data: data } = await purchasesAPI.list(searchParams) 
        // console.log("data", data);
        commit('PURCHASES', data.purchases) 
        commit('PENAS', data.penas) 

    },
    showModalPurchase({commit}, {...searchParams}) {
         commit('PURCHASE', searchParams)
     },
    async createPurchases({ commit }, { ...searchParams }) { 
        const { status  } = await purchasesAPI.createPurchases(searchParams)
        if (status === 200)  commit('CREATE_PURCHASE', true) 
    },  
}

const mutations = {
    PURCHASES(state, data) { 
        state.purchases = []
        state.purchases = data   
    },
    PURCHASE(state, data) {
        state.purchase = data;
    },
    PENAS(state, data){
        state.penas = []
        state.penas = data 
    },
    CREATE_PURCHASE(state, val) {
        state.statePurchase = val
    },
    
}
export default {
    actions,
    mutations,
    getters,
    state
}