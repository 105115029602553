<template>
  <div class="content-table">
    <highcharts :options="chartOptions"></highcharts>
  </div>
</template>

<script>
import { Chart } from "highcharts-vue";
import analyticAPI from "../../../../api/analytic";
import {mapActions, mapState} from "vuex";
import {EventBus} from "@/plugins/event-bus";

export default {
  name: 'FrequencyTrio',
  components: {
    highcharts: Chart,
  },
  data() {
    return {
      chartOptions: {
        chart: {
          type: "bar",
          spacingBottom: 10,
          spacingTop: 0,
          spacingLeft: 0,
          spacingRight: 0,
          width: 300,
          height: 900,
          backgroundColor: this.picked,
        },
        legend: {
          enabled: true,
          layout: "horizontal",
          verticalAlign: 'top',
          itemStyle: { color: '#b68c64' }
        },
        plotOptions: {
          bar : {
            zones: [{
              color:"#68001c"
            }]
          },
          series: {
            pointWidth: 9,
            cursor: "pointer",
            point: {
              events: {
                mouseOver: (e) => {
                  this.sendIndications({ball: e.target.category, count: e.target.y, date: e.target.date})
                },
              },
            },
            borderColor: '#fff'

          },
        },
        xAxis: {
          categories: null,
          title: {
            text: "BOLILLAS",
            style: {
              color: null
            }
          },
          labels: {
            style: {
             color: null
            }
          }
        },
        yAxis: {
          categories: null,
          title: {
            text: "CANTIDAD",
            style: {
              color: null
            }
          },
          labels: {
            style: {
              color: null
            }
          }
        },
        title: { text: " " },
        series: null,
      },
    }
  },
  mounted() {
    this.init();
    this.eventBusTheme()
  },
  props: {
    type: {
      type: Number
    }
  },
  computed: {
    ...mapState({
      picked: state => state.costumer.picked,
      theme_dark: state => state.costumer.theme_dark,
      theme_light: state => state.costumer.theme_light,
    }),

  },
  methods: {
    ...mapActions({
      changeIndicators: "changeIndicators",
    }),
    async init() {
      const { data: data } = await analyticAPI.findMoreTrio({
        name: this.$route.params.name,
        id: this.$route.params.id,
        limit: 10,
        type: this.type,
      });

      this.chartOptions.xAxis.categories = data.labels;
      this.chartOptions.series = data.data;
      this.appearances = data.appearances;
      EventBus.$on('change_theme', this.eventBusTheme);
    },
    eventBusTheme() {
      this.chartOptions.xAxis.labels.style.color = this.picked ? this.theme_dark.textColor: this.theme_light.textColor
      this.chartOptions.xAxis.title.style.color = this.picked ? this.theme_dark.textColor: this.theme_light.textColor
      this.chartOptions.yAxis.labels.style.color = this.picked ? this.theme_dark.textColor: this.theme_light.textColor
      this.chartOptions.yAxis.title.style.color = this.picked ? this.theme_dark.textColor: this.theme_light.textColor
    },
    async sendIndications({...searchParams}) {
      await this.changeIndicators(searchParams)
    }
  },

}
</script>

<style>

</style>