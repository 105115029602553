export const strings = {
    pronosticoLeyenda:[
        {cross: true ,color:'#506466', name: 'Pronóstico mix.'},
        {cross: true ,color:'#68001C', name: 'Más frecuentes.'},
        {cross: true ,color:'#027B76', name: 'Menos frecuentes.'},
        {cross: true ,color:'#AB853A', name: 'Pares más frecuentes.'}
    ],
    pronosticoOjo:[
        {color:'#8F8F8F', name: 'Últimas apariciones.'},
    ],
    pronosticoTabla:[
        {color:'#8F8F8F', name: 'Posibles apariciones.'},
    ],
    pronosticoM1:[
        {cross: false ,color:'#8F8F8F', name: 'Números del último mes.'},
        {cross: false ,color:'#F76300', name: 'Repetición de dos veces.'},
        {cross: false ,color:'#DC001A', name: 'Repetición de tres veces.'},
        {cross: false ,color:'#660001', name: 'Repetición de más de tres veces.'}
    ],
    pronosticoM2:[
        {cross: false ,color:'#8F8F8F', name: 'Números de la última semana.'},
        {cross: false ,color:'#F76300', name: 'Repetición de dos veces.'},
        {cross: false ,color:'#DC001A', name: 'Repetición de tres veces.'},
        {cross: false ,color:'#660001', name: 'Repetición de más de tres veces.'}
    ],
    pronosticoM3:[
        {cross: false ,color:'#8F8F8F', name: 'Números de las dos últimas semanas.'},
        {cross: false ,color:'#F76300', name: 'Repetición de dos veces.'},
        {cross: false ,color:'#DC001A', name: 'Repetición de tres veces.'},
        {cross: false ,color:'#660001', name: 'Repetición de más de tres veces.'}
    ],
    pronosticoS3:[
        {cross: false ,color:'#8F8F8F', name: 'Números de las tres últimas semanas.'},
        {cross: false ,color:'#F76300', name: 'Repetición de dos veces.'},
        {cross: false ,color:'#DC001A', name: 'Repetición de tres veces.'},
        {cross: false ,color:'#660001', name: 'Repetición de más de tres veces.'}
    ],
    pronosticoS4:[
        {cross: false ,color:'#8F8F8F', name: 'Números de las cuatro últimas semanas.'},
        {cross: false ,color:'#F76300', name: 'Repetición de dos veces.'},
        {cross: false ,color:'#DC001A', name: 'Repetición de tres veces.'},
        {cross: false ,color:'#660001', name: 'Repetición de más de tres veces.'}
    ],
    pronosticoJA:[
        {cross: false ,color:'#F76300', name: 'Repetición de dos veces.'},
        {cross: false ,color:'#DC001A', name: 'Repetición de tres veces.'},
        {cross: false ,color:'#660001', name: 'Repetición de más de tres veces.'}
    ],
    pronosticoJA1:[
        {cross: false ,color:'#8F8F8F', name: 'Números de la última jugada'},
        {cross: false ,color:'#F76300', name: 'Repetición de dos veces.'},
        {cross: false ,color:'#DC001A', name: 'Repetición de tres veces.'},
        {cross: false ,color:'#660001', name: 'Repetición de más de tres veces.'}
    ],
    pronosticoJA2:[
        {cross: false ,color:'#8F8F8F', name: 'Números de 2 últimas jugadas.'},
        {cross: false ,color:'#F76300', name: 'Repetición de dos veces.'},
        {cross: false ,color:'#DC001A', name: 'Repetición de tres veces.'},
        {cross: false ,color:'#660001', name: 'Repetición de más de tres veces.'}
    ],
    pronosticoJA3:[
        {cross: false ,color:'#8F8F8F', name: 'Números de 3 últimas jugadas.'},
        {cross: false ,color:'#F76300', name: 'Repetición de dos veces.'},
        {cross: false ,color:'#DC001A', name: 'Repetición de tres veces.'},
        {cross: false ,color:'#660001', name: 'Repetición de más de tres veces.'}
    ],
    pronosticoJA4:[
        {cross: false ,color:'#8F8F8F', name: 'Números de 4 últimas jugadas.'},
        {cross: false ,color:'#F76300', name: 'Repetición de dos veces.'},
        {cross: false ,color:'#DC001A', name: 'Repetición de tres veces.'},
        {cross: false ,color:'#660001', name: 'Repetición de más de tres veces.'}
    ],
    pronosticoJA5:[
        {cross: false ,color:'#8F8F8F', name: 'Números de 5 últimas jugadas.'},
        {cross: false ,color:'#F76300', name: 'Repetición de dos veces.'},
        {cross: false ,color:'#DC001A', name: 'Repetición de tres veces.'},
        {cross: false ,color:'#660001', name: 'Repetición de más de tres veces.'}
    ],
    pronosticoJA6:[
        {cross: false ,color:'#8F8F8F', name: 'Números de 6 últimas jugadas.'},
        {cross: false ,color:'#F76300', name: 'Repetición de dos veces.'},
        {cross: false ,color:'#DC001A', name: 'Repetición de tres veces.'},
        {cross: false ,color:'#660001', name: 'Repetición de más de tres veces.'}
    ],
    pronosticoJA7:[
        {cross: false ,color:'#8F8F8F', name: 'Números de 7 últimas jugadas.'},
        {cross: false ,color:'#F76300', name: 'Repetición de dos veces.'},
        {cross: false ,color:'#DC001A', name: 'Repetición de tres veces.'},
        {cross: false ,color:'#660001', name: 'Repetición de más de tres veces.'}
    ],
    pronosticoJA8:[
        {cross: false ,color:'#8F8F8F', name: 'Números de 8 últimas jugadas.'},
        {cross: false ,color:'#F76300', name: 'Repetición de dos veces.'},
        {cross: false ,color:'#DC001A', name: 'Repetición de tres veces.'},
        {cross: false ,color:'#660001', name: 'Repetición de más de tres veces.'}
    ],
    pronosticoJA9:[
        {cross: false ,color:'#8F8F8F', name: 'Números de 9 últimas jugadas.'},
        {cross: false ,color:'#F76300', name: 'Repetición de dos veces.'},
        {cross: false ,color:'#DC001A', name: 'Repetición de tres veces.'},
        {cross: false ,color:'#660001', name: 'Repetición de más de tres veces.'}
    ],
    pronosticoJA10:[
        {cross: false ,color:'#8F8F8F', name: 'Números de 10 últimas jugadas.'},
        {cross: false ,color:'#F76300', name: 'Repetición de dos veces.'},
        {cross: false ,color:'#DC001A', name: 'Repetición de tres veces.'},
        {cross: false ,color:'#660001', name: 'Repetición de más de tres veces.'}
    ],
}