import ApiClient from '../source/Api'
  
const purchases =  {  
    list(searchParams) {  
        return ApiClient().get(`/purchases/list`, searchParams)
    }, 
    createPurchases(searchParams) { 
        return ApiClient().post(`/purchases/create`, searchParams) 
    }, 
}

export default purchases;  