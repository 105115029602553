<template>
<div>
  <b-sidebar id="toPlay" shadow> 
        <div style="border-bottom: 1px solid rgb(221, 221, 221);"></div>
        <!-- <div v-bind:key="'side-'+index"  v-for="(item, index) in mainLoteries" > 
            <a :href="'/loteria/'+(index + 1)+'/'+  item.name" style="display: flex; align-items: center; justify-content: space-around;border-bottom: 1px solid #dddddd;padding: 20px;">
                <a :href="'/loteria/'+(index + 1)+'/'+  item.name" style=" display: flex; justify-content: center;width: 170px;margin-right: 10px;" replace> 
                    <b-img v-bind:src="item.image" fluid thumbnail style="object-fit:contain; "></b-img> 
                </a>
                <div>
                    <div class="form-group">
                        <h4 style="text-align: right;">{{format_date(item.loterie.createdAt)}}</h4>
                        <ul class="number-list-small" style="text-align: right;"> 
                            <li><span class="LottoBall__circle">{{item.loterie.ball_one}}</span></li>
                            <li><span class="LottoBall__circle">{{item.loterie.ball_two}}</span></li>
                            <li><span class="LottoBall__circle">{{item.loterie.ball_three}}</span></li>
                            <li><span class="LottoBall__circle">{{item.loterie.ball_four}}</span></li>
                            <li><span class="LottoBall__circle">{{item.loterie.ball_five}}</span></li>
                            <li><span class="LottoBall__circle" style="background-color: #ff3b3b;color: #fff">{{item.loterie.ball_red}}</span></li>  
                        </ul>
                    </div>   
                    <div class="form-group">
                        <h4 style="text-align: right;">{{ item.loterie.createdAt }}</h4>
                        <div style="text-align: right;"> $970 MILLION</div> 
                    </div>   
                </div>
            </a> 
        </div> -->
    </b-sidebar>
</div>
</template>

<script>
import {mapState, mapActions} from 'vuex'
import moment from 'moment'
moment.locale('es');

export default {
    name:'SidebarRight',
    mounted() { 
    //  this.getLoteriesMain()
    },
    computed: {
        ...mapState({
            mainLoteries: state => state.lotto.mainLoteries, 
        }) 
    },
    
    methods: {
       
        ...mapActions({
        //    getLoteriesMain: 'getLoteriesMain'
        }),
        format_date(value){
            if (value) {
                 //return moment(String(value)).format('YYYY/MM/DD')
               return moment(String(value)).format("dddd, MMMM DD,  YYYY ");
 
            }
        },
    }
}
</script>

<style>
.b-sidebar>.b-sidebar-header {
    font-size: 1.5rem;
    padding: 1rem 1.5rem;
    display: flex;
    flex-direction: row;
    flex-grow: 0;
    
    align-items: center;
}
    .b-sidebar {
    display: flex;
    flex-direction: column;
    width: 460px !important;
    max-width: 100%;
    max-height: 100%;
    margin: 0;
    outline: 0;
    -webkit-transform: translateX(0);
    transform: translateX(0);
   

}
.b-sidebar:not(.b-sidebar-right) {
    left: 0;
    right: auto;
}
.b-sidebar-outer {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 0;
    overflow: visible;
    z-index: 1035;
}
.b-sidebar-outer {
    z-index: 1071;
}
.b-sidebar.b-sidebar-right {
    left: auto;
    right: 0;
}
.b-sidebar, .b-sidebar-backdrop {
    position: fixed;
    top: 0;
    height: 100vh;
}
.text-dark {
    color: #343a40!important;
}
.shadow {
    box-shadow: 0 .5rem 1rem rgba(0,0,0,.15)!important;
}
.bg-light {
    background-color: #f8f9fa!important;
}
.b-icon.bi {
    display: inline-block;
    overflow: visible;
    vertical-align: -.15em;
}
button.close {
    padding: 0;
    background-color: transparent;
    border: 0;
}
.close {
    float: right;
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1;
    color: #000;
    text-shadow: 0 1px 0 #fff;
    opacity: .5;
}
.b-sidebar>.b-sidebar-header .close {
    float: none;
    font-size: 1.5rem;
}
.b-sidebar.b-sidebar-right>.b-sidebar-header .close {
    margin-right: auto;
}
.bg-light {
    background-color: white !important;
}
.b-sidebar:not(.b-sidebar-right)>.b-sidebar-header .close {
    margin-left: auto;
}
.pl-3, .px-3 {
    padding-left: 2rem!important;
}
.pr-3, .px-3 {
    padding-right: 2rem!important;
}
.pt-2, .py-2 {
    padding-top: 1rem!important;
}
.pb-2, .py-2 {
    padding-bottom: 1rem!important;
}
.img-thumbnail {
    padding: .50rem;
    background-color: #fff;
    border: 0px solid #dee2e6 !important;
    border-radius: .50rem;
}
/*
.img-fluid, .img-thumbnail {
    max-width: 100%;
    height: 150px;
}*/
img, svg {
    vertical-align: middle;
}
.b-sidebar>.b-sidebar-header {
    font-size: 2rem;
}
 .b-sidebar-body {
     background: white;
 }
 .b-sidebar-header {
     background: white;
 }
</style>
