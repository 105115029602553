<template>
<div>
<b-container>  
        <div style="margin-top: 30px; margin-bottom: 30px;width: -webkit-fill-available;">
            <h1  style="color: #294977; font-weight: 500; font-size: revert;">Carrito de compra <span class="count">({{cartillasForecast.length}})</span> </h1>
        </div>
        <div> 
                    <!-- cols="12"   -->
                    <table class="shop_table shop_table_responsive cart woocommerce-cart-form__contents" cellspacing="0">
                    <thead>
                        <tr >
                            <!-- &nbsp; -->
                            <th class="textoSubtituloAzul">#</th>
                            <th class="textoSubtituloAzul">Lotería</th>
                            <th class="textoSubtituloAzul">Números</th>
                            <th class="textoSubtituloAzul">Precio</th>
                            <th class="textoSubtituloAzul">Eliminar</th> 
                        </tr>
                    </thead>
                    <tbody>
                        <tr   v-for="(i, index) in cartillasForecast" :key="index" class="woocommerce-cart-form__cart-item cart_item">
                            
                            <td class="product-thumbnail styleTextOneCheckOut"  >
                                <a> {{index.toString().padStart(4, "0")}}</a> 
                            </td>
                            <td class="product-thumbnail"> 
                                <div   v-for="(item2, index2) of mainLoteries" :key="index2" >  
                                    <div v-if="i.lottery_id == item2.id" > <p class="styleTextOneCheckOut" >{{item2.description}}</p> </div>
                                </div>
                            </td>
                            <td class="product-name" data-title="Product">
                                <div class=" ">
                                    <div class="numerosSidebarStyle">
                                        <div class="numeroSidebarStyle" v-for="j in i.balls" :key="j">
                                            <div><p style="font-weight: 700; font-size: revert; margin:0px !important;"> {{j}} </p></div> 
                                        </div>
                                        <div class="numeroSidebarStyle" style="background: #ff3535 !important;" v-if="i.ball_red != null">
                                            <div><p style="font-weight: 700; font-size: revert; color:white; margin:0px !important"> {{i.ball_red}} </p></div> 
                                        </div>
                                    </div> 
                                        <!-- <li v-for="j in i.balls" :key="j" > {{j}} </li>   -->
                                        <!-- <li v-if="i.ball_red != null"> {{i.ball_red}} </li>  -->
        
                                </div>				
                            </td>
                            <td class="product-price" data-title="Price">
                                <span class="woocommerce-Price-amount amount"><bdi><span class="woocommerce-Price-currencySymbol">$</span>3.00</bdi></span>						
                            </td>
                            <td class="product-remove" v-on:click="removeNumbers(index, i.id)">
                                <a   class="remove" aria-label="Remove this item" data-product_id="17107" data-product_sku="KTS-0002-1"><i class="fal fa-trash-alt"></i></a>						
                            </td>
                            <!-- <td class="product-subtotal" data-title="Subtotal">
                                <span class="woocommerce-Price-amount amount"><bdi><span class="woocommerce-Price-currencySymbol">$</span>199.99</bdi></span>						
                            </td> -->
                        </tr>

                        <tr  class="woocommerce-cart-form__cart-item cart_item" style="background: #FEC000;">
                            
                            <!-- <td class="product-thumbnail">
                                <button type="submit" class="button empty-cart-button" name="ts_empty_cart" value="Clear All" v-on:click="clearNumbers()" > Limpiar todo </button>
                            </td> -->
                            <td  v-on:click="clearNumbers()"> 
                                <div style="background: #D9473A;padding: 15px; border-radius: 5px; border: 5px solid white; cursor: pointer;display: flex; align-items: center; justify-content: center;">
                                    <div style="color: white;"><i  class="fas fa-trash-alt"></i></div>
                                    <div style="margin-left: 10px;"><h4 style="color: white; font-weight: 500;font-size: inherit;margin-bottom: 0px;">Limpiar todo</h4></div>   					
                                </div>
                            </td> 

                            <td class="product-thumbnail">
                                <a  ></a>
                            </td>
                            <td class="product-name" data-title="Product">
                                <span class="woocommerce-Price-amount amount"><bdi style="font-size: larger; font-weight: 600; color: red;"> TOTAL </bdi></span>					
                            </td>
                            <td class="product-price" data-title="Price">
                                <span class="woocommerce-Price-amount amount"><bdi style="font-size: larger; font-weight: 600; color: red;"><span class="woocommerce-Price-currencySymbol">$</span>{{total.toFixed(2)}}</bdi></span>						
                            </td>
                            <td  @click="activePay()">
                                <div style="background: #00974D; padding: 15px; border-radius: 5px; border: 5px solid white; cursor: pointer;display: flex; align-items: center; justify-content: center;">
                                    <div style="color: white;"><i class="fas fa-bags-shopping"></i></div>
                                    <div style="margin-left: 10px;"><h4 style="color: white; font-weight: 500;font-size: inherit;margin-bottom: 0px;">Comprar cartillas</h4></div>   					
                                </div>
                            </td> 
                            
                        </tr>
                        
                        </tbody>
                    </table>

                    <div v-if="this.country == 'Estados Unidos'" style="display:flex; background:#E0E2E4;padding:15px;width: max-content; height: max-content;"  >
                        <div style="background: #6D6E70; padding: 15px; border-radius: 5px; border: 5px solid white; cursor: pointer;display: flex; align-items: center; justify-content: center;" @click="imprimirCheckout()">
                                    <div style="color: white;"><i class="fas fa-file-alt"></i></div> 
                                    <div style="margin-left: 10px;"><h4 style="color: white; font-weight: 700;font-size: inherit;margin-bottom: 0px;">Imprimir jugadas</h4></div>   					
                        </div> 
                        <div style="width: 20px" ></div>
                        <div style="background: #217446; padding: 15px; border-radius: 5px; border: 5px solid white; cursor: pointer;display: flex; align-items: center; justify-content: center;" @click="exportarExcelCheckout()">
                                    <div style="color: white;"><i class="fas fa-file-excel"></i></div>
                                    <div style="margin-left: 10px;"><h4 style="color: white; font-weight: 700;font-size: inherit;margin-bottom: 0px;">Exportar Excel</h4></div>   					
                        </div>      
                    </div>
                                
                 
        </div> 
  </b-container >

    <!-- ----------------------------- --> 
    <b-modal v-model="show"  title="Pagar" :header-bg-variant="headerBgVariant"  :header-text-variant="headerTextVariant" :body-bg-variant="bodyBgVariant" :body-text-variant="bodyTextVariant" :footer-bg-variant="footerBgVariant" :footer-text-variant="footerTextVariant" >
        <b-container fluid> 
                <div  class="modalSubtitle"  >  Elija su método de pago  </div> 
            <div > 
                    <div ref="paypal" ></div>  
            </div>   
        </b-container>
        <template #modal-footer>
            <div class="w-100"> 
            <div style="cursor: pointer;" @click="show=false" class="float-right">
                Cerrar
            </div> 
            </div>
        </template>
     </b-modal>        
    <!-- -------------------------------------- -->

  </div> 
</template>

<script>
 
// import axios from 'axios';
import swal from 'sweetalert2'; 
   
import { mapActions, mapState } from 'vuex';
// import { jsPDF } from "jspdf";
import Excel from 'exceljs'
import {saveAs} from "file-saver";
import logoImagen from '../../../../public/uploads/logoPdf.png'
import jsPDF from 'jspdf' 
import 'jspdf-autotable'
import moment from 'moment'


// import html2pdf from 'html2pdf.js'
export default {
    name:'CheckoutMain',  
    data() {
        return {     
            // --------------modal
            lat: null ,
            lon: null , 
            paisMostrar:false,
            show: false, 
            variants: ['primary', 'secondary', 'success', 'warning', 'danger', 'info', 'light', 'dark'],
            headerBgVariant: 'primary',
            headerTextVariant: 'light',
            bodyBgVariant: 'light',
            bodyTextVariant: 'dark',
            footerBgVariant: 'light',
            footerTextVariant: 'dark',   
            cartillasForecast: [], 
                total:0 
        }
       },
       created(){               
            this.mostrar() 
       },  
        mounted(){ 
            // await this.listCheckout(); 
             this.getLoteriesMain({})
             const script = document.createElement("script");
                    script.src = "https://www.paypal.com/sdk/js?client-id=AWFR6DpC6P2bboUuBVAMxB9MZysiB4mbpcilwqdxqPCGY3tVTFMcBK6XQ_c-5gr-cm0kKM0KlXq11E84";
                    script.addEventListener("load", this.setLoaded);
                    document.body.appendChild(script); 
        },
        computed: {
            ...mapState({  
                numberCheckout: state => state.checkout.numberCheckout, 
                mainLoteries: state => state.lotto.mainLoteries,
                country: state => state.checkout.country 
            }),
            
        }, 
       methods:{    
           ...mapActions({ 
               listOneCheckout:'listOneCheckout', 
                listCheckout: 'listCheckout',
                deleteOneCheckout: 'deleteOneCheckout',
                deleteAllCheckout: 'deleteAllCheckout',
                deleteOneLotteryCheckout: 'deleteOneLotteryCheckout',
               getLoteriesMain: 'getLoteriesMain',  
                createPurchases: 'createPurchases' 
            }),  
            addWaterMark:function(doc) {
                var totalPages = doc.internal.getNumberOfPages();

                for (let i = 1; i <= totalPages; i++) {
                    doc.setPage(i); 
                    // doc.setTextColor(200);
                    // doc.setFontSize(50); 
                    // doc.setGState( new doc.GState({opacity: 0.4}));
                    // doc.text(60, 200 , 'IA LOTTERY PLAY', null, 45);
                     
                    doc.saveGraphicsState();
                    doc.setGState(new doc.GState({opacity: 0.4}));
                    doc.setTextColor(200);
                    doc.setFontSize(50); 
                    doc.text(60, 200 , 'IA LOTTERY PLAY', null, 45);
                    doc.restoreGraphicsState(); 
                }

                return doc;
            },  
            async exportarExcelCheckout(){ 
                if (this.cartillasForecast.length == 0) {
                       swal.fire('¡ Carrito vacio !', '', 'success');
                       return;      
                }
                    let hoy = new Date();
                    let hora = hoy.getHours() + '-' + hoy.getMinutes() + '-' + hoy.getSeconds();
                    
                    const workbook = new Excel.Workbook();

                    workbook.creator = 'Diego Armando Condori Cabrera';
                    workbook.lastModifiedBy = 'Diego Armando Condori Cabrera';
                    workbook.created = new Date(2021, 7, 15);
                    workbook.modified = new Date();
                    workbook.lastPrinted = new Date(2021, 7, 15);

                    const worksheet = workbook.addWorksheet('Página 1', { 
                                    properties:{defaultColWidth:15, tabColor:{argb:'FF00FF00'}},
                                    // pageSetup:{horizontalCentered : true, verticalCentered:true}          
                        });  


                    worksheet.addRow(
                    { package_name: "ABC", author_name: "Author 1" } 
                    ); 

                    let filas = [];
                let fila = [];

                //ESCRITURA
                // console.log("0 % 7: ", 0 % this.ballForecast);
                // let valor;
                // let count;
                // let x = 0;
                // for ( x = 0; x < jugadas.length; x++) {
                    
                    // let columnaNombrePosition = (x == 0) ? 2 : ((this.columnasForecast * x) + 2* x) + 2 ;
                    filas.push(...[ ["B1","B2","B3","B4","B5","B6"] ]);
                    for (let i = 0; i < this.cartillasForecast.length; i++) {
                    // Lunes 26 de Julio del 2021
                    let element = this.cartillasForecast[i];
                    // let fechaf1 = moment(String(this.playes[i].date)).format('YYYY-MM-DD');
                    // let fechaf2 = moment(String(this.playes[i].date)).format('YYYY-MMMM-dddd');
                    // fechaf1 = fechaf1.split('-')
                    // fechaf2 = fechaf2.split('-') 
                    // let balls = JSON.parse(element.balls)
                        // fila.push(`${fechaf2[2]} ${fechaf1[2]} de ${fechaf2[1]} del ${fechaf2[0]}` );  
                        // fila.push(element.date); 
                        fila.push(element.balls[0]); 
                        fila.push(element.balls[1]); 
                        fila.push(element.balls[2]); 
                        fila.push(element.balls[3]); 
                        fila.push(element.balls[4]);  
                        fila.push(element.ball_red); 
                        
                        filas.push(fila);
                            fila = [];
                    }
                    
                    worksheet.addTable({
                        // name: 'MyTable2'+columnaPosition[columnaNombrePosition],
                        name: 'MyTable2',
                        ref: 'C5',
                        headerRow: false, 
                        style: { 
                        showRowStripes: false,
                        },
                        columns: [
                        {name: '___',  filterButton: false},
                        {name: '____',  filterButton: false},
                        {name: '_____',    filterButton: false},
                        {name: '______',  filterButton: false},
                        {name: '_______',  filterButton: false},
                        {name: '________',   filterButton: false},
                        // {name: '_________',   filterButton: false},
                        // {name: '__________',   filterButton: false}
                        ],
                        rows: filas,
                    });
                    
                    //       filas = [];
                //       count = x;
                // }
                worksheet.getCell('A1').value = `IA LOTTERY PLAY `;

                worksheet.getCell('A1').font = {
                    name: 'Arial Black',
                    color: { argb: 'FFFFFF' },
                    family: 2,
                    size: 14,
                    italic: true
                    };
                worksheet.getCell('A1').fill = { type: "pattern",  pattern: "solid",  fgColor: {  argb: "92D050" }, bgColor: { argb: "92D050" } } 
                worksheet.getCell('B1').fill = { type: "pattern",  pattern: "solid",  fgColor: {  argb: "92D050" }, bgColor: { argb: "92D050" } } 
                // worksheet.getCell('C1').fill = { type: "pattern",  pattern: "solid",  fgColor: {  argb: "92D050" }, bgColor: { argb: "92D050" } } 
                
                worksheet.getCell('C5').font = { name: 'Arial Black', color: { argb: 'FFFFFF' }, family: 2, size: 10  };
                worksheet.getCell('C5').fill = { type: "pattern",  pattern: "solid",  fgColor: {  argb: "0D274A" }, bgColor: { argb: "0D274A" } } 

                worksheet.getCell('D5').font = { name: 'Arial Black', color: { argb: 'FFFFFF' }, family: 2, size: 10  };
                worksheet.getCell('D5').fill = { type: "pattern",  pattern: "solid",  fgColor: {  argb: "0D274A" }, bgColor: { argb: "0D274A" } } 

                worksheet.getCell('E5').font = { name: 'Arial Black', color: { argb: 'FFFFFF' }, family: 2, size: 10  };
                worksheet.getCell('E5').fill = { type: "pattern",  pattern: "solid",  fgColor: {  argb: "0D274A" }, bgColor: { argb: "0D274A" } } 

                worksheet.getCell('F5').font = { name: 'Arial Black', color: { argb: 'FFFFFF' }, family: 2, size: 10  };
                worksheet.getCell('F5').fill = { type: "pattern",  pattern: "solid",  fgColor: {  argb: "0D274A" }, bgColor: { argb: "0D274A" } } 

                worksheet.getCell('G5').font = { name: 'Arial Black', color: { argb: 'FFFFFF' }, family: 2, size: 10  };
                worksheet.getCell('G5').fill = { type: "pattern",  pattern: "solid",  fgColor: {  argb: "0D274A" }, bgColor: { argb: "0D274A" } } 

                worksheet.getCell('H5').font = { name: 'Arial Black', color: { argb: 'FFFFFF' }, family: 2, size: 10  };
                worksheet.getCell('H5').fill = { type: "pattern",  pattern: "solid",  fgColor: {  argb: "0D274A" }, bgColor: { argb: "0D274A" } } 
              
            worksheet.getCell('D2').value = `LISTADO DE JUGADAS MARCADAS`;
                
                worksheet.getCell('D2').font = {
                    name: 'Arial Black',
                    family: 4,
                    size: 16, 
                    underline: true,
                    bold: true
                };


                    const buffer = await workbook.xlsx.writeBuffer();
                    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
                    const fileExtension = '.xlsx';

                    const blob = new Blob([buffer], {type: fileType}); 
                    saveAs(blob, `IALOTTERYPLAY ${hora} ` + fileExtension   );
            
            },
            imprimirCheckout:function(){ 
                 if (this.cartillasForecast.length == 0) {
                       swal.fire('¡ Carrito vacio !', '', 'success');
                       return;      
                }  
                let  doc = new jsPDF();  
                var img = new Image();
                img.src = logoImagen;
                doc.addImage (img, "PNG", 65, 10, 70, 25) 

                    let data= [];
                    let fila = [];
                    for (let index = 0; index < this.cartillasForecast.length; index++) {
                            this.cartillasForecast[index].balls = this.cartillasForecast[index].balls.sort(function(a, b){return a - b});
                            fila.push( index.toString().padStart(4, "0") ); 
                            for (let index2 = 0; index2 < this.mainLoteries.length; index2++) {
                                if (this.cartillasForecast[index].lottery_id == this.mainLoteries[index2].id) {
                                    fila.push(  this.mainLoteries[index2].description );
                                }  
                            }
                            let balls = '';
                            for (let index3 = 0; index3 < this.cartillasForecast[index].balls.length; index3++) {
                                 let bolilla = this.cartillasForecast[index].balls[index3];
                                    if ( bolilla > 0 && bolilla < 10 ) {
                                        bolilla = '0' + bolilla;
                                    }
                                    if ( index3 == 0) {
                                        balls = balls + bolilla; 
                                    }else{
                                        balls = balls + '         ' + bolilla; 
                                    } 
                            }
                            fila.push( balls );
                            let bolilla = this.cartillasForecast[index].ball_red
                            if ( bolilla != null  ) {
                                if (  bolilla > 0 && bolilla < 10 ) {
                                        bolilla = '0' + bolilla;
                                    }
                                //  balls = balls + '         ' + bolilla;
                                 fila.push( '             ' + bolilla );
                            }else{
                                    fila.push( '              -');
                            }
                            
                            data.push( fila );
                            fila = [];
                    }
                    
                doc.autoTable({  
                    theme: 'grid',
                    startY: 40,
                    headStyles:{ halign: 'center'},
                    columnStyles: { 0: { halign: 'center'}, 1: { halign: 'center'}, 2: { halign: 'center'}  }, 
                    head: [['LISTA DE JUGADAS']],
                    
                });

                doc.autoTable({  
                    theme: 'striped',
                    headStyles:{ halign: 'center'},
                    columnStyles: { 0: { halign: 'center'}, 1: { halign: 'center'}, 2: { halign: 'center'}  }, 
                    head: [['#', 'Lotería', 'Números', 'Extra ball']],
                    body: data,
                }); 
                doc = this.addWaterMark(doc);
                doc.output('dataurlnewwindow'); 
            },
            activePay:function(){

                if (this.cartillasForecast.length != 0 ) {
                    this.show = true;

                    const script = document.createElement("script");
                        script.src = "https://www.paypal.com/sdk/js?client-id=AZWiAboiONJrvZ6K6mnuPR2n56vjXZwHc6gE_xu-nvyXenQZ8lP7zW23IK0se9UhtXEeAzlNar-v7QdP";
                        script.addEventListener("load", this.setLoaded);
                        document.body.appendChild(script);  
                } else {
                    swal.fire('¡ Carrito vacio !', '', 'success');
                }
                
            },
            //MÉTODOS PARA EL CRUD
           
            async mostrar(){   
                 
                let datos = await this.listOneCheckout({ id: this.$route.params.id, name: this.$route.params.name });  
                let add = false;
                this.cartillasForecast = datos.listCheckout;  
                for (let i = 0; i < this.cartillasForecast.length; i++) {
                    this.cartillasForecast[i].balls =   JSON.parse(this.cartillasForecast[i].balls)  
                    if ( moment(String(this.cartillasForecast[i].date_lottery)).format('YYYY-MM-DD')   != moment(String(datos.dateLottery)).format('YYYY-MM-DD') ) {
                        add = true;
                    }

                }   
                this.relaodPrice();   

                
                if ( add ) {
                    swal.fire({
                        title: '¿Desea continuar con las jugadas del sorteo pasado?',   
                        confirmButtonText: `Continuar`,    
                        cancelButtonText:  `Eliminar`,                
                        showCancelButton: true,                          
                    }).then(async (result) => {                
                        if (result.isConfirmed) {   
                                // //procedimiento borrar 
                                
                        } else if (result.dismiss == "cancel") { 
                           for (let i = 0; i < this.cartillasForecast.length; i++) {  
                                    if ( moment(String(this.cartillasForecast[i].date_lottery)).format('YYYY-MM-DD')   != moment(String(datos.dateLottery)).format('YYYY-MM-DD') ) {
                                        await this.deleteOneCheckout( this.cartillasForecast[i].id );   
                                        // this.cartillasForecast.splice(index,1);

                                    }

                                }   
                                this.mostrar();
                            
                        }
                    }); 
                }
                
                 

            }, 
            setLoaded: function() { 
                this.loaded = true;
                window.paypal
                .Buttons({
                    style: {
                         
                    },
                    createOrder: (data, actions) => {
                    return actions.order.create({ 
                        purchase_units: [
                        {
                            description: "Compra de tarjetillas",
                            amount: {
                            currency_code: "USD",
                            value: this.total
                            }
                        }
                        ]
                    });
                    },
                    onApprove: async (data, actions) => {
                        const order = await actions.order.capture();
                        // this.paidFor = true;
                        console.log(order);
                                let params ={}; 
                                let a = []; 
                                    for (let i = 0; i < this.cartillasForecast.length; i++) {
                                        a.push({numbers:[], bExtra:null, loteria:null })
                                        a[i].numbers = this.cartillasForecast[i].balls;
                                        a[i].bExtra = this.cartillasForecast[i].ball_red;  
                                        a[i].loteria = this.cartillasForecast[i].lottery_id;
                                    }  
                                    params = { balls: JSON.stringify(a)}
                                    // console.log("params: ", params);
                                    await this.createPurchases(params);  

                            // ----------------------------------------
                            this.cartillasForecast= [];
                            this.relaodPrice();
                            // await this.deleteAllCheckout();
                            await this.deleteOneLotteryCheckout( this.$route.params.id );
                            await this.listCheckout({});
                            this.show = false;
                        swal.fire('¡Números registrados!', '', 'success')

                    },
                    onError: err => {
                    console.log(err);
                    swal.fire('¡ Carrito vacio !', '', 'success')
                    }
                })
                .render(this.$refs.paypal);
            }, 
            removeNumbers:function( index, id ){  
                swal.fire({
                    title: '¿Confirma eliminar el registro?',   
                    confirmButtonText: `Confirmar`,   
                    cancelButtonText:  `Cancelar`,                 
                    showCancelButton: true,                          
                }).then(async (result) => {                
                    if (result.isConfirmed) {      
                        //procedimiento borrar 
                        await this.deleteOneCheckout( id );   
                        this.cartillasForecast.splice(index,1);
                        this.relaodPrice();
                        await this.listCheckout({});
                        swal.fire('¡Eliminado!', '', 'success')
                    } else if (result.isDenied) { 
                        // console.log();         
                    }
                });    
                
            },
            clearNumbers:function( ){ 
                    if (this.cartillasForecast.length != 0) {
                            swal.fire({
                            title: '¿Confirma eliminar todos los registros?',   
                            confirmButtonText: `Confirmar`,    
                            cancelButtonText:  `Cancelar`,                
                            showCancelButton: true,                          
                        }).then(async (result) => {                
                            if (result.isConfirmed) {      
                                // await this.deleteAllCheckout();
                                // { id: this.$route.params.id, name: this.$route.params.name }
                                await this.deleteOneLotteryCheckout( this.$route.params.id );
                                this.cartillasForecast= [];
                                this.relaodPrice();
                                await this.listCheckout({});
                                swal.fire('¡Eliminado!', '', 'success')
                            } else if (result.isDenied) { 
                                // console.log();         
                            }
                        }); 
                } else {
                    swal.fire('¡ Carrito vacio !', '', 'success')
                } 
            },
            async playNumbers( ){
                if (this.cartillasForecast.length == 0) {
                    return
                }
                swal.fire('¡Números registrados!', '', 'success')
                this.cartillasForecast= [];
                this.relaodPrice();
                // await this.deleteAllCheckout();
                // { id: this.$route.params.id, name: this.$route.params.name }
                                await this.deleteOneLotteryCheckout( this.$route.params.id );
                await this.listCheckout({});
            },
            relaodPrice( ){
                this.total = this.cartillasForecast.length * 3.00;
            }
       } 
}
</script>

<style scoped>
    .styleTextTwo{
        margin: 0px 0 0px;
        font-weight: 500;
        font-size: 18px;
    }
    .styleTextOneCheckOut{
            margin: 0px 0 0px; 
            font-size: 17px; 
            font-weight: 600; 
            color:#294977   
    }
    .textoSubtituloAzul{
        color: #294977;
        font-weight: 700;
        font-size: 18px;
    }
    .textoSubtituloAzulNumeral{
        color: #294977;
        font-weight: 500; 
    }
    .numerosSidebarStyle{
      display:flex;  
      justify-content: center;
    }
    .numeroSidebarStyle{
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 5px;
        height: 30px;
        width: 30px;
        background: white;
        border-radius: 20px; 
        box-shadow: inset -6px 3px 10px rgb(0 0 0 / 20%), inset 0 0 12px rgb(0 0 0 / 10%);
    }
    .modalSubtitle{
        display: flex; 
        justify-content: center; 
        padding: 10px;
        font-size: large;
    }
    table td, table th {
        padding: 15px 20px;
        border-width: 0 0 1px 0;
        border-style: solid;
        line-height: 20px;
        text-align: center !important;
    }
</style>
 