<template> 
<div>
  <v-app>
    <v-main>
      <v-expansion-panels focusable>
                <v-expansion-panel   v-for="(item, index) of articulos" :key="index" >
                  <v-expansion-panel-header>
                    <b-row>
                 <b-col sm="12" lg="12" class="py-1">
                     <div class="text-left">
                         <h4 class="text-white">{{item.title}}</h4>
                      </div> 
                   </b-col> 
            </b-row>   
                    
                    </v-expansion-panel-header>
                  <v-expansion-panel-content>
                      <b-card-text>
                            <!-- ------------------------------- -->
                             <v-expansion-panels focusable class="mt-3">
                                  <v-expansion-panel  v-for="(item2, index2) of questions" :key="index2"  >
                                    <v-expansion-panel-header v-if="item.id == item2.subject_id">
                                      <h5 class="text-white">{{item2.title}}</h5>
                                    </v-expansion-panel-header>
                                    <v-expansion-panel-content>
                                      <div v-html="item2.description"></div>
                                    </v-expansion-panel-content>
                                  </v-expansion-panel>
                                </v-expansion-panels>
                            <!-- ------------------------------- -->
                      </b-card-text>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
 
        </v-main>
      </v-app>
  </div>
</template>

<script>
  import axios from "axios"; 
  import { mapActions } from 'vuex'
  // let url = 'http://localhost:3000/subjects/list'; 
  let url = 'https://www.ialotteryplay.com/subjects/list'; //Temas
 
  let url2 = ' https://www.ialotteryplay.com/questions/list'; //questions
  // let url2 = 'http://localhost:3000/questions/list'; 
  
export default {
    name: 'Question',
    data() { 
      return {
        articulos: [],
        questions: []
      }
    },
    created(){               
        this.mostrar();
    },  
    methods:{     
            ...mapActions({
              getProfile: "getProfile",  
            }),    
            //MÉTODOS PARA EL CRUD
            async mostrar(){
              let datos = await axios.get(url);
                this.articulos = datos.data;    

              let questions = await axios.get(url2);
                this.questions = questions.data;                                                
            },
            //Botones y formulario
            // async mostrarPreguntas( id ){
            //   let datos = await axios.get(url);
            //     this.articulos = datos.data;    
             // }
    }

}
</script>

<style scoped>
  /* .accordion .card:not(:first-of-type):not(:last-of-type), .accordion .card:last-of-type {
    border-radius: 0px;
    -webkit-border-radius: 0px;
    -moz-border-radius: 30px;
    -ms-border-radius: 30px;
    -o-border-radius: 30px;
}
 
.accordion .card:first-of-type {
    border-radius: 30px;
    -webkit-border-radius: 0px;
    -moz-border-radius: 30px;
    -ms-border-radius: 30px;
    -o-border-radius: 30px;
} */
 button, a.button-readmore, .ts-banner-button a, .woocommerce button.button.alt:hover, .woocommerce input.button.alt:hover,  .woocommerce div.product, a.dokan-btn, .dokan-btn, .button.button-light:hover, a.button.button-light:hover, .product-category .meta-wrapper a.button:hover, .ts-shortcode .shop-more a.button:hover, .ts-shortcode .show-all-button a.button:hover, .wishlist_table .product-add-to-cart a.button, .woocommerce div.product div.summary form.cart .button, .woocommerce .wishlist_table .product-add-to-cart a.button:hover, .woocommerce .wishlist_table .product-add-to-cart a.button.alt:hover, .product-style-3 .ts-megamenu-container .ts-shortcode .products .product .meta-wrapper-2 .loop-add-to-cart .button, .product-style-4 .ts-megamenu-container .ts-shortcode .products .product .meta-wrapper-2 .loop-add-to-cart .button, .woocommerce .widget_shopping_cart .buttons a:first-child:hover {
    border-color: rgb(255 255 255);
    background: rgb(255 255 255);
    color: #000000;
    
     border-radius: 30px;
    -webkit-border-radius: 0px;
    -moz-border-radius: 30px;
    -ms-border-radius: 30px;
    -o-border-radius: 30px;
}
</style>