<template>
  <div class="container con-profile">
      <div class="row"> 
        <div class="col-lg-12" style="margin-bottom: 20px;">
            <div>
                <b-card no-body id="Idprofile">
                    <b-tabs pills card vertical :class="{ dark: picked_ }">
                         <b-tab title="Compras" :active="pestanaCompra()">
                            <b-card-text style="margin-top: 20px; padding-bottom: 40px;">
                                <div class="row">
                                    <div class="col-lg-12  " >
                                        <h3 :class="{ dark: picked_ }">LISTA DE COMPRAS</h3>
                                        <hr> 
                                            <table :class="{ dark: picked_ }" class="shop_table shop_table_responsive cart woocommerce-cart-form__contents" cellspacing="0">
                                                <thead>
                                                    <tr>
                                                        <th class="product-thumbnail" :class="{ dark: picked_ }">Nro. de Orden</th>
                                                        <th class="product-name" :class="{ dark: picked_ }">Lotería</th>
                                                        <th class="product-name" style="width: 310px" :class="{ dark: picked_ }">Numeros</th>
                                                        <th class="product-price" :class="{ dark: picked_ }">Precio</th>
                                                        <th class="product-remove" :class="{ dark: picked_ }">Ver</th>
                                                     </tr>
                                                </thead>
                                                <tbody>
                                                    <tr v-for="(i, index) in purchases" :key="index" class="woocommerce-cart-form__cart-item cart_item" :class="{ dark: picked_ }">
                                                        <td class="product-thumbnail">
                                                            <a> #{{i.id.toString().padStart(6, "0")}}</a> 
                                                        </td>
                                                        <td class="product-thumbnail"> 
                                                            <div v-for="(item2, index2) of mainLoteries" :key="index2" >
                                                                <img  v-if="i.lottery_id == item2.id" :src="item2.image" style="height: 40px;width: 240px;object-fit: contain;" alt="">
                                                            </div>
                                                        </td>
                                                        <td class="product-name" data-title="Product">
                                                            <div class=" ">
                                                                <ul class="number-list-small">
                                                                    <li v-for="j in JSON.parse(i.balls) " :key="j">
                                                                        <span class="LottoBall__circle"> 
                                                                            {{j}}
                                                                        </span>
                                                                    </li>  
                                                                    <li v-if="i.ball_red != null">
                                                                        <span class="LottoBall__circle" style="background-color: #ff3b3b;color: #fff">  
                                                                            {{i.ball_red}}
                                                                        </span>
                                                                    </li>  
                                                                </ul>	
                                                            </div>				
                                                        </td>
                                                        <td class="product-price" data-title="Price">
                                                            <span class="woocommerce-Price-amount amount"><bdi><span class="woocommerce-Price-currencySymbol">$</span>20.99</bdi></span>						
                                                        </td>
                                                        <td>
                                                            <button class="btn btn-show" @click="showPurchase(i)" ref="btnShowPurchase"> Ver </button>
                                                        </td> 
                                                    </tr> 
                                                </tbody>
                                            </table>
                                       
                                    </div>
                                </div>
                                
                            </b-card-text>
                        </b-tab>
                      
                         <b-tab title="Datos personales" >
                            <b-card-text style="margin-top: 20px; padding-bottom: 40px;">
                                <div class="row">
                                    <div class="col-lg-12">
                                        <h3 :class="{ dark: picked_ }">INFORMACION PERSONAL</h3>
                                        <hr>
                                    </div>
                                    <form>
                                    <div class="col-lg-6">
                                        <div class=" ">
                                            <label class="form-label">Nombre</label>
                                            <input type="text" class="form-control"   placeholder="ejem. Jon" v-model="costumer.name">
                                        </div> 
                                    </div>
                                    <div class="col-lg-6">
                                        <div class=" ">
                                            <label class="form-label">Apellido</label>
                                            <input type="text" class="form-control" placeholder="ejem. Doe" v-model="costumer.lastname">
                                        </div> 
                                    </div>
                                      <div class="col-lg-6">
                                        <div class="form-group">
                                          <label class="form-label">Fecha de cumpleaños</label>
                                          <input type="date" style="height: 51px;" class="form-control" placeholder="23/09/1993" v-model="costumer.birthday">
                                        </div>
                                      </div>
                                      <div class="col-lg-6">
                                        <div class="form-group">
                                          <label class="form-label">Email</label>
                                          <b-input-group >
                                            <b-form-input style="border-top-right-radius: 60px; border-bottom-right-radius: 60px;" type="email" class="form-control" placeholder="example@gmail.com" v-model="email" autocomplete="off"></b-form-input>
                                            <b-input-group-append class="btn-add">
                                              <b-button  class="btn-add-email" @click='addEmail'>Agregar</b-button>
                                            </b-input-group-append>
                                          </b-input-group>
                                        </div>
                                      </div>
                                    <div class="col-lg-12">
                                            <table id="tableEmail" style="margin-top: 10px;margin-bottom: 10px;">
                                                <tr>
                                                    <td>Email</td>
                                                    <td>Eliminar</td> 
                                                    <td>Principal</td>
                                                </tr>
                                                <tr v-for="item in costumer.emails" :key="'email-'+item.id"> 
                                                    <td>{{item.email}}</td>
                                                    <td> <a @click='deleteEmail(item)'><i class="fas fa-trash"></i></a></td> 
                                                    <td>
                                                        <input type="radio"  v-model="picked"  v-bind:value='item.id' @click="changeMainEmail(item)"  />
                                                    </td>
                                                </tr> 
                                                
                                            </table>

                                     
                                    </div>
                                    <div class="col-lg-6">
                                        <div class="form-group">
                                            <label class="form-label">Pais</label>
                                            <select v-model="costumer.country" class="custom-select">
                                                <option disabled value="" selected>Seleccionar pais</option>
                                                <option v-for=" item in countries" :key="'countries-'+item" v-bind:value='item' selected>{{item}}</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-lg-6">
                                        <div class="form-group">
                                            <label class="form-label">Direccion</label>
                                            <input type="text" class="form-control" placeholder="Direccion" v-model="costumer.address">
                                        </div>
                                    </div> 
                                       </form>
                                    <div class="col-lg-12">
                                        <button class="btn btn-secondary btn-save" @click="btnSave">Guardar</button>
                                    </div>
                                </div>
                                <div>
                                    <div class="col-lg-12">
                                        <h3 :class="{ dark: picked_ }">Cambiar Contraseña</h3>
                                        <hr>
                                    </div>
                                    <div class="col-lg-12">
                                         <div class="form-group">
                                            <label class="form-label">Contraseña actual</label>
                                            <input type="password" class="form-control" placeholder="************" v-model="password.current">
                                        </div>
                                    </div>
                                    <div class="col-lg-12">
                                        <div class="form-group">
                                            <label class="form-label">Nueva contraseña</label>
                                            <input type="password" class="form-control" placeholder="************" v-model="password.password_new">
                                        </div>
                                    </div>
                                    <div class="col-lg-12">
                                        <div class="form-group">
                                            <label class="form-label">Confirmar nueva contraseña</label>
                                            <input type="password" class="form-control" placeholder="************" v-model="password.confirm_new">
                                        </div>
                                    </div>
                                    <div class="col-lg-12">
                                        <div class="form-group">
                                            <button class="btn btn-secondary btn-save" @click="sendNewPassword">Guardar</button> 
                                        </div>
                                    </div>
                                </div>
                            </b-card-text>
                        </b-tab>
                         <b-tab title="Suscripción" :active="showSuscription()">
                            <b-card-text > 
                                <div  style="display:flex;flex-direction: column;" v-if="existeSubscription == true">
                                     
                                    <div style="margin-bottom: 10px;">
                                        <p class="styleTextTwoProfile"> PAQUETE DE SUSCRIPCIÓN ACTUAL </p>
                                    </div>
                                    <!-- v-bind:style="{ background: item.color }" -->
                                        <div style="position: relative; background: linear-gradient(to right, rgba(  15 ,  142  ,  27  ,1),rgba(   15 ,  142  ,  27 ,1), rgba(   15 ,  142  ,  27 ,0.9),rgba(   15 ,  142  ,  27 ,0.8)) "     class="cardSuscriptionProfile">
                                             
                                               <div style="  clip: rect(20px,150px,158px,0px);  position: absolute; right: -20px; top: -20px; font-size: 47px; z-index: 0;">
                                                    <div style=" font-size: 150px ; opacity: 0.2; color: white;  "><i class="fas fa-address-card"></i></div>  
                                                </div>
                                                <div> <p style="color: white; font-size: 18px; font-weight: 600;" > Usuario suscrito por {{count_mounth_expired}} meses </p> </div>
                                                
                                                <div>
                                                    <ul style="margin-left: 20px;">
                                                        <li style="margin-bottom: 5px; color: white; font-weight: 400; margin: 10px 0px;">Usuario suscrito desde el {{day_updated}} de {{month_updated}}, {{year_updated}}  hasta el {{day_final}} de {{month_final}}, {{year_final}}.</li>
                                                      
                                                    </ul>
                                                </div>
                                                
                                                <div @click="showPackages()" style="display:flex; justify-content: flex-end;z-index: 1; position: relative;">
                                                    <div class="botonPagarPaquete">
                                                        <div style=" color:white; font-size: 20px;  "><i class="fas fa-stars"></i></div>
                                                        <div > <p style=" color:white"> Ampliar suscripción</p>  </div>
                                                    </div>
                                                </div> 
                                            </div>     
                                </div>
 
                                <div   style="display:flex;flex-direction: column;" v-if="existeSubscription == false">
                                     
                                    <div style="margin-bottom: 10px;">
                                        <p class="styleTextTwoProfile"> PAQUETE DE SUSCRIPCIÓN ACTUAL </p>
                                    </div>
                                    <!-- v-bind:style="{ background: item.color }" -->
                                        <div style="position: relative; background: linear-gradient(to right, rgba(  15 ,  142  ,  27  ,1),rgba(   15 ,  142  ,  27 ,1), rgba(   15 ,  142  ,  27 ,0.9),rgba(   15 ,  142  ,  27 ,0.8)) "     class="cardSuscriptionProfile">
                                             
                                               <div style="  clip: rect(20px,150px,158px,0px);  position: absolute; right: -20px; top: -20px; font-size: 47px; z-index: 0;">
                                                    <div style=" font-size: 150px ; opacity: 0.2; color: white;  "><i class="fas fa-address-card"></i></div>  
                                                </div>
                                                <div> <p style="color: white; font-size: 18px; font-weight: 600;" > Usuario sin suscripción.</p> </div>
                                                
                                              
                                                
                                                <div @click="showPackages()" style="display:flex; justify-content: flex-end;z-index: 1; position: relative;">
                                                    <div class="botonPagarPaquete">
                                                        <div style=" color:white; font-size: 20px;  "><i class="fas fa-stars"></i></div>
                                                        <div > <p style=" color:white"> Obtener suscripción.</p>  </div>
                                                    </div>
                                                </div> 
                                            </div>     
                                </div>
                                <div style="display:flex;flex-direction: column;" v-if="existeSubscription == false">
                                     
                                    <div style="margin-bottom: 10px;">
                                        <p class="styleTextTwoProfile"> LISTA DE PAQUETES DE SUSCRIPCIÓN </p>
                                    </div>
                                    <!-- v-bind:style="{ background: item.color }" -->
                                        <div style="position: relative;" v-for="(item, index4) in paquetes" :key="index4"   v-bind:style="{background: colorGradiente(index4) }"    class="packagesSuscriptions cardSuscriptionProfile">
                                             
                                               <div style="  clip: rect(20px,124px,158px,0px);  position: absolute; right: -20px; top: -20px; font-size: 47px; z-index: 0;">
                                                    <div style=" font-size: 150px ; opacity: 0.2; color: white;  "><i class="fas fa-stars"></i></div>
                                                </div>
                                                <div> <p style="color: white; font-size: 18px; font-weight: 600;" > Suscripción de {{item.months}} meses </p> </div>
                                                
                                                <div>
                                                    <ul style="margin-left: 20px;">
                                                        <li style="margin-bottom: 5px; color: white; font-weight: 400; margin: 10px 0px;">Acceso directo al menú de Resultados.</li>
                                                        <li style="margin-bottom: 5px; color: white; font-weight: 400; margin: 10px 0px;">Acceso directo al menú de Analítica.</li>
                                                        <li style="margin-bottom: 5px; color: white; font-weight: 400; margin: 10px 0px;">Acceso directo al menú de Pronóstico Mix.</li>
                                                    </ul>
                                                </div>
                                                
                                                <div style="display:flex; justify-content: flex-end;z-index: 1; position: relative;">
                                                    <div @click="activePay( index4 )" class="botonPagarPaquete">
                                                        <div style=" color:white; font-size: 20px;  "><i class="fas fa-stars"></i></div>
                                                        <div > <p style=" color:white"> Suscríbete desde {{item.price.toFixed(2)}} x Mes</p>  </div>
                                                    </div>
                                                </div> 
                                            </div>     
                                </div>

                                <div style="display:flex;flex-direction: column;" v-if="existeSubscription == true"> 
                                   

                                    <div style="margin-bottom: 10px;">
                                        <p class="styleTextTwoProfile"> LISTA DE PAQUETES DE SUSCRIPCIÓN </p> 
                                    </div>
                                    <!-- v-bind:style="{ background: item.color }" --> 
                                        <div  style="position: relative;" v-for="(item, index4) in paquetes" :key="index4"   v-bind:style="{background: colorGradiente(index4) }"    class="packagesSuscriptions cardSuscriptionProfile">
                                             
                                               <div style="  clip: rect(20px,124px,158px,0px);  position: absolute; right: -20px; top: -20px; font-size: 47px; z-index: 0;">
                                                    <div style=" font-size: 150px ; opacity: 0.2; color: white;  "><i class="fas fa-stars"></i></div>
                                                </div>
                                                <div> <p style="color: white; font-size: 18px; font-weight: 600;" > Suscripción de {{item.months}} meses </p> </div>
                                                
                                                <div>
                                                    <ul  style="margin-left: 20px;">
                                                        <li style="margin-bottom: 5px; color: white; font-weight: 400; margin: 10px 0px;">Acceso directo al menú de Resultados.</li>
                                                        <li style="margin-bottom: 5px; color: white; font-weight: 400; margin: 10px 0px;">Acceso directo al menú de Analítica.</li>
                                                        <li style="margin-bottom: 5px; color: white; font-weight: 400; margin: 10px 0px;">Acceso directo al menú de Pronóstico Mix.</li>
                                                    </ul>
                                                </div>
                                                
                                                <div style="display:flex; justify-content: flex-end;z-index: 1; position: relative;">
                                                    <div @click="activePay( index4 )" class="botonPagarPaquete"  > 
                                                        <div style=" color:white; font-size: 20px;  "><i class="fas fa-stars"></i></div>
                                                        <div > <p style=" color:white"> Renovar desde {{item.price.toFixed(2)}} x Mes</p>  </div>
                                                    </div>
                                                </div> 
                                            </div>     
                                </div> 

                                 
                                
                            </b-card-text>
                        </b-tab>
                         <b-tab title="Notificaciones" :active="showNotification()" @click=" actualizarNotificaciones() " >
                            <b-card-text style="margin-top: 20px; padding-bottom: 40px;">
                                <div class="row">
                                    <div class="col-lg-12  " >
                                        <h3 :class="{ dark: picked_ }">NOTIFICACIONES</h3>
                                        <hr> 
                                            <table class="shop_table shop_table_responsive cart woocommerce-cart-form__contents" cellspacing="0">
                                                <thead>
                                                    <tr>
                                                        <th :class="{ dark: picked_ }" class="product-thumbnail">N°</th>
                                                        <th :class="{ dark: picked_ }" class="product-thumbnail">Mensaje</th>
                                                        <th :class="{ dark: picked_ }" class="product-remove">Ver</th>
                                                     </tr> 
                                                </thead>
                                                <tbody> 
                                                    <tr :class="{ dark: picked_ }" v-for="(notification, index) in notifications" :key="index" class="woocommerce-cart-form__cart-item cart_item">
                                                    
                                                            <td class="product-thumbnail" v-if="notification.monto != '0'">
                                                                <a> {{index }} </a> 
                                                            </td> 
                                                            <td class="product-thumbnail" v-if="notification.monto != '0'">
                                                                <a>¡Felicidades, acabas de ganar en loteria {{notification.lottery.description}}!, click a 'ver' para más información   </a> 
                                                            </td> 
                                                            <td v-if="notification.monto != '0'">
                                                                <button class="btn btn-show" @click="selectedNotification(index)" ref="btnShowNotification"> Ver </button>
                                                            </td> 
                                                  
                                                        
                                                    </tr> 
                                                </tbody>
                                            </table>
                                       
                                    </div>
                                </div>
                                
                            </b-card-text>
                        </b-tab>
                    </b-tabs>
                </b-card>
            </div>
            
        </div> 
      </div>
    <PurchaseModal/>     
     <!-- ----------------------------- --> 
            <b-modal v-model="show" :class="{ dark: picked_ }" title="Pagar" :header-bg-variant="headerBgVariant"  :header-text-variant="headerTextVariant" >
            <b-container fluid> 
                  <div  class="modalSubtitle"  >  Elija su método de pago  </div>   
              <div > 
                      <div ref="paypal" ></div>  
              </div>   
            </b-container>
            <template #modal-footer>
              <div class="w-100"> 
                <div style="cursor: pointer;" @click="show=false" class="float-right">
                  Cerrar
                </div> 
              </div>
            </template>
          </b-modal>
          <!-- -------------------------------------- -->
          <NotificationModal/>

          
  </div>
</template>

<script>
import { mapActions, mapState, mapMutations } from "vuex";
import PurchaseModal  from "./PurchaseModal";
import NotificationModal  from "./NotificationModal";

import swal from 'sweetalert2'; 

export default {
    name: "Profile", 
    data(){
        return {
            valorPaquete: 0,
            mesesPaquete:0,
            headerBgVariant: 'primary',
            headerTextVariant: 'light',
            openNotificaciones:false,
            show: false, 
            profile: {},

            email: '',
            picked: 0,
            password: {
                password_new: '',
                current: '',
                confirm_new: ''
            }
        }
    }, 
    components: {
        PurchaseModal,
        NotificationModal
    },
    mounted(){  
       this.getLoteriesMain({})
        this.listPurchases() 
        this.init() 
        this.validateOneSubscription()  
         
        
    },
    computed: {
        ...mapState({
            costumer: state => state.costumer.costumer,
            purchases: state => state.purchase.purchases,
            penas: state => state.purchase.penas,

            mainLoteries: state => state.lotto.mainLoteries,
            primary_email: state => state.costumer.primary_email,
            countries: state => state.costumer.countries,

            existeSubscription: state =>state.subscription.existeSubscription,
            count_mounth_expired: state =>state.subscription.count_mounth_expired, 
            day_final: state =>state.subscription.day_final, 
            month_final: state =>state.subscription.month_final, 
            year_final: state =>state.subscription.year_final, 
            day_updated: state =>state.subscription.day_updated, 
            month_updated: state =>state.subscription.month_updated, 
            year_updated: state =>state.subscription.year_updated,   

            notifications: state =>state.notification.notifications,
            picked_: state => state.costumer.picked,

            paquetes: state =>state.promotions.paquetes
        })  
    }, 
    methods: {
        ...mapActions({ 
            getProfile: "getProfile", 
            sendEmail: 'sendEmail',
            removeEmail: 'removeEmail',
            saveProfile: 'saveProfile',
            getLoteriesMain: 'getLoteriesMain',
            listPurchases: 'listPurchases',
            showModalPurchase: 'showModalPurchase',
            changeEmailPrimary: 'changeEmailPrimary',
            sendChangePassword: 'sendChangePassword',

            createOneSubscription: 'createOneSubscription',
            validateOneSubscription: 'validateOneSubscription',
            renovateOneSubscription: 'renovateOneSubscription',
 
            checkedNotification: 'checkedNotification',

            listPromotion: 'listPromotion'
        }),
        ...mapMutations({ 
                ONE_NOTIFICATION: 'ONE_NOTIFICATION', 
        }), 
        init() {
            this.picked =  this.primary_email
            this.email = this.purchases.email 
        },
        colorGradiente( index ){
            return  'linear-gradient(to right, rgba( '+ this.paquetes[index].r +' ,'+ this.paquetes[index].g +','+ this.paquetes[index].b +',1),rgba('+ this.paquetes[index].r +','+ this.paquetes[index].g +','+ this.paquetes[index].b +',1), rgba('+ this.paquetes[index].r +','+ this.paquetes[index].g +','+ this.paquetes[index].b +',0.9),rgba('+ this.paquetes[index].r +','+ this.paquetes[index].g +','+ this.paquetes[index].b +',0.8))';
        },
        pestanaCompra(){
                if ( ! this.$route.query.notification || !this.$route.query.suscription ) { 
                    return true;
                }
        },
        showPackages(){

            document.getElementsByClassName('packagesSuscriptions')[1].scrollIntoView({ behavior: 'smooth', block: 'center' });
            
        },
        showNotification(){
        
                if ( this.$route.query.notification ) { 
                    return true;
                } 
        },
        showSuscription(){
        
                if ( this.$route.query.suscription ) { 
                    return true;
                } 
        },
        addEmail() { 
            this.sendEmail({ email: this.email }) 
            this.email = '' 
        },
        changeMainEmail(item) {
            this.changeEmailPrimary(item)
        },
        deleteEmail(item) {  
            if (item.type == 1) {
                swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: 'No puede eliminar el email principal!!!', 
                })
            } else {
                this.removeEmail(item)
            } 
        },
        btnSave() {
            this.saveProfile({ name: this.costumer.name, lastname: this.costumer.lastname, country: this.costumer.country, address: this.costumer.address, birthday: this.costumer.birthday })
            swal.fire( 'Exito!', 'Datos cambiados correctamente!', 'success')
        },
        showPurchase(item) { 
            this.showModalPurchase(item);
            this.$root.$emit('bv::show::modal', 'purchasesModal', '#btnShowPurchase')  
            
        },
        
        async sendNewPassword() {

            if (!this.password.password_new )  return swal.fire({ icon: 'error', title: 'Error', text: 'La nueva contraseña es requerida!!!',  })
            if (!this.password.current )  return swal.fire({ icon: 'error', title: 'Error', text: 'La contraseña actual es requerida!!!',  })
            if (!this.password.confirm_new )  return swal.fire({ icon: 'error', title: 'Error', text: 'Debe confirmar la contraseña!!!',  })

             
            if (this.password.password_new == this.password.confirm_new) {  
                let data = await this.sendChangePassword(this.password) 
               
                if (data.status == 406) {
                     swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: 'Contraseña incorrecta!!!', 
                    })
                } else {
                     swal.fire(  
                        'Exito!',
                        'Contraseña actualizada correctamente!',
                        'success'
                     )
                } 
            } else {
                // console.log("testsetset"); 
                swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: 'Las contraseñas deben ser iguales!!!', 
                })
            } 
        },
        activePay:function( index ){  
            this.valorPaquete = this.paquetes[index].price ;
            this.mesesPaquete = this.paquetes[index].months; 
                    this.show = true;  
                    const script = document.createElement("script");
                        // script.src = "https://www.paypal.com/sdk/js?client-id=Ace9JgbpMBx78eKAKUWlImag_tHf4S7vYy2_MiQ3MReCJMAbtnDa7iNz8SyAMD8eLB_ZWHKGWm3RahNF";
                        script.src = "https://www.paypal.com/sdk/js?client-id=AX8EVyFc1JuBRVw0wP80QzZY1ziLbaC7zMuV5vAAREjOZEIE2jF3FSOpTSMjEKAUc2UnYLrmU9xwYvA5";
                        script.addEventListener("load", this.setLoaded);
                        document.body.appendChild(script);   
        },
        setLoaded: function() {  
                window.paypal
                .Buttons({ 
                    createOrder: (data, actions) => {
                    return actions.order.create({ 
                        purchase_units: [
                        {
                            description: "Compra de una suscripcion de 9 dolares mensual",
                            amount: { 
                            currency_code: "USD",
                            value: this.valorPaquete * this.mesesPaquete
                            }
                        }, 
                        ]
                    });
                    },
                    onApprove: async (data, actions) => {
                        // const order = 
                    await actions.order.capture();  
                                let params = { meses: this.mesesPaquete, precio: this.mesesPaquete }
                                if (this.existeSubscription == false) {
                                    await this.createOneSubscription(params); 
                                }else{
                                    await this.renovateOneSubscription(params); 
                                }
                                this.validateOneSubscription();

                        // ----------------------------------------
                   
                        this.show = false;
                    swal.fire('¡Suscripción realizada!', '', 'success')

                    },
                    onError: err => {
                    console.log(err);
                    swal.fire('¡Vuelva a intentarlo más tarde!', '', 'success')
                    }
                })
                .render(this.$refs.paypal);
        },
        selectedNotification:function( index){
            //El commit
            // console.log('this.notifications[index]: ', this.notifications[index]);
            this.ONE_NOTIFICATION(this.notifications[index]);  
            this.$root.$emit('bv::show::modal', 'notificationModal', '#btnShowNotification')  
                // this.cartNotification = this.notifications[index]; 
        },
        checkNotification:function( ){
            if ( this.$route.query.notification ) { 
                    this.checkedNotification({notificaciones:this.notifications});
            }
        },
        actualizarNotificaciones: function(){ 
            if (this.openNotificaciones == false) { 
                this.openNotificaciones = true;
                this.checkedNotification({notificaciones:this.notifications});    
            }
        }
         
    },  
    async created(){  
        await this.listPromotion();
        await this.getProfile(); 
        await this.init() ; 
        await this.checkNotification();
    }
     
}
</script>

<style scoped  >

    .tab-content   {
        height: 80vh !important;
        overflow: auto !important;
    }
    #tableEmail,  td, th {
        border-color: #d0d2d5 !important;
    }
.botonPagarPaquete{
     display: flex;
     font-weight: 600;
     width: max-content;
     border-style: solid;
     border-radius: 14px;
     padding: 6px;
     cursor: pointer;
     transition: 0.3s;
}

.botonPagarPaquete:hover  { 
      background: white; 
}

.botonPagarPaquete:hover div{ 
      color: black !important ;  
}

.botonPagarPaquete:hover div p{ 
      color: black !important;  
}

.cardSuscriptionProfile{ 
     width: 100%; 
     height: max-content; 
     border-radius: 10px;  
     box-shadow: 3px 3px 8px black; 
     padding: 10px;
     margin-bottom: 15px;
     transition: 0.3s;
}
.cardSuscriptionProfile:hover  { 
      transform: translateY(-2px);
}

 .styleTextTwoProfile{
        margin: 0px 0 0px;
        font-size: 20px;
        font-weight: 700;
    }
.modalSubtitle{
        display: flex; 
        justify-content: center; 
        padding: 10px;
        font-size: large;
    }
.card {
     border: 0px solid rgba(0, 0, 0, 0.125) !important;
}
.row {
    display: flex !important;
    flex-wrap: wrap !important;
    flex: 1 1 auto !important;
    margin: -12px;
}
.tab-content {
    padding: 0px 0px !important;
}
.col {
    flex-basis: 0 !important;
    flex-grow: 1 !important;
    max-width: 100% !important;
}
.col-auto {
    flex: 0 0 auto !important;
    width: auto !important;
    max-width: 100% !important;
}
.btn-show {
    border-color: #b68c64;
    background: #b68c64;
    color: white;
    height: 35px !important;
    border-radius: 50px !important;
}
.woocommerce table.shop_table {
    margin: 0;
    border-width: 0;
    table-layout: auto;
}
.woocommerce table.shop_table {
    border: 1px solid rgba(0,0,0,.1);
    margin: 0 -1px 24px 0;
    text-align: left;
    width: 100%;
    border-collapse: separate;
    border-radius: 5px;
}
.woocommerce-cart table.cart img {
    width: 90px;
}
.woocommerce table.shop_table tbody > tr:first-child > td {
    border-top-width: 1px;
}
 .product-thumbnail, .woocommerce-cart table.cart .product-thumbnail, .woocommerce-checkout table.cart .product-thumbnail {
    min-width: 90px;
}
.woocommerce table.shop_table tbody td {
    padding: 25px 20px;
    vertical-align: middle;
    border-width: 0 0 1px 0;
    overflow: hidden;
}
.woocommerce table.shop_table td {
    border-top: 1px solid rgba(0,0,0,.1);
    padding: 9px 12px;
    vertical-align: middle;
    line-height: 1.5em;
}
    .btn-save {
        border-radius: 60px !important;
        color: #ffffff;
        background: #b68c64 !important;
        border: none !important;
        float:right;
        width: 150px;
    }
     
    .btn-add-email {
        border-radius: 60px !important;
        height:40px !important; 
        color: #ffffff;
        background: #b68c64 !important;
        border: none !important;
    }
    .btn-add {
        
        margin-left: -100px;
        display: flex;
        justify-content: center;
        flex-direction: column;
    }
    .input-group>.custom-file, .input-group>.custom-select, .input-group>.form-control, .input-group>.form-control-plaintext {
         flex: 1 1 auto;
        width: 1%;
        min-width: 0;
        margin-bottom: 0;
    }
    .input-group {
         display: flex;
        flex-wrap: wrap;
        align-items: stretch;
        width: 100%;
    }
    .card-text {
        padding: 0px 40px;
    }
    .con-profile {
        margin-top: 40px;
    }
    .h-100 {
        height: 100%!important;
    }
    .flex-column {
    flex-direction: column!important;
}
   .form-control{
        box-shadow: none;
        border-radius: 2px;
        padding: 12px 15px;
        height: 46px;
        font-size: 16px;
    }
    .nav-pills .nav-link {
        border-radius: .25rem;
    }
    .nav-pills .nav-link.active, .nav-pills .show>.nav-link {
        color: #fff;
        background-color: #b68c64  ;
    }

    .form-control:focus{
        border-color: #7777779e;
    }
    .btn {
        height:50px;
        text-transform: uppercase;
        font-size: 12px;
        letter-spacing: 1px;
        border-radius: 2px;
        font-weight: 600;
        padding: .6rem .8rem;
    }
    .input-group > .form-control:focus, .input-group > .custom-select:focus, .input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
        z-index: 1;
    }
    .custom-select {
        height: 51px !important;
    }
</style>