import subscriptionAPI from '../../api/subscription' 
import Vue from 'vue'
import VueCookie from 'vue-cookie'
 
Vue.use(VueCookie);

const state = {  
    existeSubscription:false,
    alertaWillExpiredSubscription:false, 
    countDayExpired:0,
    count_mounth_expired:"",
    month_final: "",
    day_final: "",
    year_final: "",
    month_updated: "",
    day_updated: "",
    year_updated: ""
    
}
const getters = { 

}

const actions = { 
    async createOneSubscription({ commit }, { ...searchParams }) {   
        const authorization = VueCookie.get('costumer_token'); 
        searchParams.token = authorization
        if(authorization) {    
            const { status  } = await subscriptionAPI.createOneSubscription(searchParams)
            if (status === 200)  commit('CREATED_SUBSCRIPTION', true) 
        } else{
            commit('CREATED_SUBSCRIPTION', false) 
            return false;
        }
    }, 
    async renovateOneSubscription ({ commit }, { ...searchParams }) {  
        const authorization = VueCookie.get('costumer_token'); 
        if(authorization) {   
            const { status  } = await subscriptionAPI.renovateOneSubscription(searchParams)
            if (status === 200)  commit('CREATED_SUBSCRIPTION', true) 
        } else{
            commit('CREATED_SUBSCRIPTION', false) 
            return false;
        }
    },  
    async validateOneSubscription({ commit }) {  
        const authorization = VueCookie.get('costumer_token');  
        if(authorization) {   
            const { data } = await subscriptionAPI.validateOneSubscription()  
            if (data.ok){
                commit('CREATED_SUBSCRIPTION', true) 
                commit('SHOW_DATE_SUSCRIPTION', data)    
            } else{
                commit('CREATED_SUBSCRIPTION', false)
            }
        } else{
            commit('CREATED_SUBSCRIPTION', false) 
            return false;
        }
    },  
    async timeOneSubscription({ commit }) {  
        const authorization = VueCookie.get('costumer_token'); 
        if(authorization) {   
            const { data } = await subscriptionAPI.timeOneSubscription() 
            // console.log("timeOneSubscription: ", data);
            if (data.ok){ 
                commit('W_EXPIRED_SUBSCRIPTION', true)
                commit('DAY_SUBSCRIPTION', data.dias + 1)
                 
            } else{
                // console.log('NO abrir alerta22');
                commit('W_EXPIRED_SUBSCRIPTION', false)
            }
        } else{
            commit('W_EXPIRED_SUBSCRIPTION', false) 
            return false;
        }
    }
    
}

const mutations = {
    SHOW_DATE_SUSCRIPTION(state, val) {
        // console.log(val);
        state.count_mounth_expired = val.count_mounth_expired,
        state.day_final = val.date_day_end,
        state.month_final = val.date_month_end,
        state.year_final = val.date_year_end,
        state.day_updated = val.date_day_updated,
        state.month_updated = val.date_month_updated,
        state.year_updated = val.date_year_updated  
    },
    CREATED_SUBSCRIPTION(state, val) {
        state.existeSubscription = val
    },
    W_EXPIRED_SUBSCRIPTION(state, val) {
        state.alertaWillExpiredSubscription = val
    },
    DAY_SUBSCRIPTION(state, val) {
        state.countDayExpired = val
    }
    
   
}
export default {
    actions,
    mutations,
    getters,
    state
}