 
const state = {
    tabIndexStatistics:0,
    tabIndexMain:0,
     
}
const actions = {
   
     
}

const getters = {

}

const mutations = { 
    UPDATE_MESSAGE_TAB_ANALYSIS(state, data) { 
        state.tabIndexStatistics = data; 
    },
    UPDATE_MESSAGE_TAB_MAIN_ANALITYCS(state, data) { 
        state.tabIndexMain = data;  
    },
    NAVIGATE_TABS(state, data) {
        state.tabIndexMain= data[0];
        state.tabIndexStatistics = data[1];  
    },
    NAVIGATE_TAB_PRINCIPAL(state, data){
        state.tabIndexMain= data;    
    }
    
}

export default {
    state,
    actions,
    getters,
    mutations
}
