<template>
   <v-app>
     <!-- <div  style=" background-color:red ">
      <div style="padding: 20px 10%;background: #F5F5F5;">
        <div style="display: flex; justify-content: center; margin: 15px 0px;">
          <img style="  height: auto;max-width: 300px;" src="https://ialotteryplay.com/uploads/logo.png" alt="IALotteryPlay" width="1000" height="300">
        </div>
        <div >
          <div style="display:flex">
            <p style="margin: 0px 0px; font-size: 24px; color: #0F8F1B;">Hola Diego , respecto a tu consulta:</p>
          </div>
          <div style="height: 10px;"></div>
          <div style=" background-color:white ">
            <p style="text-align: justify;color:#515A63 ; margin: 0px 0px; padding: 10px 10px; font-size: 17px; font-weight: 400; ">
              texto
            </p>
          </div>
          <div style="height: 10px;"></div>
          <div>
            <p style="margin: 0px 0px; font-size: 24px; color: #0F8F1B;">Te indicamos lo siguiente: </p>
          </div>
          <div style="height: 10px;"></div>
          <div style=" background-color:white ">
            <p style="text-align: justify;color:#515A63 ; margin: 0px 0px; padding: 10px 10px; font-size: 17px; font-weight: 400; ">
            
            texto
            </p>
          </div>
          
        </div>
    </div>
     </div> -->
     
     

      <div></div>
      <p></p>

        <div class="d-flex  flex-row">  
            <p class="text-h4 mx-5 mt-5 mr-auto">Preguntas</p>
            <p class="mx-5 mt-5 ml-auto">Dashboard > Preguntas</p>
        </div> 
        <div>
            <b-card no-body>
                <b-tabs card> 
                    <b-tab title="Preguntas" active>
                        <b-card-text>
                            <v-main>
                                <v-card class="mx-auto mt-5" color="transparent" max-width="1280" elevation="0">
                                        <v-simple-table class="mt-5 mx-5 rounded-lg">
                                            <template v-slot:default>
                                                <thead >
                                                    <tr class="purple darken-2">   
                                                        <th h1 class="white--text blue-grey darken-2 text-center text-sm-h5 text-md-h5 text-lg-h5">Nº</th>
                                                        <th h1 class="white--text blue-grey darken-2 text-center text-sm-h5 text-lg-h5" >USUARIO</th>
                                                        <th h1 class="white--text blue-grey darken-2 text-center text-sm-h5 text-md-h5 text-lg-h5">EMAIL</th>
                                                        <th h1 class="white--text blue-grey darken-2 text-center text-sm-h5 text-lg-h5">MENSAJE</th> 
                                                        <th h1 class="white--text blue-grey darken-2 text-center text-sm-h5 text-lg-h5" >ACCIONES</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr :key="i" v-for="(item, i) in compras_prueba">
                                                        <td class="text-center text-h5"> {{i + 1}} </td>
                                                        <!-- <td class="text-center text-h5"> {{item.id}}</td> -->
                                                        <td class="text-center text-h5"> {{item.name}}</td>
                                                        
                                                        <td class="text-center text-h5"> {{item.email}}</td>
                                                        <td class="text-center text-h5">  {{item.message}} </td>
                                                        <td class="text-center">
                                                            <v-btn class="mx-5 orange"  dark samll :fab="false"  @click="verQuestions(item.name, item.email, item.message)"><i class="fas fa-eye"></i></v-btn>
                                                            <v-btn class="red" dark samll :fab="false" @click="sendEmail(item.id, item.name, item.email, item.message)"><i class="fas fa-envelope"></i></v-btn>
                                                        </td>
                                                    </tr>
                                                </tbody>  
                                            </template>
                                        </v-simple-table>
                                        
                                        
                                </v-card>
                                <div class=" col-md-12 " style="text-align:center;">
                                    <button
                                        type="button"
                                        class="btn btn-sm btn-outline-secondary button_pagination"
                                        v-if="pageFlag != 1"
                                        @click="pageFlag--"
                                    > Ant
                                    </button>
                                    <button
                                        type="button"
                                        class="btn btn-sm btn-outline-secondary button_pagination"
                                        v-for="pageNumber in pages_prueba"
                                        @click="page = pageNumber"
                                        :class="{active_1: pintar(pageNumber)}"
                                        :key="pageNumber"
                                    >{{pageNumber}}</button>
                                    <button
                                        type="button"
                                        @click="pageFlag++"
                                        v-if="pages_prueba.length >= pageForSection"
                                        class="btn btn-sm btn-outline-secondary button_pagination"
                                    >>></button>
                                </div>
                            </v-main>
                            
                        </b-card-text>
                        
                    </b-tab>
                    <b-tab title="Respuestas">
                        <b-card-text>
                            <v-main>
                                <v-card class="mx-auto mt-5" color="transparent" max-width="1280" elevation="0">
                                        <v-simple-table class="mt-5 mx-5 rounded-lg">
                                            <template v-slot:default>
                                                <thead >
                                                    <tr class="purple darken-2">   
                                                        <th h1 class="white--text blue-grey darken-2 text-center text-sm-h5 text-md-h5 text-lg-h5">Nº</th>
                                                        <!-- <th h1 class="white--text blue-grey darken-2 text-center text-sm-h5 text-lg-h5">ID</th>  -->
                                                        <th h1 class="white--text blue-grey darken-2 text-center text-sm-h5 text-lg-h5" >USUARIO</th>
                                                        <th h1 class="white--text blue-grey darken-2 text-center text-sm-h5 text-md-h5 text-lg-h5">EMAIL</th>
                                                        <th h1 class="white--text blue-grey darken-2 text-center text-sm-h5 text-lg-h5">MENSAJE</th>
                                                        <th h1 class="white--text blue-grey darken-2 text-center text-sm-h5 text-lg-h5">RESPUESTA</th> 
                                                        <th h1 class="white--text blue-grey darken-2 text-center text-sm-h5 text-lg-h5" >ACCIONES</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr :key="i" v-for="(item, i) in compras_prueba_ticket">
                                                        <td class="text-center text-h5"> {{i + 1}} </td>
                                                        <!-- <td class="text-center text-h5"> {{item.id}}</td> -->
                                                        <td class="text-center text-h5"> {{item.name}}</td>
                                                        
                                                        <td class="text-center text-h5"> {{item.email}}</td>
                                                        <td class="text-center text-h5"> {{item.message}}</td>
                                                        <td class="text-center text-h5"> {{item.answer}}</td>
                                                        <td class="text-center">
                                                            <v-btn class="mx-5 orange"  dark samll :fab="false"  @click="verAnswer(item.name, item.email, item.message, item.answer)"><i class="fas fa-eye"></i></v-btn>
                                                        </td>
                                                    </tr>
                                                </tbody>   
                                            </template>
                                        </v-simple-table>
                                       
                                </v-card>
                                <div class=" col-md-12 " style="text-align:center;">
                                    <button
                                        type="button"
                                        class="btn btn-sm btn-outline-secondary button_pagination"
                                        v-if="pageFlag_ticket != 1"
                                        @click="pageFlag_ticket--"
                                    > Ant
                                    </button>
                                    <button
                                        type="button"
                                        class="btn btn-sm btn-outline-secondary button_pagination"
                                        v-for="pageNumber_ticket in pages_prueba_ticket"
                                        @click="page_ticket = pageNumber_ticket"
                                        :class="{active_1: pintar_ticket(pageNumber_ticket)}"
                                        :key="pageNumber_ticket"
                                    >{{pageNumber_ticket}}</button>
                                    <button
                                        type="button"
                                        @click="pageFlag_ticket++"
                                        v-if="pages_prueba_ticket.length >= pageForSection_ticket"
                                        class="btn btn-sm btn-outline-secondary button_pagination"
                                    >>></button>
                                </div>

                            </v-main>
                        </b-card-text>
                    </b-tab>
                </b-tabs>
            </b-card>
        </div>
        <v-dialog v-model="dialog" max-width="500" >
                <v-card>
                  <v-card-title class="purple darken-4 white--text">Ver Mensaje</v-card-title>
                  <v-form>
                      <v-card-text>   
                            <b-row class="my-1">
                              <b-col sm="3" lg="3">
                                <label for="input-valid">Nombre:</label>
                              </b-col>
                              <b-col sm="3" lg="9">
                                <label for="input-valid">{{name}}</label>
                              </b-col>
                            </b-row>  
                            <b-row class="my-1">
                              <b-col sm="3" lg="3">
                                <label for="input-valid">Correo Electrónico:</label>
                              </b-col>
                              <b-col sm="3" lg="9">
                                <label for="input-valid">{{correo}}</label>
                              </b-col>
                            </b-row>  
                            <b-row class="my-1">
                              <b-col sm="3" lg="3">
                                <label for="input-valid">Mensaje:</label>
                              </b-col>
                              <b-col sm="3" lg="9">
                                <label for="input-valid">{{mensaje}}</label>
                              </b-col>
                            </b-row>  
                            <b-row v-if="type == 'answer'" class="my-1">
                              <b-col sm="3" lg="3">
                                <label for="input-valid">Respuesta:</label>
                              </b-col>
                              <b-col sm="3" lg="9">
                                <label for="input-valid">{{respuesta}}</label>
                              </b-col>
                            </b-row> 
                            <b-row v-if="type == 'questions'" class="my-1">
                              <b-col sm="3" lg="3">
                                <label for="input-valid">Respuesta:</label>
                              </b-col>
                              <b-col sm="3" lg="9">
                                <textarea v-model="respuesta" rows="10" cols="50">Write something here</textarea>
                              </b-col>
                            </b-row>
                      </v-card-text>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn v-if="type != 'questions'" @click="dialog=false" color="blue-grey" dark>OK</v-btn>
                        <v-btn v-if="type == 'questions'" @click="Send(id,respuesta)" color="blue-grey" dark>Enviar</v-btn>
                      </v-card-actions>
                  </v-form>
                </v-card>
        </v-dialog>
        <v-dialog v-model="dialog_answer" max-width="500" >
                <v-card>
                  <v-card-title class="purple darken-4 white--text">Enviar Mensaje</v-card-title>
                  <v-form>
                      <v-card-text>   
                            <b-row class="my-1">
                              <b-col sm="3" lg="3">
                                <label for="input-valid">Nombre:</label>
                              </b-col>
                              <b-col sm="3" lg="9">
                                <label for="input-valid">{{name}}</label>
                              </b-col>
                            </b-row>  
                            <b-row class="my-1">
                              <b-col sm="3" lg="3">
                                <label for="input-valid">Correo Electrónico:</label>
                              </b-col>
                              <b-col sm="3" lg="9">
                                <label for="input-valid">{{correo}}</label>
                              </b-col>
                            </b-row>  
                            <b-row class="my-1">
                              <b-col sm="3" lg="3">
                                <label for="input-valid">Mensaje:</label>
                              </b-col>
                              <b-col sm="3" lg="9">
                                <label for="input-valid">{{mensaje}}</label>
                              </b-col>
                            </b-row>  
                      </v-card-text>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn @click="dialog=false" color="blue-grey" dark>OK</v-btn>
                      </v-card-actions>
                  </v-form>
                </v-card>
        </v-dialog>
    </v-app> 
</template>

<script> 
import moment from 'moment'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import Swal from 'sweetalert2'
import { mapActions, mapState} from "vuex";
export default {
    name: 'ListSoporteQuestion',
    data(){
        return{
            //resultados
            compras : [],
            compra_tickets:[],
            //fin de resultados
            //datos del modal

            dialog: false,
            dialog_answer: false,
            type:'',
            name: '',
            correo:'',
            mensaje:'',
            respuesta:'',
            id:'',
            //paginador
                //paginador1
            page: 1,
            perPage: 10,
            pages: [],
            pageFlag : 1,
            pageForSection: 10,
                //paginador 2
            page_ticket: 1,
            perPage_ticket: 10,
            pages_ticket: [],
            pageFlag_ticket : 1,
            pageForSection_ticket : 10,
            //fin de paginador
            
        }
    }, 
    computed: {
      ...mapState({
      
      }),
      compras_prueba: function () {
        return this.paginate(this.compras);
      },
      compras_prueba_ticket: function () {
        return this.paginate_ticket(this.compra_tickets);
      },
      pages_prueba:function(){
        //let cant = this.pages.length
        let forPaginate = this.pageForSection
        let flag = this.pageFlag
        let from = (flag * forPaginate) - forPaginate;
        let to = (flag * forPaginate);
        return this.pages.slice(from,to)
      },
      pages_prueba_ticket:function(){
        //let cant = this.pages.length
        let forPaginate = this.pageForSection_ticket
        let flag = this.pageFlag_ticket
        let from = (flag * forPaginate) - forPaginate;
        let to = (flag * forPaginate);
        return this.pages_ticket.slice(from,to)
      }
    },
    methods:{
        ...mapActions({
          listQuestionSupport: 'listQuestionSupport', 
          createAnswerSupport: 'createAnswerSupport'
        }),
        format_date(value){
            if (value) {
                return moment(String(value)).format('YYYY-MM-DD')
            }
        },
        async mostrar(){
            let response = await this.listQuestionSupport(); 
                this.compras = [];
                this.compra_tickets = [];
                this.compras = response.questions ;
                this.compra_tickets = response.answer;     
        },
        async verQuestions(name, email, message){
            this.name = name
            this.correo = email
            this.mensaje = message
            this.dialog = true;
        },
        async sendEmail(id, name, email, message){
            this.type= 'questions'
            this.name = name
            this.correo = email
            this.mensaje = message
            this.id = id
            this.dialog = true;
        },
        async verAnswer(name, email, message, answer){
            this.type = 'answer'
            this.name = name
            this.correo = email
            this.mensaje = message
            this.respuesta = answer
            this.dialog = true;
        },
        async Send(id,respuesta){
            //console.log(id,respuesta)
            let data = {
                id:id,
                respuesta:respuesta,
                correo: this.correo,
                mensaje: this.mensaje,
                nombre: this.name
            }
            let send = await this.createAnswerSupport(data); 
            
            if (send) {
                this.dialog = false
                await Swal.fire(
                    '¡Mensaje enviado exitosamente!', 
                    'Su mensaje ah sido enviado',
                    'success'
                )
                await this.mostrar()
            } else {
                await Swal.fire(
                      '¡Mensaje no enviado!', 
                      'Vuelva a intentarlo',
                      'warning'
                  )
            }
               
        },
        setArticles() {
            this.pages = []
            let numberOfPages = Math.ceil(this.compras.length / this.perPage);
            for (let i = 1; i <= numberOfPages; i++) {
                this.pages.push(i);
            }
        },
        paginate(cartillas) {
            let page = this.page;
            let perPage = this.perPage;
            let from = (page * perPage) - perPage;
            let to = (page * perPage);
            return cartillas.slice(from, to);
        },
        pagination_section(pagine){
            let forPaginate = this.pageForSection
            let flag = this.pageFlag
            let from = (flag * forPaginate) - forPaginate;
            let to = (flag * forPaginate);
            return pagine.slice(from,to)
        },

        setArticles_tickets() {
            this.pages_ticket = []
            let numberOfPages_ticket = Math.ceil(this.compra_tickets.length / this.perPage_ticket);
            for (let i = 1; i <= numberOfPages_ticket; i++) {
                this.pages_ticket.push(i);
            }
        },
        paginate_ticket(cartillas) {
            let page = this.page_ticket;
            let perPage = this.perPage_ticket;
            let from = (page * perPage) - perPage;
            let to = (page * perPage);
            return cartillas.slice(from, to);
        },
        pagination_section_ticket(pagine){
            let forPaginate = this.pageForSection_ticket
            let flag = this.pageFlag_ticket
            let from = (flag * forPaginate) - forPaginate;
            let to = (flag * forPaginate);
            return pagine.slice(from,to)
        },

        pintar(value){
            if(value == this.page){
                return true
            }else{
                return false
            }
        },
        pintar_ticket(value){
            if(value == this.page_ticket){
                return true
            }else{
                return false
            }
        }
    },
    mounted(){               
      this.mostrar()
    },  
    watch: {
      compras(){
        this.setArticles();
      },
      compra_tickets(){
        this.setArticles_tickets()
      }
    },
    
}
</script>

<style>

</style>