<template>
  <div style="width: 100%; height: 100vh; background: #1f2027">
    <div style="display: flex; flex-direction: row; justify-content: space-evenly;padding-top: 10%;">
      <div>
        <img src="/uploads/favicon.png" alt="" style="width: 350px">
      </div>
      <div id="authenticated" style="display: flex; flex-direction: column">
        <h1 for="">REGISTRARSE</h1>

        <form class="login-form cmn-frm">
            <div class="frm-group">
<!--              <label>Email</label>-->
              <input type="email" v-model="user.email" placeholder="Email">

            </div>
            <div class="frm-group" style="margin-bottom: 16px">
<!--              <label>Fecha de Nacimiento</label>-->
              <input type="date" v-model="user.birthday" style="font-size: 16px" placeholder="Fecha de Nacimiento">

            </div>
            <div class="frm-group">
<!--              <label>Email</label>-->
              <b-form-select v-model="selected" class="selectRegister" > 
                <b-form-select-option :value="index4" v-for="(item, index4) in paquetes" :key="index4">
                  Suscripción de {{item.months}} meses  
                </b-form-select-option> 
              </b-form-select>
               
            </div>
            <div class="frm-group">
<!--              <label>Contraseña</label>-->
              <input id="txtPassword" type="password" v-model="user.password" placeholder="Contraseña">
              <button type="button" id="btnToggle" class="toggle"><i id="eyeIcon" class="fa fa-eye"></i></button>

            </div>
            <div class="frm-group">
<!--              <label>Confirmar Contraseña</label>-->
              <input id="txtPassword_" type="password" v-model="user.confirm_password" placeholder="Confirmar contraseña">
              <button type="button" id="btnToggle_" class="toggle"><i id="eyeIcon_" class="fa fa-eye"></i></button>
            </div>
          <div class="margin-top-30" style="display: flex; flex-direction: row; justify-content: space-between; align-items: center">
            <div class="checkboxes ">
              <input name="rememberme" type="checkbox" id="remember-me" value="forever">
              <label class="remember-me" for="remember-me">Estoy de acuerdo con los términos y condiciones</label>
            </div>
          </div>
          <div class="frm-group margin-top-10" style="text-align: center; display: flex; flex-direction: column">
            <button class="sign-up" @click="signUp">Registrarse</button>
            <a class="account margin-top-10" @click="linkSignIn">¿ Ya tienes una cuenta ?</a>
          </div>
        </form>
        <div class="margin-top-60">
          <label style="text-align: center">Copyright © 2020 Lotery. Todos los derechos</label>
        </div>
        <loading :active.sync="isLoadingRegister" :is-full-page="fullPageRegister" />

      </div>
    </div>

  </div>

</template>

<script>
  import { mapActions, mapState } from 'vuex';
  import Swal from "sweetalert2";

  export default {
    name: 'RegisterCostumer',
    data() {
      return {
        selected: 0,  
        credentials: {
          email: "",
          password: ""
        },
        user: {
          birthday: "",
          email: "",
          password: "",
          confirm_password: ""
        },
        fbSignInParams: {
          scope: "email",
          return_scopes: true
        },
        googleSignInParams: {
          client_id: ""
        },
        fullPage: false,
        fullPageRegister: false,
        warnings:[]

      };
    },
    mounted () {
      let pass = document.getElementById('txtPassword')
      let toggle = document.getElementById('btnToggle')
      let icon =  document.getElementById('eyeIcon')
      let pass_ = document.getElementById('txtPassword_')
      let toggle_ = document.getElementById('btnToggle_')
      let icon_ =  document.getElementById('eyeIcon_')

      function togglePassword() {
        if (pass.type === 'password') {
          pass.type = 'text';
          icon.classList.add("fa-eye-slash");
          //toggle.innerHTML = 'hide';
        } else {
          pass.type = 'password';
          icon.classList.remove("fa-eye-slash");
          //toggle.innerHTML = 'show';
        }
      }
      function togglePassword_() {
        if (pass_.type === 'password') {
          pass_.type = 'text';
          icon_.classList.add("fa-eye-slash");
          //toggle.innerHTML = 'hide';
        } else {
          pass_.type = 'password';
          icon_.classList.remove("fa-eye-slash");
          //toggle.innerHTML = 'show';
        }
      }
      toggle.addEventListener('click', togglePassword, false);
      toggle_.addEventListener('click', togglePassword_, false);

      //   initFbsdk()
    },
    async created(){   
        await this.listPromotion(); 
        this.selected =  (this.$route.query.promotion === undefined) ? 0 : this.$route.query.promotion;
    },
    computed: {   
      ...mapState({
        paquetes: state =>state.promotions.paquetes,
        isAuthenticated: state => state.costumer.isAuthenticated
      })
    },
    methods: {
      ...mapActions({
        listPromotion: 'listPromotion',
        login: "login",
        oauthLogin: "oauthLogin",
        register: "register",
        //validateSubscription: 'validateSubscription',
      }),
      linkSignIn() {
        this.$router.push('login-client')
      },
      async signUp2(e) {
        e.preventDefault();
        e.stopPropagation();
        const credentials = this.user;
        this.isLoading = true;
        await this.register(credentials);

      },
      async signUp(e) {
        e.preventDefault();
        e.stopPropagation();
        this.isLoadingRegister = true;
        this.errors = [];
        let regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[$@.,/:^%;#})<'">({$!%*?&])([A-Za-z\d$@.,/:^%;#})<'">({$!%*?&]|[^ ]){6,20}$/;

        if (this.user.email && this.user.password && this.user.birthday && this.user.confirm_password && regex.test(this.user.password) && regex.test(this.user.confirm_password)) {
          setTimeout( async() => {
            let data = await this.register(this.user) 
            switch (data.status) {
              case 405:
                this.errors.push(data.message);
                this.swallErrors()
                break;
              case 406:
                this.errors.push(data.message);
                this.swallErrors()
                break;
              default:

                Swal.fire({
                  position: 'center',
                  icon: 'success',
                  title: 'Registrado correctamente',
                  showConfirmButton: false,
                  timer: 1500
                }, function() {
                  // this.$router.push("/promotion")
                })
                     
                                this.$router.push('/login-client?promotion='+this.selected)
                 
                //this.$refs['authenticated_'].hide()
                break;
            }
            this.isLoadingRegister = false
          },2000)
        } else {

          setTimeout( async() => {
            if (!this.user.email) {
              this.errors.push('El Email es obligatorio.');
              this.swallErrors()
            }
            if (!this.user.birthday) {
              this.errors.push('La Fecha de nacimiento es obligatoria.');
              this.swallErrors()
            }
            if (!this.user.password) {
              this.errors.push('La Contraseña es obligatoria.');
              this.swallErrors()
            }
            if (this.user.password !== this.user.confirm_password) {
              this.errors.push('La Contraseñas deben coincidir.');
              this.swallErrors()
            }
            if(!regex.test(this.user.password) &&  !regex.test(this.user.password)) {
              this.warnings.push('La contraseña debe tener:')
              this.warnings.push('Al menos una mayuscula.')
              this.warnings.push('Al menos un numero.')
              this.warnings.push('Al menos 8 caracteres.')
              this.warnings.push('Al menos un caracter especial.')
              console.log('TEST', this.warnings);
              this.swallWarnings()

            }
            this.isLoadingRegister = false
            this.warnings = [];
          },2000)

        }
      },
      async signIn2(e) {
        e.preventDefault()
        e.stopPropagation()
        const credentials = this.credentials;
        this.isLoading = true;
        await this.login(credentials)
        this.$refs['authenticated'].hide()
      },
      async signIn(e) {

        e.preventDefault()
        e.stopPropagation()
        this.isLoading = true;
        this.errors = [];
        if (this.credentials.email && this.credentials.password) {
          setTimeout( async() => {
            let data = await this.login(this.credentials)
            switch (data.status) {
              case 405:
                this.errors.push(data.message);
                this.swallErrors()
                break;
              case 406:
                this.errors.push(data.message);
                this.swallErrors()
                break;
              default:
                if (this.$route.query.price === undefined || this.$route.query.months === undefined) {
                  this.$router.push('/loterias');
                } else {

                  this.$router.push('/loterias?price='+this.$route.query.price+'&months='+ this.$route.query.months)
                }
                //this.$refs['authenticated'].hide();
                //await this.validateSubscription();
                break;
            }
            this.isLoading = false
          },2000)
        } else {
          setTimeout(  () => {
            if (!this.credentials.email) {
              this.errors.push('El Email es obligatorio.');
              this.swallErrors()
            }
            if (!this.credentials.password) {
              this.errors.push('La Contraseña es obligatoria.');
              this.swallErrors()
            }
            this.isLoading = false
          },2000)
        }
      },
      swallWarnings() {

        let _ = []

        for (let i = 0; i < this.warnings.length; i++) {
          _[i] = `<li>${this.warnings[i]}</li>`
        }
        Swal.fire({
          icon: 'error',
          title: 'Error',
          footer: `<ul>${_}</ul>`,
        })
      },
      swallErrors() {
        let _ = []
        for (let i = 0; i < this.errors.length; i++) {
          _[i] = `<li> ${this.errors[i]}  </li>`
        }
        Swal.fire({
          icon: 'error',
          title: 'Error',
          footer: `<ul>${_}</ul>`,

        })
      },
      redirectToBack() {
        this.$router.push("/")
      },
      loginWithGoogle () {
        /*this.$gAuth
              .signIn()
              .then(GoogleUser => {
                console.log('GoogleUser', GoogleUser)
                console.log('getId', GoogleUser.getId())
                console.log('getBasicProfile', GoogleUser.getBasicProfile())
                console.log('getAuthResponse', GoogleUser.getAuthResponse())
              var userInfo = {
                  loginType: 'google',
                  google: GoogleUser
              }
              this.$store.commit('setLoginUser', userInfo)
                router.push('/home')
              }).catch(error => {
                console.log('error', error)
              })
         */
      },
      loginWithFacebook () {
        /*window.FB.login(response => {
            var userInfo = {
            loginType: 'fb',
            fb: response
            }
            console.log('fb response', response)
            this.$store.commit('setLoginUser', userInfo)
            router.push('/home')
        }, this.params)*/
      },

    }
  }
</script>

<style scoped>

.selectRegister{
  height: 56px;
    color: #fdad59;
    border: 2px solid #fdad59 !important;
    border-radius: 0 !important;
    padding-left: 45px;
    font-size: 17px;
    font-weight: 600;
    -webkit-text-fill-color: #fdad59;
}
input[type="text"], input[type="password"] {
  -webkit-appearance: none;
  border-radius: 1px;
  box-sizing: border-box;
  font-size: 1.25em;
  height: auto;
  padding: .5em;
}
.cmn-frm .frm-group i {
  color: #85878f;
  position: relative;
  bottom: 0 !important;
  left: 0 !important;
}
input[type="password"]::-ms-reveal {
  display: none;
}
.frm-group {
  position: relative;
  width: 100%;
}
.toggle {
  background: none;
  border: none;
  color: #337ab7;
  /*display: none;*/
  /*font-size: .9em;*/
  font-weight: 600;
  /*padding: .5em;*/
  position: absolute;
  right: 10px;
  top: 7px;
  z-index: 9;
}

.modal-content {
  border: none;
}

.remember-me {
  color: #fdad59;
}
a.account {
  margin-top: 10px;
  text-align: center;
  color: #fdad59;
  text-underline-mode: none;
}
#authenticated {
  width: 400px;
}
#authenticated h1, h4 {
  color: #fdad59;
  text-align: center;
}
#authenticated input {
  height: 56px;
  color: #fdad59;
  border: 2px solid #fdad59 !important;
  border-radius: 0 !important;
  padding-left : 45px;
  font-size: 17px;
  font-weight: 600;
  -webkit-text-fill-color: #fdad59;
}
input:-webkit-autofill {
  -webkit-box-shadow:0 0 0 50px white inset; /* Change the color to your own background color */
  -webkit-text-fill-color: #fdad59;
}
input:-webkit-autofill:focus {
  -webkit-box-shadow:0 0 0 50px white inset; /* Change the color to your own background color */
  -webkit-text-fill-color: #fdad59;
  font-family: "Montserrat", "HelveticaNeue", "Helvetica Neue", Helvetica, Arial, sans-serif;
}
#authenticated .sign-up {
  width : 100%;
  margin-top: 10px;
  border-radius: 0 !important;
  background: #fdad59;
  font-size: 16px;
}
#btnToggle:hover {
  border: 0 solid transparent !important;
}
#btnToggle_:hover {
  border: 0 solid transparent !important;
}
#authenticated .sign-un:hover, .sign-up:hover {
  color: #fdad59 !important;
  background: white !important;
}
input::placeholder {
  color: #fdad59;
  font-weight: 400;
}
</style>

