import notificationAPI from '../../api/notification' 
import Vue from 'vue'
import VueCookie from 'vue-cookie'
 
Vue.use(VueCookie);

const state = { 
    notifications : [],  //La lista de purchases
    oneNotifications:{ lottery: {description: "PowerBall"}, balls:'[]' }, // Un purchase
    countNotification:0 
    
}
const getters = { 

}

const actions = {  
    async listNewNotification({ commit }, { ...searchParams }) {   
        const { data } = await notificationAPI.listNewNotification(searchParams) 
        if ( data.ok ){  
            commit('LIST_NOTIFICATION', data.listNewNotification)
            commit('COUNT_NOTIFICATION', data.listNewNotification)
        } 
    }, 
    async checkedNotification( { commit },{ ...searchParams}) { 
        const {   data } = await notificationAPI.checkedNotification(searchParams)
         
        if (data.ok){  
            commit('EMPTY_NOTIFICATION') 
        }  
    }
    
}

const mutations = {
    LIST_NOTIFICATION(state, val) { 
        state.notifications = val
    },
    ONE_NOTIFICATION(state, val){  
        state.oneNotifications = val
    },
    COUNT_NOTIFICATION(state, val){
        let purchases = val;  
        state.countNotification = 0;
        let count = 0;
    
        for (let index = 0; index < purchases.length; index++) {
            const purchase = purchases[index];
            if ( purchase.monto != '0' && purchase.stateNotification == 0 ) {
                count++;  
            } 
        } 
        state.countNotification = count;  
    },
    EMPTY_NOTIFICATION( state ){  
        state.countNotification = 0;   
    } 

   
}
export default {
    actions,
    mutations,
    getters,
    state
}