<template>
  <div style="width: 100%; height: 100vh; background: #1f2027">

    <template v-if="expiredChangePassword">
      <div style="display: flex; flex-direction: row; justify-content: space-evenly;padding-top: 10%;">
        <div>
          <img src="/uploads/favicon.png" alt="" style="width: 370px">
        </div>
        <div id="authenticated" style="display: flex; flex-direction: column">
          <h1 for="">CAMBIAR CONTRASENA</h1>
          <h4 >Ingresar la nueva contrasena y confirmar</h4>
          <form class="login-form cmn-frm">
            <div class="frm-group">
              <input id="password" :type="passwordFieldType" v-model="credentials.password" placeholder="nueva contraseña">
              <button type="button" id="btnToggle" @click="switchTypePassword" class="toggle"><i id="eyeIcon" class="fa" :class="{ 'fa-eye': isShowEye, 'fa-eye-slash': isNotEye }"></i></button>
            </div>
            <div class="frm-group">
              <input id="password_" :type="passwordFieldType_" v-model="credentials.new_password" placeholder="nueva contraseña">
              <button type="button" id="btnToggle_" @click="switchTypePassword_" class="toggle"><i id="eyeIcon_" class="fa" :class="{ 'fa-eye': isShowEye_, 'fa-eye-slash': isNotEye_ }"></i></button>
            </div>
            <div class="frm-group margin-top-10" style="text-align: center; display: flex; flex-direction: column">
              <button class="sign-in" @click="changePassword">Enviar</button>
            </div>
          </form>
          <div class="margin-top-60">
            <label style="text-align: center">Copyright © 2020 Lotery. Todos los derechos</label>
          </div>
          <loading :active.sync="isLoading" :is-full-page="fullPage" />
        </div>
      </div>
    </template>
    <template v-else>
      <div style="display: flex; flex-direction: row; justify-content: space-evenly;padding-top: 10%;">
        <div class="container full-width full-height" style="margin-top:80px">
          <div class=" ">
            <article id="post-38" class="col-md-12 post-38 page type-page status-publish hentry">
              <div class="col-lg-5 col-md-4 col-md-offset-3 sign-in-form style-1 margin-bottom-45">

                <div class="tabs-container alt">
                  <div class=" "  style='z-index: 10; display: inline-block; width: 100%; padding-top: 15px'>
                    <form  class=" ">
                      <h4>Su solicitud a caducado, por favor vuelva a intentarlo </h4>
                    </form>
                  </div>
                </div>
              </div>
            </article>
          </div>
        </div>
      </div>
    </template>

  </div>
</template>
<script>
import { mapActions, mapState } from 'vuex';
import swal from "sweetalert2";

export default {
  name: 'LoginCostumer',
  data() {
    return {
      credentials: {

        password: "",
        new_password: '',
        id:''
      },
      fullPage: false,
      warnings:[],
      passwordFieldType: 'password',

      passwordFieldType_: 'password',
      isShowEye: true,
      isShowEye_: true,
      isNotEye: false,
      isNotEye_:false,
    };
  },
  created() {
  },
  mounted () {

    this.validateExpireChangePassword(this.$route.query)


  },
  computed: {
    ...mapState({
      isAuthenticated: state => state.costumer.isAuthenticated,
      expiredChangePassword: state => state.costumer.expiredChangePassword
    })
  },
  methods: {
    ...mapActions({
      login: "login",
      recoverPassword: 'recoverPassword',
      validateExpireChangePassword: "validateExpireChangePassword"
      //validateSubscription: 'validateSubscription',

    }),
    switchTypePassword(){
      this.passwordFieldType = this.passwordFieldType === "password" ? "text" : "password";
      this.isShowEye = this.isShowEye === true;
      this.isNotEye = this.isNotEye === false;


    },
    switchTypePassword_() {
      this.passwordFieldType_ = this.passwordFieldType_ === "password" ? "text" : "password";
      this.isShowEye_ = this.isShowEye_ === true;
      this.isNotEye_ = this.isNotEye_ === false;

    },
    linkSignUp() {
      this.$router.push('/register-client')
    },
    linkForgot() {
      this.$router.push('/forgot')
    },
    changePassword(e) {
      e.preventDefault();
      e.stopPropagation();
      this.isLoading = true;
      this.errors = [];
      let regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[$@.,/:^%;#})<'">({$!%*?&])([A-Za-z\d$@.,/:^%;#})<'">({$!%*?&]|[^ ]){6,20}$/;

      if (regex.test(this.credentials.password) && regex.test(this.credentials.new_password)) {

        setTimeout(  () => {
          this.credentials.email = this.$route.query.email
          this.recoverPassword(this.credentials)
          this.isLoading = false
          this.$router.push('/login-client')
        },2000)
      } else {

        setTimeout( async() => {

          if (!this.credentials.password) {
            this.errors.push('La Contraseña es obligatoria.');
            this.swallErrors()
          }
          if (this.credentials.password !== this.credentials.new_password) {
            this.errors.push('La Contraseñas deben coincidir.');
            this.swallErrors()
          }
          if(!regex.test(this.credentials.password) &&  !regex.test(this.credentials.new_password)) {

            this.warnings.push('La contraseña debe tener:')
            this.warnings.push('Al menos una mayuscula.')
            this.warnings.push('Al menos un numero.')
            this.warnings.push('Al menos 8 caracteres.')
            this.warnings.push('Al menos un caracter especial.')

            this.swallWarnings()
          }
          this.isLoading = false
          this.warnings = [];
        },2000)
      }
    },


    swallWarnings() {

      let _ = []

      for (let i = 0; i < this.warnings.length; i++) {
        _[i] = `<li>${this.warnings[i]}</li>`
      }
      swal.fire({
        icon: 'error',
        title: 'Error',
        footer: `<ul>${_}</ul>`,
      })
    },
    swallErrors() {
      let _ = []
      for (let i = 0; i < this.errors.length; i++) {
        _[i] = `<li> ${this.errors[i]}  </li>`
      }
      swal.fire({
        icon: 'error',
        title: 'Error',
        footer: `<ul>${_}</ul>`,

      })
    },
    redirectToBack() {
      this.$router.push("/")
    },


  }
}
</script>

<style scoped>


.cmn-frm .frm-group i {
  color: #85878f;
  position: relative;
  bottom: 0 !important;
  left: 0 !important;
}
.toggle {
  background: none;
  border: none;
  color: #337ab7;
  /*display: none;*/
  /*font-size: .9em;*/
  font-weight: 600;
  /*padding: .5em;*/
  position: absolute;
  right: 10px;
  top: 7px;
  z-index: 9;
}
.frm-group {
  position: relative;
  width: 100%;
}
a.account {
  margin-top: 10px;
  text-align: center;
  color: #fdad59;
  text-underline-mode: none;
}
#authenticated {
  width: 420px;
}
#authenticated h1, h4 {
  color: #fdad59;
  text-align: center;
}
#authenticated input {
  height: 56px;
  color: #fdad59;
  border: 2px solid #fdad59 !important;
  border-radius: 0 !important;
  padding-left : 45px;
  -webkit-text-fill-color: #fdad59;
  font-weight: 600;
}
input:-webkit-autofill {
  -webkit-box-shadow:0 0 0 50px white inset; /* Change the color to your own background color */
  -webkit-text-fill-color: #fdad59;
}
input:-webkit-autofill:focus {
  -webkit-box-shadow:0 0 0 50px white inset; /* Change the color to your own background color */
  -webkit-text-fill-color: #fdad59;
  font-family: "Montserrat", "HelveticaNeue", "Helvetica Neue", Helvetica, Arial, sans-serif;
}

#btnToggle:hover {
  border: 0 solid transparent !important;
}
#btnToggle_:hover {
  border: 0 solid transparent !important;
}
#authenticated .sign-in:hover, .sign-up:hover {
  color: #fdad59 !important;
  background: white !important;
}
#email::placeholder {
  color: #fdad59;
  font-weight: 600;
}
#authenticated .sign-in {
  width : 100%;
  margin-top: 10px;
  border-radius: 0 !important;
  background: #fdad59;
  font-size: 16px;
}
#password::placeholder {
  color: #fdad59;
  font-weight: 600;
}
</style>

