import lotteryAPI from '../../api/loterie' 
import Vue from 'vue'
import VueCookie from 'vue-cookie'
 
Vue.use(VueCookie);

const state = {  
    total:0,
    countTotal:0,
    lottery: {
        image: '',
        result: { amount: 0, date_before: '' },
        prizes: [],
        selectNumber: 0,
        numberBallsToSelect: 0,
        hasSpecialBall: false
    },

    prize: { prize_level: "", lottery_winner: '', lottery_prize:'', play_winner: '', play_prize: ''},
    updated: false,
    lotteries: [],
    vcartillas:[],
    paginadorCartilla:0,
    namelottery: 'none',
    results: [],
    result_pages: 0,
    result:{
        lottery:{
            image :''
        },
        detail: [],
    },
    valid_number: {},
    lotteryLast:null
}
const getters = {

}

const actions = {
    async getLottery({ commit }, {...searchParams }) {
        const { data: data } = await lotteryAPI.findOne(searchParams)
        commit('DETAIL_LOTTERY', data) 
    },
    async getLotteries({ commit }, {...searchParams}) {
        const { data: data } = await lotteryAPI.list(searchParams)
        commit('LOTTERIES', data)
    },
    async editLottery({ commit },  searchParams ) {
        const { data: data } = await lotteryAPI.edit(searchParams)
       commit('UPDATE_LOTTERY', data)
    },
    async deleteLottery({ commit }, { ...searchParams }) {
        const {data: data } = await lotteryAPI.delete(searchParams)
        commit('LOTTERIES', data)
    },
    async createClasic({ commit }, { ...searchParams }) {
        const { status } = await lotteryAPI.createClasic(searchParams)
        if (status === 200)  commit('CREATE_CLASIC', true)
        commit('CREATE_CLASIC', false) 
    },
    
    async getResult({commit}, { ...searchParams }) {
        const { data: data } = await lotteryAPI.list_results(searchParams)
        commit('LIST_RESULT', data.data)
        commit('RESULT_PAGES', data.pages)
        
    },
    async getLotteryResult({commit}, {...searchParams}) {
        const { data: data } = await lotteryAPI.getLotteryResult(searchParams) 
        commit('LOTTERY_RESULT', data[0]) 
        commit('LOTTERY_RESULT_FORECAST', data[0].date_before)
        return data[0]
    },
    async validNumbers({commit}, {...searchParams}) {
        const { data: data } = await lotteryAPI.validNumbers(searchParams)
        commit('VALID_NUMBERS', data)
    }
}

const mutations = {
    RELOAD_CARTILLAS_JUGAR_RESULTADO( state, val){
        state.vcartillas.unshift(val);
        state.total = 0;
                for (let i = 0; i < state.vcartillas.length; i++) {
                    if (state.lottery.sublength != 0) {
                        
                        if (state.vcartillas[i].balls.length ==  state.lottery.selectNumber && state.vcartillas[i].bExtra != null) {
                            state.total = state.total + 2.50;  
                        }
                    } else {
                        
                        if (state.vcartillas[i].balls.length ==  state.lottery.selectNumber) {
                            state.total = state.total + 2.50;  
                        }

                    }
                    
                } 

                state.countTotal = state.total / 2.50;
    },
    UPDATE_COUNTTOTAL_JUGAR(state, val) {
        state.countTotal = val
    }, 
    UPDATE_TOTAL_JUGAR(state, val) {
        state.total = val
    },
    
    RELOAD_CARTILLAS( state, val){
        state.vcartillas = val  
    }, 
    RELOAD_CARTILLAS_JUGAR( state, val){
        state.vcartillas = val  
    },
    
    RELOAD_PAGINADOR( state, val){
        state.paginadorCartilla = val  
    },
    DETAIL_LOTTERY(state, val) {
        
        state.lottery = val 
    },
    LOTTERIES(state, val) {
        state.lotteries = val
    },
    UPDATE_LOTTERY(state, val) {
        state.updated = val
    },
    LIST_RESULT(state, val) {
        state.results = []
        state.results.push(...val)
    },
    RESULT_PAGES(state, val) {
        state.result_pages = val
    },
    LOTTERY_RESULT(state, val){ 
        state.result = val
    },
    VALID_NUMBERS(state, val) {
        state.valid_number = val
    },
    LOTTERY_RESULT_FORECAST(state, val){ 
        state.lotteryLast = val
    },
    UPDATE_LOTTERY_DATE(state, val){  
        state.lotteryLast = val
    },

}
export default {
    actions,
    mutations,
    getters,
    state
}