<template>
  <div class="col-md-12">
          <div class="tab-content margin-top-0">
              <div class="row margin-bottom-25">
                <div class="col-md-12">
                  <h1>Editar Usuario</h1>
                </div>
              </div>
            <div class="row">
                <div class="col-lg-12 col-md-12">
                  <div class="dashboard-list-box margin-top-0" id="completadoForm">
                  
                      <div class="dashboard-list-box-static">
                        <div id="lista-detalle" >
                          <div class="row with-forms">
                            <div class="col-md-12">
                              <div class="row with-forms">
                                <div class="col-md-6">
                                  <label>Nombre</label>
                                  <div class="input-with-icon medium-icons">
                                    <input name="id_producto" type="hidden" value="">
                                    <input required class="text-input"   v-model="user.name" type="text">
                                    
                                  </div>
                                </div> 
                                <div class="col-md-6">
                                  <label>Apellido</label>
                                  <div class="input-with-icon medium-icons">
                                      <input required class="text-input"   v-model="user.lastname" type="text" >
                                    
                                  </div>
                                </div>
                                <div class="col-md-4">
                                  <label>DNI:</label>
                                  <div class="input-with-icon medium-icons">
                                    <input required class="text-input"  v-model="user.dni" type="number" placeholder="Ejm: ########">
                                    
                                  </div>
                                </div>
                                <div class="col-md-4">
                                  <label>Email:</label>
                                  <div class="input-with-icon medium-icons">
                                    <input required class="text-input"   v-model="user.email" type="email" placeholder="example@example.com">
                                  </div>
                                </div>
                                <div class="col-md-4">
                                  <label>Password:</label>
                                  <div class="input-with-icon medium-icons">
                                    <input required class="text-input"  v-model="user.password" type="password" placeholder="************">
                                    
                                  </div>
                                </div>
                                <button style="padding: 12px 50px;"  @click="edit()" class="button margin-top-20 margin-bottom-20 margin-right-20">Guardar</button>
                                <router-link style="padding: 12px 50px;" class="button margin-top-20 margin-bottom-20"
                                      :to="{  tag: 'a',  path: `/admin`}">  Cancelar </router-link>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                  </div>
                </div>
            </div>
          </div>
    </div>
</template>

<script>
import {mapActions, mapState} from 'vuex'
import sweet from 'sweetalert2'

export default {
    name: 'EditUser',
    data() {
      return { }
    },
    async mounted(){
      await this.getUser({id: this.$route.params.id})
    },
    computed:{
      ...mapState({
          user: state => state.user.user,
          updated: state => state.user.updated,
     })
    },
    methods: {
      ...mapActions({
        updateUser: 'editUser',
        getUser: 'getUser'
      }),
      edit() {
        this.updateUser(this.user);
        if(this.updated) {
          sweet.fire({
            position: 'center',
            icon: 'success',
            title: '¡Registro actualizado exitosamente!',
            showConfirmButton: true,
          })
          this.$router.push("/admin")
        }
      },
    },

}
</script>

<style>

</style>