<template>
    <div id="group-icon-header" class="ts-floating-sidebar mobile-menu-wrapper hidden "  >
        <div class="ts-sidebar-content">
            <div class="ts-menu">
                <div class="menu-main-mobile">
                    <nav class="mobile-menu">
                    <ul id="menu-menu-mobile" class="menu">
                        <li id="menu-item-17251" class="menu-item menu-item-type-custom menu-item-object-custom menu-item-17251 ts-normal-menu">
                            <a href="../shop/index8e69.html?hover_style=style-4"><span class="menu-label" data-hover="Shop">Inicio</span></a>
                        </li>
                        <li id="menu-item-17227" class="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-17227 ts-normal-menu parent">
                            <a href="#"><span class="menu-label" data-hover="Products">Loterias</span></a><span class="ts-menu-drop-icon"></span>
                            <ul class="sub-menu"> 
                                <li v-bind:key="'loteries-'+index"  v-for="(item, index) in loteries" id="menu-item-17228" class="menu-item menu-item-type-taxonomy menu-item-object-product_cat menu-item-has-children menu-item-17228 parent"> 
                                    <router-link  :to="{  tag: 'a',  path: 'loteria/'+  item.name }"  class="menu-label" data-hover="Wound Care" >  
                                        <span class="menu-label" data-hover="Wound Care"> {{item.name}}</span>
                                    </router-link>
                                </li> 
                            </ul>
                        </li> 
                        <li id="menu-item-17258" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-17258 ts-normal-menu">
                            <a href="../contact-us/index.html"><span class="menu-label" data-hover="Contact">Analisis</span></a>
                        </li> 
                        <li id="menu-item-172612" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-172612 ts-normal-menu">
                            <a href="../contact-us/index.html"><span class="menu-label" data-hover="Contact">Relatos</span></a>
                        </li>
                        <li id="menu-item-17269" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-17269 ts-normal-menu">
                            <a href="../blog/index.html"><span class="menu-label" data-hover="Blog">Resultados</span></a>
                        </li>
                        <li id="menu-item-17268" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-17268 ts-normal-menu">
                            <a href="../contact-us/index.html"><span class="menu-label" data-hover="Contact">Soporte</span></a>
                        </li> 
                        <li id="menu-item-17239" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-17239 ts-normal-menu">
                            <a href="../contact-us/index.html"><span class="menu-label" data-hover="Contact">Preguntas Frecuentes</span></a>
                        </li>
                        
                    </ul>
                    </nav>
                </div>
            </div>
            <div class="group-button-header">
                <div class="language-currency">
                    <div class="header-language">
                    <div class="wpml-ls-statics-shortcode_actions wpml-ls wpml-ls-legacy-dropdown js-wpml-ls-legacy-dropdown">
                        <ul>
                            <li tabindex="0" class="wpml-ls-slot-shortcode_actions wpml-ls-item wpml-ls-item-en wpml-ls-current-language wpml-ls-first-item wpml-ls-item-legacy-dropdown">
                                <a href="#" class="js-wpml-ls-item-toggle wpml-ls-item-toggle">
                                <span class="wpml-ls-native">Español</span>
                                </a>
                                <ul class="wpml-ls-sub-menu"> 
                                <li class="wpml-ls-slot-shortcode_actions wpml-ls-item wpml-ls-item-de wpml-ls-last-item">
                                    <a href="#" class="wpml-ls-link">
                                    <span class="wpml-ls-native">English</span>
                                    </a>
                                </li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                    </div>
                    <div class="header-currency">
                    <div class="wcml_currency_switcher">
                        <a href="javascript: void(0)" class="wcml-cs-active-currency">USD</a>
                        <ul>
                            <li><a href="#">USD</a></li>
                         </ul>
                    </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
</template>

<script>
import { mapState } from 'vuex'
 
export default {
    computed: {
        ...mapState({
            loteries: state => state.lotto.loteries
        })
    }, 
}
</script>

<style>

</style>