<template>
  <v-app>
        <div class="d-flex  flex-row">  
            <p class="text-h4 mx-5 mt-5 mr-auto">Tickets</p>
            <p class="mx-5 mt-5 ml-auto">Dashboard > Tickets</p>
        </div> 
          
        <div>
            <b-card no-body>
                <b-tabs card>
                    <b-tab title="Cartillas Pendientes" active>
                        <b-card-text>
                            <v-main>
                                <v-card class="mx-auto mt-5" color="transparent" max-width="1280" elevation="0">
                                        <v-simple-table class="mt-5 mx-5 rounded-lg">
                                            <template v-slot:default>
                                                <thead >
                                                    <tr class="purple darken-2">   
                                                        <th h1 class="white--text blue-grey darken-2 text-center text-sm-h5 text-md-h5 text-lg-h5">Nº</th>
                                                        <th h1 class="white--text blue-grey darken-2 text-center text-sm-h5 text-lg-h5">LOTERIA</th> 
                                                        <th h1 class="white--text blue-grey darken-2 text-center text-sm-h5 text-lg-h5" >CLIENTE</th>
                                                        <th h1 class="white--text blue-grey darken-2 text-center text-sm-h5 text-md-h5 text-lg-h5">POTENCIADOR</th>
                                                        <th h1 class="white--text blue-grey darken-2 text-center text-sm-h5 text-lg-h5">BOLILLAS</th> 
                                                        <th h1 class="white--text blue-grey darken-2 text-center text-sm-h5 text-lg-h5" >BOLA ROJA</th> 
                                                        <th h1 class="white--text blue-grey darken-2 text-center text-sm-h5 text-lg-h5" >ACCIONES</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr :key="i" v-for="(item, i) in compras_prueba">
                                                        <td class="text-center text-h5"> {{i + 1}} </td>
                                                        <td class="text-center text-h5"> {{item.loterie_name}}</td>
                                                        <td class="text-center text-h5"> {{item.name}} {{item.lastname}}</td>
                                                        <td class="text-center text-h5">
                                                            <ul class="number-list" style="text-align:center">
                                                                <li class="active ball-blue">{{item.potenciador}} </li>
                                                            </ul>
                                                        </td>
                                                        <!--<td class="text-center text-h5"> 
                                                            <ul  class="number-list" style="text-align:center" >
                                                                <li class="active_1">{{print_ball()}}</li>
                                                            </ul> 
                                                        </td>-->
                                                        <td class="text-center text-h5"><ul class="number-list" style="text-align:center" >
                                                                <li class="active_1" :key="index" v-for="(element, index) in JSON.parse(item.balls)">{{element}}</li>
                                                            </ul></td>
                                                        <td class="text-center text-h5">
                                                            <ul class="number-list" style="text-align:center">
                                                                <li class="active ball-red">{{item.ball_red}}</li>
                                                            </ul>
                                                        </td>
                                                        <td class="text-center">
                                                            <v-btn class="mx-5 orange"  dark samll :fab="false"  @click="subirAarchivo(item.id)"><v-icon>cloud_upload</v-icon></v-btn>
                                                            <v-btn class="red" dark samll :fab="false" @click="deleteTicket(item.id)"><v-icon>delete</v-icon></v-btn>
                                                        </td>
                                                    </tr>
                                                </tbody>  
                                            </template>
                                        </v-simple-table>
                                        
                                        
                                </v-card>
                                <div class=" col-md-12 " style="text-align:center;">
                                    <button
                                        type="button"
                                        class="btn btn-sm btn-outline-secondary button_pagination"
                                        v-if="pageFlag != 1"
                                        @click="pageFlag--"
                                    > Ant
                                    </button>
                                    <button
                                        type="button"
                                        class="btn btn-sm btn-outline-secondary button_pagination"
                                        v-for="pageNumber in pages_prueba"
                                        @click="page = pageNumber"
                                        :class="{active_1: pintar(pageNumber)}"
                                        :key="pageNumber"
                                    >{{pageNumber}}</button>
                                    <button
                                        type="button"
                                        @click="pageFlag++"
                                        v-if="pages_prueba.length >= pageForSection"
                                        class="btn btn-sm btn-outline-secondary button_pagination"
                                    >>></button>
                                </div>
                            </v-main>
                            
                        </b-card-text>
                        
                    </b-tab>
                    <b-tab title="Cartillas Compradas">
                        <b-card-text>
                            <v-main>
                                <v-card class="mx-auto mt-5" color="transparent" max-width="1280" elevation="0">
                                        <v-simple-table class="mt-5 mx-5 rounded-lg">
                                            <template v-slot:default>
                                                <thead >
                                                    <tr class="purple darken-2">   
                                                        <th h1 class="white--text blue-grey darken-2 text-center text-sm-h5 text-md-h5 text-lg-h5">Nº</th>
                                                        <th h1 class="white--text blue-grey darken-2 text-center text-sm-h5 text-lg-h5">LOTERIA</th> 
                                                        <th h1 class="white--text blue-grey darken-2 text-center text-sm-h5 text-lg-h5" >CLIENTE</th>
                                                        <th h1 class="white--text blue-grey darken-2 text-center text-sm-h5 text-md-h5 text-lg-h5">POTENCIADOR</th>
                                                        <th h1 class="white--text blue-grey darken-2 text-center text-sm-h5 text-lg-h5">BOLILLAS</th> 
                                                        <th h1 class="white--text blue-grey darken-2 text-center text-sm-h5 text-lg-h5" >BOLA ROJA</th> 
                                                        <th h1 class="white--text blue-grey darken-2 text-center text-sm-h5 text-lg-h5" >ACCIONES</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                     <tr :key="i" v-for="(item, i) in compras_prueba_ticket">
                                                        <td class="text-center text-h5"> {{i + 1}} </td>
                                                        <td class="text-center text-h5"> {{item.loterie_name}}</td>
                                                        <td class="text-center text-h5"> {{item.name}} {{item.lastname}}</td>
                                                        <td class="text-center text-h5">
                                                            <ul class="number-list" style="text-align:center">
                                                                <li class="active ball-blue">{{item.potenciador}} </li>
                                                            </ul>
                                                        </td>
                                                        <!--<td class="text-center text-h5"> 
                                                            <ul  class="number-list" style="text-align:center" >
                                                                <li class="active_1">{{print_ball()}}</li>
                                                            </ul> 
                                                        </td>-->
                                                        <td class="text-center text-h5">
                                                            <ul class="number-list" style="text-align:center" >
                                                                <li class="active_1" :key="index" v-for="(element, index) in JSON.parse(item.balls)">{{element}}</li>
                                                            </ul>
                                                        </td>
                                                        <td class="text-center text-h5">
                                                            <ul class="number-list" style="text-align:center">
                                                                <li class="active ball-red">{{item.ball_red}}</li>
                                                            </ul>
                                                        </td>
                                                        <td class="text-center">
                                                            <v-btn class="mx-5 orange"  dark samll :fab="false"  @click="subirAarchivo(item.id)"><v-icon>edit</v-icon></v-btn>
                                                            
                                                            <a v-bind:href="item.image" target="_blank" rel="noopener noreferrer"><v-btn class="red" dark samll :fab="false"><v-icon>picture_as_pdf</v-icon></v-btn></a>
                                                        </td>
                                                    </tr>
                                                </tbody>  
                                            </template>
                                        </v-simple-table>
                                       
                                </v-card>
                                <div class=" col-md-12 " style="text-align:center;">
                                    <button
                                        type="button"
                                        class="btn btn-sm btn-outline-secondary button_pagination"
                                        v-if="pageFlag_ticket != 1"
                                        @click="pageFlag_ticket--"
                                    > Ant
                                    </button>
                                    <button
                                        type="button"
                                        class="btn btn-sm btn-outline-secondary button_pagination"
                                        v-for="pageNumber_ticket in pages_prueba_ticket"
                                        @click="page_ticket = pageNumber_ticket"
                                        :class="{active_1: pintar_ticket(pageNumber_ticket)}"
                                        :key="pageNumber_ticket"
                                    >{{pageNumber_ticket}}</button>
                                    <button
                                        type="button"
                                        @click="pageFlag_ticket++"
                                        v-if="pages_prueba_ticket.length >= pageForSection_ticket"
                                        class="btn btn-sm btn-outline-secondary button_pagination"
                                    >>></button>
                                </div>

                            </v-main>
                        </b-card-text>
                    </b-tab>
                </b-tabs>
            </b-card>
        </div>
    </v-app> 
    
</template>
<script src="https://cdnjs.cloudflare.com/ajax/libs/axios/0.20.0/axios.js" integrity="sha512-nqIFZC8560+CqHgXKez61MI0f9XSTKLkm0zFVm/99Wt0jSTZ7yeeYwbzyl0SGn/s8Mulbdw+ScCG41hmO2+FKw==" crossorigin="anonymous"></script>

<script>

import axios from 'axios'
import moment from 'moment'
// Import Bootstrap an BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import pdf from 'vue-pdf'
export default {
    name: 'TicketList',
    data(){
        return{
            compras : [],
            compra_tickets:[],

            page: 1,
            perPage: 10,
            pages: [],
            pageFlag : 1,
            pageForSection: 10,

            page_ticket: 1,
            perPage_ticket: 10,
            pages_ticket: [],
            pageFlag_ticket : 1,
            pageForSection_ticket: 10,

            
        }
    },
    components: {
        pdf
    },
    mounted(){               
      this.mostrar()
    },  
    methods:{
        format_date(value){
            if (value) {
                return moment(String(value)).format('YYYY-MM-DD')
            }
        },
        async mostrar(){
            await axios.get('https://latam.ialotteryplay.com/purchases/list-purchases')
            .then(response =>{
                this.compras = []
                this.compra_tickets = []
                this.compras = response.data.compras 
                this.compra_tickets = response.data.comprasTicket    
            }) 
            //console.log("EXAMPLE 000141111")
        },
        async subirAarchivo(id){
            
            //this.mostrar()
            await Swal.fire({ 
                title: 'Seleccionar archivo',
                input: 'file',
                inputAttributes: {
                    'accept': 'application/pdf',
                },
                showCancelButton: true,
                cancelButtonText: 'Cancelar',
                confirmButtonText: 'Guardar',
                showLoaderOnConfirm: true,
                preConfirm: (file) => {
                    if (file !== null) {
                        if(file.size < 1048576){
                            if(file.type == "application/pdf"){
                                return file
                            }else{
                                Swal.showValidationMessage(
                                    `Extension no valida, Solo .pdf`
                                ) 
                            }
                        }else{
                            Swal.showValidationMessage(
                                `El archivo excede el límite.`
                            ) 
                        }
                    }
                    else{
                        Swal.showValidationMessage(
                                    `Elija un archivo`
                        ) 
                    }
                },
                allowOutsideClick: () => !Swal.isLoading()
            })
            .then(async (result) => {
                    if(result.isConfirmed){
                        if (result.value !== null) {
                            //console.log(result.value)
                            let formData = new FormData()
                            formData.append('image', result.value)
                            await axios.post('https://latam.ialotteryplay.com/purchases/purchase-sold/'+id,  formData )
                            .then(function () {
                                    Swal.fire(
                                        '¡Archivo subido exitosamente!', 
                                        'Archivo almacenado exitosamente',
                                        'success'
                                    )
                                    
                            })  
                            .catch(function (error) {
                                alert(error);
                            });
                            this.mostrar()
                            this.mostrar()
                            
                        }
                    }
                    //this.mostrar()
                    
            })
            
           
        },
        async deleteTicket(id){
            Swal.fire({
        title: '¿Deseas eliminar el registro?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Si, eliminar',
        cancelButtonText: 'Cancelar'
        }).then(async (result) => {
          if (result.isConfirmed) {
            
                  await axios.post('https://latam.ialotteryplay.com/purchases/purchase-sold-delete/'+id)
                  this.cartillas = []
                  
              
            Swal.fire(
              '¡Registro eliminado!',
              'El registro ha sido eliminado',
              'success'
            )
              
            this.mostrar()
            this.mostrar()
          }
        })
        },
        
        setArticles() {
            this.pages = []
            let numberOfPages = Math.ceil(this.compras.length / this.perPage);
            for (let i = 1; i <= numberOfPages; i++) {
                this.pages.push(i);
            }
        },
        paginate(cartillas) {
            let page = this.page;
            let perPage = this.perPage;
            let from = (page * perPage) - perPage;
            let to = (page * perPage);
            return cartillas.slice(from, to);
        },
        pagination_section(pagine){
            let forPaginate = this.pageForSection
            let flag = this.pageFlag
            let from = (flag * forPaginate) - forPaginate;
            let to = (flag * forPaginate);
            return pagine.slice(from,to)
        },

        setArticles_tickets() {
            this.pages_ticket = []
            let numberOfPages_ticket = Math.ceil(this.compra_tickets.length / this.perPage_ticket);
            for (let i = 1; i <= numberOfPages_ticket; i++) {
                this.pages_ticket.push(i);
            }
        },
        paginate_ticket(cartillas) {
            let page = this.page_ticket;
            let perPage = this.perPage_ticket;
            let from = (page * perPage) - perPage;
            let to = (page * perPage);
            return cartillas.slice(from, to);
        },
        pagination_section_ticket(pagine){
            let forPaginate = this.pageForSection_ticket
            let flag = this.pageFlag_ticket
            let from = (flag * forPaginate) - forPaginate;
            let to = (flag * forPaginate);
            return pagine.slice(from,to)
        },

        pintar(value){
            if(value == this.page){
                return true
            }else{
                return false
            }
        },
        pintar_ticket(value){
            if(value == this.page_ticket){
                return true
            }else{
                return false
            }
        }
    },
    watch: {
      compras(){
        this.setArticles();
      },
      compra_tickets(){
        this.setArticles_tickets()
      }
    },
    computed: {
      compras_prueba: function () {
        return this.paginate(this.compras);
      },
      compras_prueba_ticket: function () {
        return this.paginate_ticket(this.compra_tickets);
      },
      pages_prueba:function(){
        //let cant = this.pages.length
        let forPaginate = this.pageForSection
        let flag = this.pageFlag
        let from = (flag * forPaginate) - forPaginate;
        let to = (flag * forPaginate);
        return this.pages.slice(from,to)
      },
      pages_prueba_ticket:function(){
        //let cant = this.pages.length
        let forPaginate = this.pageForSection_ticket
        let flag = this.pageFlag_ticket
        let from = (flag * forPaginate) - forPaginate;
        let to = (flag * forPaginate);
        return this.pages_ticket.slice(from,to)
      }
    }
    
}
</script>

<style>
.v-application a {
    color: #28a745 !important;
}

.center{
    text-align: center !important;
}
img.change{
      height: 100px !important;
}
table.thead.tr.td.active{
  display: none; 
}
table.tbody.tr.td.active{
  display: none; 
}
.number-list li {
    border-radius: 50% !important;
    /*text-align:center;*/
}

.ball-blue{
  background-color: #1654f2 !important;
    border-color: #1654f2 !important;
}
/*.number-list.btn-outline-secondary {
    color: #6c757d !important;
    border-color: #6c757d !important;
}*/
.btn-outline-secondary {
    color: #6c757d !important;
    border-color: #6c757d !important;
}
.active_1{
    text-align:center !important;
    border-color: #32a842 !important;
    background-color: #32a842 !important;
    color: white !important;
}
.pintar{
    border-color: white !important;
    background: blue !important;
}
.swal2-content input[type="file"] {
    
    padding: 0px 0px !important;
}
.swal2-content input[type="number"] {
    height: 29px !important;
    line-height: 20px !important;
}
.ball-red{
    background-color: #ed2626 !important;
    border-color: #ed2626 !important;
}
</style>