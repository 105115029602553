
<template>
  <div class=" ">
    <div class="col-lg-12">
      <div  class="add-listing-section  ">
          <div class="col-lg-9">
            <img class="margin-bottom-20 margin-left-20 margin-top-20 change" v-bind:src="`${lottery.image}`" alt="image">
          </div>
          <div class="col-lg-3 margin-top-35">
            <a  class="button" @click="showLottery()" > Ingresar Bolillas  </a>
          </div>
          <div  >
            <table id="table_result" class="table">
              <thead>
                  <tr>
                    <th class="fecha center">Fecha</th>
                    <th class="numeros center">Panel de Números</th>
                    <th class="potenciador center" v-if="lottery.ball_lottery !== 'MULTIPLICADOR'  ">{{lottery.ball_lottery}}</th>
                    <th class="multiplicador center" v-if="lottery.ball_lottery !== 'MULTIPLICADOR'  " >{{lottery.plier_lottery}}</th>
                    <th class="center" >Tipo</th>
                    <th class="acciones center">Acciones</th>
                  </tr>
              </thead>
              <tbody>
                  <tr class="center" :key="index" v-for="(item, index) in books">
                    <td><span class="winning-date">{{format_date(item.date)}}</span></td>
                    <td>
                      <ul class="number-list" style="text-align:center">
                        <li   :key="i" v-for="(items, i) in JSON.parse(item.balls)"><span class="LottoBall__circle">{{items}}</span></li>
                      </ul>
                    </td>
                    <td  v-if="lottery.ball_lottery !== 'MULTIPLICADOR'">
                      <ul class="number-list" style="text-align:center">
                        <li ><span class="LottoBall__circle" style="background-color: rgb(255, 59, 59); color: rgb(255, 255, 255);">{{item.ball_red}}</span> </li>
                      </ul>
                    </td>
                    <td  v-if="lottery.plier_lottery !== 'MULTIPLICADOR'">
                      <ul class="number-list" style="text-align:center">
                        <li >  <span class="LottoBall__circle" style="background-color: #3b99ff; color: rgb(255, 255, 255);">x{{item.enhancer}}</span></li>
                      </ul>
                    </td>
                    <td class="center" v-if="item.type === 0" >TRADICIONAL</td>
                    <td class="center" v-if="item.type === 1" >REZAGADOS</td>
                    <td style="text-align:center;">
                      <v-btn class="mx-5 orange"  dark samll :fab="false" @click="edit(item)"><v-icon>edit</v-icon></v-btn>
                      <v-btn class="red" dark samll :fab="false" @click="remove(item)"><v-icon>delete</v-icon></v-btn>
                    </td>
                  </tr>
              </tbody>
            </table>
          </div>

          <div style="text-align:center;">
            <button
                type="button"
                class="btn button_pagination"
                v-if="pageFlag !== 1"
                @click="pageFlag--" > Ant
            </button>
            <button
                type="button"
                class="btn  button_pagination"
                v-for="pageNumber in pages_test"
                @click="changePage(pageNumber)"
                :class="{active_1: paint(pageNumber)}"
                :key="pageNumber" >{{pageNumber}}</button>
            <button
                type="button"
                @click="pageFlag++"
                v-if="pageFlag <= pages_ / pageForSection"
                class="btn button_pagination" >Sigt</button>
          </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import Swal from 'sweetalert2';
import {mapActions, mapMutations, mapState} from "vuex";
export default {
  name: 'DetailResult',
  data(){
    return{
      type: "",
      PlierLottery: "",
      BallLottery:"",
      page: 1,
      perPage: 10,
      pages: [],
      pageFlag : 1,
      pageForSection: 10
    }
  },
  methods:{
    ...mapActions({
      getResult: 'getResult',
      getLottery: 'getLottery',
      deleteResultLotteryAdmin:'deleteResultLotteryAdmin'
    }),
    ...mapMutations({
      PRIZES_LIST: 'PRIZES_LIST',
      DETAIL_RESET: 'DETAIL_RESET',
    }),
    format_date(value){
      if (value) {
        return moment(String(value)).format('YYYY-MM-DD')
      }
    },
    showLottery(){
      this.$router.push(`/admin/results/new/${this.$route.params.name}/${this.$route.params.id}/`)
      this.PRIZES_LIST([])
      this.DETAIL_RESET([])
    },
    remove(item){
        Swal.fire({
        title: '¿Deseas eliminar el registro?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Si, eliminar',
        cancelButtonText: 'Cancelar'
      }).then(  (result) => {
        if (result.isConfirmed) {
            this.deleteResultLotteryAdmin({ name:this.$route.params.name, ...item})
            this.getResult(this.$route.params)
            Swal.fire(
              '¡Registro eliminado!',
              'El registro ha sido eliminado',
              'success'
          )
        }
      })
    },
    edit(item){
      let id = this.$route.params.id.trim()
      this.$router.push(`/admin/results/edit/${this.$route.params.name}/${id}/${item.id}/`)
    },
    setArticles() {
        let numberOfPages = Math.ceil(this.books.length / this.perPage);
            for (let i = 1; i <= numberOfPages; i++) {
            this.pages.push(i);
        }
    },
    changePage(pageNumber) {
        this.page = pageNumber
        this.$route.params.page = pageNumber
        let data = this.$route.params
        this.getResult(data)
    },
    paginate(books) {
        let page = this.page;
        let perPage = this.perPage;
        let from = (page * perPage) - perPage;
        let to = (page * perPage);
        return books.slice(from, to);
    },
    pagination_section(page){
      let from = (this.pageFlag *  this.pageForSection) -  this.pageForSection;
      let to = (this.pageFlag *  this.pageForSection);
      return page.slice(from,to)
    },
    paint(value){
      return value === this.page;
    }
  },
  mounted(){
    this.$route.params.page = 1
    let data = this.$route.params
    this.getLottery(this.$route.params)
    this.getResult(data)
  },
  watch: {
    books(){
     // this.setArticles();
    }
  },
  computed: {
    ...mapState({
      books: state => state.lottery.results,
      lottery: state => state.lottery.lottery,
      pages_: state => state.lottery.result_pages
    }),
    books_test: function () {
      return this.paginate(this.books);
    },
    pages_test:function(){
      let pages = [...Array(this.pages_ + 1).keys()]
      pages.shift()
      let from = (this.pageFlag * this.pageForSection) - this.pageForSection;
      let to = (this.pageFlag * this.pageForSection);
      return pages.slice(from,to)
    }
  }
}
</script>

<style scoped>
.dashboard-content {
    padding: 0 !important;
}
.lottery-winning-num-table th.fecha{
  width:20% !important;
}
.lottery-winning-num-table th.numeros{
  width:30% !important;
}
.lottery-winning-num-table th.potenciador{
  width:15% !important;
}
.lottery-winning-num-table th.multiplicador{
  width:15% !important;
}
.lottery-winning-num-table th.acciones{
  width:25% !important;
}
.center{
  text-align: center !important;
}
img.change{
  height: 100px !important;
}
table.thead.tr.td.active{
  display: none;
}
table.tbody.tr.td.active{
  display: none;
}
.number-list li {
  border-radius: 50% !important;
  /*text-align:center;*/
}
.active_1{
  text-align:center !important;
  border-color: #2196f3 !important;
  background-color: #2196f3 !important;
  color: white !important;
}
.ball-red{
  background-color: #ed2626 !important;
  border-color: #ed2626 !important;
}
.ball-blue{
  background-color: #1654f2 !important;
  border-color: #1654f2 !important;
}
.number-list li {
  padding-top: 0 !important;
}
.button_pagination{
  border-color: #2a2a2a47 ;
  background: #2a2a2a00 ;
  position: relative;
  padding: .5rem .75rem;
  line-height: 1.25;
}
.paint{
  border-color: white !important;
  background: blue !important;
}
.button_pager{
  display: block !important;
  margin-left: 0 !important;
}
.orange {
  background-color: #ff9800 !important;
  border-color: #ff9800 !important;
}
.red {
  background-color: #F44336 !important;
  border-color: #F44336 !important;
}
.v-btn:not(.v-btn--round).v-size--default {
  height: 36px;
  min-width: 36px;
  padding: 0 16px;
  width: 50px;
}
.btn {
  width: 50px;
  text-transform: capitalize;
  font-size: 13px;
}
</style>