
<template>
  <div style="display: flex;">
    <div id='left-drawer' :class="{ dark: picked }" class="nav-bar visible-phone-left" style="z-index: 99999; height: 100vh; ">
      <div class="nav-links" >
        <a class="nav-link" style="height: 44px;" @click="isActiveArrow">
          <div class="arrow">
            <i class="fas fa-chevron-left" style="margin-left: 8px"></i>
          </div>
        </a>
        <div class="nav-link-header">
          <div style="position: relative"  v-if="!isArrow">
            <img :src="lottery.logo" style=" width: 40px; text-align: center;"  >
              <!--<div style="width: 30px;height: 30px; background: #d91535; font-size: 9px; border-radius: 10px; padding: 2px ; color: white; font-weight: 700; text-align: center">
                <strong style="">BALL</strong>
              </div>-->
          </div>
          <img :src="lottery.image" style=" width: 160px; text-align: center;" v-if="isArrow">
        </div>
        <a @click="tabChange(0)" class="nav-link marker" :class="{ 'is-active-tab': tabs[0].state }">
          <i class="fas fa-calendar-star"></i>
          <span class="nav-link-name"> <strong>Detalle</strong> </span>
        </a>
        <a @click="tabChange(1)"  class="nav-link marker" :class="{ 'is-active-tab': tabs[1].state }">
          <i class="fas fa-poll"></i>
          <span class="nav-link-name"> <strong>Analitica </strong></span>
        </a>
        <template v-if="this.costumer.type === 1 " >
          <template v-if="lottery.type" >
            <div class="nav-link-header">
              <div style="position: relative" v-if="!isArrow">
                <div style="border: 1px solid black; width: 30px;height: 30px; background: white; font-size: 10px; text-align: center; border-radius: 10px; padding: 2px ; color: black; font-weight: 700;">
                  <strong style="font-weight: 700">DP</strong>
                </div>
              </div>
              <img src="/uploads/double_play.png" style=" width: 160px; text-align: center;" v-if="isArrow">
            </div>
            <a @click="tabChange(2)" class="nav-link marker" :class="{ 'is-active-tab': tabs[2].state }">
              <i class="fas fa-calendar-star"></i>
              <span class="nav-link-name"> <strong> Detalle</strong></span>
            </a>
            <a @click="tabChange(3)" class="nav-link marker" :class="{ 'is-active-tab': tabs[3].state }">
              <i class="fas fa-poll"></i>
              <span class="nav-link-name"> <strong> Analitica</strong></span>
            </a>

          </template>
          <div class="nav-link-header" disabled="">
            <div style="position: relative" v-if="!isArrow">
              <div style="border: 1px solid black; width: 30px;height: 30px; background: white; font-size: 10px; text-align: center; border-radius: 10px; padding: 2px ; color: black; font-weight: 700;">
                <strong style="font-weight: 700">LP</strong>
              </div>
            </div>
            <span :class="{ dark: picked }" class="nav-link-name" style="font-weight: bold;">LOTTERY PLAY</span>
          </div>
          <a  @click="tabChange(4)" class="nav-link marker" :class="{ 'is-active-tab': tabs[4].state }">
            <i class="fas fa-club"></i>
            <span class="nav-link-name"> <strong>Jugar</strong> </span>
          </a>
        </template>
      </div>
    </div>
    <!-- margin-bottom: 150px; -->
    <b-card class="container-custom"  no-body style="display: flex; flex-direction: column; width: 100%; border: 0 solid rgba(0, 0, 0, 0.125);"  >
<!--      <div id='left-drawer' :class="picked" class="nav-bar visible-phone" style="z-index: 99999; height: 80px;   ">
        <div class="nav-links" style="display: flex; justify-content: center" >

          <div class="nav-link-header">
            <div style="position: relative" >
              <div style="width: 30px;height: 30px; background: #d91535; font-size: 9px; border-radius: 10px; padding: 2px ; color: white; font-weight: 700; text-align: center">
                <strong style="">BALL</strong>
              </div>
            </div>
          </div>
          <a @click="tabChange(0)" class="nav-link marker mobile-top" :class="{ 'is-active-tab-top': tabs[0].state }">
            <i class="fas fa-calendar-star icon-top"></i>
            <span class="nav-link-name" > DETALLE </span>
          </a>
          <a @click="tabChange(1)"  class="nav-link marker mobile-top" :class="{ 'is-active-tab-top': tabs[1].state }">
            <i class="fas fa-poll icon-top"></i>
            <span class="nav-link-name">ANALITICA</span>
          </a>
          <template v-if="this.costumer.type === 1" >
            <div class="nav-link-header mobile-top">
              <div style="position: relative" >
                <div style="border: 1px solid black; width: 30px;height: 30px; background: white; font-size: 10px; text-align: center; border-radius: 10px; padding: 2px ; color: black; font-weight: 700;">
                  <strong style="font-weight: 700">DP</strong>
                </div>
              </div>
              <span class="nav-link-name">DOBLE PLAY</span>
            </div>
            <a @click="tabChange(2)" class="nav-link marker mobile-top" :class="{ 'is-active-tab-top': tabs[2].state }">
              <i class="fas fa-calendar-star icon-top"></i>
              <span class="nav-link-name">DETALLE</span>
            </a>
            <a @click="tabChange(3)" class="nav-link marker mobile-top" :class="{ 'is-active-tab-top': tabs[3].state }">
              <i class="fas fa-poll icon-top"></i>
              <span class="nav-link-name">ANALITICA</span>
            </a>
            <div class="nav-link-header mobile-top icon-top" disabled="">
              <div style="position: relative" >
                <div style="border: 1px solid black; width: 30px;height: 30px; background: white; font-size: 10px; text-align: center; border-radius: 10px; padding: 2px ; color: black; font-weight: 700;">
                  <strong style="font-weight: 700">LP</strong>
                </div>
              </div>
              <span class="nav-link-name" style="margin-left: 0 !important;">LOTTERY PLAY</span>
            </div>
            <a  @click="tabChange(4)" class="nav-link marker mobile-top" :class="{ 'is-active-tab-top': tabs[4].state }">
              <i class="fas fa-club icon-top"></i>
              <span class="nav-link-name"> <strong>JUGAR</strong> </span>
            </a>
          </template>
        </div>
      </div>-->
      <b-tabs id="home-tabs" v-model="mainMessage" pills card vertical :class="{ dark: picked }" >
        <b-tab active>
          <template #title>
            <div  style="font-size: 13px;">
              <i class="fas fa-club"></i>  <strong>Detalle</strong>
            </div>
          </template>
          <Lottery :type="0" />
        </b-tab>
        <b-tab  v-if="this.costumer.type === 1"  >
          <template #title>
            <div  style="font-size: 13px; margin-bottom: 100px">
              <i class="fas fa-analytics"></i>  <strong>Analítica</strong>
            </div>
          </template>
          <Statistics :type="0"/>
        </b-tab>
        <template v-if="this.costumer.type === 1  && lottery.type"  >
          <b-tab   >
            <template #title>
              <div  style="font-size: 13px;">
                <i class="fas fa-club"></i>  <strong>Detalle</strong>
              </div>
            </template>
            <Lottery :type="1" />
          </b-tab>
          <b-tab  >
            <template #title>
              <div  style="font-size: 13px;margin-bottom: 100px">
                <i class="fas fa-analytics"></i>  <strong>Analítica</strong>
              </div>
            </template>
            <Statistics :type="1"/>
          </b-tab>
        </template>

        <b-tab v-if="this.costumer.type === 1" >
          <template #title>
            <div  style="font-size: 13px;">
              <i class="fas fa-chart-line"></i>  <strong>Juego</strong>
            </div>
          </template>
          <PronosticoPage :type="0"/>
        </b-tab>
        <b-tab v-if="this.costumer.type === 0">
          <template #title>
            <div  style="font-size: 13px;">
              <i class="fas fa-chart-line"></i>  <strong>Juego</strong>
            </div>
          </template>
          <PronosticoNormalPage :type="0"/>
        </b-tab>
      </b-tabs>
    </b-card>
  </div>
</template>
<script>
import { mapState, mapActions, mapMutations } from "vuex";
import VueCookie from "vue-cookie";
import Statistics from "./Tab/Statistics";
import PronosticoPage from "./Iframes/PronosticoPage";
import PronosticoNormalPage from "./Iframes/PronosticoNormalPage";
import Lottery from "./Tab/Lottery";
export default {
  name: "MainJulio",
  components: {
    Statistics,
    Lottery,
    PronosticoPage,
    PronosticoNormalPage
  },
  data() {
    return {
      isActiveFlashToggle: false,
      isActiveToggle:true,

      isShowFlecha: false,
      normal: 'normal',
      laggards: 'laggards',
      image: '/uploads/double_play.png',
      isActive: false,
      isArrow: false,
      tabs: [
        { key: 0,  state: true },
        { key: 1,  state: false },
        { key: 2,  state: false },
        { key: 3,  state: false },
        { key: 4,  state: false },
      ]
    }
  },
  computed: {
    ...mapState({
      type: state => state.lotto.type,
      //lotteries: state => state.lotto.mainLoteries,
      costumer: state => state.costumer.costumer,
      lottery: (state) => state.lottery.lottery,
      tabIndexMain: state => state.tabsNavegation.tabIndexMain,
      picked: state => state.costumer.picked,
      theme_dark: state => state.costumer.theme_dark,
      theme_light: state => state.costumer.theme_light,
    }),
    mainMessage:{
      get(){
        return this.$store.state.tabsNavegation.tabIndexMain
      },
      set( value ){
        this.$store.commit('UPDATE_MESSAGE_TAB_MAIN_ANALITYCS', value)
      }
    }
  },
  methods: {
    ...mapMutations({
      CHANGE_SELECTED:'CHANGE_SELECTED'
    }),
    ...mapActions({
      getLottery: 'getLottery',
      validateOneSubscription: "validateOneSubscription",
    }),
    isActiveArrow() {
      let nav_bar = document.querySelector(".nav-bar");
      this.isArrow = !this.isArrow;
      nav_bar.classList.toggle("extended");
    },
    tabChange(index) {
      this.$store.state.tabsNavegation.tabIndexMain = index;
      this.mainMessage = index;
      this.tabs.forEach(tab => {
        tab.state = tab.key === index;
      });
    },
    validateColorSelected(type ){
      if (  type === this.type ) {
        return '#fff';
      }
    },
    validateBackgroundSelected(type){
      if (   type == this.type ) {
        return '#5B6C8F';
      }
    },
    btnNext(type, val, number) {
      this.CHANGE_SELECTED(number);
      this.$router.push(`/loteria/${this.$route.params.id}/${this.$route.params.name}/${type}/${val}`);
    },
    cartillaDetail:function( index ){
      const authorization = VueCookie.get('costumer_token');
      if (authorization && this.procartillas[index] != undefined ) {
        this.RELOAD_PAGINADOR(index);
        this.$router.push('/loteria/'+this.$route.params.id+'/'+this.$route.params.name+'/detail');
      }
    },
  },
  async mounted(){
    await this.getLottery({...this.$route.params})
    this.validateOneSubscription(); //valida si tenemos una suscripción

  },
};
</script >

<style lang="css" >
  #Idprofile .tab-content {
    height: 80vh !important;
    overflow: auto;
  }
  #Idprofile ul.nav.nav-pills  {
    border: 2px solid #b68c64 !important;
   /* border-radius: 0.25rem !important;*/
  }
  #Idprofile ul .nav-item {
    margin-top: 5px;
    border-radius: 0 !important
  }
  a.active:hover {
    color: white !important;
  }
.nav-link {
  display: block;
  padding: 1rem 1rem !important;
}

.card-body {
  padding: 0 !important;
}
.is-arrow {
  display: none;
}
.icon-top { text-align: center }

@media screen and (max-width: 900px) {
  .mobile-top {
    padding: 0 14px 0 14px !important;
  }
}

.mobile-top {
  padding: 0 15px 0 15px !important;
  align-items: center;
  align-content: center;
  flex-direction: column;
  justify-content: center;
}
.mobile-top .nav-link-name {
  margin-left: 0 !important;

}
.mobile-top span {
  margin-top: 20px;
}
#left-drawer {
  /*//display: none;*/
}
@media only screen and (max-width: 767px) {

  .visible-phone {
    display: flex !important;
  }
}
@media only screen and (max-width: 767px) {

  .visible-phone-left {
    display: none !important;
  }
}

.nav-bar {
  background: white;
  padding: 15px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.08), 0 2px 2px rgba(0, 0, 0, 0.12), 0 4px 4px rgba(0, 0, 0, 0.16), 0 8px 8px rgba(0, 0, 0, 0.2);
  position: relative;
  transition: width 400ms ease;
}
.task-pane {
  display: flex;
  margin-bottom: 15px;
}

.circle {
  height: 12px;
  width: 12px;
  border-radius: 50%;
  margin-right: 10px;
  cursor: pointer;
}

.brand-icon {
  margin-bottom: 25px;
  padding-left: 17px;
  font-size: 20px;
  font-weight: bolder;
  display: flex;
  align-items: center;
}
.brand-icon .fab {
  margin-right: 15px;
}
.brand-icon .brand-text {
  opacity: 0;
}

.search-bar {
  margin-bottom: 15px;
  padding:12px;
  padding-left:17px;
  border-radius: 5px;
  margin-right: 15px;
  transition: all 600ms ease;
}
.search-bar .fa-search {
  width: 35px;
  font-size: 20px;
}
.search-bar input {
  border: 0;
  outline: 0;
  width: calc(100% - 42px);
  font-size: 15px;
  color: #fff;
  background: transparent;
  opacity: 0;
  position: absolute;
  transition: all 400ms ease;
}
a.nav-link svg {
  display: flex;
  justify-content: center;
}

.nav-link-header svg  {
  display: flex;
  justify-content: center;
}
div .nav-link-header {
  display: flex;
}
.nav-link-header {
  padding: 12px;
  border-radius: 5px;
  cursor: pointer;
  color: #2c2f32;;
  transition: background 0.4s ease;
  display: flex;
  align-items: center;
}
.nav-links .nav-link-header .nav-link-name {
  display: inline-block;
  font-size: 15px;
  position: absolute;
  margin-left: 40px;
  white-space: nowrap;
  opacity: 0;
}
.nav-links  .nav-link {
  padding: 12px;
  cursor: pointer;
  transition: background 0.4s ease;
  display: flex;
  align-items: center;
}
.nav-links  a:hover  {
  color: #b68c64 !important;
}
.nav-links .nav-link .fas,
.nav-links .nav-link .far {
  width: 35px;
  font-size: 20px;
  margin-left: 2px;
}
.nav-links .nav-link-header .fas,
.nav-links .nav-link-header .far {
  width: 35px;
  font-size: 20px;
  margin-left: 2px;
}
.nav-links .nav-link:hover {
  background: #0000;
}
.nav-links .nav-link .nav-link-name {
  display: inline-block;
  font-size: 15px;
  position: absolute;
  margin-left: 40px;
  white-space: nowrap;
  opacity: 0;
}
.nav-links .nav-link .nav-link-name:hover {

  color: #8c9196;
}
.arrow {
  position: absolute;
  height: 30px;
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  cursor: pointer;
  transition: transform 1s ease;
}
.nav-bar.extended {
  width: 270px;
}
.nav-bar.extended .arrow {
  transform: rotate(0.5turn);
  transition: all 100ms ease;
}
.nav-bar.extended .brand-text {
  opacity: 1;
  transition: all 3s ease;
}


.nav-bar.extended .nav-link-name {
  opacity: 1;
  transition: all 1s ease;
}

.nav-custom {
  box-shadow: 0 0 0 0 rgb(0 0 0 / 0);
  background: #f6f6f6;
  color: #0e0e0e !important;
  min-height: auto;
  overflow: hidden;
  min-width: auto;
  position: initial;
}
/*.dashboard-nav ul li a {
  color: #0e0e0e;
}
.dashboard-nav ul li:hover {
  color: #fff;
  border-color: inherit;
  background-color: rgba(255,255,255,0.05);
}
.dashboard-nav ul li a:hover {
  color: #fff !important;
  background: #5b6c8f;
}*/
.onFlashToggle{
  animation-duration: 1s;
  animation-name: flashToggle;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

.toogle-forecast-right {
  width: 370px;
  height: inherit;
  position: fixed;
  left: 0 !important;
  top: 120px;
  display:flex;
  z-index: 2;
  align-items: center;
}
.close_toogle_forecast_right{
  transform: translateX(-300px);
  transition-duration: 0.3s;
}

#home-tabs .col-auto .nav.nav-pills {
   display: none;
}
.titlePrincipal{
  background:#00A010;
  color:white
}
.titlePrincipal:hover {
  background:#00A010  !important;
  color:white !important;
}
.optionsPrincipal{
  background:#F7F7F7;
  color:black;
  padding: 1.2rem 1.4rem;
  border-color:#F7F7F7;
  transition: 0.3s;
  cursor: pointer;
  text-align: center;
  border-radius: .25rem;
}
.optionsPrincipal span {
  color:#5B6C8F;
}
.optionsPrincipal span:hover {
  color: white !important;
}

.card-body {
  padding: 0 1.25rem;
}
.tab-content {
  padding: 15px 30px !important;
}
ul li a.nav-link.disabled {
  background: white;
  border: 1px solid rgba(0, 0, 0, 0.125);
}
ul .nav.nav-pills.flex-column {
  border-right: 1px solid rgba(0, 0, 0, 0.125);
}

.nav-link.marker {
  //border-left: 5px solid  #c9ae77;
}
.is-active-tab {
  color: #b68c64;
  border-left: 5px solid  #b68c64;
}
.is-active-tab-top {
  color: #b68c64;
  border-bottom: 5px solid  #b68c64;
}
.tab-content {
  padding: 15px 30px !important;
}
</style>