<template>
   <div>
    <div class="container" >  
    
     
         <div style= 'display:flex; justify-content: center; '>
            <div class="opcionesEliminarTodo"   style="justify-content: center; background: #294977; padding: 10px; width: max-content;" @click="visionGeneral()">
                  <div style="color: white;"><i class="fas fa-long-arrow-alt-left"></i></div>
                  <div style="margin-left: 10px;width: max-content; "><h4 style="color: white;"> Visión general </h4></div>
            </div>
            <div class="containerCartillas">
                <div style="width: -webkit-fill-available;display: contents;" > <h1 class="visionGeneralTitulo">Cartilla Nro.</h1></div>
                <div style="width: -webkit-fill-available;display: contents;"> <h1 class="visionGeneral" >{{parseInt(paginador, 10) + 1}} de {{this.items.length}}</h1> </div>
            
                <div :style="{width:changeWidth()}" :class="{ punteroNormal: cartillaDetailStyle(index) }" class="play-card-little-default"  :key="'items'+ index" v-for="(item, index) in cartilla">
                   
                      <div v-if="cartillaBottomLength !=0" class="listShow" style='display: flex;'>
                        <h1 data-v-2ed2ad99="" style="color: rgb(117, 135, 164); font-weight: 500; font-size: revert;">{{ format_date(item.fecha) }}</h1>
                    </div>
                    <div class="listShow">
                        <div class="listNumeros" v-for="(i, index2) in cartillaTopLength" :key="'analysis-'+index2">   
                            <template v-if="pintarPairMore(index, i)">
                                <i class="fas fa-times desactiveCross stylePair" ></i>
                            </template>
                            <template v-else-if="pintarMore(index, i, 'single')">
                                <i class="fas fa-times desactiveCross  styleOrange" ></i>  
                                
                            </template> 
                            <template v-else-if="pintarLess(index, i)">
                                <i class="fas fa-times desactiveCross styleBlue" ></i>
                            </template>
                            <template v-else-if="pintarAnalisis(index, i)">
                                <i class="fas fa-times desactiveCross  styleGreen" ></i>
                            </template> 
                            <template v-else>
                                <div style="font-weight: 500;color: rgb(80, 100, 102);font-size:13px">
                                    <h1 data-v-2ed2ad99="" style="color: rgb(80, 100, 102); font-size: 20px; font-weight: 500;"> {{i}}</h1> 
                                </div> 
                            </template>
                        </div> 
                    </div>  
                   
                    <div v-if="cartillaBottomLength !=0" class="listShow" style='display: flex;'>
                        <h1 data-v-2ed2ad99="" style="color: rgb(117, 135, 164); font-weight: 500; font-size: revert;">{{loterie.subname}}</h1>
                    </div>

                    <div class="listShow">
                        <template v-if="cartillaBottomLength !=0" > 
                            <div class="listNumeros" v-for="j in cartillaBottomLength" :key="'sub-tnumber-'+j">
                                <!--<i class="fas fa-times  desactiveCross" :class="{ styleDefault: pintar_bf_analysis(index, j)}"></i>-->
                                <h1 data-v-2ed2ad99="" style="color: rgb(80, 100, 102); font-size: 20px; font-weight: 500;"> {{j}}</h1>
   
                            </div>
                        </template>
                    </div> 
                </div>
            </div> 
            <div class="containerOpciones"> 
                <div class="opcionesTituloTodo"> 
                    <div><h4 style="color: #294977; font-size: x-large;font-weight: 500;">Analisis</h4></div>
                </div>  
                 <div class="form-group">
                    <button class="btn-send-options" type="button" @click="cycleSidebarShow">
                        Ciclo
                    </button>
                </div>    
                <div class="form-group">
                    <button class="btn-send-options" type="button" @click="modaSidebarShow"># Moda</button>
                </div>          
                <div class="form-group">
                    <button class="btn-send-options" type="button" @click="topSidebarShow"><i class="fas fa-plus"></i> Frec </button>
                </div>
                <div class="form-group">
                    <button class="btn-send-options" type="button" @click="lessSidebarShow"><i class="fas fa-minus"></i> Frec </button>
                </div>
                <div class="form-group">
                    <button class="btn-send-options" type="button" @click="morePairSidebarShow">Pares <i class="fas fa-plus"></i> Frec </button>
                </div>
                <div style="display:flex">
                    <div class="opcionesEliminarTodo"  style="justify-content: center; background: #294977; padding: 10px; width: -webkit-fill-available;" @click="prevCartilla()">
                      <div style="color: white;"><i class="fas fa-long-arrow-alt-left"></i></div>
                      <div style="margin-left: 10px; "><h4 style="color: white;">Retroceder </h4></div>
                    </div>
                    <div class="opcionesEliminarTodo"  style="justify-content: center; background: #294977; padding: 10px; width: -webkit-fill-available;" @click="nextCartilla()">
                      <div style="margin-left: 10px; "><h4 style="color: white;">Continuar </h4></div>
                      <div style="color: white; margin-left:20px"><i class="fas fa-long-arrow-alt-right"></i></div>
                    </div>
                </div>  
            </div>  
        </div>
    </div>

  </div>
</template>

<script>



import { mapActions, mapState } from 'vuex'
import moment from 'moment';
import $ from 'jquery' 
import VueCookie from 'vue-cookie' 


export default { 
    name: 'DetailTwoAnalysis',
    data(){
        return { 
            info: {}, 
            date: {
                from: '',
                to: ''
            },
            cartilla: [],
            paginador: 0
        }
    },
    components: {

    }, 
    computed: {
        ...mapState({ 
            datamore: state => state.lotto.more, 
            dataless: state => state.lotto.less, 
            pairmore: state => state.lotto.pairmore, 
            items: state => state.analysis.items_range, 
            cartillaBottomLength: state => state.analysis.cartillaBottomLength,
            cartillaTopLength: state => state.analysis.cartillaTopLength,
            loterie: state => state.lottery.lottery,
            costumer: state => state.costumer.costumer,
 
         }) 
    }, 
    mounted(){ 
      this.getLotery() 
      this.getLoterie_()
      this.getCartilla()
    },
    created() {

    },
    methods: {
        visionGeneral() {
                this.$router.push('/analisis/'+this.$route.params.id+'/'+this.$route.params.name);   
        },
        prevCartilla(){
            let index = parseInt(this.$route.params.index) - 1;
            let length = this.items.length;
            if ( index >= 0  && index < length ) { 
                this.$router.push('/analisis/'+this.$route.params.id+'/'+this.$route.params.name+'/'+index);  
                let data = this.items[index] 
                this.cartilla = []
                this.paginador = index
                this.cartilla.push(data)
            }
        },
        nextCartilla() {
            let index = parseInt(this.$route.params.index) + 1; 
            let length = this.items.length;
            if ( index < length && index >= 0) {
                this.$router.push('/analisis/'+this.$route.params.id+'/'+this.$route.params.name+'/'+index);  
                let data = this.items[index] 
                this.paginador = index 
                this.cartilla = []
                this.cartilla.push(data)
            } 
        },
        getCartilla() { 
            let data = this.items[parseInt(this.$route.params.index)] 
            this.paginador = parseInt(this.$route.params.index)
            this.cartilla.push(data)
        },
        mostrarCartillaNumero:function( index ){
          if ( this.cartilla[index] == undefined) {
            return true;
          }
        },
        cartillaDetailStyle:function( index ){ 
            if ( this.cartilla[index] == undefined ) { 
                return true;
            }   
        },

        cartillaDetail(index) {
            const authorization = VueCookie.get('costumer_token'); 
            if (authorization) { 
                this.$router.push('/analisis/'+this.$route.params.id+'/'+this.$route.params.name+'/'+index);  
            }   
        },
        changeWidth()  {
            if (this.$route.params.name != 'tinkas'){
                return 135 + 'px';
            } else {
                return 111 + 'px';
            }
        },
        modaSidebarShow() { 
            $("#modaSidebarContent").css('display', 'flex')   
            $("#cycleSidebarContent").css('display', 'none')  
            $("#topSidebarContent").css('display', 'none')
            $("#lessSidebarContent").css('display', 'none')
            $("#morePairSidebarContent").css('display', 'none')
        },
        cycleSidebarShow() { 
            $("#cycleSidebarContent").css('display', 'flex')  
            $("#topSidebarContent").css('display', 'none')
            $("#lessSidebarContent").css('display', 'none')
            $("#morePairSidebarContent").css('display', 'none')
            $("#modaSidebarContent").css('display', 'none')   
        },
        topSidebarShow() { 
            $("#cycleSidebarContent").css('display', 'none')  
            $("#topSidebarContent").css('display', 'flex')
            $("#lessSidebarContent").css('display', 'none')
            $("#morePairSidebarContent").css('display', 'none') 
            $("#modaSidebarContent").css('display', 'none')   
        },
        lessSidebarShow() {
            $("#cycleSidebarContent").css('display', 'none')
            $("#morePairSidebarContent").css('display', 'none')
            $("#lessSidebarContent").css('display', 'flex') 
            $("#modaSidebarContent").css('display', 'none')  
            $("#topSidebarContent").css('display', 'none')   
        },
        morePairSidebarShow() {
            $("#cycleSidebarContent").css('display', 'none')   
            $("#topSidebarContent").css('display', 'none')
            $("#lessSidebarContent").css('display', 'none')
            $("#morePairSidebarContent").css('display', 'flex') 
            $("#modaSidebarContent").css('display', 'none')   
        }, 
        format_date(value) {
            if (value) {
                return moment(String(value)).format('YYYY-MM-DD')
            }
        }, 
        ...mapActions({ 
            rangeAnalysisDetail: 'rangeAnalysisDetail',
            rangeAnalysis: 'rangeAnalysis',
            getLoterie: 'getLoterie'
        }),
        async rangePrimer(){
            this.date.name = this.$route.params.name
            await this.rangeAnalysis(this.date)
        }, 
        async getLoterie_() {
            await this.getLoterie( this.$route.params )  
        },
        async getLotery() { 
            this.info = this.$route.params
            await this.rangeAnalysisDetail({ from: this.$route.query.from, to: this.$route.query.to, name: this.$route.params.name }) 
        }, 
        containerLoterie() { 
            return this.$route.params.name == 'tinkas' ? true: false; 
        },
        pintarDefault() {

        },
        addPena(){
            this.cartilla.push({
                numbers : [],
                bExtra  : null 
            });
            this.total = this.cartilla.length * this.price
        }, 
        deletePena(index){ 
            this.cartilla.splice(index,1);
            this.total = this.cartilla.length * this.price
        },
        pintarLess(index, i) {
            for(let j = 0; j < this.cartilla[index].numbers.length; j++){
                if(i == this.cartilla[index].numbers[j]){ 
                    for (let k = 0; k < this.dataless.length; k++) {
                        if (i == this.dataless[k].position) { 
                            return true
                        }
                    } 
                }
            }
        }, 
        pintarMore(index, i, type){  
            if (type == 'single') {
                for(let j = 0; j < this.cartilla[index].numbers.length; j++){
                    if(i == this.cartilla[index].numbers[j]){ 
                        for (let k = 0; k < this.datamore.length; k++) {
                            if (i == this.datamore[k].position) { 
                                return true
                            }
                        } 
                    }
                }
            }   
        },
        pintarPairMore(index, i) {
            for(let j = 0; j < this.cartilla[index].numbers.length; j++){
                if(i == this.cartilla[index].numbers[j]){ 
                    
                    for (let k = 0; k < this.pairmore.length; k++) {
                    let val1 = this.cartilla[index].numbers.includes(this.pairmore[k].val1)
                    let val2 = this.cartilla[index].numbers.includes(this.pairmore[k].val2)
                    let flat = [this.pairmore[k].val1 , this.pairmore[k].val2].includes(i) 
    
                        if (val1) {
                            if (val2) { 
                                if (flat) {
                                    return true
                                } 
                            }
                        } 
                    } 
                }
            }
        },
        pintarAnalisis(index, i) {  
            for(let j = 0; j < this.cartilla[index].numbers.length; j++){
                if(i == this.cartilla[index].numbers[j]){ 
                    return true  
                }
            }
        },
        pintar(index, i){ 
            for(let j = 0; j < this.cartilla[index].numbers.length; j++){
                if(i == this.cartilla[index].numbers[j]){  
                        return true 
                }
            }
        }, 
        pintar_bf_analysis(index, j){
            if(j == this.cartilla[index].bRed){
                return true
            }
        }, 
    }
}
</script>
<style lang="css" scoped>
     .btn-send-options{
      cursor: pointer;
      display: flex;
      width: 100%;
      font-size: 18px;
      font-weight: 500;
      align-items: center;
      justify-content: center;
        background: white;
        color: #294977 !important;
      border-radius: 7px;
      margin: 5px;
      box-shadow: 0px 0px 2px black;
      padding: 10px;
    }
    .btn-send-options:hover {
      background: #294977 !important;  
      color: white !important;
    }
    
    .col {
        width: 100%;
        padding: 12px;
    }
    .row {
        display: flex; 
    }
     .activeCross { 
      color: #506466 !important
    }
    .desactiveCross{
      float: left;
      margin-left: 0px;
      margin-top: -3px;
      color: transparent;
      font-size: 20px;
    }
    .fa {
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    }
    close:before, .fa-times:before {
        content: "\f00d";
    }

    .fa-remove:before, .fa-close:before, .fa-times:before {
    content: "\f00d";
    }
    .b-sidebar-outer {
        z-index: 1071 !important;
    }
    .b-sidebar-outer {
        position: fixed !important;
        top: 0 !important;
        left: 0 !important;
        right: 0 !important;
        height: 0 !important;
        overflow: visible !important;
        z-index: 1035 !important;
    }
    .b-sidebar.b-sidebar-right {
        left: auto !important;
        right: 0 !important;
    }
    .b-sidebar {
        display: flex !important;
        flex-direction: column !important;
        width: 320px !important;
        max-width: 100% !important;
        max-height: 100% !important;
        margin: 0 !important;
        outline: 0 !important;
        -webkit-transform: translateX(0) !important;
        transform: translateX(0) !important;
    }
    .b-sidebar, .b-sidebar-backdrop {
        position: fixed !important;
        top: 0 !important;
        height: 100vh !important;
    }
    .b-sidebar>.b-sidebar-header {
        font-size: 1.5rem !important;
        padding: .5rem 1rem !important;
        display: flex !important;
        flex-direction: row !important;
        flex-grow: 0 !important;
        align-items: center !important;
    }
    
    .containerCartillas .listShow .listNumeros i.stylePair {
        color: #AB853A;
        width: 60px;
        height: 60px; 
        margin-top: -14px;
        font-size: 94px; 
    } 
    .containerCartillas .listShow .listNumeros i.styleOrange {
        color: #68001C;
        width: 60px;
        height: 60px; 
        margin-top: -14px;
        font-size: 94px; 
    }
    .containerCartillas .listShow .listNumeros i.styleBlue {
        color: #027B76;
        margin-top: -14px;
        width: 60px;
        height: 60px; 
        font-size: 94px; 

    } 
    .containerCartillas .listShow .listNumeros i.styleGreen {
        color: #10811a;
        width: 60px;
        height: 60px; 
        margin-top: -14px;
        font-size: 94px; 
    }
    .containerCartillas .listShow .listNumeros i.styleDefault {
       /*  color: rgba(51, 55, 69, 0.8);*/
        width: 60px;
        height: 60px; 
        font-size: 94px;
        margin-top: -14px; 
    }
    .play-card-little {
        -ms-flex: 0 0 10%;
        flex: 0 0 10%; 
        position: relative;
        padding: 0 2px;
        cursor: pointer;
    }
    .play-card-little-default { 
        max-width: 540px;
        width: 540px;
        min-width: 540px;  
        box-shadow: 0px 0px 1px black; 
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: row;
        flex-wrap: wrap;
        background: #eaf0f5; 
        margin: 10px;
        border-radius: 7px;
        padding-bottom: 10px;
        padding-top: 10px;
        padding-right: 5px;
        padding-left: 5px;
    }
    .play-card-little-tinkas {
        max-width: 200px !important;
        width: 200px !important;
        min-width: 200px !important; 
        
        box-shadow: 0px 0px 1px black;
        /* border: 1px solid #e6d7d7; */
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: row;
        flex-wrap: wrap;
        background: white;
       
        margin: 10px;
        border-radius: 7px;
        padding-bottom: 10px;
        padding-top: 10px;
        padding-right: 5px;
        padding-left: 5px;
    }
    .modalRegenerarColumna{
          cursor: pointer; 
          text-align: -webkit-center;
          box-shadow: black 0px 0px 3px;
          border-radius: 7px;
          font-weight: 700;
          color: #294977;
          padding: inherit;
          width: 80px;
          height: 80px;
          margin: 9px;
    }

    .modalRegenerarColumna:hover {
          background: #294977; 
          color: white; 
    }

    .modalRegenerarTitulo{
      color: #294977;
      color: rgb(41, 73, 119);
      font-size: larger;
      font-weight: 700;
    }
    .containerCartillaOpciones{  
      display: flex; 
       
    }
    .containerOpciones{
      display: flex;  
      flex-direction: column;
      flex-wrap: wrap; 
    }
    .opcionesTituloTodo{ 
        display: flex; 
        align-items: center;
        justify-content: center;  
        height: max-content;
        border-radius: 7px;
        margin: 5px;
    }
    .opcionesRegenerar{
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        height: -webkit-max-content;
        height: -moz-max-content;
        height: max-content;
        border-radius: 7px;
        margin: 5px;
        box-shadow: 0px 0px 2px black;
        padding: 10px;
    }
    .opcionesRegenerar:hover {
      background: #294977; 
      color:white
    }
    .textoOpciones{ 
      color:#294977;
      font-weight: 500;
    }

    .opcionesRegenerar:hover div h4 { 
      color:white
    }

     
    .opcionesEliminarTodo {
      cursor:pointer;
      display: flex; 
      align-items: center; 
      height: max-content;
      border-radius: 7px;
      margin: 5px;
    }
    .desactiveBoton{
      cursor:not-allowed;
    }
    .activeCross { 
      color: #506466 !important
    }
   
    .containerCartillas{
      display: flex; 
      flex-direction: row;  
      flex-wrap: wrap;
      justify-content: center;
      font-size: 15px; 
      text-align: center;  
      padding: 3px;
    }
     
    .punteroNormal{
      cursor:context-menu !important;
    }
 
    .listCartillas{
        cursor:pointer;
        box-shadow: 0px 0px 5px black;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: row;
        flex-wrap: wrap;
        background: white; 
        margin: 10px;
        border-radius: 7px;
        padding-bottom: 10px;
        padding-top: 10px;
        padding-right: 5px;
        padding-left: 5px;
    }

     
    .listCartillas:hover { 
        background: #dfdfdf; 
    } 
    .listNumeroCartillas{ 
        color: #D1D8E3;
        font-size: 70px; 
    } 
    .listCartillas:hover div h1{ 
        color: white;
    }  
    .listShow {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: row;
        flex-wrap: wrap;
    }
    .listNumeros {
        background: #FFF;
        width: 60px;
        height: 60px;
        margin-right: 4px;
        margin-left: 5px;
        margin-top: 9px;
        border-radius: 16px;
    }
</style>
 
<style scoped>
 .btn-send{
    background-color: #66676b;
    top: 0;
    padding: 9px 20px;
    color: #fff;
    position: relative;
    font-size: 15px;
    font-weight: 600;
    display: inline-block;
    transition: all 0.2s ease-in-out;
    cursor: pointer;
    margin-right: 6px;
    overflow: hidden;
    border: none;
    border-radius: 0px;
 }
 .btn-send:hover {
       background-color: #7a7c85 !important;
       border: 0px !important;
       color: white !important;
 }
 #TopMoreTable { 
  border-collapse: collapse;
  width: 100%;
}
  #TopMoreTable #TopLessTable, #TopLesspairTable ,#TopMorepairTable,  thead tr th, tbody tr td  {
    padding: 5px;
    text-align: center;
  }
 @media (min-width: 1367px) {
    .container {
        width: 1310px;
    }
 }
 
 
    .active_content {
        border: 1px solid #bebec1 !important;
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.15);
    }
    .play-card {
        -ms-flex: 0 0 20%;
        flex: 0 0 20%;
        max-width: 20%;
        position: relative;
        padding: 0 4px;
        cursor: pointer;
    } 
    .fa {
        display: inline-block;
        font: normal normal normal 14px/1 FontAwesome;
        font-size: inherit;
        text-rendering: auto;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }
  close:before, .fa-times:before {
      content: "\f00d";
  }

  .fa-remove:before, .fa-close:before, .fa-times:before {
    content: "\f00d";
  }
  .play-card-inner {
    border: 1px solid #e8e8ea;
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.15);
    overflow: hidden;
    border-radius: 15px;
    -webkit-border-radius: 15px;
    -moz-border-radius: 15px;
    -ms-border-radius: 15px;
    -o-border-radius: 15px;
}
.text-center {
    text-align: center!important;
}
.play-card-header {
    padding: 15px 10px 10px 10px;
}
 
 
 .single-body-inner {
    margin: 0 -4px;
}
.d-flex {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}
.d-flex {
    display: -ms-flexbox!important;
    display: flex!important;
}

*, ::after, ::before {
    box-sizing: border-box;
}

.play-card-inner {
    border: 1px solid #e8e8ea;
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.15);
    overflow: hidden;
    border-radius: 15px;
    -webkit-border-radius: 15px;
    -moz-border-radius: 15px;
    -ms-border-radius: 15px;
    -o-border-radius: 15px;
}
 
 

.close-play-card {
    padding: 0px;
    position: absolute;
    top: -10px;
    right: 0;
    width: 30px;
    height: 30px;
    background-color: #ffffff;
    box-shadow: 0 0 0px 1px rgba(0, 0, 0, 0.15);
    color: #333745;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
}
 

.play-card-header .number-amount {
    color: #34b4f5;
    font-style: normal;
}

.play-card-header {
    padding: 15px 10px 10px 10px;
}
 
*, ::after, ::before {
    box-sizing: border-box;
}

 
body {
    margin: 0;
    font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    text-align: left;
    background-color: #fff;
}

 
.number-list li {
    width: 30px;
    height: 30px;
    color: var(--heading-color);
    border: 1px solid #9fa1a7;
    font-size: 14px;
    text-align: center;
    line-height: 30px;
    display: inline-block;
    margin: 3px;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
}
  
  .number-list li.active {
      background: #0f8e1b; 
      color: #ffffff; 
  }
  /*
  ul li {
      line-height: 20px;
      padding: 0px 0;
  }*/
  .play-card-header .header-btn-area button {
      background-color: transparent;
      color: rgba(51, 55, 69, 0.8);
      text-transform: capitalize;
      padding: 3px 15px;
      border-radius: 999px;
      -webkit-border-radius: 999px;
      -moz-border-radius: 999px;
      -ms-border-radius: 999px;
      -o-border-radius: 999px;
  }
   
  .button-next {
    padding: 15px;
    color: black;
    width: 45px;
    height: 45px;
    background-color: transparent;
    border: 1px solid #efefef;
    text-align: center;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    -webkit-border-radius: 50%;
  }
  .d-flex {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  }
  .card-header button {
    color: white;
    background: rgba(91, 108, 143, 1);
  }
  .item-children {
    cursor: pointer;
    margin:2px !important
  }

  .item-children-v2 {
    cursor: pointer; 
    margin: 0px 3px 0px 3px;
    border-radius: 0% !important;
    -webkit-border-radius: 0% !important;

  }
  li {
      display: list-item;
      text-align: -webkit-match-parent;
  }
 

.play-card-body .number-list li.active {
    background-color: #32a842;
    color: #ffffff;
}

.activehot {
  color: #68001C !important;
}

.activecold {
    color: #027B76 !important;
   
}

.activepar {
    color: #AB853A !important; 
}

.play-card-body .number-list li.activecartilla {
    background-color: #8F8F8F;
    color: #ffffff;
    text-shadow: 2px 1px #0d0e12;
}

.play-card-body .number-list li.activealeatorio {
    background-color: #32a842;
    color: #ffffff;
    text-shadow: 2px 1px #0d0e12;
}

.play-card-body .number-list li.activecartillaagrupacionM1 {
    background-color: #8F8F8F;
    color: #ffffff;
    text-shadow: 2px 1px #0d0e12;
}
.play-card-body .number-list li.activecartillaagrupacionM2 {
    background-color: #8F8F8F;
    color: #ffffff;
    text-shadow: 2px 1px #0d0e12;
}

.play-card-body .number-list li.activeM1celeste {
    background-color: #F76300;
    color: #ffffff;
    text-shadow: 2px 1px #0d0e12;
}

.play-card-body .number-list li.activeM1amarillo {
    background-color: #DC001A;
    color: #ffffff;
    text-shadow: 2px 1px #0d0e12;
}

.play-card-body .number-list li.activeM1naranja {
    background-color: #660001;
    color: #ffffff;
    text-shadow: 2px 1px #0d0e12;
}

.play-card-body .number-list li.activeM2celeste {
    background-color: #F76300;
    color: #ffffff;
    text-shadow: 2px 1px #0d0e12;
}

.play-card-body .number-list li.activeM2amarillo {
    background-color: #DC001A;
    color: #ffffff;
    text-shadow: 2px 1px #0d0e12;
}

.play-card-body .number-list li.activeM2naranja {
    background-color: #660001;
    color: #ffffff;
    text-shadow: 2px 1px #0d0e12;
}

.play-card-body .number-list li.activeM3celeste {
    background-color: #F76300;
    color: #ffffff;
    text-shadow: 2px 1px #0d0e12;
}

.play-card-body .number-list li.activeM3amarillo {
    background-color: #DC001A;
    color: #ffffff;
    text-shadow: 2px 1px #0d0e12;
}

.play-card-body .number-list li.activeM3naranja {
    background-color: #660001;
    color: #ffffff;
    text-shadow: 2px 1px #0d0e12;
}

.play-card-body .number-list li.activecartillaagrupacionM3 {
    background-color: #8F8F8F;
    color: #ffffff;
    text-shadow: 2px 1px #0d0e12;
}

.play-card-body .number-list li.activecartillaagrupacion {
    background-color: #8F8F8F;
    color: #ffffff;
    text-shadow: 2px 1px #0d0e12;
}

.play-card-body .number-list li {
    width: 26px;
    height: 26px; 
    border: none;
    background-color: #f5f5f6;
    margin-top: 5px;
    margin-bottom: 5px;
    color: rgba(51, 55, 69, 0.8);
    cursor: pointer;
}


 
 
</style>