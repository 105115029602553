import ApiClient from '../source/Api'

const loterie = {
    findMore(searchParams) {
        return ApiClient().get(`/analytic/more?id=${searchParams.id}&name=${searchParams.name}&type=${searchParams.type}`)
    },
    findLess(searchParams) { 
        return ApiClient().get(`/analytic/less?id=${searchParams.id}&name=${searchParams.name}&type=${searchParams.type}`)
    },
    findMorePair(searchParams) { 
        return ApiClient().get(`/analytic/more-pair?id=${searchParams.id}&name=${searchParams.name}&type=${searchParams.type}`)
    },
    findMoreTrio(searchParams) {
        return ApiClient().get(`/analytic/more-trio?id=${searchParams.id}&name=${searchParams.name}&type=${searchParams.type}`)
    },
    findCiclo(searchParams) {
        return ApiClient().get(`/analytic/cycle?id=${searchParams.id}&name=${searchParams.name}`)
    },
    findBimestre(searchParams) {
        return ApiClient().get(`/analytic/bimestre?id=${searchParams.id}&name=${searchParams.name}&from=${searchParams.from}&to=${searchParams.to}&appearance=${searchParams.appearance}&type=${searchParams.type}`)
    },
    findModa(searchParams) {
        return ApiClient().get(`/analytic/moda?id=${searchParams.id}&name=${searchParams.name}&type=${searchParams.type}`)
    }
}

export default loterie;