import resultAPI from '../../api/result'
   
const state = { 
    showResult:false, 
    constellations:[],
    fechaAnalitica:[],  
}
const actions = {  
     
    async constellationSame({commit}, {...searchParams}) {  
        const { status, data: data } = await resultAPI.constellationSame(searchParams);  
        if(status === 200) commit("SHOW_CONSTELLATION", data)   
    },  
    async fetchTopResult({commit}, {...searchParams}) {   
        const { status, data: data } = await resultAPI.topResult(searchParams);    
        if(status === 200)    
        commit("SHOW_RESULT", data.ok);
        return data.top
    },
    async fetchRangeResult({commit}, {...searchParams}) {
        const { status, data: data } = await resultAPI.fetchRangeResult(searchParams);  
        if(status === 200) 
        commit("TOP_RESULT_RANGE" )
        return data.top;
        
    },  
    async allInResult({commit}, {...searchParams}) {
        const { status, data: data } = await resultAPI.allInResult(searchParams);  
        if(status === 200) 
            commit("TOP_RESULT_CONSTELLATION_RANGE") 
        return data.top;
    },    
    async searchBallResult({commit}, {...searchParams}) {
        const { status, data: data } = await resultAPI.searchBallResult(searchParams);  
        if(status === 200) 
            commit("TOP_RESULT_RANGE_BALL") 
        return data.top;
        
    },  
     
}

const getters = {
 
}

const mutations = {
    
    //AÑADE AL POP UP LAS FECHAS QUE TIENEN UNA CONSTELACION SIMILAR Y MUESTRA EL POP UP SI HAY CONTENIDO EN EL ARREGLO
    SHOW_CONSTELLATION(state, data){
        state.constellations = [];
        state.constellations = data.loteria;
        // console.log(data.loteria);
    },
     
    // PARTE DEL TOP_RESULT Y MUESTRA EL MODULO DE RESULTADOS O NO
    SHOW_RESULT( state, data ){
        state.showResult = data //CAMBIAR ESTO POR UNO DE STORE VALIDATE
    },
    // OBTENCION DE CARTILLAS FILTRANDO FECHAS 
    TOP_RESULT_RANGE( ) {
        
    }, 
    // OBTENCION DE CARTILLAS FILTRANDO FECHAS Y BOLILLA
    TOP_RESULT_RANGE_BALL() {
        
    }, 

    //OBTENCION DE CARTILLAS ADELANTE Y ATRAS ( CONSTELACION ), AGREGANDOLO A LA LISTA DE CARTILLAS ANTERIORES
    TOP_RESULT_CONSTELLATION_RANGE( ) {
         
    }, 
    //VEMOS LA CONSTELACION DE RESULTADO, OBTENIENDO LA FECHA EN CUADRO DE FRECUENCIAS
    DATE_APPEARANCE(state, data){ 
        state.fechaAnalitica = [];
        state.fechaAnalitica.push(data);
    },
    //PARTE DEL PROCESO DE DATE_APPERANCE, PARA QUE EL UPDATE() NO SIGA DISPARANDOSE
    DATE_APPEARANCE_CLOSE(state ){ 
        state.fechaAnalitica = []; 
    },
    RESULT_LIST_NORMAL(state, data){
        state.resultCartillasNormal = data;
    },
    RESULT_LIST_LAGGARDS(state, data){
        state.resultCartillasLaggards = data;
    },
    
}

export default { 
    state,
    actions,
    getters,
    mutations
}
