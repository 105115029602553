import ApiClient from '../source/Api'
  
const subscription =  {  
    createOneSubscription( searchParams ) {  
        return ApiClient().post(`/subscriptions/create`, searchParams ) 
    },
    renovateOneSubscription( searchParams ) {  
        return ApiClient().post(`/subscriptions/renovate`, searchParams ) 
    },
    validateOneSubscription() {  
        return ApiClient().get(`/subscriptions/validate`)  
    },  
    timeOneSubscription() {  
        return ApiClient().get(`/subscriptions/timeSubscription`)
    }   
}

export default subscription; 