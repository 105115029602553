<template>
  <div class="container">
    <carousel
      :autoplay="false"
      :nav="false"
      :loop="false"
      :dots="false"
      :items="4"
    >
      <section v-bind:key="index" v-for="(item, index) in mainLoteries">
        <div
          style="margin: 15px"
          class="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-7c28a103"
          data-id="7c28a103"
          data-element_type="column"
        >
          <div class="elementor-column-wrap elementor-element-populated">
            <div class="elementor-widget-wrap">
              <div
                class="elementor-element elementor-element-6e5a1c95 elementor-widget elementor-widget-listeo-iconbox"
                data-id="6e5a1c95"
                data-element_type="widget"
                data-widget_type="listeo-iconbox.default"
              >
                <div class="elementor-widget-container">
                  <div class="icon-box-2">
                    <a
                      @click="showAnalysis(item)"
                      ref="authenticated"
                      style="display: flex; justify-content: center"
                    >
                      <img v-bind:src="item.image" style="width: 200px" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </carousel>
  </div>
</template> 
<script>
import { mapActions, mapState } from "vuex";
import carousel from "vue-owl-carousel";
export default {
  name: "ResultList",
  components: {
    carousel,
  },
  data() {
    return {
      sortBy: "name",
      sortDesc: true,
      fields: [
        {
          key: "name",
          label: "Nombre",
          formatter: "assignNames",
          sortable: true,
        },
        {
          key: "ball_one",
          label: "Loteria Resultado",
          formatter: "assignResults",
          sortable: true,
        },
        { key: "date", label: "Fecha", sortable: true },
      ],
    };
  },
  computed: {
    ...mapState({
      mainLoteries: (state) => state.lotto.mainLoteries,
      costumer: (state) => state.costumer.costumer,
    }),
  },
  mounted() {
    // this.getLoteriesMain()
  },
  methods: {
    ...mapActions({
      //    getLoteriesMain: 'getLoteriesMain'
    }),

    showAnalysis(item) {
      //const authorization = VueCookie.get('costumer_token');
      //console.log("authorization", authorization);
      //if (authorization) {
      this.$router.push("resultados/" + item.id + "/" + item.name);
      /*} else {
                console.log("else");
                 this.$root.$emit('bv::show::modal', 'authenticated', '#btnShowAnalysis')
             }*/
    },
  },
};
</script>

<style>
table th {
  text-align: center;
}
</style>