<template>
  <div>
    <div class="transition transition-1 is-active-transition" style="display: flex; align-content: center; align-items: center; justify-content: center">
      <img src="/uploads/favicon.png" alt="Lotery" title="Lotery" class="normal-logo" style="width: 60px;">
      <span id="logo" style="margin-left: 2%;
          font-family: 'Open Sans';
          text-transform: uppercase;
          letter-spacing: 5px;
          font-weight: 300;
          font-size: 50px;
          color: white;">IA LOTTERY </span>
    </div>
    <span class="desktop">
        <header>
          <span id="logo"  >
            <img src="/uploads/favicon.png" alt="Lotery" title="Lotery" class="normal-logo" style="width: 50px;">
            IA LOTTERY
          </span>
          <nav>
            <a :class="{ 'is-active': tabs[0].state }" @click="tabChange(0, '')" class="is-active-transition"> Quienes somos </a>
            <a :class="{ 'is-active': tabs[1].state }" @click="tabChange(1, 'questions')"> Preguntas frecuentes </a>
            <a :class="{ 'is-active': tabs[2].state }" @click="tabChange(2, 'ticket')"> Boleto Escaneado </a>
            <a :class="{ 'is-active': tabs[3].state }" @click="tabChange(3, 'support')"> Soporte </a>
            <a :class="{ 'is-active': tabs[4].state }" @click="tabChange(4, 'promotion')"> Promociones </a>
          </nav>
        </header>
      </span>
      <!--<span class="mobile">
        <header>
          <span id="logo">IAL</span>
          <div class="button_container">
            <span class="top"></span>
            <span class="middle"></span>
            <span class="bottom"></span>
          </div>
        </header>
      </span>-->
    <div class="body-content" style="height: 100vh; display: flex; flex-direction: column;">
        <div class="wrapper" style="flex: 1">
            <div class="landing" style="display: flex; justify-content: center; align-items: center; align-content: center">
                <router-view></router-view>
            </div>
        </div>
        <Footer style="background: #1f2027; border-top: 1px solid rgb(46, 48, 56);  z-index: 100"></Footer>
    </div>

  </div>
</template>

<script>
  import jQuery from 'jquery'
  import Footer from '../include/Copyright'
  import VueCookie from "vue-cookie";
  export default {
    name: 'MainHome',
    data() {
      return {
        tabs: [
          { key: 0,  state: true },
          { key: 1,  state: false },
          { key: 2,  state: false },
          { key: 3,  state: false },
          { key: 4,  state: false },
        ]
      }
    },
    methods: {
      tabChange(index, name) {
        this.$router.push(`/${name}`)
        const transition_el = document.querySelector(".transition");
        transition_el.classList.add('is-active-transition');
        setTimeout(() => {
          transition_el.classList.remove('is-active-transition');
          this.tabs.forEach(tab => {
            tab.state = tab.key === index;
          });
        }, 800);
      },
    },
    components: {
      Footer
    },
    created() {
      const transition_el = document.querySelector(".transition");
      setTimeout(() => {
        if (transition_el)  transition_el.classList.remove('is-active-transition');
      }, 1000);
    },
    mounted() {
       const authorization = VueCookie.get('costumer_token');
         if (authorization) {
           this.$router.push(`/loterias`)
         } else {
      const transition_el = document.querySelector(".transition");
      setTimeout(() => {
        transition_el.classList.remove('is-active-transition');
      }, 1000);
      jQuery(document).ready(function($) {
        window.onscroll = function() {
          if ($(this).scrollTop() > 1) {
            $('header').addClass("resize");
          } else {
            $('header').removeClass("resize");
          }
        };
        $(window).scroll(function() {
          $('.hideme').each(function() {
            let bottom_of_object = $(this).offset().top + $(this).outerHeight();
            let bottom_of_window = $(window).scrollTop() + $(window).height();
            if (bottom_of_window > bottom_of_object) {
              $(this).animate({
                'opacity': '1'
              }, 1250);
            }
          });
        });
        $(".mouseScroll").click(function() {
          $('html, body').animate({
            scrollTop: $(".about").offset().top - 150
          }, 800);
        });
      });
       }
    }
  }
</script>

<style scoped >
  .pt-page-rotateRoomBottomIn {
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
    -webkit-animation: rotateRoomBottomIn .8s both ease;
    animation: rotateRoomBottomIn .8s both ease;
  }
  @-webkit-keyframes rotateRoomBottomIn {
    from { opacity: .3; -webkit-transform: translateY(-100%) rotateX(-90deg); }
  }
  @keyframes rotateRoomBottomIn {
    from { opacity: .3; -webkit-transform: translateY(-100%) rotateX(-90deg); transform: translateY(-100%) rotateX(-90deg); }
  }
  /* transition {
     animation: transitionIn 1s;
   }*/
  @keyframes transitionIn {
    from {
      opacity: 0;
      transform: rotateX(-10deg);
    }
    to {
      opacity: 1;
      transform: rotateX(0);
    }
  }
  :root {
    --primary: #AF1E2D;
    --light: #F3F3F3;
    --grey: #8A8A8B;
    --dark: #212121;
  }
  .transition-1 {
    position: fixed;
    top: 100%;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 101;
    background-color: rgb(31, 32, 39);
    transition: 0.5s ease-out;
  }
  .transition-1.is-active-transition {
    top: 0;
    /*
     transition: 0.3s ease-out;
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
    -webkit-animation: rotateRoomTopIn .8s both ease;
    animation: rotateRoomTopIn .8s both ease;
    */
    /*animation: transitionIn 0.75s;
    /*opacity: 1;
    pointer-events: all;*/
  }
  html,
  body {
    background: rgba(11, 12, 19, 1);
    padding: 0;
    margin: 0;
    font-family: 'Montserrat';
  }
  body {
    overflow: auto;
  }
  body.loaded {
    overflow-y: auto;
    overflow-x: auto;
  }
  .wrapper {
    padding: 0;
    margin: 0;
    overflow: hidden;
  }
  .loading {
    background: rgba(16, 17, 24, 1);
    height: 100vh;
    overflow: hidden;
    width: 100%;
    position: absolute;
    text-align: center;
    z-index: 1001;
    animation: transitionIn 1s;
  }
  .svg-wrapper {
    height: 150px;
    margin: 0 auto;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    width: 150px;
  }
  .animate {
    fill: transparent;
    stroke-dasharray: 300;
    stroke-dashoffset: 600;
    stroke-width: 1px;
    stroke: #fdad59;
  }
  .text {
    color: #fff;
    display: inline-block;
    position: relative;
    top: -110px;
  }
  .logo {
    display: block;
    letter-spacing: 10px;
    font-size: 22px;
    padding-bottom: 5px;
    border-bottom: 1px solid #fff;
    font-family: 'Cinzel';
  }
  .motto {
    display: block;
    padding-top: 10px;
    font-family: 'Cinzel';
    font-size: 9px;
    letter-spacing: 3px;
  }
  /*Desktop navigation*/
  @media screen and (min-width: 610px) {
    .mobile {
      display: none;
    }
    header {
      width: 100%;
      height: 100px;
      line-height: 100px;
      position: fixed;
      -webkit-transition: all .5s;
      transition: all .5s ease;
      z-index: 1;
    }
    header #logo {
      float: left;
      margin-left: 5%;
      font-family: 'Open Sans';
      text-transform: uppercase;
      letter-spacing: 5px;
      font-weight: 300;
      font-size: 30px;
      color: #fdad59;
    }
    header nav {
      float: right;
      margin-right: 5%;
    }
    header nav a {
      position: relative;
      color: #a3a3a3;
      font-size: 18px;
      font-weight: 300;
      font-family: 'Montserrat';
      text-decoration: none;
      margin-left: 50px;
      border-bottom: 2px solid rgba(255, 255, 255, 0);
      transition: color .25s ease;
    }
    nav a:hover {
      color: white;
    }
    header.resize {
      height: 70px;
      line-height: 70px;
      background: rgb(31, 32, 39);
      border-bottom: 1px solid rgb(46, 48, 56);
    }
  }
  .is-active {
    color: #fdad59;
  }
  @media screen and (max-width: 609px) {
    .desktop {
      display: none;
    }
    @import url(https://fonts.googleapis.com/css?family=Vollkorn|Roboto);
    @import url(https://fonts.googleapis.com/css?family=Quicksand);
    body {
      margin: 0;
      padding: 0;
    }
    header {
      width: 100%;
      position: fixed;
      height: 70px;
      line-height: 70px;
      -webkit-transition: all .5s;
      transition: all .5s ease;
      z-index: 999;
    }
    header.resize {
      height: 50px;
      line-height: 50px;
      background: rgb(31, 32, 39);
      border-bottom: 1px solid rgb(46, 48, 56);
    }
    header.resize .button_container {
      top: 14px;
    }
    header.resize #logo {
      font-size: 20px;
    }
    #logo {
      float: left;
      margin-left: 20px;
      font-family: 'Open Sans';
      text-transform: uppercase;
      letter-spacing: 5px;
      font-weight: 300;
      font-size: 24px;
      color: #fdad59;
    }
    .button_container {
      position: fixed;
      width: 30px;
      height: 25px;
      z-index: 100;
      right: 20px;
      top: 25px;
      cursor: pointer;
      -webkit-transition: all .25s;
      transition: all .25s;
    }
    .button_container span {
      width: 30px;
      height: 1px;
      position: absolute;
      background: #fdad59;
      transition: all .35s ease;
    }
    .top {
      top: 0px;
    }
    .middle {
      top: 10px;
    }
    .bottom {
      top: 20px;
    }
    .button_container.active .top {
      -webkit-transform: translateY(10px) translateX(0) rotate(45deg);
      transform: translateY(10px) translateX(0) rotate(45deg);
    }
    .button_container.active .middle {
      opacity: 0;
    }
    .button_container.active .bottom {
      -webkit-transform: translateY(-10px) translateX(0) rotate(-45deg);
      transform: translateY(-10px) translateX(0) rotate(-45deg);
    }
    .overlay {
      position: fixed;
      background: rgba(26, 27, 34, 1);
      visibility: hidden;
      width: 100%;
      height: 0%;
      left: 0px;
      top: 0px;
      opacity: 0;
      -webkit-transition: opacity .35s, height .35s, visibility .35s;
      transition: opacity .35s, height .35s, visibility .35s;
      z-index: 1;
    }
    .overlay.open {
      height: 100%;
      opacity: .98;
      visibility: visible;
    }
    .overlay.open li {
      -webkit-animation: fadeInTop .5s ease forwards;
      animation: fadeInTop .5s ease forwards;
      -webkit-animation-delay: .35s;
      animation-delay: .35s;
    }
    .overlay.open li:nth-of-type(2) {
      -webkit-animation-delay: .4s;
      animation-delay: .4s;
    }
    .overlay.open li:nth-of-type(3) {
      -webkit-animation-delay: .45s;
      animation-delay: .45s;
    }
    .overlay.open li:nth-of-type(4) {
      -webkit-animation-delay: .50s;
      animation-delay: .50s;
    }
    .overlay nav {
      position: relative;
      height: 70%;
      top: 50%;
      -webkit-transform: translateY(-50%);
      transform: translateY(-50%);
      font-size: 40px;
      font-family: 'Lato', serif;
      font-weight: lighter;
      letter-spacing: 10px;
      text-align: center;
    }
    .overlay ul {
      list-style: none;
      padding: 0;
      margin: 0 auto;
      display: inline-block;
      position: relative;
      height: 100%;
    }
    .overlay ul li {
      display: block;
      height: 25%;
      height: calc(100% / 4);
      min-height: 50px;
      position: relative;
      opacity: 0;
    }
    .overlay ul li a {
      display: block;
      position: relative;
      color: #FFF;
      text-decoration: none;
      overflow: hidden;
      font-family: 'Montserrat';
    }
    .overlay ul li a:hover:after,
    .overlay ul li a:focus:after,
    .overlay ul li a:active:after {
      width: 100%;
    }
    .overlay ul li a:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 50%;
      width: 0%;
      -webkit-transform: translateX(-50%);
      transform: translateX(-50%);
      height: 1px;
      background: #FFF;
      -webkit-transition: .35s;
      transition: .35s;
    }
    @-webkit-keyframes fadeInTop {
      0% {
        opacity: 0;
        bottom: 20%;
      }
      100% {
        opacity: 1;
        bottom: 0;
      }
    }
    @keyframes fadeInTop {
      0% {
        opacity: 0;
        bottom: 20%;
      }
      100% {
        opacity: 1;
        bottom: 0;
      }
    }
    body {
      height: 3000px;
    }
  }
  /*----------------------------------------------*/
  .wrapper {
    width: 100%;
    position: relative;
    overflow: hidden;
  }
  .landing {
    width: 100%;
    height: 100%;
    transform: scale(1.25);
    /*background-image: linear-gradient(rgba(21, 22, 29, .9), rgba(21, 22, 29, .9)), url(http://harvardmagazine.com/sites/default/files/img/article/0215/img_HS_MA15_p08_02.jpg);*/
    background: #101010;
    /*background-position: center center;*/
    /*background-repeat: no-repeat;*/
    /*background-attachment: fixed;*/
    /*background-size: cover;*/
    transition: all 1.5s ease;
  }
  .landing.loaded {
    transform: scale(1.0);
  }
  .content-1 {
    text-align: center;
    height: 100px;
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    border-bottom: 1px solid white;
    overflow: hidden;
    width: 0;
    transition: 1s ease;
  }
  .content-1.loaded {
    width: 800px;
  }
  .one,
  .two,
  .three {
    color: white;
    font-size: 35px;
    position: absolute;
    text-align: center;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    transition: all .75s ease;
    font-family: 'Cinzel';
  }
  .mouseScroll {
    display: block;
    width: 26px;
    height: 42px;
    border-radius: 20px;
    border: 2px solid #fff;
    text-align: center;
    position: absolute;
    left: 0px;
    right: 0px;
    bottom: 20px;
    margin: 0 auto;
    transition: opacity 0.5s;
  }
  .mouseScroll::before {
    content: '';
    display: inline-block;
    width: 4px;
    height: 8px;
    position: relative;
    border-radius: 4px;
    background: #fff;
    -webkit-animation: scrollWheel 2s infinite;
    animation-duration: .7s;
    animation-iteration-count: infinite;
    box-sizing: border-box;
  }
  .mouseScroll:hover {
    cursor: pointer;
    opacity: .5;
  }
  @keyframes scrollWheel {
    from {
      top: 0px;
    }
    to {
      top: 6px;
    }
  }
  .about {
    width: 100%;
    padding-bottom: 100px;
    text-align: center;
    color: white;
  }
  .about h1 {
    font-family: 'Lato', serif, sans-serif;
    font-weight: 100;
    font-size: 40px;
    margin-top: 100px;
  }
  #mission {
    margin-top: 80px;
    font-family: 'Noto Serif', serif, sans-serif;
    font-style: italic;
    font-size: 20px;
    color: #999;
    width: 70%;
    margin-left: 15%;
    padding-bottom: 30px;
  }
  :root {
    --cube-height: 60px;
  }
  .container {
    z-index: 1;
    position: absolute;
    margin-right: auto;
    margin-left: auto;
    right: 0;
    left: 0;
    width: 260px;
    height: var(--cube-height);
  }
  .cube {
    width: 250px;
    height: var(--cube-height);
    -webkit-transition: all 300ms ease-out;
    -moz-transition: all 300ms ease-out;
    -o-transition: all 300ms ease-out;
    transition: all 300ms ease-out;
    -webkit-transform-style: preserve-3d;
    -moz-transform-style: preserve-3d;
    -o-transform-style: preserve-3d;
    -ms-transform-style: preserve-3d;
    transform-style: preserve-3d;
  }
  .default-state,
  .active-state {
    height: var(--cube-height);
  }
  .default-state {
    border: 1px solid #fdad59;
    -webkit-transform: translateZ(calc((var(--cube-height)/2)));
    -moz-transform: translateZ(calc((var(--cube-height)/2)));
    -o-transform: translateZ(calc((var(--cube-height)/2)));
    -ms-transform: translateZ(calc((var(--cube-height)/2)));
    transform: translateZ(calc((var(--cube-height)/2)));
  }
  .active-state {
    color: white;
    -webkit-transform: rotateX(90deg) translateZ(calc((var(--cube-height)*1.5)));
    -moz-transform: rotateX(90deg) translateZ(calc((var(--cube-height)*1.5)));
    -o-transform: rotateX(90deg) translateZ(calc((var(--cube-height)*1.5)));
    -ms-transform: rotateX(90deg) translateZ(calc((var(--cube-height)*1.5)));
    transform: rotateX(90deg) translateZ(calc((var(--cube-height)*1.5)));
  }
  .container:hover + .cube {
    -webkit-transform: rotateX(-90deg);
    -moz-transform: rotateX(-90deg);
    -o-transform: rotateX(-90deg);
    -ms-transform: rotateX(-90deg);
    transform: rotateX(-90deg);
  }
  .cube {
    text-align: center;
    margin: 0 auto;
  }
  .default-state,
  .active-state {
    font-size: 20px;
    text-transform: uppercase;
    font-family: 'Lato', sans-serif;
    font-weight: 300;
    color: #be9d7d;
    line-height: var(--cube-height);
  }
  .active-state {
    background: #fdad59;
    color: white;
  }
  .sponsors {
    padding-top: 200px;
    padding-bottom: 200px;
    overflow: hidden;
    position: relative;
    width: 100%;
    background: linear-gradient(rgba(21, 22, 29, .9), rgba(21, 22, 29, .9)), url(http://cdn.wonderfulengineering.com/wp-content/uploads/2014/07/black-and-white-wallpaper-13.jpg);
    background-attachment: fixed;
    background-size: cover;
    -moz-background-size: cover;
    -webkit-background-size: cover;
    background-repeat: no-repeat;
    background-position: top center;
  }
  .sponsors h2 {
    font-family: 'Oswald', sans-serif;
    font-size: 30px;
    letter-spacing: 10px;
    text-align: center;
    color: white;
    font-weight: 400;
    text-transform: uppercase;
  }
  .social {
    width: 100%;
    height: 300px;
  }
  .hideme {
    opacity: 0;
  }
  .icoTwitter {
    display: inline-block;
    position: relative;
    margin: 0 auto 0 auto;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    text-align: center;
    width: 70px;
    height: 70px;
    font-size: 40px;
    margin: 0 auto;
    line-height: 70px;
    text-align: center;
    color: rgb(40, 40, 40);
    background-color: #fdad59;
    margin: 0 auto;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    transition: all .5s ease;
  }
  .icoTwitter:hover {
    background: rgb(31, 32, 39);
    color: #fdad59;
  }
  .img-fluid {
    object-fit: cover;
    max-width: 100%;
  }
  .cloud-circle-one {
    background: rgba(255, 255, 255);
    position: absolute;
    width: 80px;
    height: 30px;
    border-radius: 40%;
    top: 50%;
    left: 25%;
    box-shadow: 5px 5px rgba(0,0,0,0.2);
  }
  .cloud-circle-two {
    background: rgba(255, 255, 255);
    position: absolute;
    width: 60px;
    height: 25px;
    border-radius: 40%;
    top: 54%;
    left: 22%;
    box-shadow: 5px 5px rgba(0,0,0,0.2);
  }
  .animate.center {
    position: absolute;
    top: 40%;
    left: 36%;
    transform: translate(-50%,-50%);
  }
  .animate.center-two {
    position: absolute;
    top: 40%;
    left: 65%;
    transform: translate(-50%,-50%);
  }
  .btn-position-bubble {
    width: max-content;
    position: absolute;
    z-index: 9;
    top: 65%;
    border-radius: 0 !important;
    left: 35%;
  }
  .btn-position {
    position: absolute;
    z-index: 9;
    top: 60%;
    left: 65%;
  }
  .txt-position {
    position: absolute;
    z-index: 9;
    text-align: center;
    padding: 20px 80px 40px  80px;
    top: -10%;
    left: 5%;
  }
  .txt-position-bubble {
    position: absolute;
    z-index: 9;
    text-align: center;
    padding: 10px 20px 10px 20px;
    top: 10%;
  }
  #cloud {
    background: rgba(255, 255, 255);
    width: 400px;
    height: 150px;
    border-radius: 150px;
    box-shadow: 10px 10px rgba(0,0,0,0.2);
    animation: move 3s infinite;
  }
  #cloud-bubble {
    background: rgba(255, 255, 255);
    width: 500px;
    height: 170px;
    border-radius: 150px;
    box-shadow: 10px 10px rgba(0,0,0,0.2);
  }
  #cloud-bubble::before {
    content: '';
    background: rgba(255, 255, 255);
    position: fixed;
    width: 50px;
    z-index: 9;
    padding: 60px;
    height: 100px;
    transform: rotate(185deg);
    border-radius: 0;
    top: 50px;
    left: 350px;
  }
  #cloud:after {
    content: '';
    background: rgba(255, 255, 255);
    position: fixed;
    width: 200px;
    height: 200px;
    border-radius: 50%;
    top: -60px;
    left: 60px;
  }
  #cloud:before {
    content: '';
    background: rgba(255, 255, 255);
    position: absolute;
    width: 200px;
    z-index: 9;
    padding: 60px;
    height: 170px;
    border-radius: 50%;
    top: -90px;
    right: 50px;
  }
</style>