<template> 
   <header class="ts-header has-sticky"> 
   <div class="header-container">
      <div class="header-template">
           <div style="z-index: 10 !important; box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);" :class="{ dark: picked }" class="header-middle header-sticky">
            <div class="container">
               <div class="search-button search-icon visible-phone">
                  <span class="icon">
                     <svg width="46" height="46" viewBox="0 0 46 46" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M21.6167 27.9833C25.1329 27.9833 27.9833 25.1329 27.9833 21.6167C27.9833 18.1005 25.1329 15.25 21.6167 15.25C18.1005 15.25 15.25 18.1005 15.25 21.6167C15.25 25.1329 18.1005 27.9833 21.6167 27.9833Z" stroke="#FF9923" stroke-width="1.91" stroke-miterlimit="10"/>
                        <path d="M32.7495 32.75L25.9912 25.9917" stroke="#FF9923" stroke-width="1.91" stroke-miterlimit="10"/>
                     </svg>
                  </span>
               </div>
               <div class="logo-wrapper">
                  <div class="logo"> 
                     <router-link to="/" custom v-slot="{ navigate }">
                         <a @click="navigate" @keypress.enter="navigate" role="link" style="display: flex; justify-content: center; align-items: center">
                            <img :src="'/uploads/favicon.png'" alt="Lotery" title="Lotery" class="normal-logo"  style="width: 60px;"/>
                            <img :src="'/uploads/favicon.png'" alt="Lotery" title="Lotery" class="mobile-logo" />
                            <img :src="'/uploads/favicon.png'" alt="Lotery" title="Lotery" class="sticky-logo" />
                            <h3 style="padding-bottom: 0; margin-bottom: 0;  margin-left: 10px; color: #b68c64; font-weight: 500">IA LOTTERY </h3>
                         </a>
                     </router-link>
                  </div>
               </div>
               <div class="header-right">
                  <!--<div class="shopping-cart-wrapper">
                     <div class="ts-tiny-cart-wrapper">
                        <div class="cart-icon">
                              <div @click="showCheckout()" class="cart-control" title="Revisar carrito">
                                <div class="wcml_currency_switcher">
                              <span class="ic-cart">
                                 <svg width="46" height="46" viewBox="0 0 46 46" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M18.0085 26.9441L30.7335 24.5817V17.4781H15.4585" stroke="#FF9923" stroke-width="1.91" stroke-miterlimit="10"/>
                                    <path d="M12.4331 15.1158H14.8248L17.9998 26.9441L16.6164 29.0008C16.484 29.2022 16.4145 29.4378 16.4164 29.6782V29.6782C16.4164 29.998 16.5446 30.3047 16.7728 30.5309C17.001 30.7571 17.3104 30.8841 17.6331 30.8841H27.5498" stroke="#FF9923" stroke-width="1.91" stroke-miterlimit="10"/>
                                    <path d="M27.55 32.4618C27.9872 32.4618 28.3416 32.1105 28.3416 31.6771C28.3416 31.2437 27.9872 30.8924 27.55 30.8924C27.1127 30.8924 26.7583 31.2437 26.7583 31.6771C26.7583 32.1105 27.1127 32.4618 27.55 32.4618Z" stroke="#FF9923" stroke-width="1.91" stroke-miterlimit="10"/>
                                    <path d="M19.5915 32.4618C20.0287 32.4618 20.3831 32.1105 20.3831 31.6771C20.3831 31.2437 20.0287 30.8924 19.5915 30.8924C19.1542 30.8924 18.7998 31.2437 18.7998 31.6771C18.7998 32.1105 19.1542 32.4618 19.5915 32.4618Z" stroke="#FF9923" stroke-width="1.91" stroke-miterlimit="10"/>
                                 </svg>
                              </span>
                                </div>
                              <span v-if="!isAuthenticated" class="cart-number">0</span>
                              <span v-if="isAuthenticated" class="cart-number">{{numberCheckout}}</span>
                                <ul>
                                  <li v-for="(lottery, i) in loteries" :key="i"><a href="#">{{ lottery.description }}</a></li>
                                </ul>
                            </div>
                           <span class="cart-drop-icon drop-icon"> </span>
                        </div>

                     </div>
                  </div>-->
                  <div class="language-currency hidden-phone">

                   <div class="header-currency">
                     <div class="shopping-cart-wrapper">
                       <div class="ts-tiny-cart-wrapper">
                        <div class="wcml_currency_switcher">
                            <!--  <span class="ic-cart">
                                 <svg width="46" height="46" viewBox="0 0 46 46" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M18.0085 26.9441L30.7335 24.5817V17.4781H15.4585" stroke="#FF9923" stroke-width="1.91" stroke-miterlimit="10"/>
                                    <path d="M12.4331 15.1158H14.8248L17.9998 26.9441L16.6164 29.0008C16.484 29.2022 16.4145 29.4378 16.4164 29.6782V29.6782C16.4164 29.998 16.5446 30.3047 16.7728 30.5309C17.001 30.7571 17.3104 30.8841 17.6331 30.8841H27.5498" stroke="#FF9923" stroke-width="1.91" stroke-miterlimit="10"/>
                                    <path d="M27.55 32.4618C27.9872 32.4618 28.3416 32.1105 28.3416 31.6771C28.3416 31.2437 27.9872 30.8924 27.55 30.8924C27.1127 30.8924 26.7583 31.2437 26.7583 31.6771C26.7583 32.1105 27.1127 32.4618 27.55 32.4618Z" stroke="#FF9923" stroke-width="1.91" stroke-miterlimit="10"/>
                                    <path d="M19.5915 32.4618C20.0287 32.4618 20.3831 32.1105 20.3831 31.6771C20.3831 31.2437 20.0287 30.8924 19.5915 30.8924C19.1542 30.8924 18.7998 31.2437 18.7998 31.6771C18.7998 32.1105 19.1542 32.4618 19.5915 32.4618Z" stroke="#FF9923" stroke-width="1.91" stroke-miterlimit="10"/>
                                 </svg>
                             </span>
                             <span v-if="!isAuthenticated" class="card-number-new">0</span>
                             <span v-if="isAuthenticated" class="card-number-new">{{numberCheckout}}</span>
                            <ul style="left: 0">
                                 <template v-for="(theme , i) in themes">
                                     <li v-bind:key="'input-theme-'+ i" style="display: flex;justify-content: center;align-items: center; align-content: center;">
                                         <input :id="'input-theme--'+ i"  :value="theme.value" type="radio" name="options" @change="onChangeTheme($event)">
                                         <label :for="'label-theme'+ i" style="margin-top: 10px">{{ theme.value }}</label>
                                     </li>
                                 </template>
                             </ul>-->
                            <template>
                                <b-form-checkbox v-model="checked" name="check-button" @change="onChangeTheme($event)" switch style="margin-top: 13px;">
                                </b-form-checkbox>
                            </template>
                        </div>
                       </div>
                     </div>
                   </div>
                 </div>
                  <div class="shopping-cart-wrapper">
                     <div class="ts-tiny-cart-wrapper">
                        <div class="cart-icon" @click="showNotification()">
                            <div class="cart-control">
                              <span class="ic-cart">
                                <div class="campanaHeader"><i class="fas fa-bell" style="font-size: 24px;"></i></div>
                              </span>
                              <span v-if="!isAuthenticated" style="right: 14px !important;" class="cart-number">0</span>
                              <span v-if="isAuthenticated" style="right: 14px !important;" class="cart-number" >{{countNotification}}</span> 
                            </div>
                            <span class="cart-drop-icon drop-icon"></span>
                        </div>
                     </div>
                  </div>
                  <template v-if="!isAuthenticated"> 
                     <div class="my-account-wrapper hidden-phone">
                        <div class="ts-tiny-account-wrapper">
                           <div class="account-control"> 
                              <a class="login" title="Iniciar Sesion" @click="showSignIn()">
                                 <svg width="46" height="46" viewBox="0 0 46 46" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M22.4999 23.2684C25.0617 23.2684 27.1385 21.1916 27.1385 18.6298C27.1385 16.068 25.0617 13.9912 22.4999 13.9912C19.9381 13.9912 17.8613 16.068 17.8613 18.6298C17.8613 21.1916 19.9381 23.2684 22.4999 23.2684Z" stroke="#FF9923" stroke-width="1.91" stroke-miterlimit="10"/>
                                    <path d="M14 31.7684L14.2995 30.1088C14.6534 28.1923 15.6674 26.4602 17.1655 25.2135C18.6636 23.9668 20.551 23.2843 22.5 23.2845V23.2845C24.4513 23.285 26.3406 23.9698 27.839 25.2197C29.3374 26.4696 30.35 28.2055 30.7005 30.125L31 31.7845" stroke="#FF9923" stroke-width="1.91" stroke-miterlimit="10"/>
                                 </svg>
                                 Iniciar Sesion 
                              </a>  
                           </div>
                        </div>
                     </div> 
                  </template> 
                  <div class="search-button search-icon visible-ipad-portrait hidden-phone"> 
                     <span class="icon">
                        <svg width="46" height="46" viewBox="0 0 46 46" fill="none" xmlns="http://www.w3.org/2000/svg">
                           <path d="M21.6167 27.9833C25.1329 27.9833 27.9833 25.1329 27.9833 21.6167C27.9833 18.1005 25.1329 15.25 21.6167 15.25C18.1005 15.25 15.25 18.1005 15.25 21.6167C15.25 25.1329 18.1005 27.9833 21.6167 27.9833Z" stroke="#FF9923" stroke-width="1.91" stroke-miterlimit="10"/>
                           <path d="M32.7495 32.75L25.9912 25.9917" stroke="#FF9923" stroke-width="1.91" stroke-miterlimit="10"/>
                        </svg>
                     </span>
                  </div>
                  <div class="language-currency hidden-phone"  > 
                     <template v-if="isAuthenticated">  
                        <div class="header-language">
                           <div class="wpml-ls-statics-shortcode_actions wpml-ls wpml-ls-legacy-dropdown js-wpml-ls-legacy-dropdown">
                              <ul>
                                 <li tabindex="0" class="wpml-ls-slot-shortcode_actions wpml-ls-item wpml-ls-item-en wpml-ls-current-language wpml-ls-first-item wpml-ls-item-legacy-dropdown">
                               
                                    <a href="#" class="js-wpml-ls-item-toggle wpml-ls-item-toggle" style="display: flex;">
                                       <svg width="46" height="46" viewBox="0 0 46 46" fill="none" xmlns="http://www.w3.org/2000/svg">
                                          <path d="M22.4999 23.2684C25.0617 23.2684 27.1385 21.1916 27.1385 18.6298C27.1385 16.068 25.0617 13.9912 22.4999 13.9912C19.9381 13.9912 17.8613 16.068 17.8613 18.6298C17.8613 21.1916 19.9381 23.2684 22.4999 23.2684Z" stroke="#FF9923" stroke-width="1.91" stroke-miterlimit="10"/>
                                          <path d="M14 31.7684L14.2995 30.1088C14.6534 28.1923 15.6674 26.4602 17.1655 25.2135C18.6636 23.9668 20.551 23.2843 22.5 23.2845V23.2845C24.4513 23.285 26.3406 23.9698 27.839 25.2197C29.3374 26.4696 30.35 28.2055 30.7005 30.125L31 31.7845" stroke="#FF9923" stroke-width="1.91" stroke-miterlimit="10"/>
                                       </svg>
                                       <span class="wpml-ls-native" :class="{ dark: picked }" style="margin-top: 12px;">{{costumer.name}} {{costumer.lastname}}</span>
                                       <i class="fas fa-chevron-down" style="margin-left:  5px;margin-top: 17px;"></i>
                                    </a>
                                    <ul class="wpml-ls-sub-menu">
                                       <li class="wpml-ls-slot-shortcode_actions wpml-ls-item wpml-ls-item-fr"> 
                                          <router-link to="/perfil" custom v-slot="{ navigate }"> 
                                             <a @click="navigate" @keypress.enter="navigate" role="link" >
                                                <span class="wpml-ls-native" style="margin-left: 20px;">Perfil</span>   
                                             </a>
                                          </router-link>
                                       </li> 
                                       <li class="wpml-ls-slot-shortcode_actions wpml-ls-item wpml-ls-item-fr">
                                          <a @click='logout' class="wpml-ls-link">
                                             <span class="wpml-ls-native" style="margin-left: 20px;">Cerrar Sesion</span> 
                                          </a> 
                                       </li> 
                                    </ul>
                                 </li>
                              </ul>
                           </div>
                        </div>
                     </template>
                  </div> 
                  <div class="content-header" >
                  </div>
                    <!--<div class="ts-search-by-category hidden-ipad-portrait" style="float:left">
                     <form  >
                        <div class="search-table">
                           <div class="search-field search-content">
                              <v-select v-model="selected.name" as="name::id" :from="loteries" tagging @change="searchMain($event, $event)"></v-select>
                           </div>
                        </div>
                     </form>
                  </div>-->
               </div>
            </div>
         </div>  
      </div>
   </div>
   <SignIn></SignIn>

</header>
 

 
</template>

<script>

import { mapActions, mapState } from 'vuex';
import VueCookie from 'vue-cookie'
import $ from "jquery";
import SignIn from '../auth/Login'
import {EventBus} from "@/plugins/event-bus";
 
export default {
   name: 'Header',
   components: {
      SignIn
   },
   data() {
    return {
        img_banner: '../../assets/uploads/2020/08/2.jpg',
        items: [ 
                { name: 'Analisis', image: '/uploads/2020/08/wallet.png', path: '/analisis', icon: 'analytics', other: true, id: 0, state: false },   
                { name: 'Pronóstico', image: '/uploads/2020/08/wallet.png', path: '/pronostico', icon: 'chart-line', other: true, id: 1, state: false },    
                { name: 'Resultados', image: '/uploads/2020/08/wallet.png', path: '/resultados', icon: 'poll-h', other: true, id: 2 , state: false}, 
                { name: 'P. Frecuentes', image: '/uploads/2020/08/wallet.png', path: '/preguntas-frecuentes', icon: 'question', other: true, id: 3, state: false}, 
                { name: 'Testimonios',image: '/uploads/2020/08/wallet.png', path: '/testimonios', icon: 'history', other: true, id: 4, state: false},
                { name: 'Soporte', image: '/uploads/2020/08/wallet.png', path: '/soporte', icon: 'user-headset', other: true, id: 5, state: false }, 
                { name: 'Jugar', image: '/uploads/2020/08/wallet.png', path: '/jugar', icon: 'club', other: false, id: 6, state: false},   
    
        ],
        selected: {
            value: '',
            id: '', 
        },
        checked: false,
        themes:[
                {id: 1, value: 'dark' },
                {id: 2, value: 'light'}
        ],

    };
  }, 
  
  mounted () { 
  
      this.init()
      this.getProfile()
      this.listCheckout({})
      this.listNewNotification({});
  
  },
  computed: {
    ...mapState({ 
         isAuthenticated: state => state.costumer.authenticated, 
         costumer: state => state.costumer.costumer, 
         loteries: state => state.lotto.mainLoteries,
         numberCheckout: state => state.checkout.numberCheckout, 
         countNotification: state =>state.notification.countNotification, 
         notifications: state =>state.notification.notifications,
         picked: state =>  state.costumer.picked,
      theme_dark: state => state.costumer.theme_dark,
      theme_light: state => state.costumer.theme_light,
    }),
  }, 
  methods: {
         async onChangeTheme(val) {
          //let data = event.target.value;
         await this.setTheme(val)
         await EventBus.$emit('change_theme',  val)

        },
         init() {  
               $(function (s) {
                  if ("undefined" == typeof wc_single_product_params) return !1;
                  s("body").on("init", ".wc-tabs-wrapper, .woocommerce-tabs", function () {
                     s(this).find(".wc-tab, .woocommerce-tabs .panel:not(.panel .panel)").hide();
                     var t = window.location.hash,
                        e = window.location.href,
                        i = s(this).find(".wc-tabs, ul.tabs").first();
                     (0 <= t.toLowerCase().indexOf("comment-") || "#reviews" === t || "#tab-reviews" === t || 0 < e.indexOf("comment-page-") || 0 < e.indexOf("cpage=") ? i.find("li.reviews_tab a") : "#tab-additional_information" === t ? i.find("li.additional_information_tab a") : i.find("li:first a")).click()
                  }).on("click", ".wc-tabs li a, ul.tabs li a", function (t) {
                     t.preventDefault();
                     var e = s(this),
                        // eslint-disable-next-line no-redeclare
                        t = e.closest(".wc-tabs-wrapper, .woocommerce-tabs");
                     t.find(".wc-tabs, ul.tabs").find("li").removeClass("active"), t.find(".wc-tab, .panel:not(.panel .panel)").hide(), e.closest("li").addClass("active"), t.find(e.attr("href")).show()
                  })  
               });
          
               let state = this.$route.path
  
               this.items.filter((item, i) => {
                  if (item.path == state ) {
                     this.items[i].state = true
                  }
                  return item
               });
          //     this.getLoteriesMain()
              
         },
         searchMain(z,item) {      
            if (item) {
               location.href = `/loteria/${item.raw.id}/${item.raw.name}` 
            } 
         },
         showSignIn() {
             //this.$root.$emit('bv::show::modal', 'authenticated', '#btnShowAnalysis')
            this.$router.push('/login-client')
         },
         ...mapActions({
            listCheckout: 'listCheckout',
            checkedNotification:'checkedNotification',
            listNewNotification:'listNewNotification',
            login: "login",
            oauthLogin: "oauthLogin",
            register: "register",
            getProfile: "getProfile",
            logoutCostumer: 'logoutCostumer',
            setTheme: 'setTheme'
         //   getLoteriesMain: 'getLoteriesMain'
         }),
         logout(){
            this.logoutCostumer()
            this.$router.push('/login-client')
         },
         showCheckout(lottery) {
            const authorization = VueCookie.get('costumer_token'); 
            if (authorization) {
               // this.$router.push(`/checkout/${lottery.id}/${lottery.name}`) 
               location.href = `/checkout/${lottery.id}/${lottery.name}`;
            } else { 
                 //this.$root.$emit('bv::show::modal', 'authenticated', '#btnShowAnalysis')
              this.$router.push('/login-client')
            }
         },
         /* async signUp(e) {
               e.preventDefault();
               e.stopPropagation();
               const credentials = this.user;
               this.isLoading = true;
               await this.register(credentials); 
         },
         async signIn(e) { 
               e.preventDefault()
               e.stopPropagation()
               const credentials = this.credentials;
               this.isLoading = true;
               await this.login(credentials) 
               //this.$refs['authenticated'].hide() 
         },
         redirectToBack() {
               this.$router.push("/") 
         },
            
         loginWithGoogle () {
               this.$gAuth
                  .signIn()
                  .then(GoogleUser => {
               
               var userInfo = {
                      loginType: 'google',
                     google: GoogleUser
                  } 
                
                  this.$store.commit('setLoginUser', userInfo)
                     router.push('/home') 
                  })
                  .catch(error => {
                  console.log('error', error)
                  })
         },
         loginWithFacebook () {
                  window.FB.login(response => {
                  var userInfo = {
                  loginType: 'fb',
                  fb: response
                  }
                  // console.log('fb response', response)
                  this.$store.commit('setLoginUser', userInfo)
                  // router.push('/home')
               }, this.params)  
         },*/
         pintar(index, id) { 
            console.log(index, id);
               
               if (this.items[index].state == true) {
                  return true
               } else {
                  return false
               }
          },
         active(id) {
            //let state = this.$route.path
            for(let j = 0; j < this.items.length; j++){
               if(id == this.items[j].id){  
                  this.items[j].state = true 
               } else {
                  this.items[j].state = false

               }
            }
            /*
            this.items[index].state
            this.items.filter((item, i) => {
               if (item.path == state ) {
                  this.items[i].state = true
               }
               return item
            });  */
         },
         showNotification:function(){
            if (this.isAuthenticated) {
               this.checkedNotification({notificaciones:this.notifications});
               location.href ='/perfil?notification=true';
            }
         }
  }
} 
</script>

<style scoped>
    @media only screen and (max-width: 1024px) {
      #header-container .right-side  {

        height: 83px !important;
      }
    }
    .custom-control-input {
        top: 5px !important;
        left: 5px !important;
    }
    .light {
        color: black !important;
        background: white;
    }
    .dark {
        color: #d6d2d2 !important;
        background: #1f2027 !important;
    }
    .campanaHeader {
        font-size: x-large;
        color: #FF9922;
    }
    .campanaHeader:hover i{
        color: #103178;
    }
    

   #wp-submit-signup {
      border-color: #5b6c8f !important;
      background: transparent !important;
      border: 1px solid #5b6c8f !important;
      color: #5b6c8f !important;
   }
   #wp-submit-signup:hover {
      background-color: #66676b !important;
      top: 0;
      padding: 9px 20px;
      color: #fff !important;
      position: relative;
      font-size: 15px;
      font-weight: 600;
      display: inline-block;
      transition: all 0.2s ease-in-out;
      cursor: pointer;
      margin-right: 6px;
      overflow: hidden;
      border: none;
      border-radius: 50px;
   }
   .example-slide {
      align-items: center;
      background-color: #666;
      color: #999;
      display: flex;
      font-size: 1.5rem;
      justify-content: center;
      min-height: 10rem;
  }
  .dropdown-item:last-child {
      border-bottom-right-radius: calc(.25rem - 1px);
      border-bottom-left-radius: calc(.25rem - 1px);
  }
  .user-name span img {
      border: 3px solid #fff;
      box-shadow: 0 2px 3px rgba(0, 0, 0, .1);
      box-sizing: content-box;
  }
  .user-name span img {
    width: 100%;
    display: inline-block;
    border-radius: 50%;
    image-rendering: -webkit-optimize-contrast;
  }
  .user-name span {
    width: 35px;
    height: 35px;
    left: -40px;
    top: -8px;
  }
  .user-menu .user-name:after {
    content: "\f107";
    font-family: "FontAwesome";
    transition: 0.2s;
    transform: rotate(0deg);
    display: inline-block;
    margin-left: 6px;
    color: #aaa;
  }
  .user-menu .user-name {
    transition: .2s;
  }
  #ts-login-form a:hover {
      background-color: #5b6c8f;
      color: #5b6c8f;
      background: white;
  }
  #ts-login-form .google a.google   {
      background-color:  #ea4335;
      color:  #ea4335;
      background: white;
  }
  #ts-login-form .google a.google:hover   {
      background-color:  #ea4335;
      color:  #ea4335;
      background: white;
  }
  #ts-login-form .google a:hover  {
      background-color:  #5b6c8f;
      color:  #5b6c8f;
      background: white;
  }
.authenticated-lg {
   display: flex;
   flex-direction: row;
   justify-content: space-around;
   text-decoration: underline;
}
a.button:hover, button:hover, input[type^="submit"]:hover, .ts-button:hover, a.button-readmore:hover, .ts-banner-button a:hover, .woocommerce a.button:hover, .woocommerce button.button:hover, .woocommerce input.button:hover, .woocommerce a.button.alt, .woocommerce button.button.alt, .woocommerce input.button.alt, .woocommerce #respond input#submit:hover, .woocommerce #payment #place_order:hover, .woocommerce-page #payment #place_order:hover, .dropdown-footer>a.button.view-cart, .woocommerce .dropdown-footer>a.button.view-cart, .shopping-cart p.buttons a:hover, .woocommerce div.product .summary a.compare:hover, .woocommerce div.product .summary .yith-wcwl-add-to-wishlist a:hover, .more-less-buttons a:hover, body table.compare-list .add-to-cart td a:hover, body table.compare-list .add-to-cart td a:not(.unstyled_button):hover, .woocommerce div.product div.summary form.cart .button:hover, .product-style-3 .ts-megamenu-container .ts-shortcode .products .product .meta-wrapper-2 .loop-add-to-cart .button:hover, .product-style-4 .ts-megamenu-container .ts-shortcode .products .product .meta-wrapper-2 .loop-add-to-cart .button:hover, .woocommerce .widget_shopping_cart .buttons a:first-child, .woocommerce.widget_shopping_cart .buttons a:first-child, input[type="submit"].dokan-btn:hover, a.dokan-btn:hover, .dokan-btn:hover, .woocommerce .wc-proceed-to-checkout .button.checkout-button:hover {
   border-color: #5b6c8f !important;
   background: transparent !important;
   border: 1px solid #5b6c8f !important;
   color: #5b6c8f !important;

}
.header-language .wpml-ls>ul>li>a:after   {
   border: 0;
   position: static;
   display: inline-block;
   font-size: 10px;
   line-height: inherit !important;
   margin: 0 0 0 5px;
}
.language-currency:after {
   content: none !important;
   width: 1px;
   height: 26px;
   border-width: 0 1px 0 0;
   border-style: solid;
   position: absolute; 
   right: 0;
   margin-top: none;
}
.content-header {
   border-width: 0 1px 0 0; 
   border-style: solid;
}
.card-number-new {
  position: absolute;
  right: 0;
  top: 0;
  left: auto;
  width: 22px;
  height: 22px;
  font-size: 11px;
  line-height: 22px;
  text-align: center;
  border-radius: 100%;
  overflow: hidden;
  background: rgba(16, 49, 120, 1);
  color: rgba(255, 255, 255, 1);
}
</style>