<template>
  <v-app>
        <div class="d-flex  flex-row">  
            <p class="text-h4 mx-5 mt-5 mr-auto">Jugadores</p>
            <p class="mx-5 mt-5 ml-auto">Dashboard > Jugadores</p>
        </div> 
        <div>
                            <v-main>
                                <v-card class="mx-auto mt-5" color="transparent" max-width="1280" elevation="0">
                                        <v-simple-table class="mt-5 mx-5 rounded-lg">
                                            <template v-slot:default>
                                                <thead >
                                                    <tr class="purple darken-2">   
                                                        <th h1 class="white--text blue-grey darken-2 text-center text-sm-h5 text-lg-h5">ID</th> 
                                                        <th h1 class="white--text blue-grey darken-2 text-center text-sm-h5 text-lg-h5" >NOMBRE</th>
                                                        <th h1 class="white--text blue-grey darken-2 text-center text-sm-h5 text-md-h5 text-lg-h5">APELLIDO</th>
                                                        <th h1 class="white--text blue-grey darken-2 text-center text-sm-h5 text-lg-h5">DIRECCIÓN</th> 
                                                        <th h1 class="white--text blue-grey darken-2 text-center text-sm-h5 text-lg-h5" >PAIS</th>
                                                        <th h1 class="white--text blue-grey darken-2 text-center text-sm-h5 text-lg-h5" >ACCIÓN</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr :key="i" v-for="(costumer, i) in costumers">
                                                        <td class="text-center text-h5"> {{costumer.id}}</td>
                                                        <td class="text-center text-h5"> {{costumer.name}}</td>
                                                        
                                                        <td class="text-center text-h5"> {{costumer.lastname}}</td>
                                                        <td class="text-center text-h5"> {{costumer.address}}</td>
                                                        <td class="text-center text-h5"> {{costumer.country}}</td>
                                                        <td class="text-center">
                                                            <v-btn class="mx-5 orange"  dark samll :fab="false"  @click="show(costumer)"><i class="fas fa-edit"></i></v-btn>
                                                            <v-btn class="red" dark samll :fab="false" @click="showChangePassword(costumer.id)"><i class="fas fa-key"></i></v-btn>
                                                        </td>
                                                    </tr>
                                                </tbody>  
                                            </template>
                                        </v-simple-table>
                                        
                                        
                                </v-card>
                                <div class=" col-md-12 " style="text-align:center;">
                                    <button
                                        type="button" class="btn btn-sm btn-outline-secondary button_pagination"
                                        v-if="pageFlag !== 1" @click="pageFlag--" > Ant </button>
                                    <button
                                        type="button" class="btn btn-sm btn-outline-secondary button_pagination"
                                        v-for="pageNumber in pages_test" @click="page = pageNumber"
                                        :class="{active_1: paint(pageNumber)}" :key="pageNumber" >{{pageNumber}}</button>
                                    <button
                                        type="button" @click="pageFlag++" v-if="pageFlag <= pages.length / pageForSection"
                                        class="btn btn-sm btn-outline-secondary button_pagination" >>></button>
                                </div>
                            </v-main>
        </div>
        <v-dialog v-model="dialog" max-width="500" >
                <v-card>
                  <v-card-title class="purple darken-4 white--text">Editar Jugador</v-card-title>
                  <v-form>
                      <v-card-text>   
                            <b-row class="my-1">
                              <b-col sm="3" lg="3">
                                <label>Nombres:</label>
                              </b-col>
                               <b-col sm="3" lg="9">
                                <div>
                                  <b-form-input v-model="costumer.name"  placeholder="Nombre"></b-form-input>
                                </div> 
                              </b-col>
                            </b-row>  
                            <b-row class="my-1">
                              <b-col sm="3" lg="3">
                                <label>Apellidos:</label>
                              </b-col>
                              <b-col sm="3" lg="9">
                                <div>
                                  <b-form-input v-model="costumer.lastname"  placeholder="Apellido"></b-form-input>
                                </div> 
                              </b-col>
                            </b-row>
                        <b-row class="my-1">
                          <b-col sm="3" lg="3">
                            <label>Email:</label>
                          </b-col>
                          <b-col sm="3" lg="9">
                            <div>
                              <b-form-input v-model="costumer.email"  placeholder="Email"></b-form-input>
                            </div>
                          </b-col>
                        </b-row>
                          <b-row class="my-1">
                            <b-col sm="3" lg="3">
                              <label>Tipo:</label>
                            </b-col>
                            <b-col sm="3" lg="9">
                              <div>
                                <select class="custom-select" v-model="costumer.type">

                                  <option value="0">Cliente</option>
                                  <option value="1">Admin</option>
                                </select>
                               </div>
                            </b-col>
                          </b-row>
                        <b-row class="my-1">
                              <b-col sm="3" lg="3">
                                <label>Dirección:</label>
                              </b-col>
                              <b-col sm="3" lg="9">
                                <div>
                                  <b-form-input v-model="costumer.address"  placeholder="Direccion"></b-form-input>
                                </div> 
                              </b-col>
                            </b-row>  
                            <b-row class="my-1">
                              <b-col sm="3" lg="3">
                                <label>Pais:</label>
                              </b-col>
                              
                              <b-col sm="3" lg="9">
                                <div>
                                  <b-form-input v-model="costumer.country"  placeholder="Pais"></b-form-input>
                                </div> 
                              </b-col>
                            </b-row> 
                            
                      </v-card-text>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn @click="dialog=false" color="blue-grey" dark>Cancelar</v-btn>
                        <v-btn @click="saveProfile()" color="blue-grey" dark>Guardar</v-btn>
                      </v-card-actions>
                  </v-form>
                </v-card>
        </v-dialog>
        <v-dialog v-model="dialog_answer" max-width="500" >
                <v-card>
                  <v-card-title class="green darken-4 white--text">Editar Nueva Contraseña</v-card-title>
                  <v-form>
                      <v-card-text>   
                            <b-row class="my-1">
                              <b-col sm="3" lg="3">
                                <label>Contraseña:</label>
                              </b-col>
                              <b-col sm="3" lg="9">
                                <div>
                                  <b-form-input v-model="password.new_password"  placeholder="Contraseña"></b-form-input>
                                </div> 
                              </b-col>
                            </b-row>  
                            <b-row class="my-1">
                              <b-col sm="3" lg="3">
                                <label>Confirmar Contraseña:</label>
                              </b-col>
                              <b-col sm="3" lg="9">
                                <div>
                                  <b-form-input v-model="password.new_confirmation"  placeholder="Nueva Contraseña"></b-form-input>
                                </div> 
                              </b-col>
                            </b-row>
                      </v-card-text>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn @click="savePassword()" color="blue-grey" dark>OK</v-btn>
                      </v-card-actions>
                  </v-form>
                </v-card>
        </v-dialog>
    </v-app> 
</template>

<script>

import sweet from 'sweetalert2'
import {mapActions, mapState} from "vuex";

export default {
    name: 'ListCostumer',
    data(){
        return{
            dialog: false,
            dialog_answer: false,
            type:'',
            costumer_id:'',
            password: {
              new_password: '',
              new_confirmation: ''
            },
            page: 1,
            perPage: 10,
            pages: [],
            pageFlag : 1,
            pageForSection: 10,
            costumer: {
              dialog: false,
              name: '',
              country:'',
              lastname:'',
              address:'',
              id:'',
              type: ''
            }
        }
    },

    mounted(){
      this.list()
    },  
    methods:{
        ...mapActions({
          list: 'getCostumers',
          update: 'updateCostumer',
          changePassword: 'changePassword'
        }),
        show(costumer){
          this.dialog = true
          this.costumer = costumer
        },
        saveProfile(){
          this.update(this.costumer)
          if (this.updated_costumer) sweet.fire('¡Actualización realizada!', '', 'success')
          this.dialog = false
        },
        async showChangePassword(id){

          this.costumer_id = id
          this.dialog_answer = true
        },
        savePassword(){
           if(this.password.new_password !== '' || this.password.new_confirmation !== ''){

              if (this.password.new_password !== this.password.new_confirmation) {
                sweet.fire('¡Los campos no coinciden!', "error", 'warning')

              } else {
                this.password.costumer_id = this.costumer_id
                let updated_password = this.changePassword(this.password)
                if (updated_password) {
                  sweet.fire('¡Actualización realizada!', '', 'success')
                } else {
                  sweet.fire('¡Ha ocurrido un error!', "error", 'warning')
                }
              }

            } else{
              sweet.fire('¡Rellene los campos!', '', 'warning')
            }
            this.dialog_answer = false
        },
        setArticles() {
            this.pages = []
            let numberOfPages = Math.ceil(this.costumers.length / this.perPage);
            for (let i = 1; i <= numberOfPages; i++) {
                this.pages.push(i);
            }
        },
        paginate(books) {
            let page = this.page;
            let perPage = this.perPage;
            let from = (page * perPage) - perPage;
            let to = (page * perPage);
            return books.slice(from, to);
        },
        pagination_section(page){
            let paginate = this.pageForSection
            let flag = this.pageFlag
            let from = (flag * paginate) - paginate;
            let to = (flag * paginate);
            return page.slice(from,to)
        },
        paint(value){
            return value === this.page;
        },
    },
    watch: {
      compras(){
        this.setArticles();
      }
    },
    computed: {
      compras_prueba: function () {
        return this.paginate(this.compras);
      },
      pages_test:function(){
        //let cant = this.pages.length
        let forPaginate = this.pageForSection
        let flag = this.pageFlag
        let from = (flag * forPaginate) - forPaginate;
        let to = (flag * forPaginate);
        return this.pages.slice(from,to)
      },
      ...mapState({
        costumers: state => state.costumer.costumers,
        updated_costumer: state => state.costumer.updated_costumer,
        updated_password: state => state.costumer.updated_password
      })
    }
}
</script>

<style>

</style>