import supportAPI from '../../../api/admin/support.js' 

const state = {  
    showSupport: false,
    createAnswerSupport: false
} 
const actions = {
     
     async listQuestionSupport({ commit }, {...searchParams }) {  
        const { data: data } = await supportAPI.listQuestionSupport(searchParams)
        if (data.ok) {
            commit('SHOW_SUPPORT', data.ok)    
            return data;
        }
         
    },  
    async createAnswerSupport({ commit }, { ...searchParams }) {
        const { data } = await supportAPI.createAnswerSupport(searchParams)

            commit('CREATE_ANSWER_SUPPORT', data.ok)     
            return data.ok;
        
    },  
} 

const getters = {
 
}

const mutations = {
    SHOW_SUPPORT(state, val){
        state.showSupport = val
    },
    CREATE_ANSWER_SUPPORT(state, val){
        state.createAnswerSupport = val
    },
}

export default { 
    state,
    actions,
    getters,
    mutations
}
