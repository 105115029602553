import ApiClient from '../source/Api'

const auth =  {  
    // constellationSame(searchParams) {  
    //     return ApiClient().post(`/results/constellations`, searchParams)
    // },
    listPromotion() {
        return ApiClient().get(`/promotions/list`)   
    } 
} 

export default auth;