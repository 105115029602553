<template>
    <v-app>
          <div class="d-flex  flex-row">  
                 <p class="text-h4 mx-5 mt-5 mr-auto">Juegos en Grupo</p>
                 <p class="mx-5 mt-5 ml-auto"><v-btn class="mx-5" :fab="false" dark color="#458ef3" @click="formNuevo()"> <v-icon>add</v-icon> </v-btn></p>
          </div> 
          <div>
            <b-card no-body>
                <b-tabs card>
                    <b-tab title=" Juegos en Grupo" active>
                        <b-card-text>
                            <v-main>
                                <v-card class="mx-auto mt-5" color="transparent" max-width="1280" elevation="0">
                                    <v-simple-table class="mt-5 mx-5 rounded-lg">
                                        <template v-slot:default>
                                            <thead >
                                                <tr class="purple darken-2">   
                                                    <th h1 class="white--text blue-grey darken-2 text-center text-sm-h5 text-md-h5 text-lg-h5">ID</th>
                                                    <th h1 class="white--text blue-grey darken-2 text-center text-sm-h5 text-lg-h5">LOTERIA</th> 
                                                    <th h1 class="white--text blue-grey darken-2 text-center text-sm-h5 text-lg-h5" >LINEAS</th>
                                                    <th h1 class="white--text blue-grey darken-2 text-center text-sm-h5 text-md-h5 text-lg-h5">PARTICIPANTES</th>
                                                    <th h1 class="white--text blue-grey darken-2 text-center text-sm-h5 text-lg-h5">CUPOS</th> 
                                                    <th h1 class="white--text blue-grey darken-2 text-center text-sm-h5 text-lg-h5" >ACCIONES</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(pena, index) in penas_prueba" :key="index">
                                                    <td class="text-center text-h5"> {{pena.id}} </td>
                                                    <td class="text-center text-h5" v-if="pena.loterie_id == 1">Powerball</td>
                                                    <td class="text-center text-h5" v-else-if="pena.loterie_id == 2">MegaMillions</td>
                                                    <td class="text-center text-h5" v-else-if="pena.loterie_id == 3">Florida</td>
                                                    <td class="text-center text-h5" v-else>Tinka</td>
                                                    <td class="text-center text-h5" v-if="pena.type == 50">50 lineas</td>
                                                    <td class="text-center text-h5" v-else-if="pena.type == 100">100 lineas</td>
                                                    <td class="text-center text-h5" v-else>200 lineas</td>
                                                    <td class="text-center text-h5"> {{pena.participations}} </td>
                                                    <td class="text-center text-h5"> {{pena.coupons}} </td>
                                                    <td class="text-center">
                                                        <v-btn class="mx-5 orange"  dark samll :fab="false"  @click="activar(pena.id)"><i class="fas fa-lock-open-alt"></i></v-btn>
                                                        <v-btn class="red" dark samll :fab="false" @click="delete_pena(pena.id)"><v-icon>delete</v-icon></v-btn>
                                                    </td>
                                                </tr>
                                            </tbody>
                                            
                                        </template>
                                    </v-simple-table>
                                    <div class="col-md-12" style="text-align:center;">
                                                <button
                                                    type="button"
                                                    class="btn btn-sm btn-outline-secondary button_pagination"
                                                    v-if="pageFlag != 1"
                                                    @click="pageFlag--"
                                                > Ant
                                                </button>
                                                <button
                                                    type="button"
                                                    class="btn btn-sm btn-outline-secondary button_pagination"
                                                    v-for="pageNumber in pages_prueba"
                                                    @click="page = pageNumber"
                                                    :class="{active_1: pintar(pageNumber)}"
                                                    :key="pageNumber"
                                                >{{pageNumber}}</button>
                                                <button
                                                    type="button"
                                                    @click="pageFlag++"
                                                    v-if="pageFlag <= pages.length / pageForSection"
                                                    class="btn btn-sm btn-outline-secondary button_pagination"
                                                >Sgt</button>
                                            </div>
                                </v-card>
                            </v-main>
                        </b-card-text>
                        
                    </b-tab>
                    <b-tab title="Juegos en Grupo Activados">
                        <b-card-text>
                            <v-main>
                                <v-card class="mx-auto mt-5" color="transparent" max-width="1280" elevation="0">
                                    <v-simple-table class="mt-5 mx-5 rounded-lg">
                                        <template v-slot:default>
                                            <thead >
                                                <tr class="purple darken-2">   
                                                    <th h1 class="white--text blue-grey darken-2 text-center text-sm-h5 text-md-h5 text-lg-h5">ID</th>
                                                    <th h1 class="white--text blue-grey darken-2 text-center text-sm-h5 text-lg-h5">LOTERIA</th> 
                                                    <th h1 class="white--text blue-grey darken-2 text-center text-sm-h5 text-lg-h5" >LINEAS</th>
                                                    <th h1 class="white--text blue-grey darken-2 text-center text-sm-h5 text-md-h5 text-lg-h5">PARTICIPANTES</th>
                                                    <th h1 class="white--text blue-grey darken-2 text-center text-sm-h5 text-lg-h5">CUPOS</th> 
                                                    <th h1 class="white--text blue-grey darken-2 text-center text-sm-h5 text-lg-h5" >ACCIONES</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(pena, index) in penas_activate_prueba" :key="index">
                                                    <td class="text-center text-h5"> {{pena.id}} </td>
                                                    <td class="text-center text-h5" v-if="pena.loterie_id == 1">Powerball</td>
                                                    <td class="text-center text-h5" v-else-if="pena.loterie_id == 2">MegaMillions</td>
                                                    <td class="text-center text-h5" v-else-if="pena.loterie_id == 3">Florida</td>
                                                    <td class="text-center text-h5" v-else>Tinka</td>
                                                    <td class="text-center text-h5" v-if="pena.type == 50">50 lineas</td>
                                                    <td class="text-center text-h5" v-else-if="pena.type == 100">100 lineas</td>
                                                    <td class="text-center text-h5" v-else>200 lineas</td>
                                                    <td class="text-center text-h5"> {{pena.participations}} </td>
                                                    <td class="text-center text-h5"> {{pena.coupons}} </td>
                                                    <td class="text-center" style="display:flex;align-items: center;">
                                                        <v-btn class="mx-5 orange"  dark samll :fab="false"  @click="ver_balls(pena.id)"><v-icon>remove_red_eye</v-icon></v-btn>
                                                        <div style="display:flex">
                                                             <b-form-file
                                                                v-model="file1"
                                                                :state="Boolean(file1)"
                                                                placeholder="Seleccionar archivo" 
                                                                drop-placeholder="Drop file here...">
                                                                    <v-icon>attach_file</v-icon>                                                                
                                                                </b-form-file> 
                                                         
                                                            <v-btn class=" green"  dark samll :fab="false"  @click="attachFile(pena.id)"><v-icon>send</v-icon></v-btn>   
                                                        </div>

                                                    </td>
                                                </tr>
                                            </tbody>
                                            
                                        </template>
                                    </v-simple-table>
                                    <div class=" col-md-12 " style="text-align:center;">
                                    <button
                                        type="button"
                                        class="btn btn-sm btn-outline-secondary button_pagination"
                                        v-if="pageFlag_ticket != 1"
                                        @click="pageFlag_ticket--"
                                    > Ant
                                    </button>
                                    <button
                                        type="button"
                                        class="btn btn-sm btn-outline-secondary button_pagination"
                                        v-for="pageNumber_ticket in pages_penas_activate_prueba"
                                        @click="page_ticket = pageNumber_ticket"
                                        :class="{active_1: pintar_ticket(pageNumber_ticket)}"
                                        :key="pageNumber_ticket"
                                    >{{pageNumber_ticket}}</button>
                                    <button
                                        type="button"
                                        @click="pageFlag_ticket++"
                                        v-if="pages_penas_activate_prueba.length >= pageForSection_ticket"
                                        class="btn btn-sm btn-outline-secondary button_pagination"
                                    >>></button>
                                </div>
                                </v-card>
                            </v-main>
                        </b-card-text>
                    </b-tab>
                    <b-tab title="Lineas">
                        <b-card-text>
                            <v-main>
                                <v-card class="mx-auto mt-5" color="transparent" max-width="1280" elevation="0">
                                    <v-simple-table class="mt-5 mx-5 rounded-lg">
                                        <template v-slot:default>
                                            <thead >
                                                <tr class="purple darken-2">   
                                                    <th h1 class="white--text blue-grey darken-2 text-center text-sm-h5 text-md-h5 text-lg-h5">ID</th>
                                                    <th h1 class="white--text blue-grey darken-2 text-center text-sm-h5 text-lg-h5">BOLILLAS</th> 
                                                    <th h1 class="white--text blue-grey darken-2 text-center text-sm-h5 text-lg-h5" >BOLILLA ROJA</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(line, index) in lines_prueba" :key="index">
                                                    <td class="text-center text-h5"> {{line.id}} </td>
                                                    <td class="text-center text-h5"> 
                                                        <ul class="number-list" style="text-align:center" >
                                                                <li class="active_1" :key="index" v-for="(element, index) in JSON.parse(line.balls)">{{element}}</li>
                                                            </ul>
                                                    </td>
                                                    <td class="text-center text-h5"> 
                                                        <ul class="number-list" style="text-align:center">
                                                                <li class="active ball-red">{{line.ball_red}}</li>
                                                            </ul>
                                                    </td>
                                                    
                                                </tr>
                                            </tbody>
                                            
                                        </template>
                                    </v-simple-table>
                                    <div class=" col-md-12 " style="text-align:center;">
                                    <button
                                        type="button"
                                        class="btn btn-sm btn-outline-secondary button_pagination"
                                        v-if="pageFlag_ticket_lines != 1"
                                        @click="pageFlag_ticket_lines--"
                                    > Ant
                                    </button>
                                    <button
                                        type="button"
                                        class="btn btn-sm btn-outline-secondary button_pagination"
                                        v-for="pageNumber_ticket in lines_prueba_page"
                                        @click="page_ticket_lines = pageNumber_ticket"
                                        :class="{active_1: pintar_ticket_lines(pageNumber_ticket)}"
                                        :key="pageNumber_ticket"
                                    >{{pageNumber_ticket}}</button>
                                    <button
                                        type="button"
                                        @click="pageFlag_ticket_lines++"
                                        v-if="pages_penas_activate_prueba.length >= pageForSection_ticket_lines"
                                        class="btn btn-sm btn-outline-secondary button_pagination"
                                    >>></button>
                                </div>
                                </v-card>
                            </v-main>
                        </b-card-text>
                        
                    </b-tab>
                </b-tabs>
            </b-card>
        </div>
          
    </v-app> 
    
</template>

<script>
import axios from 'axios'
import moment from 'moment'
import Swal from 'sweetalert2'
import { mapActions } from 'vuex'
 
export default {
    name:'GroupGame',
    data(){
        return{
            penas : [],
            penas_activate : [],
            lines : [],
            //url:"https://latam.ialotteryplay.com/",
            url:"https://latam.ialotteryplay.com/",
            page: 1,
            perPage: 10,
            pages: [],
            pageFlag : 1,
            pageForSection: 10,

            page_ticket: 1,
            perPage_ticket: 10,
            pages_ticket: [],
            pageFlag_ticket : 1,
            pageForSection_ticket: 10,

            page_ticket_lines: 1,
            perPage_ticket_lines: 10,
            pages_ticket_lines: [],
            pageFlag_ticket_lines : 1,
            pageForSection_ticket_lines: 10,
            file1:''
        }
    },
    mounted(){               
      this.mostrar()
    }, 
    methods:{
        ...mapActions({
            createLines: 'createLines'
        }),
        format_date(value){
            if (value) {
            return moment(String(value)).format('YYYY-MM-DD')
            }
        },
        async mostrar(){
            await axios.get(this.url+'penas/list-pena')
            .then(response =>{
                this.penas = response.data.data;   
                this.penas_activate = response.data.data_activate                
            })    
        },
        attachFile(id) {

            const file = this.file1;
           // let fileJson = this.readTextFile(file)
           // console.log('test', fileInput.files.item(0)); 
           const self = this
            
            Swal.fire({
                title: '¿Deseas Adjuntar el la informacion a la base de datos?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Si, eliminar',
                cancelButtonText: 'Cancelar'
            }).then(async (result) => {
                if (result.isConfirmed) {
                    let data = []

                    let reader = new FileReader(); 
                    reader.readAsText(file); 
                    reader.onload = function() {
                        var arrayDeCadenas = reader.result.split('\r\n'); 
                        for (let i = 0; i < arrayDeCadenas.length; i++) {
                            let a = arrayDeCadenas[i].split('|')
                            data.push({ balls: [ parseInt(a[0]), parseInt(a[1]), parseInt(a[2]), parseInt(a[3]), parseInt(a[4]), parseInt(a[5])], ball_red: parseInt(a[6]) })
                        }
                        self.createLines({id: id, data: JSON.stringify(data)}) 
                    };  
                    reader.onerror = function() {
                        console.log(reader.error);
                    }; 
                    Swal.fire('¡Exito!',
                              'La informacion a sido guardada',
                              'success')
                }
            })
        },
        getAsText(file) {

            var fr = new FileReader();
            fr.onload = function(e) { 
                console.log('e.target.result', e.target.result);
            };
            fr.readAsText(file);
        },
        delete_pena(id){
        Swal.fire({
        title: '¿Deseas eliminar el registro?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Si, eliminar',
        cancelButtonText: 'Cancelar'
        }).then(async (result) => {
            if (result.isConfirmed) {
                await axios.post(this.url+'penas/delete_pena/'+id)
                Swal.fire(
                    '¡Registro eliminado!',
                    'El registro ha sido eliminado',
                    'success'
                )
                this.mostrar()
          }
        })
        },
        activar(id){
            Swal.fire({
            title: '¿Deseas activar este juego en grupo?',
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Si, activar',
            cancelButtonText: 'Cancelar'
            }).then(async (result) => {
                if (result.isConfirmed) {
                    await axios.post(this.url+'penas/activate-pena/'+id)
                    Swal.fire(
                        '¡Lineas activadas',
                        'El registro ha sido activado',
                        'success'
                    )
                    this.mostrar()
            }
            })
        },
        async formNuevo(){
                const data = await axios.get('https://latam.ialotteryplay.com/loterie/loteries_list')
                let data_ = data.data
                let html = []
                for(let i = 0; i < data_.length; i++){
                    html[i] = `<option value="${data_[i].id}">${data_[i].name}</option>`
                }
                    await Swal.fire({
                        title: 'Ingresar Datos',
                        html: `
                        <label>Loteria</label>
                        <select id="loteria" class="select ">
                            ${html}
                        </select>
                        <label>Lineas</label>
                        <select id="lines" class="select">
                            <option value="50">50</option>
                            <option value="100">100</option>
                            <option value="200">200</option>
                        </select>
                        <label>Participaciones</label>
                        <input type="number" class="input height-50" id="participations"> 
                        `,
                        confirmButtonText: 'Guardar',
                        focusConfirm: true,
                        preConfirm: () => {
                            const loteria = Swal.getPopup().querySelector('#loteria').value
                            const lines = Swal.getPopup().querySelector('#lines').value
                            const participations = Swal.getPopup().querySelector('#participations').value
                            if (!loteria || !lines || !participations ) {
                                Swal.showValidationMessage(`Por favor, complete el formulario`)
                            }
                            else{
                                if(participations <= 0){
                                    Swal.showValidationMessage(`Seleccione una cantidad menor a 100`) 
                                }
                                /*else{
                                    if(date_start > date_end){
                                        Swal.showValidationMessage(`Son fechas incorrectas`)
                                    }
                                }*/
                            }
                            return {    loterie_id : loteria, 
                                        type : lines,
                                        participations : participations,
                                        coupons : 0}
                        }
                    }).then(async (result) => {
                        if(result.isConfirmed){
                            //console.log(result)
                            await axios.post(this.url+'penas/create-pena',result.value)
                            Swal.fire(
                            '¡Registro creado exitosamente!',
                            'El registro ha sido creado',
                            'success'
                            )
                            this.mostrar()
                        }
                        
                    })
        },
        async ver_balls(id){
            await axios.get(this.url+'penas/get_pena_ball/'+id)
            .then(response => {
                this.lines = response.data.data
                Swal.fire(
                    '¡Dirigase a la pestaña de "Lineas"!', 
                    'Las lineas se han encontrado',
                    'success'
                ) 
                //console.log(this.lines)
            })  
            .catch(async function (error) {
                alert(error);
                /*await Swal.fire(
                    '¡Hubo una falla!', 
                    error,
                    'warning'
                )  */
            });
        },

        setArticles() {
            this.pages = []
            let numberOfPages = Math.ceil(this.penas.length / this.perPage);
            for (let i = 1; i <= numberOfPages; i++) {
            this.pages.push(i);
            }
        },
        paginate(cartillas) {
            let page = this.page;
            let perPage = this.perPage;
            let from = (page * perPage) - perPage;
            let to = (page * perPage);
            return cartillas.slice(from, to);
        },
        pagination_section(pagine){
            let forPaginate = this.pageForSection
            let flag = this.pageFlag
            let from = (flag * forPaginate) - forPaginate;
            let to = (flag * forPaginate);
            return pagine.slice(from,to)
        },

        setArticles_tickets() {
            this.pages_ticket = []
            let numberOfPages_ticket = Math.ceil(this.penas_activate.length / this.perPage_ticket);
            for (let i = 1; i <= numberOfPages_ticket; i++) {
                this.pages_ticket.push(i);
            }
        },
        paginate_ticket(cartillas) {
            let page = this.page_ticket;
            let perPage = this.perPage_ticket;
            let from = (page * perPage) - perPage;
            let to = (page * perPage);
            return cartillas.slice(from, to);
        },
        pagination_section_ticket(pagine){
            let forPaginate = this.pageForSection_ticket
            let flag = this.pageFlag_ticket
            let from = (flag * forPaginate) - forPaginate;
            let to = (flag * forPaginate);
            return pagine.slice(from,to)
        },

        setArticles_tickets_lines() {
            this.pages_ticket_lines = []
            let numberOfPages_ticket = Math.ceil(this.lines.length / this.perPage_ticket_lines);
            for (let i = 1; i <= numberOfPages_ticket; i++) {
                this.pages_ticket_lines.push(i);
            }
        },
        paginate_ticket_lines(cartillas) {
            let page = this.page_ticket_lines;
            let perPage = this.perPage_ticket_lines;
            let from = (page * perPage) - perPage;
            let to = (page * perPage);
            return cartillas.slice(from, to);
        },
        pagination_section_ticket_lines(pagine){
            let forPaginate = this.pageForSection_ticket_lines
            let flag = this.pageFlag_ticket_lines
            let from = (flag * forPaginate) - forPaginate;
            let to = (flag * forPaginate);
            return pagine.slice(from,to)
        },

        pintar(value){
            if(value == this.page){
            return true
            }else{
            return false
            }
        },
        pintar_ticket(value){
            if(value == this.page_ticket){
                return true
            }else{
                return false
            }
        },
        pintar_ticket_lines(value){
            if(value == this.page_ticket_lines){
                return true
            }else{
                return false
            }
        }
    },
    watch: {
      penas(){
        this.setArticles();
      },
      penas_activate(){
        this.setArticles_tickets()
      },
      lines(){
        this.setArticles_tickets_lines()
      }
    },
    computed: {
      penas_prueba: function () {
        return this.paginate(this.penas)
      },
      
      penas_activate_prueba: function () {
        return this.paginate_ticket(this.penas_activate)
      },
      lines_prueba: function(){
          return this.paginate_ticket_lines(this.lines)
      },
      pages_prueba:function(){
        //let cant = this.pages.length
        let forPaginate = this.pageForSection
        let flag = this.pageFlag
        let from = (flag * forPaginate) - forPaginate;
        let to = (flag * forPaginate);
        return this.pages.slice(from,to)
      },
      pages_penas_activate_prueba:function(){
        //let cant = this.pages.length
        let forPaginate = this.pageForSection_ticket
        let flag = this.pageFlag_ticket
        let from = (flag * forPaginate) - forPaginate;
        let to = (flag * forPaginate);
        return this.pages_ticket.slice(from,to)
      },
      lines_prueba_page: function (){
        let forPaginate = this.pageForSection_ticket_lines
        let flag = this.pageFlag_ticket_lines
        let from = (flag * forPaginate) - forPaginate;
        let to = (flag * forPaginate);
        return this.pages_ticket_lines.slice(from,to)
      }
    }
}
</script>

<style>
.custom-file-input:lang(en) ~ .custom-file-label::after {
  content: none !important;
}
span.d-block.form-file-text { 
    margin-right: 40px !important;
}
.select{
   height: 50px !important;
   color: rgba(91, 108, 143, 1) !important;
}
.input{
    height: 51px !important;
    border-radius: 5px !important;
}
.height-50{
      height: 51px !important; 
}
</style>