<template>
    
  
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-8">
          <div class="section-header text-center">
            <h2 class="section-title">¡Mira nuestros ganadores!</h2>
            <p>En Lotery estamos orgullosos de poder decir que ya son más de 
                6 millones de jugadores que han confiado en Lotter y han ganado, 
                y por si fuera poco, la suma de los premios pagados en Lotery 
                superan los ¡100 millones de dólares! ¿Será usted el siguiente?</p>
          </div>
        </div>
      </div>
      <div class="row justify-content-between">
        <div class="col-lg-12">
          <div class="row m-bottom-not-30">
            <div class="col-lg-4 col-md-6">
              <div class="post-item m-bottom-30">
                <div class="thumb">
                  <img src="/images/blog/profile.jpg" alt="image">
                </div>
                <div class="content">
                  <h3 class="post-title"><a href="#0">Learn the Advantages of Playing Lottery...</a></h3>
                  <ul class="post-meta">
                    <li><a href="#0"><span>BY</span>David Millward</a></li>
                    <li><a href="#0"><i class="fa fa-calendar"></i>11 June 2018</a></li>
                  </ul>
                  <p>Do you play lotto regularly, yet, the necessity of visiting an outlet each time you want to purchase a Lotto ticket is uncomfortable...</p>
                  <a href="#0" class="blog-btn">read more</a>
                </div>
              </div>
            </div><!-- post-item end -->
            <div class="col-lg-4 col-md-6">
              <div class="post-item m-bottom-30">
                <div class="thumb">
                  <img src="images/blog/m2.jpg" alt="image">
                </div>
                <div class="content">
                  <h3 class="post-title"><a href="#0">Try Playing Lotto Online and Check How...</a></h3>
                  <ul class="post-meta">
                    <li><a href="#0"><span>BY</span>David Millward</a></li>
                    <li><a href="#0"><i class="fa fa-calendar"></i>11 June 2018</a></li>
                  </ul>
                  <p>Do you play lotto regularly, yet, the necessity of visiting an outlet each time you want to purchase a Lotto ticket is uncomfortable...</p>
                  <a href="#0" class="blog-btn">read more</a>
                </div>
              </div>
            </div><!-- post-item end -->
            <div class="col-lg-4 col-md-6">
              <div class="post-item m-bottom-30">
                <div class="thumb">
                  <img src="images/blog/m1.jpg" alt="image">
                </div>
                <div class="content">
                  <h3 class="post-title"><a href="#0">Learn the Advantages of Playing Lottery...</a></h3>
                  <ul class="post-meta">
                    <li><a href="#0"><span>BY</span>David Millward</a></li>
                    <li><a href="#0"><i class="fa fa-calendar"></i>11 June 2018</a></li>
                  </ul>
                  <p>Do you play lotto regularly, yet, the necessity of visiting an outlet each time you want to purchase a Lotto ticket is uncomfortable...</p>
                  <a href="#0" class="blog-btn">read more</a>
                </div>
              </div>
            </div><!-- post-item end -->
            <div class="col-lg-4 col-md-6">
              <div class="post-item m-bottom-30">
                <div class="thumb">
                  <img src="images/blog/m3.jpg" alt="image">
                </div>
                <div class="content">
                  <h3 class="post-title"><a href="#0">Do You Know How to Become a Lotto....</a></h3>
                  <ul class="post-meta">
                    <li><a href="#0"><span>BY</span>David Millward</a></li>
                    <li><a href="#0"><i class="fa fa-calendar"></i>11 June 2018</a></li>
                  </ul>
                  <p>Do you play lotto regularly, yet, the necessity of visiting an outlet each time you want to purchase a Lotto ticket is uncomfortable...</p>
                  <a href="#0" class="blog-btn">read more</a>
                </div>
              </div>
            </div><!-- post-item end -->
            <div class="col-lg-4 col-md-6">
              <div class="post-item m-bottom-30">
                <div class="thumb">
                  <img src="images/blog/m4.jpg" alt="image">
                </div>
                <div class="content">
                  <h3 class="post-title"><a href="#0">All-time highest lottery winnings</a></h3>
                  <ul class="post-meta">
                    <li><a href="#0"><span>BY</span>David Millward</a></li>
                    <li><a href="#0"><i class="fa fa-calendar"></i>11 June 2018</a></li>
                  </ul>
                  <p>Do you play lotto regularly, yet, the necessity of visiting an outlet each time you want to purchase a Lotto ticket is uncomfortable...</p>
                  <a href="#0" class="blog-btn">read more</a>
                </div>
              </div>
            </div><!-- post-item end -->
            <div class="col-lg-4 col-md-6">
              <div class="post-item m-bottom-30">
                <div class="thumb">
                  <img src="images/blog/m3.jpg" alt="image">
                </div>
                <div class="content">
                  <h3 class="post-title"><a href="#0">Do You Know How to Become a Lotto....</a></h3>
                  <ul class="post-meta">
                    <li><a href="#0"><span>BY</span>David Millward</a></li>
                    <li><a href="#0"><i class="fa fa-calendar"></i>11 June 2018</a></li>
                  </ul>
                  <p>Do you play lotto regularly, yet, the necessity of visiting an outlet each time you want to purchase a Lotto ticket is uncomfortable...</p>
                  <a href="#0" class="blog-btn">read more</a>
                </div>
              </div>
            </div><!-- post-item end -->
            <div class="col-lg-4 col-md-6">
              <div class="post-item m-bottom-30">
                <div class="thumb">
                  <img src="images/blog/m5.jpg" alt="image">
                </div>
                <div class="content">
                  <h3 class="post-title"><a href="#0">A few tips on how to win the lotto online</a></h3>
                  <ul class="post-meta">
                    <li><a href="#0"><span>BY</span>David Millward</a></li>
                    <li><a href="#0"><i class="fa fa-calendar"></i>11 June 2018</a></li>
                  </ul>
                  <p>Do you play lotto regularly, yet, the necessity of visiting an outlet each time you want to purchase a Lotto ticket is uncomfortable...</p>
                  <a href="#0" class="blog-btn">read more</a>
                </div>
              </div>
            </div><!-- post-item end -->
            <div class="col-lg-4 col-md-6">
              <div class="post-item m-bottom-30">
                <div class="thumb">
                  <img src="images/blog/m6.jpg" alt="image">
                </div>
                <div class="content">
                  <h3 class="post-title"><a href="#0">What to avoid when you win the lotto</a></h3>
                  <ul class="post-meta">
                    <li><a href="#0"><span>BY</span>David Millward</a></li>
                    <li><a href="#0"><i class="fa fa-calendar"></i>11 June 2018</a></li>
                  </ul>
                  <p>Do you play lotto regularly, yet, the necessity of visiting an outlet each time you want to purchase a Lotto ticket is uncomfortable...</p>
                  <a href="#0" class="blog-btn">read more</a>
                </div>
              </div>
            </div><!-- post-item end -->
            <div class="col-lg-4 col-md-6">
              <div class="post-item m-bottom-30">
                <div class="thumb">
                  <img src="images/blog/m5.jpg" alt="image">
                </div>
                <div class="content">
                  <h3 class="post-title"><a href="#0">A few tips on how to win the lotto online</a></h3>
                  <ul class="post-meta">
                    <li><a href="#0"><span>BY</span>David Millward</a></li>
                    <li><a href="#0"><i class="fa fa-calendar"></i>11 June 2018</a></li>
                  </ul>
                  <p>Do you play lotto regularly, yet, the necessity of visiting an outlet each time you want to purchase a Lotto ticket is uncomfortable...</p>
                  <a href="#0" class="blog-btn">read more</a>
                </div>
              </div>
            </div><!-- post-item end -->
          </div>
          <div class="row">
            <div class="col-12">
              <nav class="d-pagination" aria-label="Page navigation example">
                <ul class="pagination justify-content-start">
                  <li class="page-item previous"><a href="#"><i class="fa fa-chevron-left"></i>prev</a></li>
                  <li class="page-item active"><a href="#">1</a></li>
                  <li class="page-item"><a href="#">2</a></li>
                  <li class="page-item"><a href="#">3</a></li>
                  <li class="page-item next"><a href="#">next<i class="fa fa-chevron-right"></i></a></li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>
  
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'Storie',
  components: {
  //  LoteryItem,
     //Carousel,
     //Login,
    
    },
    mounted(){
  
    },
  data(){
    return {

      /*img_banner: '/images/blog/b-1.jpg',
      items: [ 
          { name: 'Loteria-1', image: '/images/svg-icons/overview-icons/2.svg'}, 
          { name: 'Loteria-2', image: '/images/svg-icons/overview-icons/2.svg'}, 
          { name: 'Loteria-3', image: '/images/svg-icons/overview-icons/2.svg'},
          { name: 'Loteria-4', image: '/images/svg-icons/overview-icons/2.svg'}, 
          { name: 'Loteria-5', image: '/images/svg-icons/overview-icons/2.svg'}, 
          { name: 'Loteria-6', image: '/images/svg-icons/overview-icons/2.svg'}, 

      ],*/ 
       
    } 
  },
  methods: {
    ...mapActions({
        getProfile: "getProfile", 

    })
  }
}
</script>

<style>
.container .col-lg-8{
    margin-top: 40px;
}
</style>