<template>  
    <div class="container">  
        <div style="display:flex;flex-direction: column; align-items: center;"  > 
                
            <div style="margin-bottom: 10px;">
                <p class="styleTextTwoProfile" style="color:#fdad59"> Elige tu plan </p>
            </div>
            <div style="height:16px">

            </div>
            <div style="display:flex;flex-direction: row;" >
                <div style="position: relative; margin-right: 16px;" v-for="(item, index4) in paquetes" :key="index4"   v-bind:style="{background: colorGradiente(index4) }"    class="packagesSuscriptions cardSuscriptionProfile">
                        
                        <div style="  clip: rect(20px,124px,158px,0px);  position: absolute; right: -20px; top: -20px; font-size: 47px; z-index: 0;">
                            <div style=" font-size: 150px ; opacity: 0.2; color: white;  "><i class="fas fa-stars"></i></div>
                        </div>
                        <div> <p style="color: white; font-size: 18px; font-weight: 600;" > Suscripción de {{item.months}} meses </p> </div>
                        
                        <div>
                            <ul style="margin-left: 20px;">
                                <li style="margin-bottom: 5px; color: white; font-weight: 400; margin: 10px 0px;"> Paga {{item.price}} dólares por los {{item.months}} meses.</li> 
                            </ul>
                        </div> 
                        
                        <div style="display:flex; justify-content: flex-end;z-index: 1; position: relative;">
                            <div @click="showLogin(index4 )" class="botonPagarPaquete">
                                <div style=" color:white; font-size: 20px;  "><i class="fas fa-stars"></i></div>
                                <div v-if="index4 == 0" > <p style=" color:white"> $ 30 x Mes</p>  </div>
                                <div v-if="index4 != 0" > <p style=" color:white"> Ahorra un {{ (((((item.months/paquetes[0].months )* paquetes[0].price) -  item.price)*100)/((item.months/paquetes[0].months )* paquetes[0].price)).toFixed(2)  }} %</p>  </div>
                            </div>
                        </div> 
                    </div>  
            </div>
            <div style="height:16px">

            </div>
            <div class="jugarPageOptions tooltip-boton-limpiar" style="display: flex" v-on:click="iniciarSesion()">
 
                <div style="margin-left: 10px;"><p  class="styleTextOne">o Inicie sesión </p></div>
                
            </div>

        </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
 
export default {
    name: "ListPromotions",
    components: {
 
    },
    data() {
      return {
        
      }
    },
    computed: {
      ...mapState({
            paquetes: state =>state.promotions.paquetes
      }),
      
    },
    methods: {
      ...mapMutations({
         
      }),
      ...mapActions({
         listPromotion: 'listPromotion'
      }),
      colorGradiente( index ){
            return  'linear-gradient(to right, rgba( '+ this.paquetes[index].r +' ,'+ this.paquetes[index].g +','+ this.paquetes[index].b +',1),rgba('+ this.paquetes[index].r +','+ this.paquetes[index].g +','+ this.paquetes[index].b +',1), rgba('+ this.paquetes[index].r +','+ this.paquetes[index].g +','+ this.paquetes[index].b +',0.9),rgba('+ this.paquetes[index].r +','+ this.paquetes[index].g +','+ this.paquetes[index].b +',0.8))';
      },
      showLogin( promotion ) {
               location.href ='/register-client?promotion='+promotion;
      },
      iniciarSesion() { 
        // location.href ='/perfil?suscription=true';
        location.href ='/login-client';
      }
    },
    async mounted(){
        
    },
    async created(){   
        await this.listPromotion();  
    }
};
</script>

<style scoped>
.styleTextOne{
        margin: 0px 0 0px;
        font-size: 17px;
        font-weight: 600;
        /* color:#294977 */
        color:white
    }
.jugarPageOptions{
        cursor: pointer;
        background: #fdad59;
        text-align: center;
        margin-bottom: 8px  ;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 7px;
        box-shadow: 0px 0px 2px black;
        padding: 10px;
        transition: 0.3s;
        width: 225px;
    }
    .jugarPageOptions:hover {
          background: white;
          color:#fdad59
    }
    .jugarPageOptions:hover p {
          color: #fdad59  ;
    }
.botonPagarPaquete{
     display: flex;
     font-weight: 600; 
     width: max-content; 
     border-style: solid; 
     border-radius: 14px; 
     padding: 6px; 
     cursor: pointer;
     transition: 0.3s; 
}

.botonPagarPaquete:hover  { 
      background: white; 
}

.botonPagarPaquete:hover div{ 
      color: black !important ;  
}

.botonPagarPaquete:hover div p{ 
      color: black !important;  
}
    .cardSuscriptionProfile{ 
     width: 330px;  
     height: max-content; 
     border-radius: 10px;  
     box-shadow: 3px 3px 8px black; 
     padding: 10px;
     margin-bottom: 15px;
     transition: 0.3s;
}
.cardSuscriptionProfile:hover  { 
      transform: translateY(-2px);
}

 .styleTextTwoProfile{
        margin: 0px 0 0px;
        font-size: 20px;
        font-weight: 700;
    }

  p, address {
        margin: 0 0 0px;
        display: block;
    }
</style>