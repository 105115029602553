<template>
    <div class="container ">
        <b-form @submit="onSubmit" @reset="onReset" v-if="show">
        <b-form-group
            id="input-group-1"
            label="Email:"
            label-for="input-1"
            description="No compartiremos tu email con nadie" 
        >
            <b-form-input
            id="input-1"
            v-model="form.email"
            type="email"
            placeholder="Ingrese su email"
            required 
            class="inputSupport descriptionSupport"
            ></b-form-input>
        </b-form-group>

        <b-form-group id="input-group-2" label="Nombres:" label-for="input-2">
            <b-form-input
            id="input-2"
            v-model="form.name"
            placeholder="Ingrese su nombre"
            required
            class="inputSupport"
            ></b-form-input>
        </b-form-group> 
        
        <b-form-group id="input-group-2" label="Mensaje:" label-for="input-2"> 
            <b-form-textarea
                id="textarea-no-resize"
                v-model="form.message"
                placeholder="Su mensaje"
                rows="3"
                no-resize
                required
                class="inputSupport"
            ></b-form-textarea>
        </b-form-group>

        

        <b-button type="submit" variant="primary">Enviar ahora</b-button>
        <b-button type="reset" variant="danger">Limpiar</b-button>
        <!-- <div @click="onSubmit()" ><h3>Enviar ahora</h3></div>
        <div type="reset" variant="danger"><h3>Limpiar</h3></div> -->
        </b-form>
        <!-- <b-card class="mt-3" header="Form Data Result">
        <pre class="m-0">{{ form }}</pre>
        </b-card>   -->
    </div>
</template>

<script>

  import { mapActions, mapState } from 'vuex'
  import swal from 'sweetalert2'; 

export default { 
    name: 'SupportMain', 
    data(){
        return {
             form: {
            message: '',
            email: '',
            name: '',  
            },
            show: true
        }; 
    },
    mounted(){
        
    },
    computed: {
        ...mapState({
            costumer: state => state.costumer.costumer
        })
    },

    methods: {
        ...mapActions({
              createOneSupport: "createOneSupport",  
        }),
        async onSubmit(event) {
            event.preventDefault()
            // alert(JSON.stringify(this.form))
            await this.createOneSupport(this.form);
            swal.fire( 'Exito!', 'Mensaje enviado correctamente!', 'success')
            this.onReset();
        },
        onReset(event) {
            event.preventDefault()
            // Reset our form values
            this.form.email = ''
            this.form.message = ''
            this.form.name = ''  
            // Trick to reset/clear native browser form validation state
            this.show = false
            this.$nextTick(() => {
            this.show = true
            })
        }
        
    }
}
</script>

<style scoped>

    .inputSupport{
        border-radius: 10px !important;
    }
    .descriptionSupport{
        margin-bottom: 0px;
    }
</style>
