<template>
    <div>
        <div id="wrapper">
            <Header></Header>
            <div id="dashboard">
                <a href="#" class="dashboard-responsive-nav-trigger"><i class="fa fa-reorder"></i> Dashboard Navigation</a>
                <Sidebar></Sidebar>
                <div class="dashboard-content">
                    <router-view></router-view>
                  <Footer/>
                </div>
            </div>
        </div>

    </div> 
</template>

<script>
import Header from './include/Header'
import Sidebar from './include/Sidebar'
import Footer from './include/Footer'

export default {
    name: 'backend-main',
    components: {
        Header,
        Sidebar,
        Footer
    }
}
</script>

<style scoped>
    @import url('../../assets/backend/style.css');
    @media only screen and (max-width: 1024px) {
      #header-container.header-style-2 .right-side, .right-side {
        width: 100%;
        height: 83px;
      }
    }
    @media (min-width: 1025px) and (max-width: 1239px) {
      .right-side {
        margin-top: -42px !important;
      }
    }
    .mm-wrapper {
      overflow-y: hidden !important;
      position: relative;
    }
</style>


<style  >

#dashboard {
  background-color: white;
}

.title h1,h2,h3,h4,h5,h6 {
  padding: 0;
  margin: 0;
}
.title h1,h1>a,
.title h2,h2>a,
.title h3,h3>a,
.title h4,h4>a,
.title h5,h5>a,
.title h6,h6>a {
  line-height: 1.25;
  font-weight: 700;
  transition: all 0.3s;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  font-family: var(--heading-font);
  color: var(--heading-color);
  font-weight: 700;
}
.title h1 {
  font-size: 60px !important;
}
.title h2, h2>a {
  font-size: 52px !important;
}
.title h3, h3>a {
  font-size: 24px !important;
}
.title h4, h4>a {
  font-size: 22px !important;
}
/*.title h5, h5>a {
  font-size: 20px !important;
}*/
.title h6, h6>a {
  font-size: 18px !important;
}
/*
a {
  display: inline-block;
  font-family: var(--para-font);
  color: var(--para-color);
  font-weight: 700;
}*/
.title a,a:focus, a:hover {
  text-decoration: none;
  color: inherit;
}
.title p, span, li {
  margin: 0;
  font-family: var(--para-font);
  color: var(--para-color);
  line-height: 1.7;
  font-size: 16px;
}



.cmn-btn {
  font-size: 20px;
  text-transform: capitalize;
  background-color: var(--main-color);
  color: #ffffff;
  padding: 10px 26px;
  font-weight: 400;
  border-radius: 999px;
  -webkit-border-radius: 999px;
  -moz-border-radius: 999px;
  -ms-border-radius: 999px;
  -o-border-radius: 999px;
  transition: all 0.3s;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  font-size: 16px;
  text-transform: uppercase;
}
.cmn-btn:hover {
  box-shadow: 0 11px 15px 0 rgba(51, 181, 247, 0.45);
  color: #ffffff;
}
/* jackpot-section css start */
.jackpot-section .text-btn {
  margin-top: 42px;
}
.jackpot-item {
  padding: 30px 30px 10px 30px;
  background-color: #ffffff;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.15);
  border-bottom: 2px solid var(--main-color);
  transition: all 0.3s;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
}
.jackpot-item:hover {
  box-shadow: 1.5px 2.598px 17.22px 3.78px rgba(51, 181, 247, 0.21),-1.678px 2.487px 17.22px 3.78px rgba(51, 181, 247, 0.21);
}
.jackpot-item span {
  display: block;
}
.jackpot-item .amount {
  font-size: 28px;
  font-weight: 700;
  color: #ea2e49;
  margin-top: 15px;
}
.jackpot-item .title {
  font-weight: 500;
}
.jackpot-item .next-draw-time {
  margin-top: 25px;
}
.jackpot-item .next-draw-time span {
  display: inline-block;
}
.jackpot-item a {
  transform: translateY(30px);
  -webkit-transform: translateY(30px);
  -moz-transform: translateY(30px);
  -ms-transform: translateY(30px);
  -o-transform: translateY(30px);
}
/* jackpot-section css end */

/* lottery-result-section css start */
.lottery-result-section {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.lottery-winning-num-part {
  background-color: #ffffff;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.15);
  overflow: hidden;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}
.lottery-winning-num-table {
  position: relative;
}
.lottery-winning-num-table::before {
  position: absolute;
  content: '';
  bottom: -1px;
  left: 50%;
  transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  width: 318px;
  height: 294px;
}
.block-title {
  text-transform: capitalize;
  padding-top: 25px;
  padding-bottom: 17px;
  padding-left: 20px;
}
.lottery-winning-num-table th.name {
  width: 25%;
  max-width: 25%;
  padding-left: 20px;
}
.lottery-winning-num-table th.date {
  width: 25%;
  max-width: 25%;
  text-align: center;
}
.lottery-winning-num-table th.numbers {
  width: 50%;
  max-width: 50%;
  padding-right: 20px;
}
#table_result th {
  font-family: var(--heading-font);
  font-weight: 400;
  text-transform: capitalize;
  color: var(--heading-color);
  background-color: #f1f2f4;
  padding-top: 5px;
  padding-bottom: 5px;
}
#table_result tbody tr:nth-child(odd) {
  background-color: #ffffff;
}
#table_result tbody tr:nth-child(even) {
  background-color: #f1f2f4;
}
#table_result td {
  padding: 10px 0;
}
#table_result tbody tr {
  border-bottom: 1px solid #e7e9ed;
}
#table_result tbody tr:last-child {
  border-bottom: none;
}

#table_result tr td:first-child {
  padding-left: 20px;
}
.lottery-winning-num-table .winner-name {
  margin-left: 10px;
  font-size: 14px;
}
.lottery-winning-num-table .winning-date {
  display: block;
  text-align: center;
  font-size: 14px;
}
.number-list {
  margin: 0 -3px;
}
.number-list li {
  width: 30px;
  height: 30px;
  color: var(--heading-color);
  border: 1px solid #9fa1a7;
  font-size: 14px;
  text-align: center;
  line-height: 30px;
  display: inline-block;
  margin: 3px;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}
.number-list li.active {
  background-color: var(--main-color);
  border-color: var(--main-color);
  color: #ffffff;
  box-shadow: 0 10px 10px 0 rgba(51, 181, 247, 0.15);
}
.winner-part {
  background-color: #ffffff;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.15);
  overflow: hidden;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  padding: 0 20px;
}
.winner-list {
  border-top: 1px solid #e5e5e5;
  padding-bottom: 20px;
}
.winner-single {
  margin-top: 30px;
}
.winner-single .winner-header .name {
  font-family: var(--heading-font);
  font-weight: 500;
  color: var(--main-color);
  text-transform: capitalize;
  margin-left: 15px;
}
.winner-single p {
  margin-top: 6px;
}
.winner-single p span {
  position: relative;
}
.winner-single p span::before {
  position: absolute;
  content: ',';
  bottom: 0;
  right: -3px;
  color: var(--para-color);
}
.winner-single p span:last-child::before {
  display: none;
}
.winner-single p span+span {
  margin-left: 5px;
}
.winner-single .prize-amount {
  color: #02e32d;
  margin-top: 10px;
}
.lottery-result-section .text-btn {
  margin-top: 34px;
}
.latest-result-item {
  box-shadow: 0 0 5px 2px rgba(51, 181, 247, 0.15);
  overflow: hidden;
  margin: 15px 0;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  transition: all 0.3s;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
}
.latest-result-item:hover {
  box-shadow: 4px 6.928px 21px 0px rgba(51, 181, 247, 0.47);
}
.latest-result-item .icon {
  height: 105px;
}
.latest-result-item-header {
  padding: 20px 30px;
  border-bottom: 1px solid #f2f2f2;
}
.latest-result-item-body {
  padding: 30px 30px;
}
.next-jack-amount span {
  display: block;
}
.winner-num .number-list {
  margin-top: 5px;
}
.next-jack-amount .amount {
  font-family: var(--heading-font);
  font-weight: 700;
  color: var(--heading-color);
  font-size: 24px;
  margin-top: 5px;
}
.latest-result-item-footer {
  padding: 8px 0;
  background-color: var(--main-color);
  color: #ffffff;
}
.latest-result-item-footer i {
  margin-right: 10px;
  position: relative;
  -webkit-animation: mymove 5s infinite;
  animation: mymove 5s infinite;
}
@keyframes mymove {
  0% {
    transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
}
  25% {
    transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -o-transform: rotate(180deg);
}
  50% {
    transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
  }
  75% {
    transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -o-transform: rotate(180deg);
}
  100% {
    transform: rotate(0);
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
  }
}
.lottery-result-section button.cmn-btn {
  margin-top: 30px;
}
/* lottery-result-section css end */


/* online-ticket-section css start */
.online-ticket-section .jackpot-item {
  margin-bottom: 30px;
}
.online-ticket-section button.cmn-btn {
  margin-top: 30px;
}
/* online-ticket-section css end */

/* single-categories-play-section css start */
.single-cat-play-area {
  padding: 20px 20px;
  box-shadow: 0 0 16px 1px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}
.single-header .content {
  padding-left: 15px;
}
.single-header .content .title {
  font-size: 35px;
  font-weight: 500;
}
.single-header .content .amount {
  font-size: 24px;
  font-family: var(--heading-font);
  color: #3ccd0f;
  font-weight: 700;
}
.single-header .right .draw-days {
  color: #333745;
  opacity: 0.7;
}
.single-header .right button {
  color: #333745;
  background-color: transparent;
  border: 1px solid #efefef;
  transition: all 0.3s;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
}
.single-header .right button:hover {
  color: var(--main-color);
}
.single-header .right .header-btn-area {
  margin-top: 15px;
}
.single-header .right button+button {
  margin-left: 15px;
}
.single-header .right #quick-pick-all {
  padding: 10px 30px;
  border-radius: 999px;
  -webkit-border-radius: 999px;
  -moz-border-radius: 999px;
  -ms-border-radius: 999px;
  -o-border-radius: 999px;
}
.single-header .right #add-item,
.single-header .right #delete-item {
  width: 45px;
  height: 45px;
  font-size: 18px;
  text-align: center;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}
.single-body {
  margin-top: 30px;
}
.single-body-inner {
  margin: 0 -4px;
}
.play-card {
  -ms-flex: 0 0 20%;
  flex: 0 0 20%;
  max-width: 20%;
  position: relative;
  padding: 0 4px;
}
.close-play-card {
  position: absolute;
  top: -10px;
  right: 0;
  width: 30px;
  height: 30px;
  background-color: #ffffff;
  box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.15);
  color: #333745;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}
.play-card-inner {
  border: 1px solid #e8e8ea;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.15);
  overflow: hidden;
  border-radius: 15px;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  -ms-border-radius: 15px;
  -o-border-radius: 15px;
}
.play-card-header {
  padding: 15px 10px 10px 10px;
}
.play-card-header .number-amount {
  color: var(--main-color);
  font-style: italic;
}
.play-card-header .header-btn-area {
  margin-top: 10px;
}
.play-card-header .header-btn-area button {
  background-color: transparent;
  color: rgba(51, 55, 69, 0.8);
  text-transform: capitalize;
  padding: 3px 15px;
  border-radius: 999px;
  -webkit-border-radius: 999px;
  -moz-border-radius: 999px;
  -ms-border-radius: 999px;
  -o-border-radius: 999px;
}
.play-card-header .header-btn-area #quick-pick {
  border: 1px solid #b1eb9f;
}
.play-card-header .header-btn-area #clear-pick {
  border: 1px solid #ade1fc;
}
.play-card-body {
  padding: 10px 11px 10px 11px;
  border-top: 1px solid #ededed;
}
.play-card-body .number-list li {
  width: 26px;
  height: 26px;
  line-height: 28px;
  border: none;
  background-color: #f5f5f6;
  margin-top: 5px;
  margin-bottom: 5px;
  color: rgba(51, 55, 69, 0.8);
  cursor: pointer;
}
.play-card-body .number-list li.active {
  background-color: var(--main-color);
  color: #ffffff;
}
.play-card-body .add-more-text {
  color: var(--main-color);
  font-style: italic;
  margin-top: 5px;
  margin-bottom: 5px;
  display: inline-block;
}
.play-card-footer {
  background-color: var(--main-color);
  padding: 10px 20px;
}
.play-card-footer .play-card-footer-text {
  text-transform: capitalize;
  color: #ffffff;
}
.play-card-footer .selected-numbers span {
  color: #ffffff;
  position: relative;
}
.play-card-footer .selected-numbers span::before {
  position: absolute;
  content: ',';
  bottom: 0;
  right: -4px;
  color: #ffffff;
}
.play-card-footer .selected-numbers span:last-child::before {
  display: none;
}
.single-footer {
  padding: 30px 0 10px 0;
}
.single-footer .left {
  -ms-flex: 0 0 35%;
  flex: 0 0 35%;
  max-width: 35%;
}
.single-footer .left .progress-bar {
  width: 50%;
  background-color: var(--main-color);
}
.single-footer .right {
  -ms-flex: 0 0 42%;
  flex: 0 0 42%;
  max-width: 42%;
}
.single-footer .right .content p span {
  color: rgba(51, 55, 69, 0.7);
}
.single-footer .right .content p span.amount {
  font-weight: 500;
  color: var(--main-color);
}
.single-footer .right .card-cart-btn-area .single-cart-btn {
  vertical-align: middle;
  padding: 0 20px;
  font-weight: 400;
  text-transform: uppercase;
  color: #ffffff;
  border-radius: 999px;
  -webkit-border-radius: 999px;
  -moz-border-radius: 999px;
  -ms-border-radius: 999px;
  -o-border-radius: 999px;
}
.single-footer .right .card-cart-btn-area .single-cart-btn .single-cart-amount {
  color: #ffffff;
  border-right: 1px solid #91daf0;
  padding: 10px 10px 10px 0;
  margin-right: 10px;
}
 

</style>