import supportAPI from '../../api/support' 
import Vue from 'vue'
import VueCookie from 'vue-cookie'
 
Vue.use(VueCookie);

const state = { 
    vprocartillas : [],
    loterie:true
    
}
const getters = { 

}

const actions = { 
    async createOneSupport({ commit }, { ...searchParams }) { 
        const { status  } = await supportAPI.createOneSupport(searchParams)
        if (status === 200)  commit('CREATE_FORECAST', true) 
    },   
}

const mutations = {
    CREATE_FORECAST(state, val) {
        state.loterie = val
    },
   
}
export default {
    actions,
    mutations,
    getters,
    state
}