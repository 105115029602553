<template>
    <div>
        <b-carousel
            id="carousel-fade"
            style="text-shadow: 0px 0px 2px #000"
            fade
            controls
            indicators
            img-width="1024"
            img-height="480"
            @sliding-start="onSlideStart"
            @sliding-end="onSlideEnd">
            <b-carousel-slide #img>
                <template>
                    <img
                        class="d-block img-fluid w-100"
                        width="1024"
                        height="480"
                        src="/uploads/img-blue.png"
                        alt="image slot" >
                    <div>
                        <img src="/uploads/img-persons.png" alt="" style="position: absolute; left: 15%; top: 45%; width: 200px;">
                        <div class="animate center">
                            <div id = "cloud">
                                <div class="txt-position">Sabias que?, la probabilidad de acertar 5 numeros de 69 es de 1 a en 292 201 338.</div>
                                <button class='btn-position'> >> </button>
                            </div>
                        </div>
                        <div>
                            <div class='cloud-circle-one'> </div>
                            <div class='cloud-circle-two'> </div>
                        </div>
                    </div>
                  <div>
                    <img src="/uploads/img-person-two.png" alt="" style="position: absolute; left: 75%; top: 50%; width: 170px;">
                    <div class="animate center-two">
                      <div id = "cloud-bubble">
                        <div class="txt-position-bubble">Te ofresco mediante la App la posibilidad de reducir hasta un 40%. es decir eliges 5 de 36 aproximadamente. Y tienes mayor chance al gordo</div>
                        <button class='btn-position-bubble'>Descubre como Aqui!!!</button>
                      </div>
                    </div>
                  </div>
                </template>
            </b-carousel-slide>
            <b-carousel-slide #img>
                  <template>
                      <img class="d-block img-fluid w-100" width="1024" height="480" src="/uploads/img-blue.png" alt="image ">
                      <div style="display: flex;flex-direction: row; justify-content: center; align-content: center;align-items: center; top: 25%; position: absolute; width: 100%;">
                          <div style="width: 40%; text-align: center; display: flex;flex-direction: column; align-items: center">
                               <div>
                                   Reduce hasta un 40% aproximadamente!!!
                               </div>
                              <img src="/uploads/logo.png" alt="Lotery" title="Lotery" class="normal-logo" width="200px" style="margin-top:20px">
                              <a  style="margin-top: 20px; animation: 3s ease-in 1s infinite reverse both running slidein;" href="#section-plans">Elige tu Plan</a>
                          </div>
                          <div style="width: 40%; text-align: center">
                              <img src="/uploads/equation.png" alt="Lotery" title="Lotery"    style="margin-top:20px">
                          </div>
                      </div>
                  </template>
              </b-carousel-slide>

              </b-carousel>

        <section id='section-plans' style="height: 600px; display: flex; justify-content: center; justify-items: center; align-items: center; align-content: center;">
          <ListPromotions></ListPromotions>
        </section>
    </div>
  </template>



  <script>
  import ListPromotions from "@/components/frontend/Promotions/ListPromotions";
  export default {
      name: 'HomeAnimation',
      components: {
        ListPromotions
      },
      data(){
          return {
              slide: 0,
              sliding: null
          }
      },
      mounted() {

      },
      methods: {

          onSlideStart() {
              this.sliding = true
          },
          onSlideEnd() {
              this.sliding = false
          }
      },
      computed: {

      }
  }

  </script>

  <style   >
  .img-fluid {
      object-fit: cover;
      max-width: 100%;
      height: 90vh;
  }

  .cloud-circle-one {

      background: rgba(255, 255, 255);
      position: absolute;
    width: 80px;
    height: 30px;
    border-radius: 40%;
    top: 50%;
    left: 25%;
      box-shadow: 5px 5px rgba(0,0,0,0.2);
  }
  .cloud-circle-two {
      background: rgba(255, 255, 255);
    position: absolute;
    width: 60px;
    height: 25px;
    border-radius: 40%;
    top: 54%;
    left: 22%;
      box-shadow: 5px 5px rgba(0,0,0,0.2);
  }
  .animate.center {
      position: absolute;
      top: 40%;
      left: 36%;
      transform: translate(-50%,-50%);
  }
  .animate.center-two {
    position: absolute;
    top: 40%;
    left: 65%;
    transform: translate(-50%,-50%);
  }
  .btn-position-bubble {
      width: max-content;
      position: absolute;
      z-index: 9;
      top: 65%;
      left: 35%;
      border-color: #b68c64 !important;
      background: #b68c64 !important;
      border: 1px solid #b68c64 !important;
      color: white !important;
  }
  .btn-position {
      position: absolute;
      z-index: 9;
      top: 60%;
      left: 65%;
  }
  .txt-position {
      position: absolute;
      z-index: 9;
      text-align: center;
      padding: 20px 80px 40px  80px;
      top: -10%;
      left: 5%;
  }
  .txt-position-bubble {
      position: absolute;
      z-index: 9;
      text-align: center;
      padding: 10px 20px 10px 20px;
      top: 10%;
  }
  #cloud {
      background: rgba(255, 255, 255);
      width: 400px;
      height: 150px;
      border-radius: 150px;
      box-shadow: 10px 10px rgba(0,0,0,0.2);
      animation: move 3s infinite;
  }
  #cloud-bubble {
      background: rgba(255, 255, 255);
    width: 500px;
    height: 170px;
      border-radius: 150px;
      box-shadow: 10px 10px rgba(0,0,0,0.2);

  }



  #cloud-bubble::before {
    content: '';
    background: rgba(255, 255, 255);
    position: fixed;
    width: 50px;
    z-index: 9;
    padding: 60px;
    height: 100px;
    transform: rotate(185deg);
    border-radius: 0;
    top: 50px;
    left: 350px;
  }

  #cloud:after {
      content: '';
      background: rgba(255, 255, 255);
      position: fixed;
      width: 200px;
      height: 200px;
      border-radius: 50%;
      top: -60px;
      left: 60px;
  }

  #cloud:before {
    content: '';
    background: rgba(255, 255, 255);
    position: absolute;
    width: 200px;
    z-index: 9;
    padding: 60px;
    height: 170px;
    border-radius: 50%;
    top: -90px;
    right: 50px;
  }


  </style>