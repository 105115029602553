import lottoAPI from '../../api/lotto'
import analyticAPI from '../../api/analytic'
const state = { 
    ballAppearance: [0], 
    items: [],
    more: [],
    less: [],
    moda: [],
    indicators: {},
    date_result: {},
    triomore: [],
    pairless: [],
    pairmore: [],
    cartillaTopLength: 69,
    cartillaBottomLength: 26,
    loterie: {},
    appearences: [],
    cartilla: [],
    items_range: []
}
const actions = {
    async fetchTop({ commit }) {
        const { status, data: data } = await lottoAPI.fetchTop();
        if (status === 200) commit("FETCH_TOP", data)
    },
    // async cycle({ commit }, { ...searchParams }) {
    //     const { status, data: data } = await analyticAPI.findCiclo(searchParams);
    //     if (status === 200) {
    //         commit("APPEARENCES", data)
    //     }
    // },
    async triosFrequency({ commit }, { ...searchParams }) {
        const { status, data: data } = await analyticAPI.findMoreTrio(searchParams);
        if (status === 200) {
            commit("APPEARANCES_TRIO", data)
        }
    },
    async changeIndicators({commit}, {...searchParams}) {
        commit("INDICATORS", searchParams)
    },
    async sendDateResult({ commit }, { ...searchParams }) {
        commit("DATE_RESULT", searchParams)
    },
    async rangeAnalysisDetail({ commit }, { ...searchParams }) {
        const { status, data: data } = await lottoAPI.rangeLastDetail(searchParams)
        if (status === 200) {
            commit("FETCH_RANGE", data)
        }
    },
    async rangeAnalysis({ commit }, { ...searchParams }) {
        const { status, data: data } = await lottoAPI.rangeLast(searchParams)
        if (status === 200) {
            commit("FETCH_TOP", data)
        }
    },
    async loterieChange(name, data) {
        let __;
        switch (name) {
            case 'powerballs':
                __ = data.lastPowerballs
                break;
            case 'megamillions':
                __ = data.lastMegamillions

                break;
            case 'floridas':
                __ = data.lastFlorida

                break;
            case 'tinkas':
                __ = data.lastTinkas

                break;
            default:
                break;
        }
        return __
    },
    showCartilla({ commit }, { ...searchParams }) {
        commit("CARTILLA", searchParams)
    }
}

const getters = {

}

const mutations = {
    INDICATORS(state, val){
      state.indicators = val
    },
    DATE_RESULT(state, val) {
        state.date_result = val;
    },
    CARTILLA_TOP_LENGTH(state, val) {
        state.cartillaTopLength = val
    },
    CARTILLA_BOTTOM_LENGTH(state, val) {
        state.cartillaBottomLength = val
    },
    LOTERIE(state, val) {
        state.lottery = val
    },
    FETCH_TOP(state, data) {
        state.items = []
        state.items.push(...data);
    },
    FETCH_RANGE(state, data) {
        state.items_range = []
        state.items_range.push(...data)
    },
    LESS_FREQUENT(state, data) {
        state.less = []
        state.less.push(...data)
    },
    MORE_FREQUENT(state, data) {
        state.more = []
        state.more.push(...data)
    },
    PAIR_MORE_FREQUENT(state, data) {
        state.pairmore = []
        state.pairmore.push(...data)
    },
    PAIR_LESS_FREQUENT(state, data) {
        state.pairless = []
        state.pairless.push(...data)
    },
    APPEARENCES(state, data) {
        state.appearences = []
        state.appearences.push(...data)
    },
    APPEARANCES_TRIO(state, data) {
        state.triomore = []
        state.triomore.push(...data)
    },
    MODA(state, data) {
        state.moda = []
        state.moda.push(...data)
    },
    CARTILLA(state, data) {
        state.cartilla = []
        state.cartilla.push(data)
    },
    BALL_APPEARANCE(state, data) { 
        state.ballAppearance = []  
        state.ballAppearance.push(data)  
    },
    BALL_APPEARANCE_PRONOSTICO(state, data) { 
        state.ballAppearance = []  
        state.ballAppearance.push(data)  
    },
    
}

export default {
    state,
    actions,
    getters,
    mutations
}
