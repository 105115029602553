<template>
  <tbody class="ui-sortable">
    <tr :key="i" v-for="(prize, i) in prizes" class="pricing-list-item pattern ui-sortable-handle">
      <td v-if="parseInt(type_result) === 0">
            <div class="fm-move" style="text-align: left">
              <i class="sl sl-icon-cursor-move" style="opacity: 0;"></i>
            </div>
            <div class="fm-input">
              <input type="text" class="form-control" placeholder="Nivel"  v-model="prize.prize_level" >
            </div>
            <div class="fm-input">
              <input type="text" placeholder="Ganadores*"  v-model="prize.lottery_winner" >
            </div>
            <div class="fm-input">
              <input type="number" @keyup.enter="updatePlayPrize(i, prize.lottery_prize, enhancer)" placeholder="Premio" v-model="prize.lottery_prize"  >
            </div>
            <div class="fm-input">
              <input type="text" placeholder="Ganadores*" v-model="prize.play_winner" >
            </div>
            <div class="fm-input">
              <input type="text" placeholder="Premio*" v-model="prize.play_prize"  >
            </div>
            <div class="fm-close">
              <a class="delete">
                <i class="fa fa-remove" @click="remove(prize,i)"></i>
              </a>
            </div>
        </td>
        <td v-if="parseInt(type_result) === 1 " >
            <div class="fm-move" style="text-align: left">
              <i class="sl sl-icon-cursor-move" style="opacity: 0;"></i>
            </div>
            <div class="fm-input" >
              <input type="text" placeholder="Nivel"  v-model="prize.prize_level"  >
            </div>
            <div class="fm-input">
              <input type="text" placeholder="1"  v-model="prize.lottery_winner" >
            </div>
            <div class="fm-input">
              <input type="text" placeholder="222.000.000" v-model="prize.lottery_prize" >
            </div>
            <div class="fm-close">
              <a class="delete">
                <i class="fa fa-remove" @click="remove(prize,i)"></i>
              </a>
            </div>
        </td>
    </tr>
  </tbody>
</template>

<script>
import {mapActions} from "vuex";
export default {
  name: "item",
  data(){
    return {
      detail: []
    }
  },
  props: {
    prizes: Array,
    type: String,
    type_result: Number,
    enhancer: Number,
    lottery_name: String
  },
  methods: {
    ...mapActions({
      deletePrizeLevel: 'deletePrizeLevel',
      deletePrizeLevelCreate: 'deletePrizeLevelCreate'
    }),
    mounted(){
      this.detail = this.prizes.sort((a,b) => {return a.position - b.position;})
    },
    updatePlayPrize(i, lottery_prize, enhancer) {
      if (enhancer) {
        this.prizes[i].play_prize = lottery_prize *  enhancer ;
      }
    },
    remove(item, i){
      if (this.type === 'create') {
        this.deletePrizeLevelCreate(i)
      } else {
         this.deletePrizeLevel(item,i)
      }
    }
  }
}
</script>
<style scoped>
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }
input {
  background: white !important;
  padding: 0 15px !important;
  margin:  0 5px 5px 0 !important;
}
.item-prize {
  padding-bottom: 0;
  padding-top: 5px;
}
td, th {
  padding: 0 !important;
  border-color: white !important;
}
</style>