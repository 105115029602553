import ApiClient from '../source/Api'
  
const forecast =  {  
    // createForecast(searchParams) { 
    //     return ApiClient().post(`/checkouts/create`, searchParams)
    // }, 
    getLoterieForecast(searchParams) {   
        return ApiClient().get(`/forecasts/forecast?id=${searchParams.id}&name=${searchParams.name}&type=${searchParams.type}`)
    },
    getForecastCard({date, type, name}) {
        return ApiClient().get(`/forecasts/forecast_for_card?date=${date}&type=${type}&name=${name}`)
    },
    getForecastResult(searchParams) {
        return ApiClient().get(`/forecasts/forecast_result?id=${searchParams.id}&name=${searchParams.name}&type=${searchParams.type}`)
    },
    getSuggestionForecast(searchParams) { 
        return ApiClient().post(`/forecasts/posible-play`, searchParams) 
    },
    findCicleForecast(searchParams) {
        return ApiClient().get(`/analytic/cycle-forecast?id=${searchParams.id}&name=${searchParams.name}`)
    },
    list({id: id, limit: limit, name: name, type: type}) {
        return ApiClient().get(`forecasts/list?id=${id}&name=${name}&limit=${limit}&type=${type}`)
    },
    list_range_forecast({id: id, name: name, type: type, from: from, to: to}) {
        return ApiClient().get(`forecasts/list-range?id=${id}&name=${name}&type=${type}&from=${from}&to=${to}`)
    }
}

export default forecast; 